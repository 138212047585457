import { FC } from 'react'

interface props {
  progress: number
}

const Progress: FC<props> = ({ progress = 0 }) => {
  const strokeDasharray = Math.PI * 22.5 * 2
  const strokeDashoffset = strokeDasharray * (1 - progress)
  return (
    <div className="relative w-min h-min">
      <svg width={48} height={48} viewBox="0 0 48 48" style={{ rotate: '-90deg' }} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="22.5" className="stroke-primary-50" strokeWidth={3} />
        <circle cx="24" cy="24" r="22.5" className="transition-all stroke-primary-600" strokeWidth={3} strokeLinecap="round" strokeDasharray={`${strokeDasharray} ${strokeDasharray}`} strokeDashoffset={strokeDashoffset} />
      </svg>
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary-600 font-semibold text-[0.75rem]">
        {Math.round(progress * 100)}%
      </div>
    </div>
  )
}

export default Progress
