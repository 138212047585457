import api from 'api'
import AreYouSureModal from 'components/AreYouSureModal'
import IconButton from 'components/buttons/IconButton'
import Loader from 'components/loaders/Loader'
import useAwaitableModal from 'hooks/useAwaitableModal'
import { FC, useState } from 'react'
import { IPaymentStructure } from 'types/billing'

interface props {
  structure: IPaymentStructure
  onEdit: () => void
  onDelete: () => void
}

const PaymentStructure: FC<props> = ({ structure, onEdit, onDelete }) => {
  const [removing, setRemoving] = useState(false)
  const [areYouSure, AreYouSureComponent] = useAwaitableModal(AreYouSureModal, {} as any)

  const onRemove = () => {
    if (removing) return
    setRemoving(true)
    areYouSure({text: "Are you sure you want to delete this payment structure?"}).then(() => api.removePaymentStructure(structure.id).then(onDelete))
    .finally(() => setRemoving(false))
  }
  
  return (
    <tr className="cursor-pointer hover:opacity-[0.98]">
      <AreYouSureComponent />
      <td>{structure.identifier}</td>
      <td className="border-l">{(new Date(structure.created_at)).toLocaleDateString()}</td>
      <td className="border-l">
        <div className="flex items-center gap-2 -my-2">
          {removing ? 
            <Loader size={24} /> : 
            <>
            <IconButton name="edit-2" onClick={onEdit} />
            <IconButton name="trash" className="!fill-red-500" onClick={onRemove} />
            </>
            }
        </div>
        </td>
    </tr>
  )
}

export default PaymentStructure
