import AuthenticatedImage from 'components/AuthenticatedImage'
import { FC } from 'react'
import { IMessage } from 'types/chats'
import { isToday } from "date-fns"

const ChatBubble: FC<IMessage & { mine: boolean }> = ({ id, content, createdAt, mine, type }) => {
  const dateString = isToday(new Date(createdAt)) ? new Date(createdAt).toLocaleTimeString() : new Date(createdAt).toLocaleString()
  return (
    <div className="w-full flex px-2 relative group items-center py-1" style={{ justifyContent: 'flex-end', flexDirection: mine ? 'row' : 'row-reverse' }}>
      <p className="text-xs text-base-900 group-hover:block hidden mx-2">{dateString}</p>
      <p className={mine ? 'bg-white text-base-900 p-3 rounded-b-xl rounded-tl-xl border border-base-200 overflow-hidden' : 'bg-base-100 text-base-900 px-2 py-1 rounded-b-xl rounded-tr-xl overflow-hidden'}>
        {type !== 'image' ? content : <AuthenticatedImage className="w-[200px] h-auto -m-3 cursor-pointer" src={content} openOnClick />}
      </p>
    </div>
  )
}

export default ChatBubble
