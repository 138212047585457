import useAwaitableModal from 'hooks/useAwaitableModal'
import { FC } from 'react'
import MarkReadyModal from './modal'
import Icon from 'components/Icon'
import { useOrder } from 'contexts/Order'

const MarkReadyAction: FC = () => {
    const {order: {id, contact}, setOrder} = useOrder()
  const [request, Element] = useAwaitableModal(MarkReadyModal, { orderId: id, contact })

  return (
    <>
      <Element />
      <div className="action-wrapper">
        <Icon name="box-1" />
        <p className='grow'>Send to Prep Center</p>
        <button className="button-secondary -my-2" onClick={() => request().then(() => setOrder(old => old && ({...old, state: "SENT" })))}>Send</button>
      </div>
    </>
  )
}

export default MarkReadyAction
