import FullLoader from 'components/loaders/FullLoader'
import {FC} from 'react'
import Empty from 'components/Empty'
import MarkReadyAction from './actions/MarkReady'
import Boxes from './Boxes'
import { useOrder } from 'contexts/Order'

interface props {
  loading?: boolean
  isPrepCenter: boolean
}

const OrderActions: FC<props> = ({ loading, isPrepCenter }) => {
  const {order} = useOrder()
  const actions = order.bill ? [] : [
    order.state !== 'IN_PROGRESS' || isPrepCenter ? undefined : <MarkReadyAction />,
  ]
  const count = actions.filter(Boolean).length

  if (!['IN_PROGRESS', 'SENT', 'AT_PREP_CENTER'].includes(order.state) && isPrepCenter) return <Boxes />

  return (
    <section className="grow min-w-[500px] bg-white box h-[245px] overflow-hidden relative">
      <header>Actions Required</header>
      <div className="flex flex-col w-full overflow-y-auto h-[188px]">
        {loading ? (
          <div className="h-[185px]">
            <FullLoader />
          </div>
        ) : count ? (
          actions.map((action) => (
            action !== null ? <div key={Math.random()}>{action}</div> : null
          ))
        ) : (
          <div className="h-[185px] items-center flex flex-col justify-center">
            <Empty text="No Actions to take" />
          </div>
        )}
      </div>
    </section>
  )
}

export default OrderActions
