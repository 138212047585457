import Icon from 'components/Icon'
import { FC } from 'react'

const SellerFeaturesPage: FC = () => {
  return (
    <div className="flex flex-col">
      <div className="flex w-screen py-32 px-32 bg-black-orange items-center">
        <Icon type="bulk" name="speedometer" className="fill-orange-500 h-full grow shrink blur-[256px]" />
        <div className="flex flex-col w-max">
          <span className="text-white font-normal text-[4rem] leading-[6rem] mb-2 text-center">
            For <span className="text-orange-500">Seller</span>
          </span>
          <span className="text-white-700 text-base text-center mb-16">Managing an Amazon Business just got a whole lot easier.</span>
          <a className="button-orange" href="#getstarted">
            Get Started Today
          </a>
        </div>
        <Icon type="bulk" name="speedometer" className="fill-orange-500 h-full grow shrink blur-[256px]" />
      </div>
      <div className="flex w-screen overflow-hidden relative w-screen justify-between">
        <div className="w-[624px] flex flex-col shrink-0 mr-16 px-24 ml-32 pt-[248px] border-l-[1.5px] border-l-orange-500 justify-center">
          <span className="font-normal text-[2.5rem] leading-[3.75rem] text-white mb-8">
            <span className="text-orange-500">See</span>
            <br /> The Stages
          </span>
          <span className="text-white font-normal text-base">Never miss a beat on what is going on with your inventory. Seamlessly communicate and handle your shipments from beginning to end.</span>
        </div>
        <div className="hidden lg:flex">
          <img src={require('assets/images/promo/seller/status.png')} className="w-full !aspect-square object-contain object-top" />
        </div>
      </div>
      <div className="flex flex-row-reverse w-screen overflow-hidden relative w-screen justify-between">
        <div className="w-[624px] flex flex-col shrink-0 ml-16 px-24 mr-32 pt-[248px] border-r-[1.5px] border-r-orange-500 justify-center">
          <span className="font-normal text-[2.5rem] leading-[3.75rem] text-white mb-8">
            <span className="text-orange-500">Fast</span>
            <br /> and Efficient
          </span>
          <span className="text-white font-normal text-base">
            Streamline your amazon operations like never before. Focus on the task of highest leverage that result in more dollars coming your way and leave the rest to us.
          </span>
        </div>
        <div className="hidden lg:flex">
          <img src={require('assets/images/promo/seller/order.png')} className="w-full !aspect-square object-contain object-right-top" />
        </div>
      </div>
      <div className="p-32 flex" id="getstarted">
        <div className="bg-white-50 p-16 rounded-xl grow flex flex-col items-center relative">
          <Icon type="bulk" preserveAspectRatio="none" name="speedometer" className="absolute w-64 top-0 object-cover -left-48 fill-orange-500 h-full grow shrink blur-[256px] z-10" />
          <Icon type="bulk" preserveAspectRatio="none" name="speedometer" className="absolute w-64 top-0 -right-48 fill-orange-500 h-full grow shrink blur-[256px] z-10" />
          <span className="font-normal text-[2.5rem] leading-[3.75rem] text-white text-center">
            <span className="text-orange-500">You Grow</span> or You Don't Pay
          </span>
          <span className="text-white-700 text-base text-center mb-16">We are here to provide an unbeatable value.</span>
          <button className="button-orange w-max" onClick={() => window.open('https://calendly.com/apex-management', '_blank')}>
            Book a Setup Call
          </button>
        </div>
      </div>
      <div className="px-32 flex flex-col items-center w-full gap-8">
        <div className="rounded-xl bg-white-50 p-8 w-[560px]">
          <span className="font-medium text-2xl text-white">How It Works</span>
        </div>
        <img className="w-[560px]" src={require('assets/images/promo/seller/howitworks.png')} />
        <img className="w-[560px]" src={require('assets/images/promo/seller/designedfor.png')} />
      </div>
      <div className="flex w-screen py-32 px-32 bg-black-orange justify-center relative">
        <Icon type="bulk" name="speedometer" preserveAspectRatio="none" className="fill-orange-500 blur-[256px] pointer-events-none top-0 h-full w-full absolute -left-1/2 scale-x-[0.5]" />
        <div className="flex flex-col w-full bg-white-50 rounded-xl p-16 gap-24 z-10">
          <div className='flex items-start gap-16'>
            <Icon  size={64} name="quote-up" className="fill-orange-500 shrink-0" />
            <p className="text-2xl font-normal text-white">
              Apex Logistics has completely transformed our prep center and left our clients delighted! With its advanced features and seamless functionality, we have experienced remarkable
              improvements in our operations and client satisfaction.
              <br />
              <br />
              By adopting Apex Logistics, our tasks have become more efficient, saving us valuable time and resources. The software's streamlined processes and accurate billing have eliminated errors
              and accelerated payments, boosting our bottom line.
              <br />
              <br />
              But the benefits extend beyond our own operations. Our clients have experienced enhanced convenience and transparency, thanks to Apex Logistics' intuitive tracking, real-time updates,
              and comprehensive reporting. It has empowered them to run their businesses more smoothly and make informed decisions.
              <br />
              <br />
              Apex Logistics has truly elevated our prep center to new heights, revolutionizing our workflow and fostering stronger relationships with our clients.
            </p>
          </div>
          <div className="flex items-center gap-8">
            <img src={require('assets/images/promo/seller/tony.png')} className="h-24 w-24" />
            <div className='gap-2'>
            <p className="text-2xl font-normal text-white">
              Sam
            </p>
            <p className='text-base font-medium text-white-700'>New York Prep Center Owner</p>
            </div>
          </div>
        </div>
        <Icon type="bulk" name="speedometer" preserveAspectRatio="none" className="fill-orange-500 blur-[256px] pointer-events-none top-0 h-full w-full absolute -right-1/2 scale-x-[0.5]" />
      </div>
    </div>
  )
}

export default SellerFeaturesPage
