import { FC } from 'react'

interface props {
  rows?: number
  cols?: number
}

const TableSpanner: FC<React.PropsWithChildren<props>> = ({ rows = 1, cols = 1, children }) => {

  return (
        <tr>
            <td colSpan={cols} style={{ height: rows * 64 }}>{children}</td>
        </tr>
  )
}

export default TableSpanner
