import { FC } from "react";

const Switch: FC<{checked: boolean}> = ({checked}) => {
    return (
        <div className={["relative p-0.5 w-10 transition-all rounded-full", checked ? "bg-primary-100" : "bg-base-50"].asClass}>
            <div className={["w-4 h-4 rounded-full transition-all", checked ? "bg-primary-600 translate-x-5" : "bg-base-500"].asClass} />
        </div>
    )
}

export default Switch