import { FC } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import useUserContext from '../../contexts/User/useUserContext'
import Header from 'components/layout/Header'
import Icon from 'components/Icon'
import IconButton from 'components/buttons/IconButton'
import useAuthContext from 'contexts/Auth/useAuthContext'
import useAgencyContext from 'contexts/Agency/useAgencyContext'
import { SettingsIcons } from 'assets/icons'

const InventoryLayout: FC = () => {
  const user = useUserContext()
  const location = useLocation()
  const navigate = useNavigate()
  const { isAgency } = useAgencyContext()

  if (location.pathname === '/app/settings') {
    if (user?.isPrepCenter) {
      return <Navigate to="/app/settings/basic" replace />
    } else {
      return <Navigate to="/app/settings/amazon" replace />
    }
  }

  const isActive = (path: string, eq?: boolean) => eq ? location.pathname === path : location.pathname.startsWith(path)

  const activeClass = 'color-primary-500 border-b-2 border-primary-500 text-primary-500'

  const inactiveClass = 'color-base-500 hover:color-primary-500 border-b border-base-200 text-base-500 hover:text-primary-500'

  const prepCenterSettings = [
    {
      name: 'Inventory',
      path: '',
      icon: SettingsIcons.Building,
      eq: true,
    },
    {
      name: 'Merchants',
      path: '/catalog',
      icon: SettingsIcons.Hamburger,
      eq: false,
    },
  ] as const

  const sellerSettings = [] as const

  const settings = user?.isPrepCenter ? prepCenterSettings : sellerSettings

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header title="Inventory" />
      <div className="grow flex flex-col overflow-hidden gap-6">
        <header className="flex items-center justify-between">
          <span>Inventory</span>
          <div id="inventoryButtonsAnchor" className="flex items-center gap-4" />
        </header>
        {!!settings.length && (
          <div className="border-t border-t-base-200 flex items-center justify-start mx-6 -mt-6">
            {settings.map((route) => (
              <div
                style={{ height: '-webkit-fill-available' }}
                className={['flex items-center p-5 items-center cursor-pointer transition-colors box-content', isActive(`/app/inventory${route.path}`, route.eq) ? activeClass : inactiveClass].asClass}
                onClick={() => navigate(`/app/inventory${route.path}`, { replace: true })}
              >
                <route.icon className="w-6 h-6 color-inherit" />
                <span className="font-medium grow text-base w-max pl-4 pr-2">{route.name}</span>
              </div>
            ))}
            <div className="grow border-b border-b-base-200 h-full" />
          </div>
        )}
        <div className={["flex grow px-6 pb-6 xl:gap-6 space-x-0 overflow-hidden relative", !settings.length && "-mt-6"].asClass}>
          <div className="w-full flex flex-col h-full relative space-y-6 bg-base-100 rounded-lg">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InventoryLayout
