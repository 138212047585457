import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { IInvoiceListed } from 'types/billing'

interface props {
  invoice: IInvoiceListed
}

const SellerInvoice: FC<props> = ({ invoice }) => {
  const navigate = useNavigate()
  return (
    <tr className="cursor-pointer hover:opacity-90" onClick={() => navigate("./invoice/"+invoice.id, {relative: "path"})}>
      <td>{invoice.customerEmail}</td>
      <td>{new Date(invoice.created * 1000).toLocaleDateString()}</td>
      <td>{invoice.title}</td>
      <td>{(invoice.total / 100).toFixed(2)}$</td>
      <td>
        <span className={["tag", invoice.status === "paid" ? "tag-green" : "tag-red"].asClass}>{invoice.status === "paid" ? "Paid" : "Not Yet Paid"}</span>
      </td>
    </tr>
  )
}

export default SellerInvoice
