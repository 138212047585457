import { createContext } from "react";

type IActAsContext = {
    isAgency: false;
    actAs?: undefined;
    setActAs?: undefined;
    actAsOptions?: undefined;
} | {
    isAgency: true;
    actAs: string | null;
    setActAs: (arg: string | null) => void;
    actAsOptions: Record<string, string>;
}

const AgencyContext = createContext<IActAsContext>({isAgency: false})

export default AgencyContext