import Filter from 'components/Filter'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import useFilter from 'hooks/useFilter'
import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import Order from './components/Order'
import useUserContext from 'contexts/User/useUserContext'
import usePages from 'hooks/usePages'
import TableSpanner from 'components/TableSpanner'
import Empty from 'components/Empty'
import Paginator from 'components/Paginator'
import useSelectedSeller from 'hooks/useSelectedSeller'
import ordersApi from 'api/orders'
import { IOrder } from 'types/orders'

const defaultFilterOptions = (isPrep: boolean) => [
  {
    name: 'direction',
    title: 'Sort',
    selected: 'desc',
    type: 'sort' as const,
  },
  {
    name: 'state',
    title: 'Shipment status',
    selected: [],
    type: 'multi-select' as const,
    default: [],
    options: [...(isPrep ? [] : ['IN_PROGRESS']), 'SENT', '!SENT,!IN_PROGRESS',],
    optionTitles: {
      IN_PROGRESS: 'Ready',
      SENT: isPrep ? 'Incoming' : 'Sent',
      "!SENT,!IN_PROGRESS": 'Arrived',
    },
  },
]

const OrdersPage: FC = () => {
  const navigate = useNavigate()
  const sellerFilter = useSelectedSeller()
  const user = useUserContext()
  const { save: saveFilter, changed, filterOptions, onFilterChange } = useFilter(defaultFilterOptions(user?.isPrepCenter || false))
  const [page, setPage] = useState(0)
  const callback = useCallback(
    (page?: number) =>
      user?.isPrepCenter
        ? ordersApi.listPrepOrders(
            sellerFilter,
            filterOptions.reduce((acc, curr) => ({ ...acc, [curr.name]: curr.selected }), {}),
            page
          )
        : ordersApi.listSellersOrders(
            filterOptions.reduce((acc, curr) => ({ ...acc, [curr.name]: curr.selected }), {}),
            page
          ),
    [filterOptions, user?.isPrepCenter, sellerFilter]
  )
  const [orders, total, loading, setOrders] = usePages<IOrder>(callback, page, 'orders')

  const onDelete = (id: string) => {
    setOrders((old) => old?.filter(({ id: _id }) => id !== _id))
  }

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header
        additionalButtons={
          user?.isPrepCenter
            ? undefined
            : [
                {
                  id: 'add-order-button',
                  tooltip: 'Create a Shipment',
                  bigButton: true,
                  onClick: () => navigate('/app/orders/create'),
                },
              ]
        }
      />
      <div className="grow flex flex-col overflow-hidden pb-6">
        <header className="flex justify-between items-center z-[1000]">
          <div className="flex gap-1 flex-col">
          <span>Shipments</span>{/*<Link to="/app/ordersv0" className="text-xs font-semibold text-primary-600">Shipments V0</Link>*/}
          </div>
          <Filter onDismiss={saveFilter} options={changed} onChange={onFilterChange} />
        </header>
        <div className="flex flex-col grow overflow-y-auto mb-5 px-6">
          <table className="overflow-hidden">
            <colgroup>
              <col className="w-[200px]" />
              <col />
              {!user?.isPrepCenter && <col />}
              <col />
              <col />
              <col className="w-[200px]" />
              <col className="w-[120px]" />
              {!user?.isPrepCenter && <col />}
            </colgroup>
            <thead>
              <tr>
                <td>{user?.isPrepCenter ? 'Identifier' : 'Name'}</td>
                <td>{user?.isPrepCenter ? 'Inbound' : 'Created'} At</td>
                {user?.isPrepCenter && <td>Client</td>}
                <td>Asins</td>
                <td>Total Units</td>
                <td>Status</td>
                <td>Progress</td>
                {!user?.isPrepCenter && <td>Action</td>}
              </tr>
            </thead>
            <tbody>
              {orders?.map((order) => (
                <Order key={order.id} {...order} onDelete={onDelete} />
              ))}
              {!orders?.length && (
                <TableSpanner rows={4} cols={6}>
                  {orders === undefined ? <FullLoader /> : <Empty text="No Shipments" />}
                </TableSpanner>
              )}
            </tbody>
          </table>
        </div>
        <Paginator page={page} setPage={setPage} loading={loading} total={Math.ceil((total || 0) / 10)} />
      </div>
    </div>
  )
}

export default OrdersPage
