import Header from 'components/layout/Header'
import WarehouseSelect from 'components/WarehouseSelect'
import { FC, FormEvent, useCallback, useState } from 'react'
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom'
import useUserContext from 'contexts/User/useUserContext'
import Checkbox from 'components/Checkbox'
import ordersApi from 'api/orders'
import numberInputOnWheelPreventChange from 'helpers/numberInputOnWheelPreventChange'
import useDarkMode from 'hooks/useDarkMode'
import { IInventory } from 'types/inventory'
import AmazonProductImage from 'components/AmazonProductImage'

const getItemKey = ({ item }: IInventory) => item.fnsku + item.asin + item.sku

const ShipFromInventory: FC = () => {
  const inventory = useLocation()?.state?.inventory as (IInventory & { shipmentQuantity: number })[]
  const [data, setData] = useState<(IInventory & { shipmentQuantity: number; selected: boolean })[]>(inventory?.map((item) => ({ ...item, count: 0, selected: item.shipmentQuantity > 0 })))
  const [identifier, setIdentifier] = useState<string | undefined>()
  const [notes, setNotes] = useState<string | undefined>()
  const [tracking, setTracking] = useState<string | undefined>()
  const [warehouse, setWarehouse] = useState<string | undefined>()
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const me = useUserContext()
  const { dark } = useDarkMode()

  const prep = me?.isPrepCenter

  const user = useParams().user

  const prepCenter = prep ? me.id : user

  const changeCountTo = useCallback(
    (key: string, to: number) => {
      setData((old) => old?.map((item, i) => ({ ...item, count: getItemKey(item) === key ? Math.max(0, isNaN(to) ? 0 : to) : item.shipmentQuantity })))
    },
    [setData]
  )

  const toggleSelect = useCallback(
    (key: string) => {
      setData((old) =>
        old?.map((item) => ({ ...item, selected: getItemKey(item) === key ? !item.selected : item.selected, count: getItemKey(item) === key && item.selected ? 0 : item.shipmentQuantity }))
      )
    },
    [setData]
  )

  const dataFilledOut = data && !data?.some(({ shipmentQuantity, selected }) => selected && !shipmentQuantity)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!warehouse) return false
    if (!identifier) return false
    if (!dataFilledOut) return false
    if (!data) return false
    if (submitting) return false
    setSubmitting(true)
    ordersApi
      .createOrderFromInventory(
        warehouse,
        identifier,
        data
          .filter(({ selected }) => selected)
          .map(({ shipmentQuantity, id }) => ({
            storageId: id,
            quantity: shipmentQuantity,
          })),
          {
            tracking,
            notes,
          }
      )
      .then(() => navigate('/app/orders'))
      .catch(() => setSubmitting(false))

    return false
  }

  if (!prep && !me?.amazonConnected) return <Navigate to="/app/settings/amazon" replace />

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header title="Create a Shipment" />
      <div className="flex flex-col grow overflow-y-hidden">
        <header>
          <div className="flex gap-4 items-center">Shipments</div>
        </header>
        <div className="flex overflow-y-hidden w-full space-x-5 grow overflow-x-auto pb-5 px-8">
          {
            <section className="box bg-white flex flex-col w-[270px] min-w-[270px] h-full">
              <header>Select a warehouse</header>
              <WarehouseSelect onChange={setWarehouse} owner={prepCenter} />
              {warehouse && (
                <div className="overflow-y-auto grow px-5 pb-5">
                  <h4 className="pt-3 text-base-500">Shipment Name (internal)</h4>
                  <input name="identifier" placeholder="Order Name" value={identifier} className="mt-2" onChange={(e) => setIdentifier(e.target.value)} />
                  <h4 className="pt-3 text-base-500">Notes</h4>
                  <div className="flex flex-col bg-base-50 rounded-xl mt-2 overflow-hidden">
                    <textarea name="identifier" placeholder="Enter Notes" value={notes} className="p-5 outline-none resize-none h-[200px]" onChange={(e) => setNotes(e.target.value)} />
                    <input name="trackingCode" placeholder="Tracking Code" value={tracking} className="rounded-t-none border-x-transparent outline-none border-b-transparent" onChange={(e) => setTracking(e.target.value)} />
                  </div>
                </div>
              )}
            </section>
          }
          <div className="flex flex-col h-full overflow-hidden grow min-w-[400px]">
            <section className="box bg-white flex flex-col h-full mb-5">
              <header>
                <span className="flex-grow flex items-center space-x-5">
                  <span className="min-w-max">Add items</span>
                </span>
              </header>
              <div className="overflow-y-auto overflow-x-hidden w-full grow">
                {data.map((item, i) => (
                  <div key={item.id} className="flex items-center space-x-5 px-5 py-3 border-b border-base-100">
                    <button onClick={() => toggleSelect(getItemKey(item))}>
                      <Checkbox checked={item.selected} />
                    </button>
                    <div className="flex-grow flex items-center space-x-5">
                      <div className="flex items-center transition-opacity" style={{ opacity: item.selected ? 1 : 0 }}>
                        <input
                          onWheel={numberInputOnWheelPreventChange}
                          disabled={!item.selected}
                          className="text-sm text-center h-min px-0 outline-none py-2 w-10"
                          value={item.shipmentQuantity}
                          type="number"
                          step={1}
                          min={0}
                          onChange={(e) => changeCountTo(getItemKey(item), e.currentTarget.valueAsNumber)}
                        />
                      </div>
                      <AmazonProductImage asin={item.item.asin} size={64} imageSize={128} className="cursor-pointer rounded-md" />
                      <div className="flex-grow flex flex-col">
                        <span className="text-sm">{item.item.name}</span>
                        <div className="flex space-x-2">
                          <span className="text-xs text-base-500">{item.item.fnsku}</span>
                          <span className="text-xs text-base-500">•</span>
                          <span className="text-xs text-base-500">{item.item.sku}</span>
                          <span className="text-xs text-base-500">•</span>
                          <span className="text-xs text-base-500">{item.item.asin}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
            <div className={[dark ? 'bg-white' : 'bg-base-900', 'flex items-center p-5 rounded-[12px] justify-between'].asClass}>
              <button className="button-dark" onClick={() => navigate(-1)}>
                Cancel
              </button>
              <div>
                <span />
                <button className="button-primary" disabled={!dataFilledOut || !warehouse || !identifier || submitting} onClick={handleSubmit}>
                  {submitting ? 'Creating...' : 'Ship Out'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShipFromInventory
