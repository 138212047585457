import { ChangeEvent, FC, useState } from 'react'
import useOrder from '../context/hook'
import Loader from 'components/loaders/Loader'
import { IDistributionOrder } from 'types/admin'
import adminApi from 'api/admin'
import IconButton from 'components/buttons/IconButton'
import { handleError } from 'helpers/errors'

const emptyPack = { sku: '', qty: 0, bundle: 0, toStore: 0 }

const OrderItemSent: FC<{
  item: IDistributionOrder['data'][0]
  onMarkSkus: (
    itemId: string,
    sku: {
      sku: string
      qty: number
      bundle: number
      toStore: number
    }[]
  ) => void
}> = ({ item, onMarkSkus }) => {
  const { order, frozen } = useOrder()
  const { name, upc, id: itemId } = item
  const _fulfillable = order.shipmentData?.[itemId]?.fulfillable

  const initialPacks = order.shipmentData?.[itemId]?.sku
  const [packs, setPacks] = useState(initialPacks || [])
  const [newPack, setNewPack] = useState(emptyPack)

  const onEdit = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = key === "sku" ? e.currentTarget.value : e.currentTarget.valueAsNumber
    setNewPack((old) => old && ({ ...old, [key]: value } as any))
  }

  const onDelete = (index: number) => {
    setPacks((old) => old.filter((_, i) => i !== index))
  }

  const canAdd = Boolean(newPack.sku && newPack.qty && newPack.bundle)

  const onAdd = () => {
    if (!newPack.sku || !newPack.qty || !newPack.bundle) return handleError('Fill out all the data')
    setPacks((old) => [...old, newPack as any])
    setNewPack(() => emptyPack)
  }

  const [submitting, setSubmitting] = useState(false)
  const [fulfillable, setFulfillable] = useState<number>(_fulfillable || 0)

  const handleSubmit = () => {
    if (submitting) return
    setSubmitting(true)
    adminApi
      .putDistOrderSkus(order.id, itemId, packs)
      .then(() => {
        onMarkSkus(itemId, packs)
      })
      .catch((e) => {
        setFulfillable(_fulfillable || 0)
      })
      .finally(() => setSubmitting(false))
  }

  const packed = packs.reduce((acc, { qty, bundle }) => acc + qty, 0)

  return (
    <>
      {submitting && (
        <div className="absolute inset-0 bg-gray-100 bg-opacity-50 flex items-center justify-center z-50">
          <Loader />
        </div>
      )}
      <tr>
        <td>{(item.count * (item.caseQty || 1)).toLocaleString('en-US')}</td>
        <td className="select-text max-w-[200px] truncate" title={upc}>
          {upc}
        </td>
        <td className="select-text max-w-[200px] truncate" title={name}>
          {name}
        </td>
        {!frozen && (
        <td>
          <div className="flex flex-col gap-3 h-full grow">
            {packs.map((_, i) => (
              <div className="grow flex items-center h-[54.6px]">
                <IconButton key={i} name="minus" tooltip="Remove configuration" onClick={() => onDelete(i)} />
              </div>
            ))}
              <div className="grow flex items-center h-[54.6px]">
            <IconButton name="add" disabled={!canAdd} tooltip="Add packing configuration" onClick={onAdd} />
            </div>
          </div>
        </td>
        )}
        <td>
          <div className="flex flex-col gap-3">
            {packs.map(({ sku }, i) => (
              <input key={sku+i} className="input outline-none" value={sku} readOnly />
            ))}
            {!frozen && <input className="input outline-none" value={newPack.sku} onChange={onEdit.call(this, 'sku')} />}
          </div>
        </td>
        <td>
          <div className="flex flex-col gap-3">
            {packs.map(({ qty, sku }, i) => (
              <input key={sku+i} type="number" className="input w-[75px] outline-none" value={qty} readOnly />
            ))}
            {!frozen && <input type="number" className="input w-[75px] outline-none" value={newPack.qty} onChange={onEdit.call(this, 'qty')} />}
          </div>
        </td>
        <td>
          <div className="flex flex-col gap-3">
            {packs.map(({ bundle, sku }, i) => (
              <input key={sku+i} type="number" className="input w-[75px] outline-none" value={bundle} readOnly />
            ))}
            {!frozen && <input type="number" className="input w-[75px] outline-none" value={newPack.bundle} onChange={onEdit.call(this, 'bundle')} />}
          </div>
        </td>
        <td>
          <div className="flex flex-col gap-3">
            {packs.map(({ toStore, sku }, i) => (
              <input key={sku + i} type="number" className="input w-[75px] outline-none" value={toStore} readOnly />
            ))}
            {!frozen && <input type="number" className="input w-[75px] outline-none" value={newPack.toStore} onChange={onEdit.call(this, 'toStore')} />}
          </div>
        </td>
        <td>{fulfillable.toLocaleString('en-US')}</td>
        <td>{packed}</td>
        {!frozen && (
        <td>
          <button className="button-secondary" disabled={submitting} onClick={handleSubmit}>
            Save
          </button>
        </td>
        )}
      </tr>
    </>
  )
}

export default OrderItemSent
