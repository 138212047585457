import { IFilterOption } from "components/Filter";
import { useCallback, useState } from "react";

const useFilter = (defaultOptions: IFilterOption[]) => {
    const [changed, setChanged] = useState(defaultOptions);
    const [filterOptions, setFilterOptions] = useState(defaultOptions);

    
  const onFilterChange = (name: string, selected: any) => {
    setChanged((old) => old.map((option) => (option.name === name ? { ...option, selected } : option)))
  }

  const onSave = useCallback(() => {
    setFilterOptions(changed)
  }, [changed])

  return { save: onSave, changed, filterOptions, onFilterChange }
}

export default useFilter