import api from "api";
import ordersApi from "api/orders";
import servicesApi from "api/services";
import FullLoader from "components/loaders/FullLoader";
import { adminId } from "constants/admin";
import useUserContext from "contexts/User/useUserContext";
import useHasRole from "hooks/useHasRole";
import useSelectedSeller from "hooks/useSelectedSeller";
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { IOrder } from "types/orders";
import { IService } from "types/services";
import { IUser } from "types/user";
import { IWarehouse } from "types/warehouses";

export type OrderContextType = {
    order: IOrder;
    workable: boolean;
    frozen: boolean;
    setOrder: Dispatch<SetStateAction<IOrder | undefined>>;
    seller: IUser | undefined;
    prepCenter: IUser | undefined;
    isPrepCenter: boolean;
    availableItemServices: IService[] | undefined;
    setAvailableItemServices: Dispatch<SetStateAction<IService[] | undefined>>;
    warehouse: IWarehouse | null | undefined;
    setWarehouse: Dispatch<SetStateAction<IWarehouse | null | undefined>>;
    isApexOrder: boolean;
    chatId?: string;
}

export const OrderContext = createContext<OrderContextType>({} as OrderContextType);

export const useOrder = () => useContext(OrderContext);

const OrderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<IUser>()
  const me = useUserContext()
  const orderId = useParams().orderId
  const [order, setOrder] = useState<IOrder>()
  const otherUser = order?.from === me?.id ? order?.to : order?.from
  const [warehouse, setWarehouse] = useState<IWarehouse | null>()
  const [chatId, setChatId] = useState<string>()
  const selectedSeller = useSelectedSeller()
  const [availableItemServices, setAvailableItemServices] = useState<IService[]>()
  const navigate = useNavigate()
  const canPrep = useHasRole('PREP')
  const frozen = !!order?.bill || !canPrep
  const workable = Boolean(order && order.shipmentData?.transportState !== 'CONFIRMED' && !frozen)

  const seller = (order?.from === me?.id ? me : user) as IUser | undefined
  const prepCenter = (order?.from === me?.id ? user : me) as IUser | undefined

  useEffect(() => {
    if (selectedSeller && me && order && selectedSeller !== order.from) {
      navigate(`/app/orders`)
    }
  }, [selectedSeller, me, order, navigate])

  useEffect(() => {
    if (!order?.to) return
    servicesApi.getItemServices(order?.to).then(setAvailableItemServices)
  }, [order?.to])

  useEffect(() => {
    if (!otherUser) return
    api.getChat(otherUser).then((chat) => {
      if (!chat) return
      setChatId(chat)
    })
  }, [otherUser])

  useEffect(() => {
    if (!orderId) return
    ordersApi.getOrder(orderId).then(setOrder)
  }, [orderId])

  useEffect(() => {
    if (me?.isPrepCenter || !order) return setWarehouse(null)
    api.getWarehouse(order.warehouse).then(setWarehouse)
  }, [order, me?.isPrepCenter])

  useEffect(() => {
    if (!order) return
    api.getUser(otherUser).then(setUser)
  }, [order, otherUser])
  
  if (!me || !orderId) return <Navigate to="/app/dashboard/orders" replace />

  if (!order) return <FullLoader />

  const isApexOrder = me.isPrepCenter ? !user?.referrer || user.referrer === adminId : me.referrer === adminId || !me.referrer
  
  return (
    <OrderContext.Provider 
        value={{
            order,
            workable,
            frozen,
            setOrder,
            seller,
            prepCenter,
            isPrepCenter: me?.isPrepCenter || order?.to === me?.id,
            availableItemServices,
            setAvailableItemServices,
            warehouse,
            setWarehouse,
            isApexOrder,
            chatId
        }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export default OrderProvider;