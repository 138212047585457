import { useLocation, useNavigate } from "react-router"

const useOpenModal = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const background = location.state?.background || location

    const openModal = (route: string, anchor?: string, replace = location.state?.background !== undefined) => {
        navigate(route, {state: { background, anchor }, replace})
    }

    return openModal
}

export default useOpenModal