import api from 'api'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'

const OnboardingVideoModal: AwaitableModal = ({ open, resolve, onCancel, initialData }) => {

  return (
    <Modal open={open} close={resolve}>
      <div className="modal w-[768px] max-w-[calc(100vw-64px)] relative">
        <header>
          <div>
            <h1>Welcome onboard</h1>
            <h3>We're excited to have you</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        <div className="w-full aspect-[16/9]">
          <iframe src="https://www.loom.com/embed/808fd0043676480c88e2f99c89b591d3?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" className="h-full" />
        </div>
        <footer className="items-center">
          <button className="button-primary w-full" onClick={resolve}>
            Let's start
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default OnboardingVideoModal
