import { Dispatch, FC, SetStateAction } from 'react'
import PaymentStructure from './PaymentStructure'
import Empty from 'components/Empty'
import TableSpanner from 'components/TableSpanner'
import FullLoader from 'components/loaders/FullLoader'
import useAwaitableModal from 'hooks/useAwaitableModal'
import AddPaymentStructureModal from 'pages/app/modals/AddPaymentStructureModal'
import { IPaymentStructure } from 'types/billing'
import { IService } from 'types/services'

interface props {
  paymentStructures?: IPaymentStructure[]
  setPaymentStructures: Dispatch<SetStateAction<IPaymentStructure[] | undefined>>
  itemServices?: IService[]
  orderServices?: IService[]
}

const PaymentStructures: FC<props> = ({ paymentStructures, setPaymentStructures, itemServices, orderServices }) => {
  const [request, Element] = useAwaitableModal(AddPaymentStructureModal, undefined)

  return (
    <section className="box w-full flex flex-col relative bg-white">
      <Element />
      <header className="flex justify-between items-center z-[1000]">
        <span>Payment Structures</span>
        {paymentStructures !== undefined && (
          <button className="button-primary relative -my-2" onClick={() => request({itemServices: itemServices || [], orderServices: orderServices || []}).then((created) => created && setPaymentStructures((old) => old && [created, ...old]))}>
            New Payment Structure
          </button>
        )}
      </header>
      <div className="b-secondary grow flex flex-col overflow-hidden py-4">
        <div className="flex flex-col grow -mt-4 overflow-y-auto p-6">
          <div className="border border-base-200 rounded-xl">
            <table className="w-full rounded-xl overflow-hidden">
              <thead>
                <tr>
                  <td className="text-xs text-base-400">Name</td>
                  <td className="text-xs text-base-400 font-medium border-l">Added On</td>
                  <td className="text-xs text-base-400 font-medium border-l">Actions</td>
                </tr>
              </thead>
              <tbody>
              {itemServices && orderServices && paymentStructures?.map((structure) => (
                <PaymentStructure
                  key={structure.id}
                  structure={structure}
                  onEdit={() =>
                    request({ name: structure.identifier, pricingStructure: structure.data, itemServices, orderServices }).then((updated) =>
                      setPaymentStructures((old) => old && updated && old.map((oldStructure) => (oldStructure.id === updated.id ? updated : oldStructure)))
                    )
                  }
                  onDelete={() =>
                      setPaymentStructures((old) => old && old.filter((oldStructure) => (oldStructure.id !== structure.id)))
                  }
                />
              ))}
              {!paymentStructures?.length && (
                <TableSpanner rows={4} cols={3}>
                  {paymentStructures === undefined ? <FullLoader /> : <Empty text="No Payment Structures" />}
                </TableSpanner>
              )}
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </section>
  )
}

export default PaymentStructures
