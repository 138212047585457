import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { IOrder, IShippingDataLTL, IShippingDataSPD } from 'types/orders'
import FNSKU from './FNSKU'
import { useState } from 'react'
import { CloseIcon } from 'assets/icons'
import SPD from './SPD'
import LTL from './LTL'
import { useOrder } from 'contexts/Order'

const LabelsModal: AwaitableModal<{ expiryDate?: string } & Record<string, any>, { order: IOrder; item: IOrder['itemsData'][0]; step?: number }> = ({
  initialData: { order, item, step: _step = 0 },
  open,
  onCancel,
  resolve,
}) => {
  const [step, setStep] = useState(_step)
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined)
  const { setOrder } = useOrder()

  const [spdBoxes, setSPDBoxes] = useState<IShippingDataSPD['boxes']>((order.shipmentData?.type === 'SPD' && order.shipmentData.boxes) || [])
  const [ltlBoxes, setLTLBoxes] = useState<IShippingDataLTL['boxes']>((order.shipmentData?.type === 'LTL' && order.shipmentData.boxes) || [])

  const onResolve = () => {
    resolve({ expiryDate, ...(order.shipmentData?.type === 'SPD' ? { boxes: spdBoxes } : order.shipmentData?.type === 'LTL' ? { boxes: ltlBoxes } : {}) })
  }

  const onResolveWithShipping = (data: any) => {
    const { expiryDate, ...rest } = data
    setOrder((old) => {
      if (!old) return old
      return {
        ...old,
        shipmentData: {
          ...old.shipmentData,
          ...rest,
        } as any,
        itemsData: old.itemsData.map((item) => {
          if (item.sku === rest.sku) {
            item.expiryDate = expiryDate
          }
          return item
        }),
      }
    })
    resolve()
  }

  const steps = [
    { title: 'Barcode', component: <FNSKU orderId={order.id} item={item} expiryDate={expiryDate} setExpiryDate={setExpiryDate} onNext={() => setStep((old) => old + 1)} /> },
    order.shipmentData?.type === 'LTL'
      ? {
          title: 'LTL',
          component: <LTL item={item} order={order} expiryDate={expiryDate} onDone={onResolveWithShipping} onCancel={onResolve} />,
        }
      : order.shipmentData?.type === 'SPD'
      ? {
          title: 'SPD',
          component: <SPD item={item} order={order} expiryDate={expiryDate} onDone={onResolveWithShipping} onCancel={onResolve} />,
        }
      : null,
  ]

  return (
    <Modal open={open} close={onCancel}>
      <div className="modal relative max-h-[calc(100vh-64px)]">
        <header className="!items-center flex">
          <span className="grow">{steps[step]?.title}</span>
          <div className="text-sm font-medium flex !flex-row grow !items-center space-x-3">
            <span className={['w-6 h-6 rounded-full text-center flex items-center justify-center', step === 0 ? 'text-white bg-rose-600' : 'text-base-500 bg-base-300'].asClass}>1</span>
            <div className="flex grow relative">
              <div className="border h-0 border-dashed w-full z-10 transition-all text-base-300" />
            </div>
            <span className={['w-6 h-6 rounded-full text-center flex items-center justify-center', step === 1 ? 'text-white bg-rose-600' : 'text-base-500 bg-base-300'].asClass}>2</span>
          </div>
          <div className="grow flex !items-end">
            <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
          </div>
        </header>
        {steps[step]?.component}
      </div>
    </Modal>
  )
}

export default LabelsModal
