import api from 'api'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'
import { IClient } from '../Dashboard/components/ClientList'
import { handleError } from 'helpers/errors'

const InviteClientModal: AwaitableModal<IClient[]> = ({ open, resolve }) => {
  const [result, setResult] = useState<any>(undefined)
  const [sending, setSending] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

  const onResolve = () => {
    resolve(result)
  }

  const handleSubmit = () => {
    if (sending) return
    let error
    if (!firstName) error = 'Please enter a first name'
    else if (!lastName) error = 'Please enter a last name'
    else if (!email) error = 'Please enter an email address'
    else if (!emailValid) error = 'Please enter a valid email address'
    if (error) return handleError(error)

    setSending(true)
    api
      .inviteClient(firstName, lastName, email)
      .then((res) => {
        setResult(res)
      })
      .catch(() => {
        handleError('An error occurred while sending the invitation')
      })
      .finally(() => {
        setSending(false)
      })
  }
  return (
    <Modal open={open} close={onResolve}>
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>{result ? 'Invitation Sent' : 'Invite Merchant'}</h1>
            <h3>{result ? 'The merchant will receive an email' : 'Please enter the details to continue.'}</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onResolve} />
        </header>
        {!result && (
          <>
            <div className="w-full">
              <input type="text" placeholder="First Name" className="w-full" value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} />
              <input type="text" placeholder="Last Name" className="w-full" value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} />
              <input type="text" placeholder="Email Address" className="w-full" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
            </div>
            <footer className="items-center">
              <button disabled={sending} className="button-primary" onClick={handleSubmit}>
                Send Invitation
              </button>
            </footer>
          </>
        )}
      </div>
    </Modal>
  )
}

export default InviteClientModal
