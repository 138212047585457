import adminApi from 'api/admin'
import Header from 'components/layout/Header'
import { FC, useEffect, useState } from 'react'
import { IShipmentsOverview } from 'types/admin'
import Stats from './components/Stats'
import { IContactInfo } from 'pages/app/InitialSetup/types'

const AdminShipmentsPage: FC = () => {
  const [overview, setOverview] = useState<IShipmentsOverview>()
  const [prepCenters, setPrepCenters] = useState<(IContactInfo & {id: string})[]>()
  const [to, setTo] = useState<string | undefined>()
  const [days, setDays] = useState(30)

  useEffect(() => {
    setOverview(undefined)
    adminApi.getShipmentsOverview(days).then(setOverview)
  }, [days])

  useEffect(() => {
    adminApi.getPrepCenterContacts().then(setPrepCenters)
  }, [])

  return (
    <div className="flex flex-col grow">
      <Header title="Shipments Overview" />
      <div className="grow flex flex-col overflow-y-auto pb-6">
        <header>Shipments Overview</header>
        <div className="flex flex-col grow overflow-y-auto mb-5 px-6 gap-4">
          <div className="flex items-center justify-end gap-4">
            <select className="px-1 outline-none border-none py-1 text-xs border rounded-md" value={days} onChange={(e) => setDays(parseInt(e.target.value, 10))}>
              <option value={7}>Last 7 days</option>
              <option value={14}>Last 14 days</option>
              <option value={30}>Last 30 days</option>
            </select>
            <select className="px-1 outline-none border-none py-1 text-xs border rounded-md" value={to} onChange={(e) => setTo(e.target.value)}>
              <option value="">All  Prep Centers</option>
              {prepCenters?.map((center) => (
                <option key={center.name} value={center.id}>{center.name}</option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-2 gap-6 pb-6">
            <Stats type="inbound" overview={overview?.inbound} days={days} to={to} />
            <Stats type="outbound" overview={overview?.outbound} days={days} to={to} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminShipmentsPage
