import { Dispatch, FC, SetStateAction } from 'react'
import Empty from 'components/Empty'
import TableSpanner from 'components/TableSpanner'
import FullLoader from 'components/loaders/FullLoader'
import useAwaitableModal from 'hooks/useAwaitableModal'
import { IService } from 'types/services'
import AddOrderServiceModal from './AddOrderServiceModal'
import OrderService from './OrderService'

interface props {
  orderServices?: IService[]
  setOrderServices: Dispatch<SetStateAction<IService[] | undefined>>
  type: 'inbound' | 'outbound'
}

const OrderServices: FC<props> = ({orderServices, setOrderServices, type}) => {
  const [request, Element] = useAwaitableModal(AddOrderServiceModal, { type })

  return (
    <section className="box w-full flex flex-col relative bg-white">
      <Element />
      <header className="flex justify-between items-center z-[1000]">
        <span className="capitalize">{type} Services</span>
        <button className="button-primary relative -my-2 capitalize" onClick={() => request().then((created) => setOrderServices((old) => old && [created, ...old]))}>
          New {type} Service
        </button>
      </header>
      <div className="b-secondary grow flex flex-col overflow-hidden py-4">
        <div className="flex flex-col grow -mt-4 overflow-y-auto p-6">
          <div className="border border-base-200 rounded-xl">
            <table className="w-full rounded-xl overflow-hidden">
              <thead>
                <tr>
                  <td className="text-xs text-base-400">Title</td>
                  <td className="text-xs text-base-400 font-medium border-l">Identifier</td>
                  <td className="text-xs text-base-400 font-medium border-l">Base Price</td>
                  <td className="text-xs text-base-400 font-medium border-l">Added on</td>
                  <td className="text-xs text-base-400 font-medium border-l">Actions</td>
                </tr>
              </thead>
              <tbody>
                {orderServices?.map((service) => (
                  <OrderService key={service.id} service={service} remove={() => setOrderServices(old => old?.filter(s => s.id !== service.id))} />
                ))}
                {!orderServices?.length && (
                  <TableSpanner rows={4} cols={5}>
                    {orderServices === undefined ? <FullLoader /> : <Empty text="No Services Added" />}
                  </TableSpanner>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OrderServices
