import ordersApi from 'api/orders'
import { useOrder } from 'contexts/Order'
import { FC, useState } from 'react'

interface props {
  notes: string
  setNotes: (notes: string) => void
  isSeller: boolean
  isOwner: boolean
  editable: boolean
}

const OrderNotes: FC<props> = ({ notes, setNotes, isOwner, isSeller, editable: _editable }) => {
  const { frozen, order } = useOrder()
  const [unsaved, setUnsaved] = useState(notes)
  const [saving, setSaving] = useState(false)
  const editable = !frozen && _editable

  const onSave = () => {
    setSaving(true)
    const toSave = unsaved.trim()
    if (toSave === notes) {
      setUnsaved(notes)
      setSaving(false)
    } else {
      ordersApi
        .setOrderNotes(order.id, isSeller, toSave)
        .then(() => {
          setUnsaved(toSave)
          setNotes(toSave)
        })
        .finally(() => setSaving(false))
    }
  }

  return (
    <section className="box grow min-w-[500px] bg-white box h-[245px] overflow-hidden relative">
      <header>
        <span>{!isOwner ? 'Client ' : 'Your '}Notes</span>
        {editable && (
          <button className="button-secondary -mb-3 -mt-2" onClick={onSave} disabled={unsaved === notes || (!unsaved && !notes) || saving}>
            {saving ? 'Saving...' : 'Save'}
          </button>
        )}
      </header>
      <div className="flex flex-col w-full h-[188px] px-5 py-4 relative">
        {editable ? (
          <textarea className="w-full mb-4 grow p-2 resize-none" placeholder="Write something..." value={unsaved} onChange={(e) => setUnsaved(e.target.value)} />
        ) : notes ? (
          <div className="w-full h-full overflow-y-auto p-2 mb-4">
            <span>{notes}</span>
            {!!order.tracking && (
              <div className="w-full flex items-center border border-base-200 rounded-xl p-5 justify-between mt-5">
                <span className="text-xs font-semibold text-base-950">Tracking Code</span>
                <span className="text-sm text-base-950 select-text">{order.tracking}</span>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full h-full overflow-y-auto p-2 mb-4 text-base-300 items-center flex justify-center">No notes</div>
        )}
      </div>
    </section>
  )
}

export default OrderNotes
