import { FC, useCallback, useState } from 'react'
import OrderItem from './OrderItem'
import Icon from 'components/Icon'
import useOrder from '../context/hook'
import adminApi from 'api/admin'

const OrderItems: FC = () => {
  const { order, setOrder } = useOrder()
  const [search, setSearch] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const filtered = order?.data?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()) || item.upc.toLowerCase().includes(search.toLowerCase()))

  const onMarkFulfillable = useCallback(
    (itemId: string, qty: number) => {
      setOrder((old) => {
        return {
          ...old, 
          shipmentData: {
            ...old.shipmentData,
            [itemId]: {
              sku: [],
              ...old.shipmentData?.[itemId],
              fulfillable: qty
            }
          }
        }
      })
    }, [setOrder]);

  const markOut = useCallback(() => {
    if (submitting) return
    setSubmitting(true)
    adminApi.distOutForDelivery(order.id).then(() => {
      setOrder((old) => ({ ...old, status: "sent" }))
    })
    .finally(() => setSubmitting(false))
  }, [submitting, order, setOrder])

  return (
    <>
      <div className="p-6">
        <section className="box border border-base-200">
          <div className="relative border-b border-base-200">
            <Icon name="search-normal-1" className="fill-base-400 absolute pointer-events-none left-6 top-1/2 -translate-y-1/2" />
            <input className="border-0 bg-white w-full rounded-none outline-none p-6 pl-16" placeholder="Search Items..." value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
          </div>
          <div className="overflow-x-auto min-w-full overflow-y-visible">
            <table className="w-full rounded-t-[0px] overflow-visible">
              <thead>
                <tr>
                  <td>
                    Expected
                  </td>
                  <td>Fulfillable Units</td>
                  <td>
                    UPC
                  </td>
                  <td>
                    Item Name
                  </td>
                  <td>
                    <button className="button-secondary float-right" disabled={submitting} onClick={markOut}>
                      Submit
                    </button>
                  </td>
                </tr>
              </thead>
              <tbody className="min-h-full relative">
                {filtered.map((item) => (
                  <OrderItem key={item.id} item={item} onMarkFulfillable={onMarkFulfillable} />
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </>
  )
}

export default OrderItems
