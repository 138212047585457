import api from 'api'
import Header from 'components/layout/Header'
import WarehouseSelect from 'components/WarehouseSelect'
import { FC, FormEvent, useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import useUserContext from 'contexts/User/useUserContext'
import { IAmazonListing } from 'types/amazon'
import FullLoader from 'components/loaders/FullLoader'
import Checkbox from 'components/Checkbox'
import ordersApi from 'api/orders'
import numberInputOnWheelPreventChange from 'helpers/numberInputOnWheelPreventChange'
import { IOrder } from 'types/orders'
import Icon from 'components/Icon'
import Empty from 'components/Empty'
import useDarkMode from 'hooks/useDarkMode'
import AmazonProductImage from 'components/AmazonProductImage'

const getItemKey = (item: IAmazonListing) => item.fnsku + item.asin + item.sku

const CreateOrderPage: FC = () => {
  const initialOrder = useLocation()?.state?.initialOrder as IOrder | undefined
  const [data, setData] = useState<(IAmazonListing & { count: number; selected: boolean })[]>()
  const [identifier, setIdentifier] = useState<string | undefined>(initialOrder?.data?.seller?.id)
  const [warehouse, setWarehouse] = useState<string | undefined>(initialOrder?.warehouse)
  const [submitting, setSubmitting] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [notes, setNotes] = useState<string | undefined>()
  const [tracking, setTracking] = useState<string | undefined>()
  const [serviceFilters, setServiceFilters] = useState<string[]>([])
  const navigate = useNavigate()
  const me = useUserContext()
  const {dark} = useDarkMode()

  const changeCount = useCallback(
    (index: number, change: number) => {
      setData((old) => old?.map((item, i) => ({ ...item, count: i === index ? Math.max(0, item.count + change) : item.count })))
    },
    [setData]
  )

  const changeCountTo = useCallback(
    (key: string, to: number) => {
      setData((old) => old?.map((item, i) => ({ ...item, count: getItemKey(item) === key ? Math.max(0, isNaN(to) ? 0 : to) : item.count })))
    },
    [setData]
  )

  const toggleSelect = useCallback(
    (key: string) => {
      setData((old) => old?.map((item) => ({ ...item, selected: getItemKey(item) === key ? !item.selected : item.selected, count: getItemKey(item) === key && item.selected ? 0 : item.count })))
    },
    [setData]
  )

  const toggleService = useCallback(
    (service: string) => {
      setServiceFilters((old) => (old.includes(service) ? old.filter((s) => s !== service) : [...old, service]))
    },
    [setServiceFilters]
  )

  const dataFilledOut = data && !data?.some(({ count, selected }) => selected && !count)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!warehouse) return false
    if (!identifier) return false
    if (!dataFilledOut) return false
    if (!data) return false
    if (submitting) return false
    setSubmitting(true)
    if (initialOrder) {
      ordersApi
        .editOrder(
          initialOrder.id,
          identifier,
          data.flatMap(({ asin, count, fnsku, name, sku, condition }) => (count ? [{ asin, expected: count, fnsku, sku, name, condition }] : [])),
          { notes, tracking}
        )
        .then(() => navigate('/app/orders/' + initialOrder.id))
        .catch(() => setSubmitting(false))
      return false
    }
    ordersApi
      .createOrder(
        warehouse,
        identifier,
        data.flatMap(({ asin, count, fnsku, name, sku, condition }) => (count ? [{ asin, expected: count, fnsku, sku, name, condition }] : [])),
          { notes, tracking}
      )
      .then((order) => navigate('/app/orders/' + order.id))
      .catch(() => setSubmitting(false))

    return false
  }

  useEffect(() => {
    if (!warehouse) return setData(undefined)
    api.getListings(warehouse).then(({ listings }) =>
      setData(
        listings.map((listing) => {
          const itemInOrder = initialOrder?.itemsData?.find((item) => item.sku === listing.sku)
          if (itemInOrder) return { ...listing, count: itemInOrder.expected, selected: true }
          else return { ...listing, count: 0, selected: false }
        })
      )
    )
  }, [initialOrder?.itemsData, warehouse])

  const searchedItems = search
    ? data?.filter(({ name, sku, fnsku, asin }) => [name, sku, fnsku, asin].some((i) => i.toLowerCase().startsWith(search.toLowerCase()))).sort(({ selected }) => (selected ? 1 : -1))
    : data

  if (me && !me.amazonConnected) return <Navigate to="/app/settings/amazon" replace />

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header title="Create a Shipment" />
      <div className="flex flex-col grow overflow-y-hidden">
        <header>
          <div className='flex gap-4 items-center'>
          {initialOrder ? 'Edit a Shipment' : 'Shipments'}
          {initialOrder ? 
            <input name="identifier" placeholder="Order Name" value={identifier} className="mx-5 !-my-3 relative" onChange={(e) => setIdentifier(e.target.value)} /> :
            <>
            <span onClick={() => toggleService("Wholesale")} className={["rounded-full transition-all border border-green-500 hover:bg-green-200 text-green-500 text-xs px-5 py-3 w-max leading-[18px] font-medium cursor-pointer", serviceFilters.includes("Wholesale") ? "bg-green-900" : ""].asClass}>
                Wholesale
            </span>
            <span onClick={() => toggleService("Tax Free State")} className={["rounded-full border border-indigo-500 transition-all text-indigo-500 hover:bg-indigo-200 text-xs px-5 py-3 w-max leading-[18px] font-medium cursor-pointer", serviceFilters.includes("Tax Free State") ? "bg-indigo-900" : ""].asClass}>
                Online Arbitrage
            </span>
            </>
          }
            </div>
        </header>
        <div className="flex overflow-y-hidden w-full space-x-5 grow overflow-x-auto pb-5 px-8">
          {!initialOrder && (
            <section className="box bg-white flex flex-col w-[270px] min-w-[270px] h-full">
              <header>Select a warehouse</header>
              <WarehouseSelect onChange={setWarehouse} serviceFilters={serviceFilters} owner="" />
              {warehouse && (
                <div className="overflow-y-auto grow px-5 pb-5">
                  <h4 className="pt-3 text-base-500">Shipment Name (internal)</h4>
                  <input name="identifier" placeholder="Order Name" value={identifier} className="mt-2" onChange={(e) => setIdentifier(e.target.value)} />
                  <h4 className="pt-3 text-base-500">Notes</h4>
                  <div className="flex flex-col bg-base-50 rounded-xl mt-2 overflow-hidden">
                    <textarea name="identifier" placeholder="Enter Notes" value={notes} className="p-5 outline-none resize-none h-[200px]" onChange={(e) => setNotes(e.target.value)} />
                    <input name="trackingCode" placeholder="Tracking Code" value={tracking} className="rounded-t-none border-x-transparent outline-none border-b-transparent" onChange={(e) => setTracking(e.target.value)} />
                  </div>
                </div>
              )}
            </section>
          )}
          <div className="flex flex-col h-full overflow-hidden grow min-w-[400px]">
            <section className="box bg-white flex flex-col h-full mb-5">
              <header>
                <span className="flex-grow flex items-center space-x-5">
                  <span className="min-w-max">Add items</span>
                  <span className="text-base-500 text-xs">
                    Only products in your seller central are visible, please make sure to add. Please create one shipment per purchase order so your desired prep center knows what is expected on a
                    shipment basis.
                  </span>
                </span>
              </header>
              <div className="relative border-b border-b-base-100">
                <Icon name="search-normal-1"  className="fill-base-400 absolute pointer-events-none left-6 top-1/2 -translate-y-1/2" />
                <input className="border-0 bg-white w-full rounded-none outline-none p-6 pl-16" placeholder="Search something..." value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
              </div>
              <div className="overflow-y-auto overflow-x-hidden w-full grow">
                {searchedItems !== undefined ? (
                  !searchedItems.length ? (
                    <div className="py-10">
                      <Empty text="No results" />
                    </div>
                  ) : (
                    searchedItems.map((item, i) => (
                      <div key={item.fnsku + item.asin + item.sku} className="flex items-center space-x-5 px-5 py-3 border-b border-base-100">
                        <button onClick={() => toggleSelect(getItemKey(item))}>
                          <Checkbox checked={item.selected} />
                        </button>
                        <div className="flex-grow flex items-center space-x-5">
                          <div className="flex items-center transition-opacity" style={{ opacity: item.selected ? 1 : 0 }}>
                            <input
                              onWheel={numberInputOnWheelPreventChange}
                              disabled={!item.selected}
                              className="text-sm text-center h-min px-0 outline-none py-2 w-10"
                              value={item.count}
                              type="number"
                              step={1}
                              min={0}
                              onChange={(e) => changeCountTo(getItemKey(item), e.currentTarget.valueAsNumber)}
                            />
                          </div>
                          <AmazonProductImage asin={item.asin} size={64} imageSize={128} className="cursor-pointer rounded-md" />
                          <div className="flex-grow flex flex-col">
                            <span className="text-sm">{item.name}</span>
                            <div className="flex space-x-2">
                              <span className="text-xs text-base-500">{item.fnsku}</span>
                              <span className="text-xs text-base-500">•</span>
                              <span className="text-xs text-base-500">{item.sku}</span>
                              <span className="text-xs text-base-500">•</span>
                              <span className="text-xs text-base-500">{item.asin}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )
                ) : (
                  !warehouse ? (
                    <div className="py-10">
                      <Empty text="Select a warehouse to continue" />
                    </div>
                  ) :
                  <FullLoader />
                )}
              </div>
            </section>
            <div className={[dark ? "bg-white" : "bg-base-900", "flex items-center p-5 rounded-[12px] justify-between"].asClass}>
              <button className="button-dark" onClick={() => navigate(-1)}>
                Cancel
              </button>
              <div>
                <span />
                <button className="button-primary" disabled={!dataFilledOut || !warehouse || !identifier || submitting} onClick={handleSubmit}>
                  {initialOrder ? (submitting ? 'Editing...' : 'Edit Shipment') : submitting ? 'Creating...' : 'Create a Shipment'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateOrderPage
