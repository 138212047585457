import { CloseIcon } from "assets/icons";
import Modal from "components/Modal";
import { AwaitableModal } from "hooks/useAwaitableModal";
import { useState } from "react";
import WarehouseInfo from "../../../WarehouseInfo";
import { IWarehouseContactInfo } from "types/warehouses";
import ordersApi from "api/orders";

const MarkReadyModal: AwaitableModal<boolean, { orderId: string, contact: IWarehouseContactInfo }> = ({ initialData, open, onCancel, resolve }) => {
    const [submitting, setSubmitting] = useState(false)

    const onSubmit = () => {
        if (submitting) return
        setSubmitting(true)
        ordersApi.markReady(initialData.orderId).then(resolve).finally(() => setSubmitting(false))
    }

    return (
        
    <Modal open={open} close={onCancel}>
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>Send Shipment</h1>
            <h3>Copy details and send the shipment items for prepping</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        <div className="w-full">
          <WarehouseInfo contact={initialData.contact} />
        </div>
        <footer>
          <button disabled={submitting} className="button-primary" onClick={onSubmit}>
            Send
          </button>
        </footer>
      </div>
    </Modal>
    )
}

export default MarkReadyModal