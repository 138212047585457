import { FC, useEffect, useState } from 'react'
import api from 'api'
import FullLoader from 'components/loaders/FullLoader'
import { IShortUser } from 'types/user'
import useOpenModal from 'hooks/useOpenModal'

const PendingContacts: FC = () => {
  const [pending, setPending] = useState<IShortUser[]>()
  const openModal = useOpenModal()

  useEffect(() => {
    api.listPendingConnections().then((res) => setPending(res.data.connections))
  }, [])

  const onAccept = (id: string) => {
    api.acceptConnection(id).then(() => setPending((old) => old && old.filter(({ id: _id }) => _id !== id)))
  }

  const onDeny = (id: string) => {
    api.denyConnection(id).then(() => setPending((old) => old && old.filter(({ id: _id }) => _id !== id)))
  }

  const addContact = () => {
    openModal('/app/contacts/add')
  }

  return (
    <div className="px-2 py-1 flex flex-col bg-white rounded-md min-h-[200px] max-h-[400px] w-[300px]">
      {!pending ? (
        <FullLoader />
      ) : pending.length ? (
        <div className="flex-col grow overflow-y-auto my-2">
          {pending?.map(({ id, name }) => (
            <div key={id} className="flex w-full items-center space-x-1 py-1 px-2">
              <p className="grow">{name}</p>
              <button className="button rounded-md w-max h-8 bg-primary text-white px-3 py-0" onClick={() => onAccept(id)}>
                accept
              </button>
              <button className="button rounded-full text-dark hover:bg-secondary flex items-center p-1">
                <i className="material-symbols-rounded color-white w-min" onClick={() => onDeny(id)}>
                  cancel
                </i>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-dark my-3 grow">No pending connections</p>
      )}
      <button children="Add a contact" className="button-primary" onClick={addContact} />
    </div>
  )
}

export default PendingContacts
