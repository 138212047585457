import { useCallback, useEffect, useState } from 'react'

const usePaginated = <T = any>(endpoint: null | ((nextId?: string) => Promise<{ nextId: string | null } & { [key: string]: T[] }>), dataKey = 'data') => {
  const [data, setData] = useState<T[]>()
  const [nextId, setNextId] = useState<string | null>()
  const [loadingMore, setLoadingMore] = useState(false)
  const [error, setError] = useState()

  const load = useCallback(() => {
    endpoint && endpoint()
      .then((data) => {
        setNextId(data.nextId)
        setData(data[dataKey])
      })
      .catch(setError)
  }, [dataKey, endpoint])

  useEffect(() => {
    load()
  }, [load])

  const loadMore = useCallback(() => {
    if (error) return
    if (!data) return
    if (loadingMore || !nextId) return
    setLoadingMore(true)
    endpoint && endpoint(nextId)
      .then((data) => {
        setNextId(data.nextId)
        setData((old) => old?.concat(data[dataKey]))
      })
      .finally(() => setLoadingMore(false))
  }, [data, dataKey, endpoint, loadingMore, nextId, error])

  return [data, loadMore, loadingMore, Boolean(nextId), setData, load] as const
}

export default usePaginated
