import useDarkMode from 'hooks/useDarkMode'
import { FC } from 'react'

const DarkModeToggle: FC = () => {
  const { dark, toggle } = useDarkMode()
  return (
    <div onClick={toggle} className={['relative p-1 w-10 transition-all cursor-pointer rounded-full border', dark ? 'bg-primary-200 border-primary-500' : 'bg-white border-slate-200'].asClass}>
      <div className={["w-4 h-4 rounded-full transition-all", dark ? "bg-primary-500 translate-x-4" : "bg-slate-200"].asClass} />
    </div>
  )
}

export default DarkModeToggle
