import { useState, useEffect, PropsWithChildren, forwardRef, useImperativeHandle, Dispatch, SetStateAction } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import UserContext from './userContext'
import useAuthContext from '../Auth/useAuthContext'
import firebase from '../../config/firebase'
import api from 'api'
import { useLocation } from 'react-router-dom'
import FullscreenError from 'components/FullScreenError'
import { IUser } from 'types/user'
import useDarkMode from 'hooks/useDarkMode'
import { adminId } from 'constants/admin'
import adminApi from 'api/admin'

export interface IConnectedClient {
  id: string
  client: string
}

const UserProvider = forwardRef<{ setUser: Dispatch<SetStateAction<IUser | null>>; setConfig: Dispatch<SetStateAction<any>> }, PropsWithChildren>(({ children }, forwarded) => {
  const auth = useAuthContext()
  const [user, setUser] = useState<IUser | null>(null)
  const [config, setConfig] = useState<any>()
  const location = useLocation()
  const [error, setError] = useState<Error>()
  const [sellerOptions, setSellerOptions] = useState<IConnectedClient[]>()
  const { dark } = useDarkMode()

  useEffect(() => {
    if (auth?.uid) {
      if (!user) {
        api
          .getUser()
          .then(setUser)
          .catch(() => setError(new Error("Couldn't load user data, try again")))
        if (!sessionStorage.getItem('act-as')) {
          const unsubscribe = onSnapshot(doc(firebase.db, 'users', auth.uid), (doc) => {
            setUser((old) => old && { ...old, ...doc.data() })
          })

          return unsubscribe
        }
      }
    } else {
      sessionStorage.clear()
      setUser(null)
      setConfig(undefined)
    }
  }, [auth?.uid, user])

  useImperativeHandle(
    forwarded,
    () => ({
      setUser,
      setConfig,
    }),
    [setUser, setConfig]
  )

  useEffect(() => {
    if (!user || config) return
    api
      .getConfig()
      .then((config) => setConfig(config || {}))
      .catch(() => setError(new Error("Couldn't load user data, try again")))
    if (user.isPrepCenter) {
      if (user.id === adminId) {
        /*adminApi.getClients().then((res) => {
          const formatted = res.users.map((client) => ({ id: client.id, client: client.name || client.email  }))
          setSellerOptions(formatted)
        })*/
      } else {
        api
          .getConnectedClients()
          .then(setSellerOptions)
          .catch(() => {})
      }
    }
  }, [user, config])

  useEffect(() => {
    const shades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950]
    if (location.pathname.startsWith('/app')) {
      if (config?.primaryColor) {
        const [r, g, b] = config.primaryColor.split(' ').map((c: string) => parseInt(c, 10))
        shades.forEach((shade) => {
          let m = shade / 500
          const newR = 256 - Math.floor((256 - r) * m)
          const newG = 256 - Math.floor((256 - g) * m)
          const newB = 256 - Math.floor((256 - b) * m)
          document.documentElement.style.setProperty(`--color-primary-${shade}`, `${newR} ${newG} ${newB}`)
        })
      }
    } else {
      shades.forEach((shade) => {
        document.documentElement.style.removeProperty('--color-primary-' + shade)
      })
    }
  }, [config, location, dark])

  if (error && location.pathname.startsWith('/app')) return <FullscreenError error={error} />

  return <UserContext.Provider value={config && user ? { ...user, config, clients: sellerOptions } : null}>{children}</UserContext.Provider>
})

export default UserProvider
