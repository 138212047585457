import useUserContext from "contexts/User/useUserContext"

const useCanShop = () => {
    const me = useUserContext();

    if (!me) return false;

    if (me.distributionAccess || (me.referrer === "KydYecXlOOeVSABzkoRtv5UlzZj1")) return true;

    return false;
}

export default useCanShop