import { FC } from 'react'

const TOS: FC = () => {
  return (
    <div id="tos">
      <h1>
        <span>Featured Prep Center Software Terms &amp; Conditions</span>
      </h1>
      <p>
        <span>APEX LOGISTICS, LLC</span>
      </p>
      <p>
        <span>Herein referred to as &ldquo;APEX&rdquo;</span>
      </p>
      <p>
        <span>T</span>
        <span>hese Terms &amp; Conditions govern the </span>
      </p>
      <ul>
        <li>
          <span>use of the Amazon Preparation Tracking Software license and,</span>
        </li>
        <li>
          <span>
            any other related agreement or legal relationship with the APEX and the Featured Prep Center (herein referred to collectively as the &ldquo;user&rdquo; or &ldquo;users&rdquo;, and
          </span>
        </li>
        <li>
          <span>
            the pricing of the software licensing arrangement including the Maximum Purchase Price to be paid to Featured Prep Center by APEX who may, from time to time, refer clients from it&rsquo;s
            broad network of Software Users and serve as the facilitator of inventory preparation on behalf of APEX&rsquo;s users.
          </span>
        </li>
      </ul>
      <p>
        <span>By accepting, you are entering into a legally binding document. Capitalized words are defined in the relevant dedicated section of this document.</span>
      </p>
      <p>
        <span>The User must read this document carefully.</span>
      </p>
      <p>
        <span>This Amazon Preparation Tracking Software license is provided by:</span>
      </p>
      <p>
        <span>
          APEX Logistics, LLC
          <br />8 The Green, Suite B<br />
          Dover, DE 19901
        </span>
      </p>
      <p>
        <span>User may contact us at: c</span>
        <span>eo@apexautomationservices.com</span>
      </p>
      <h2>
        <span>What to Know at a Glance</span>
      </h2>
      <ul>
        <li>
          <span>
            Please note that some provisions in these Terms &amp; Conditions may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those
            Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.
          </span>
        </li>
      </ul>
      <hr />
      <p>
        <span></span>
      </p>
      <h2>
        <span>How this Amazon Preparation Tracking Software License works</span>
      </h2>
      <p>
        <span>
          Without prejudice to its role as a mere technical intermediary as described above, APEX may provide additional services to either party of such interaction, such as providing packaging,
          shipping or facilitating dispute resolution as needed or requested by the User.
        </span>
      </p>
      <p>
        <span>
          This Amazon Preparation Tracking Software license merely serves as a platform to allow Users to interact with each other. APEX &nbsp;therefore is not directly involved in any such
          interactions between Users.
        </span>
      </p>
      <h2>
        <span>Terms &amp; Conditions</span>
        <span>&nbsp;Of Use</span>
      </h2>
      <p>
        <span>Unless otherwise specified, the Terms &amp; Conditions of Use detailed in this section apply generally when using this Amazon Preparation Tracking Software license.</span>
      </p>
      <p>
        <span>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</span>
      </p>
      <p>
        <span>By using this Amazon Preparation Tracking Software license, Users confirm to meet the following requirements:</span>
      </p>
      <ul>
        <li>
          <span>There are no restrictions for Users as Consumers and/or Business Users;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Users aren&rsquo;t located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a &ldquo;terrorist-supporting&rdquo; country;
          </span>
        </li>
        <li>
          <span>Users aren&rsquo;t listed on any U.S. Government list of prohibited or restricted parties;</span>
        </li>
      </ul>
      <h3>
        <span>Account registration</span>
      </h3>
      <p>
        <span>
          To use the Amazon Preparation Tracking Software license Users must register or create an account, providing all required data or information in a complete and truthful manner. Failure to do
          so will restrict the use of the Amazon Preparation Tracking Software license.
        </span>
      </p>
      <p>
        <span>
          Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength
          permitted by this Amazon Preparation Tracking Software license.
        </span>
      </p>
      <p>
        <span>By registering, Users agree to be fully responsible for all activities that occur under their username and password.</span>
      </p>
      <p>
        <span>
          <br />
          Users are required to immediately and unambiguously inform APEX via the contact details indicated in this document, if they think their personal information, including but not limited to
          User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.
        </span>
      </p>
      <h4>
        <span>Account termination</span>
      </h4>
      <p>
        <span>Users can terminate their account and stop using the Service at any time by doing the following:</span>
      </p>
      <ul>
        <li>
          <span>By directly contacting APEX at the contact details provided in this document.</span>
        </li>
      </ul>
      <h4>
        <span>Account suspension and deletion</span>
      </h4>
      <p>
        <span>APEX</span>
        <span>
          &nbsp;reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts which it deems inappropriate, offensive or in violation of these Terms
          &amp; Conditions.
        </span>
      </p>
      <p>
        <span>The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.</span>
      </p>
      <p>
        <span>The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.</span>
      </p>
      <h3>
        <span>Content on this Amazon Preparation Tracking Software License</span>
      </h3>
      <p>
        <span>Unless where otherwise specified or clearly recognizable, all content available on this Amazon Preparation Tracking Software License is owned or provided by APEX or its licensors.</span>
      </p>
      <p>
        <span>
          APEX undertakes its utmost effort to ensure that the content provided on this Amazon Preparation Tracking Software License infringes no applicable legal provisions or third-party rights.
          However, it may not always be possible to achieve such a result.
          <br />
          In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided
          in this document.
        </span>
      </p>
      <h4>
        <span>Rights regarding content on this Amazon Preparation Tracking Software License - All rights reserved</span>
      </h4>
      <p>
        <span>APEX</span>
        <span>&nbsp;holds and reserves all intellectual property rights for any such content.</span>
      </p>
      <p>
        <span>Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.</span>
      </p>
      <p>
        <span>
          In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit,
          transfer/assign to third parties or create derivative works from the content available on this Amazon Preparation Tracking Software License, nor allow any third party to do so through the
          User or their device, even without the User&#39;s knowledge.
        </span>
      </p>
      <p>
        <span>
          Where explicitly stated on this Amazon Preparation Tracking Software License, the User may download, copy and/or share some content available through this Amazon Preparation Tracking
          Software License for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by APEX are correctly implemented.
        </span>
      </p>
      <p>
        <span>Any applicable statutory limitation or exception to copyright shall stay unaffected.</span>
      </p>
      <h3>
        <span>Access to external resources</span>
      </h3>
      <p>
        <span>
          Through this Amazon Preparation Tracking Software License Users may have access to external resources provided by third parties. Users acknowledge and accept that APEX has no control over
          such resources and is therefore not responsible for their content and availability.
        </span>
      </p>
      <p>
        <span>
          Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties&rsquo; terms and
          conditions or, in the absence of those, applicable statutory law.
        </span>
      </p>
      <h3>
        <span>Acceptable use</span>
      </h3>
      <p>
        <span>
          This Amazon Preparation Tracking Software License and the Service may only be used within the scope of what they are provided for, under these Terms &amp; Conditions and applicable law.
        </span>
      </p>
      <p>
        <span>
          Users are solely responsible for making sure that their use of this Amazon Preparation Tracking Software License and/or the Service violates no applicable law, regulations or third-party
          rights.
        </span>
      </p>
      <p>
        <span>
          Therefore, APEX reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Amazon Preparation Tracking Software License
          or the Service, terminating contracts, reporting any misconduct performed through this Amazon Preparation Tracking Software License or the Service to the competent authorities &ndash; such
          as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:
        </span>
      </p>
      <ul>
        <li>
          <span>violate laws, regulations and/or these Terms &amp; Conditions;</span>
        </li>
        <li>
          <span>infringe any third-party rights;</span>
        </li>
        <li>
          <span>considerably impair APEX&rsquo;s legitimate interests;</span>
        </li>
        <li>
          <span>offend APEX or any third party.</span>
        </li>
      </ul>
      <h3>
        <span>Client </span>
        <span>Referral Program</span>
      </h3>
      <p>
        <span>Section 1.0</span>
      </p>
      <p>
        <span>
          This Amazon Preparation Tracking Software License gives Users the opportunity to receive advantages if, as a result of their recommendation, any new User purchases a Product offered on this
          Amazon Preparation Tracking Software License.
        </span>
      </p>
      <p>
        <span>
          <br />
          In order to take advantage of this offer, Users may invite others to purchase the Products on this Amazon Preparation Tracking Software License by sending them a tell-a-friend code provided
          by APEX. Such codes can only be redeemed once.
        </span>
      </p>
      <p>
        <span>
          If upon purchase of the Products on this Amazon Preparation Tracking Software License any of the persons invited redeems a tell-a-friend code, the inviting User shall receive the advantage
          or benefit (such as: a price reduction, an additional service feature, an upgrade etc.) specified on this Amazon Preparation Tracking Software License.
        </span>
      </p>
      <p>
        <span>Tell-a-friend codes may be limited to specific Products among those offered on this Amazon Preparation Tracking Software License.</span>
      </p>
      <p>
        <span>APEX reserves the right to end the offer at any time at its own discretion.</span>
      </p>
      <p>
        <span>While no general limitation applies to the number of persons that can be invited, the amount of advantage or benefit that each inviting User can receive, may be limited.</span>
      </p>
      <p>
        <span>Section 2.0</span>
      </p>
      <p>
        <span>
          Client acknowledges that APEX Logistics, LLC has numerous Amazon FBA sellers using the software to operate their business that may wish to partner with a Prep Center featured on the
          platform. APEX Logistics, LLC and the Prep Center agree that any client that signs with the Prep Center through the APEX software platform create an affiliate relationship. Prep Center
          agrees that it is a privilege to be featured on the platform and will adhere to APEX&rsquo;s Performance Requirements outlined below.
        </span>
      </p>
      <p>
        <span>Performance Requirements</span>
      </p>
      <p>
        <span>
          APEX Logistics software is designed to optimize the fulfillment experience for both the Client and the Prep Center. As such, APEX requires that it&rsquo;s Featured Prep Centers adhere to
          certain performance and customer service requirements as outlined below:
        </span>
      </p>
      <ul>
        <li>
          <span>Prep Center agrees to be responsive to the Client and respond to client within 24-48 hours at all times</span>
        </li>
        <li>
          <span>Once items have been received, the Prep Center must confirm shipment within 5 business days of the purchase order</span>
        </li>
        <li>
          <span>The Prep Center must communicate with the client through the APEX software platform</span>
        </li>
      </ul>
      <p>
        <span>Remedy:</span>
      </p>
      <p>
        <span>
          In the event that APEX receives complaints from clients about Prep Center performance standards, APEX reserves the right to issue one written warning and request a written plan of action
          from the Prep Center. In the event additional complaints are received, APEX reserves the right to remove Prep Center&rsquo;s promotional rights from the platform and migrate all clients to
          other Prep Centers.
        </span>
      </p>
      <p>
        <span>APEX Fee Structure:</span>
      </p>
      <p>
        <span>
          Under this Agreement, APEX Logistics, LLC agrees to broadcast its Prep Center to our network of potential clients. In exchange for bringing additional exposure to the Prep Center, the Client
          agrees to a Maximum Purchase Price for Amazon prep services. &nbsp;It is understood that under this affiliate relationship Prep Center will bill Apex Logistics, LLC and APEX Logistics, LLC
          reserves the right to bill the client for inventory prep services at a rate equal to or above the Maximum Purchase Price provided by the Prep Center herein.
        </span>
      </p>
      <p>
        <span>APEX Logistics, LLC agrees to compensate the Prep Center according to the following Maximum Purchase Price Schedule:</span>
      </p>
      <p>
        <span>$0.40 per FNSKU</span>
      </p>
      <p>
        <span>$0.10 per extra polybag</span>
      </p>
      <p>
        <span>$0.15 extra bubble wrap</span>
      </p>
      <p>
        <span>$0.15 for the first bundle, plus .05 additional bundle.</span>
      </p>
      <p>
        <span>$2.00 for each medium box</span>
      </p>
      <p>
        <span>$3.00 for each large box</span>
      </p>
      <p>
        <span>$15.00 for each pallet receiving/outbound</span>
      </p>
      <p>
        <span>Non-Solicitation</span>
      </p>
      <p>
        <span>
          Prep Center expressly agrees that it will not solicit APEX Logistics, LLC&rsquo;s Clients outside of the software application, and that APEX Logistics, LLC would experience serious financial
          loss as the result of such a breach. In the event the Prep Center is suspected of soliciting APEX&rsquo;s clients outside of the platform, APEX Logistics, LLC reserves the right to remove
          the Prep Center and Client from the system and seek no less than three (3) times the assumed damages caused by the Prep Center&rsquo;s violation of this agreement, in addition to legal fees.
        </span>
      </p>
      <p>
        <span>Accepted and Agreed:</span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>_________________________________________</span>
      </p>
      <p>
        <span>Prep Center Client</span>
      </p>
      <h3>
        <span>Software license</span>
      </h3>
      <p>
        <span>
          Any intellectual or industrial property rights, and any other exclusive rights on software or technical Amazon Preparation Tracking Software Licenses embedded in or related to this Amazon
          Preparation Tracking Software License are held by APEX and/or its licensors.
        </span>
      </p>
      <p>
        <span>
          Subject to Users&rsquo; compliance with and notwithstanding any divergent provision of these Terms &amp; Conditions, APEX merely grants Users a revocable, non-exclusive, non-sublicensable
          and non-transferable license to use the software and/or any other technical means embedded in the Service within the scope and for the purposes of this Amazon Preparation Tracking Software
          License and the Service offered.
        </span>
      </p>
      <p>
        <span>
          This license does not grant Users any rights to access, usage or disclosure of the original source code. All techniques, algorithms, and procedures contained in the software and any
          documentation thereto related is APEX&rsquo;s or its licensors&rsquo; sole property.
        </span>
      </p>
      <p>
        <span>All rights and license grants to Users shall immediately terminate upon any termination or expiration of the Agreement.</span>
      </p>
      <h2>
        <span>TERMS &amp; CONDITIONS OF SALE</span>
      </h2>
      <h3>
        <span>Paid Products</span>
      </h3>
      <p>
        <span>Some of the Products provided on this Amazon Preparation Tracking Software License, as part of the Service, are provided on the basis of payment.</span>
      </p>
      <p>
        <span>
          The fees, duration and conditions applicable to the purchase of such Products are described below and in the dedicated sections of this Amazon Preparation Tracking Software License.
        </span>
      </p>
      <h3>
        <span>Product description</span>
      </h3>
      <p>
        <span>
          Prices, descriptions or availability of Products are outlined in the respective sections of this Amazon Preparation Tracking Software License and are subject to change without notice.
        </span>
      </p>
      <p>
        <span>
          While Products on this Amazon Preparation Tracking Software License are presented with the greatest accuracy possible, representation on this Amazon Preparation Tracking Software License
          through any means (including, as the case may be, graphic material, images, colors, sounds, designs, etc.) is for reference only and implies no warranty as to the characteristics of the
          purchased Product.
        </span>
      </p>
      <p>
        <span>The characteristics of the chosen Product will be outlined during the purchasing process.</span>
      </p>
      <h3>
        <span>Purchasing process</span>
      </h3>
      <p>
        <span>Any steps taken from choosing a Product to order submission form part of the purchasing process.</span>
      </p>
      <p>
        <span>The purchasing process includes these steps:</span>
      </p>
      <ul>
        <li>
          <span>Users must choose the desired Product and verify their purchase selection.</span>
        </li>
        <li>
          <span>After having reviewed the information displayed in the purchase selection, Users may place the order by submitting it.</span>
        </li>
      </ul>
      <h3>
        <span>Order submission</span>
      </h3>
      <p>
        <span>When the User submits an order, the following applies:</span>
      </p>
      <ul>
        <li>
          <span>
            The submission of an order determines contract conclusion and therefore creates for the User the obligation to pay the price, taxes and possible further fees and expenses, as specified on
            the order page.
          </span>
        </li>
        <li>
          <span>
            In case the purchased Product requires active input from the User, such as the provision of personal information or data, specifications or special wishes, the order submission creates an
            obligation for the User to cooperate accordingly.
          </span>
        </li>
        <li>
          <span>Upon submission of the order, Users will receive a receipt confirming that the order has been received.</span>
        </li>
      </ul>
      <p>
        <span>All notifications related to the described purchasing process shall be sent to the email address provided by the User for such purposes.</span>
      </p>
      <h3>
        <span>Prices</span>
      </h3>
      <p>
        <span>Users are informed during the purchasing process and before order submission, about any fees, taxes and costs (including, if any, delivery costs) that they will be charged.</span>
      </p>
      <p>
        <span>Prices on this Amazon Preparation Tracking Software License are displayed:</span>
      </p>
      <ul>
        <li>
          <span>either exclusive or inclusive of any applicable fees, taxes and costs, depending on the section the User is browsing.</span>
        </li>
      </ul>
      <h3>
        <span>Offers and discounts</span>
      </h3>
      <p>
        <span>APEX</span>
        <span>
          &nbsp;or any Seller may offer discounts or provide special offers for the purchase of Products. Any such offer or discount shall always be subject to the eligibility criteria and the Terms
          &amp; Conditions set out in the corresponding section of this Amazon Preparation Tracking Software License.
          <br />
          Offers and discounts are always granted at APEX&rsquo;s or Seller&rsquo;s sole discretion. Repeated or recurring offers or discounts create no claim/title or right that Users may enforce in
          the future. Depending on the case, discounts or offers shall be valid for a limited time only or while stocks last.
        </span>
      </p>
      <h3>
        <span>Coupons</span>
      </h3>
      <p>
        <span>
          Offers or discounts can be based on Coupons. If breach of the conditions applicable to Coupons occurs, APEX or Seller can legitimately refuse to fulfill its contractual obligations and
          expressly reserves the right to take appropriate legal action to protect its rights and interests.
          <br />
          Notwithstanding the provisions below, any additional or diverging rules applicable to using the Coupon displayed in the corresponding information page or on the Coupon itself shall always
          prevail. Unless otherwise stated, these rules apply to the use of Coupons:
        </span>
      </p>
      <ul>
        <li>
          <span>Each Coupon is only valid when used in the manner and within the timeframe specified on the website and/or the Coupon;</span>
        </li>
        <li>
          <span>A Coupon may only be applied, in its entirety, at the actual time of purchase &ndash; partial use is not permitted;</span>
        </li>
        <li>
          <span>
            Unless otherwise stated, single-use Coupons may only be used once per purchase and therefore may only be applied a single time even in cases involving installment-based purchases;
          </span>
        </li>
        <li>
          <span>A Coupon cannot be applied cumulatively;</span>
        </li>
        <li>
          <span>
            The Coupon must be redeemed exclusively within the time specified in the offer. After this period, the Coupon will automatically expire, precluding any possibility for the User to claim
            the relevant rights, including cash-out;
          </span>
        </li>
        <li>
          <span>The User is not entitled to any credit/refund/compensation if there is a difference between the value of the Coupon and the redeemed value;</span>
        </li>
        <li>
          <span>
            The Coupon is intended solely for non&ndash;commercial use. Any reproduction, counterfeiting and commercial trade of the Coupon is strictly forbidden, along with any illegal activity
            related to the purchase and/or use of the Coupon.
          </span>
        </li>
      </ul>
      <h3>
        <span>Methods of payment</span>
      </h3>
      <p>
        <span>Information related to accepted payment methods are made available during the purchasing process.</span>
      </p>
      <p>
        <span>
          Some payment methods may only be available subject to additional conditions or fees. In such cases related information can be found in the dedicated section of this Amazon Preparation
          Tracking Software License.
        </span>
      </p>
      <p>
        <span>
          All payments are independently processed through third-party services. Therefore, this Amazon Preparation Tracking Software License does not collect any payment information &ndash; such as
          credit card details &ndash; but only receives a notification once the payment has been successfully completed.
        </span>
      </p>
      <p>
        <span>
          If a payment through the available methods fails or is refused by the payment service provider, APEX shall be under no obligation to fulfill the purchase order. Any possible costs or fees
          resulting from the failed or refused payment shall be borne by the User.
        </span>
      </p>
      <h3>
        <span>Retention of usage rights</span>
      </h3>
      <p>
        <span>Users do not acquire any rights to use the purchased Product until the total purchase price is received by APEX.</span>
      </p>
      <h3>
        <span>Delivery</span>
      </h3>
      <h4>
        <span>Performance of services</span>
      </h4>
      <p>
        <span>
          The purchased service shall be performed or made available within the timeframe specified on this Amazon Preparation Tracking Software License or as communicated before the order submission.
        </span>
      </p>
      <h3>
        <span>Contract duration</span>
      </h3>
      <h4>
        <span>Subscriptions</span>
      </h4>
      <p>
        <span>Subscriptions allow Users to receive a Product continuously or regularly over time. Details regarding the type of subscription and termination are outlined below.</span>
      </p>
      <h4>
        <span>Open-ended subscriptions</span>
      </h4>
      <p>
        <span>Paid subscriptions begin on the day the payment is received by APEX.</span>
      </p>
      <p>
        <span>In order to maintain subscriptions, Users must pay the required recurring fee in a timely manner. Failure to do so may cause service interruptions.</span>
      </p>
      <h4>
        <span>Termination of open-ended subscriptions</span>
      </h4>
      <p>
        <span>
          Open-ended subscriptions may be terminated at any time by sending a clear and unambiguous termination notice to APEX using the contact details provided in this document, or &mdash; if
          applicable &mdash; by using the corresponding controls inside this Amazon Preparation Tracking Software License.
        </span>
      </p>
      <p>
        <span>Terminations shall take effect 30 days after the notice of termination has been received by APEX.</span>
      </p>
      <h2>
        <span>User rights</span>
      </h2>
      <h3>
        <span>Right of withdrawal</span>
      </h3>
      <p>
        <span>
          Unless exceptions apply, the User may be eligible to withdraw from the contract within the period specified below (generally 14 days), for any reason and without justification. Users can
          learn more about the withdrawal conditions within this section.
        </span>
      </p>
      <p>
        <span>
          The right of withdrawal does not apply to contracts that have been entered into with APEX. It may, however, apply to contracts entered into with Sellers. If applicable, the right to withdraw
          from contracts entered into with Sellers via this Amazon Preparation Tracking Software License is subject to the conditions and specifications made available by each Seller on this Amazon
          Preparation Tracking Software License.
        </span>
      </p>
      <h2>
        <span>Liability and Indemnification</span>
      </h2>
      <h3>
        <span>US Users</span>
      </h3>
      <h4>
        <span>Disclaimer of Warranties</span>
      </h4>
      <p>
        <span>
          This Amazon Preparation Tracking Software License is provided strictly on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. Use of the Service is at Users&rsquo; own risk. To the
          maximum extent permitted by applicable law, APEX expressly disclaims all conditions, representations, and warranties &mdash; whether express, implied, statutory or otherwise, including, but
          not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written,
          obtained by user from owner or through the Service will create any warranty not expressly stated herein.
        </span>
      </p>
      <p>
        <span>
          Without limiting the foregoing, APEX, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is
          accurate, reliable or correct; that the Service will meet Users&rsquo; requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any
          defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is
          downloaded at users own risk and users shall be solely responsible for any damage to Users&rsquo; computer system or mobile device or loss of data that results from such download or
          Users&rsquo; use of the Service.
        </span>
      </p>
      <p>
        <span>
          APEX does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or
          service, and APEX shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.
        </span>
      </p>
      <p>
        <span>
          The Service may become inaccessible or it may not function properly with Users&rsquo; web browser, mobile device, and/or operating system. APEX cannot be held liable for any perceived or
          actual damages arising from Service content, operation, or use of this Service.
        </span>
      </p>
      <p>
        <span>
          Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives
          Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited
          by applicable law.
        </span>
      </p>
      <h4>
        <span>Limitations of liability</span>
      </h4>
      <p>
        <span>
          To the maximum extent permitted by applicable law, in no event shall APEX, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be
          liable for
        </span>
      </p>
      <ul>
        <li>
          <span>
            any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses,
            arising out of or relating to the use of, or inability to use, the Service; and
          </span>
        </li>
        <li>
          <span>any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;</span>
        </li>
        <li>
          <span>any errors, mistakes, or inaccuracies of content;</span>
        </li>
        <li>
          <span>personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;</span>
        </li>
        <li>
          <span>any unauthorized access to or use of APEX&rsquo;s secure servers and/or any and all personal information stored therein;</span>
        </li>
        <li>
          <span>any interruption or cessation of transmission to or from the Service;</span>
        </li>
        <li>
          <span>any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;</span>
        </li>
        <li>
          <span>
            any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the
            Service; and/or
          </span>
        </li>
        <li>
          <span>
            the defamatory, offensive, or illegal conduct of any User or third party. In no event shall APEX, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners,
            suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to APEX hereunder in the
            preceding 12 months, or the period of duration of this agreement between APEX and User, whichever is shorter.
          </span>
        </li>
      </ul>
      <p>
        <span>
          This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence,
          strict liability, or any other basis, even if company has been advised of the possibility of such damage.
        </span>
      </p>
      <p>
        <span>
          Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to User. The Terms &amp;
          Conditions give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction. The disclaimers, exclusions, and limitations of liability under
          the Terms &amp; Conditions shall not apply to the extent prohibited by applicable law.
        </span>
      </p>
      <h4>
        <span>Indemnification</span>
      </h4>
      <p>
        <span>
          The User agrees to defend, indemnify and hold APEX and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any
          and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from
        </span>
      </p>
      <ul>
        <li>
          <span>User&rsquo;s use of and access to the Service, including any data or content transmitted or received by User;</span>
        </li>
        <li>
          <span>
            User&rsquo;s violation of these Terms &amp; Conditions, including, but not limited to, User&rsquo;s breach of any of the representations and warranties set forth in these Terms &amp;
            Conditions;
          </span>
        </li>
        <li>
          <span>User&rsquo;s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;</span>
        </li>
        <li>
          <span>User&rsquo;s violation of any statutory law, rule, or regulation;</span>
        </li>
        <li>
          <span>
            any content that is submitted from User&rsquo;s account, including third party access with User&rsquo;s unique username, password or other security measure, if applicable, including, but
            not limited to, misleading, false, or inaccurate information;
          </span>
        </li>
        <li>
          <span>User&rsquo;s wilful misconduct; or</span>
        </li>
        <li>
          <span>statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.</span>
        </li>
      </ul>
      <p>
        <span></span>
      </p>
      <h3>
        <span>EU Users</span>
      </h3>
      <h4>
        <span>Limitation of liability for User activities on this Amazon Preparation Tracking Software License</span>
      </h4>
      <p>
        <span>Users acknowledge and accept that APEX merely provides Users with the technical infrastructure and features incorporated in this Amazon Preparation Tracking Software License.</span>
      </p>
      <p>
        <span>
          APEX does not intermediate, moderate, promote or intervene in interactions, agreements or transactions between Users and therefore bears no liability for any such interactions among Users,
          including the performance of any Users&#39; obligations.
        </span>
      </p>
      <p>
        <span>
          Users, in particular, acknowledge and accept that APEX is not involved in sales and purchases by Users qualifying respectively as Sellers or Buyers over this Amazon Preparation Tracking
          Software License.
        </span>
      </p>
      <p>
        <span>
          This means that Sellers and Buyers are solely liable for respectively offering and purchasing through this Amazon Preparation Tracking Software License and for the obligations resulting
          thereof.
        </span>
      </p>
      <p>
        <span>In particular, APEX shall bear no liability for:</span>
      </p>
      <ul>
        <li>
          <span>any pre-contractual statement, claim or description of the Products offered through/via this Amazon Preparation Tracking Software License;</span>
        </li>
        <li>
          <span>
            the existence of any applicable license, authorization, qualification or other official permit allowing Sellers to offer specific goods or services, as may be required by applicable law;
          </span>
        </li>
        <li>
          <span>the Buyers&#39; eligibility (e.g. age, solvency etc.) for purchase according to applicable law;</span>
        </li>
        <li>
          <span>any obligation stipulated by Users over this Amazon Preparation Tracking Software License, including but not limited to product guarantees and product safety;</span>
        </li>
        <li>
          <span>any claim based on partial, incorrect or failed performance of binding agreements entered into on this Amazon Preparation Tracking Software License.</span>
        </li>
      </ul>
      <h3>
        <span>Australian Users</span>
      </h3>
      <h4>
        <span>Limitation of liability</span>
      </h4>
      <p>
        <span>
          Nothing in these Terms &amp; Conditions excludes, restricts or modifies any guarantee, condition, warranty, right or remedy which the User may have under the Competition and Consumer Act
          2010 (Cth) or any similar State and Territory legislation and which cannot be excluded, restricted or modified (non-excludable right). To the fullest extent permitted by law, our liability
          to the User, including liability for a breach of a non-excludable right and liability which is not otherwise excluded under these Terms &amp; Conditions, is limited, at APEX&rsquo;s sole
          discretion, to the re-performance of the services or the payment of the cost of having the services supplied again.
        </span>
      </p>
      <h2>
        <span>Common Provisions</span>
      </h2>
      <h3>
        <span>No Waiver</span>
      </h3>
      <p>
        <span>APEX</span>
        <span>
          &rsquo;s failure to assert any right or provision under these Terms &amp; Conditions shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or
          continuing waiver of such term or any other term.
        </span>
      </p>
      <h3>
        <span>Service interruption</span>
      </h3>
      <p>
        <span>
          To ensure the best possible service level, APEX reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.
        </span>
      </p>
      <p>
        <span>
          Within the limits of law, APEX may also decide to suspend or terminate the Service altogether. If the Service is terminated, APEX will cooperate with Users to enable them to withdraw
          Personal Data or information in accordance with applicable law.
        </span>
      </p>
      <p>
        <span>
          Additionally, the Service might not be available due to reasons outside APEX&rsquo;s reasonable control, such as &ldquo;force majeure&rdquo; (eg. labor actions, infrastructural breakdowns or
          blackouts etc).
        </span>
      </p>
      <h3>
        <span>Service reselling</span>
      </h3>
      <p>
        <span>
          Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Amazon Preparation Tracking Software License and of its Service without APEX&rsquo;s express prior
          written permission, granted either directly or through a legitimate reselling programme.
        </span>
      </p>
      <h3>
        <span>Privacy policy</span>
      </h3>
      <p>
        <span>
          For information about the use of their personal data, Users must refer to the privacy policy of this Amazon Preparation Tracking Software License which is hereby declared to be part of these
          Terms &amp; Conditions.
        </span>
      </p>
      <h3>
        <span>Intellectual property rights</span>
      </h3>
      <p>
        <span>
          Without prejudice to any more specific provision of these Terms &amp; Conditions, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights
          related to this Amazon Preparation Tracking Software License are the exclusive property of APEX or its licensors and are subject to the protection granted by applicable laws or international
          treaties relating to intellectual property.
        </span>
      </p>
      <p>
        <span>
          All trademarks &mdash; nominal or figurative &mdash; and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Amazon
          Preparation Tracking Software License are, and remain, the exclusive property of APEX or its licensors and are subject to the protection granted by applicable laws or international treaties
          related to intellectual property.
        </span>
      </p>
      <h3>
        <span>Changes to these Terms &amp; Conditions</span>
      </h3>
      <p>
        <span>APEX</span>
        <span>&nbsp;reserves the right to amend or otherwise modify these Terms &amp; Conditions at any time. In such cases, APEX will appropriately inform the User of these changes.</span>
      </p>
      <p>
        <span>Such changes will only affect the relationship with the User for the future.</span>
      </p>
      <p>
        <span>
          The continued use of the Service will signify the User&rsquo;s acceptance of the revised Terms &amp; Conditions. If Users do not wish to be bound by the changes, they must stop using the
          Service. Failure to accept the revised Terms &amp; Conditions, may entitle either party to terminate the Agreement.
        </span>
      </p>
      <p>
        <span>The applicable previous version will govern the relationship prior to the User&#39;s acceptance. The User can obtain any previous version from APEX.</span>
      </p>
      <p>
        <span>If required by applicable law, APEX will specify the date by which the modified Terms &amp; Conditions will enter into force.</span>
      </p>
      <h3>
        <span>Assignment of contract</span>
      </h3>
      <p>
        <span>APEX</span>
        <span>
          &nbsp;reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms &amp; Conditions, taking the User&rsquo;s legitimate
          interests into account. Provisions regarding changes of these Terms &amp; Conditions will apply accordingly.
        </span>
      </p>
      <p>
        <span>Users may not assign or transfer their rights or obligations under these Terms &amp; Conditions in any way, without the written permission of APEX.</span>
      </p>
      <h3>
        <span>Contacts</span>
      </h3>
      <p>
        <span>All communications relating to the use of this Amazon Preparation Tracking Software License must be sent using the contact information stated in this document.</span>
      </p>
      <h3>
        <span>Severability</span>
      </h3>
      <p>
        <span>
          Should any provision of these Terms &amp; Conditions be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect
          the validity of the remaining provisions, which shall remain in full force and effect.
        </span>
      </p>
      <h4>
        <span>US Users</span>
      </h4>
      <p>
        <span>
          Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original
          intent. These Terms &amp; Conditions constitute the entire Agreement between Users and APEX with respect to the subject matter hereof, and supersede all other communications, including but
          not limited to all prior agreements, between the parties with respect to such subject matter. These Terms &amp; Conditions will be enforced to the fullest extent permitted by law.
        </span>
      </p>
      <h4>
        <span>EU Users</span>
      </h4>
      <p>
        <span>
          Should any provision of these Terms &amp; Conditions be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and
          enforceable provisions thereby substituting the void, invalid or unenforceable parts.
          <br />
          In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.
        </span>
      </p>
      <p>
        <span>
          Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms &amp; Conditions shall not nullify the entire Agreement, unless
          the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be
          valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.
        </span>
      </p>
      <h3>
        <span>Governing Law</span>
      </h3>
      <p>
        <span>These Terms &amp; Conditions are governed by the law of the State of Delaware.</span>
      </p>
      <h4>
        <span>Exception for European Consumers</span>
      </h4>
      <p>
        <span>
          However, regardless of the above, if the User qualifies as a European Consumer and has their habitual residence in a country where the law provides for a higher consumer protection standard,
          such higher standards shall prevail.
        </span>
      </p>
      <h3>
        <span>Venue of Jurisdiction</span>
      </h3>
      <p>
        <span>
          The exclusive competence to decide on any controversy resulting from or connected to these Terms &amp; Conditions lies with the courts of the place where APEX is based, as displayed in the
          relevant section of this document.
        </span>
      </p>
      <h4>
        <span>Exception for European Consumers</span>
      </h4>
      <p>
        <span>The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in Switzerland, Norway or Iceland.</span>
      </p>
      <h2>
        <span>Non-Solicitation</span>
      </h2>
      <p>
        <span>APEX and it&rsquo;s employees </span>
        <span>will not </span>
        <span>
          solicit, divert, contact, or call upon customers of the User with the intent of doing business outside of the scope of these Terms &amp; Conditions without prior consent of the User.
        </span>
      </p>
      <h2>
        <span>Dispute resolution</span>
      </h2>
      <h3>
        <span>Amicable dispute resolution</span>
      </h3>
      <p>
        <span>Users may bring any disputes to APEX who will try to resolve them amicably.</span>
      </p>
      <p>
        <span>
          While Users&#39; right to take legal action shall always remain unaffected, in the event of any controversy regarding the use of this Amazon Preparation Tracking Software License or the
          Service, Users are kindly asked to contact APEX at the contact details provided in this document.
        </span>
      </p>
      <p>
        <span>
          The User may submit the complaint including a brief description and if applicable, the details of the related order, purchase, or account, to APEX&rsquo;s email address specified in this
          document.
        </span>
      </p>
      <p>
        <span>APEX will process the complaint without delay and within 30 days of receiving it.</span>
      </p>
      <h3>
        <span>Amicable Resolution of Disputes Between Users</span>
      </h3>
      <p>
        <span>
          Users may bring disputes with other Users resulting from their interaction via this Amazon Preparation Tracking Software License to APEX, who will then try to mediate the conflict in order
          to achieve an amicable solution. While Users&#39; right to take legal action shall always remain unaffected, if any such controversy between Users should arise in connection with using this
          Amazon Preparation Tracking Software License or the Service, Users are kindly asked to contact APEX at the contact details provided in this document.
        </span>
      </p>
      <h3>
        <span>Online Dispute Resolution for Consumers</span>
      </h3>
      <p>
        <span>
          The European Commission has established an online platform for alternative dispute resolutions that facilitates an out-of-court method for solving disputes related to and stemming from
          online sale and service contracts.
        </span>
      </p>
      <p>
        <span>
          As a result, any European Consumer or Consumer based in Norway, Iceland, or Liechtenstein can use such platform for resolving disputes stemming from contracts which have been entered into
          online. The platform is{' '}
        </span>
        <span>
          <a href="https://www.google.com/url?q=http://ec.europa.eu/consumers/odr/&amp;sa=D&amp;source=editors&amp;ust=1691590732804116&amp;usg=AOvVaw2nGKjAQNilqovK8U-C03l0">
            available at the following link
          </a>
        </span>
        <span>.</span>
      </p>
      <h2>
        <span>Definitions and Legal References</span>
      </h2>
      <h4>
        <span>This Amazon Preparation Tracking Software License (or this Amazon Preparation Tracking Software License)</span>
      </h4>
      <p>
        <span>The property that enables the provision of the Service.</span>
      </p>
      <h4>
        <span>Agreement</span>
      </h4>
      <p>
        <span>Any legally binding or contractual relationship between APEX and the User, governed by these Terms &amp; Conditions.</span>
      </p>
      <h4>
        <span>Business User</span>
      </h4>
      <p>
        <span>Any User that does not qualify as a Consumer.</span>
      </p>
      <h4>
        <span>Client</span>
      </h4>
      <p>
        <span>Featured Prep Center partner or other business that is being promoted by APEX to it&rsquo;s platform</span>
        <span>.</span>
      </p>
      <h4>
        <span>Coupon</span>
      </h4>
      <p>
        <span>Any code or voucher, in print or electronic form, which allows the User to purchase the Product at a discounted price.</span>
      </p>
      <h4>
        <span>European (or Europe)</span>
      </h4>
      <p>
        <span>Applies where a User is physically present or has their registered offices within the EU, regardless of nationality.</span>
      </p>
      <h4>
        <span>Owner (or We)</span>
      </h4>
      <p>
        <span>Indicates the natural person(s) or legal entity that provides this Amazon Preparation Tracking Software License and/or the Service to Users.</span>
      </p>
      <h4>
        <span>Product</span>
      </h4>
      <p>
        <span>A good or service available for purchase through this Amazon Preparation Tracking Software License, such as e.g. physical goods, digital files, software, booking services etc.</span>
      </p>
      <p>
        <span>The sale of Products may be part of the Service.</span>
      </p>
      <h4>
        <span>Service</span>
      </h4>
      <p>
        <span>The service provided by this Amazon Preparation Tracking Software License as described in these Terms &amp; Conditions and on this Amazon Preparation Tracking Software License.</span>
      </p>
      <h4>
        <span>Terms &amp; Conditions</span>
      </h4>
      <p>
        <span>
          All provisions applicable to the use of this Amazon Preparation Tracking Software License and/or the Service as described in this document, including any other related documents or
          agreements, and as updated from time to time.
        </span>
      </p>
      <h4>
        <span>User (or You)</span>
      </h4>
      <p>
        <span>Indicates any natural person or legal entity using this Amazon Preparation Tracking Software License.</span>
      </p>
      <h4>
        <span>Consumer</span>
      </h4>
      <p>
        <span>Any User qualifying as a natural person who accesses goods or services for personal use, or more generally, acts for purposes outside their trade, business, craft or profession.</span>
      </p>
      <p>
        <span>Latest update: May 19, 2023</span>
      </p>
      <div>
        <p>
          <span></span>
        </p>
      </div>
    </div>
  )
}

export default TOS
