import { FC, useEffect, useState } from 'react'
import { DoubleCheckIcon } from 'assets/icons'
import useHideSupportChat from 'hooks/useHideSupportChat'
import api from 'api'
import useUserContext from 'contexts/User/useUserContext'
import { userData } from 'App'
import useAreYouSure from 'hooks/useAreYouSure'

const SubscriptionPage: FC = () => {
  const [submitting, setSubmitting] = useState(false)
  const [trialAvailable, setTrialAvailable] = useState(false)
  const [areYouSure, AreYouSureModal] = useAreYouSure("Are you sure you want to cancel your subscription?")
  useHideSupportChat()
  const sub = useUserContext()?.subscription || null
  const [yearly, setYearly] = useState(sub?.yearly || false)

  const onSubscribe = () => {
    if (submitting) return
    setSubmitting(true)
    api
      .subscribe("starter", yearly)
      .then((url) => {
        window.open(url, '_self')
      })
      .finally(() => setSubmitting(false))
  }

  const onCancel = () => {
    if (!sub) return
    setSubmitting(true)
    api
      .cancelSubscription()
      .then(() => {
        userData.current?.setUser((user) => user && { ...user, subscription: undefined })
      })
      .finally(() => setSubmitting(false))
  }

  useEffect(() => {
    api.getSubscriptionInfo().then((prevSub) => setTrialAvailable(!prevSub))
  }, [])

  return (
    <div className="flex flex-col grow overflow-y-auto -mx-6 -mb-6 px-6 pb-6" >
      <AreYouSureModal />
        <div className="bg-white rounded-2xl h-max w-[512px]">
          <header className="flex p-6 gap-6 bg-white items-start rounded-t-2xl">
            <div className="flex flex-col gap-3 grow">
              <span className="text-base text-base-950 font-semibold">{yearly ? 'Annually' : 'Monthly'}</span>
              <span className="text-3xl align-top text-base-300 font-light">
                $ <span className="text-5xl align-top text-base-950">{yearly ? '479.90' : '49.99'}</span>
              </span>
            </div>
            <div className="flex gap-3 items-center">
              <div
                onClick={submitting ? undefined : () => setYearly((old) => !old)}
                className={['relative p-1 w-10 transition-all cursor-pointer rounded-full border', yearly ? 'bg-primary-200 border-primary-500' : 'bg-white border-slate-200'].asClass}
              >
                <div className={['w-4 h-4 rounded-full transition-all', yearly ? 'bg-primary-500 translate-x-4' : 'bg-slate-200'].asClass} />
              </div>
              <span className="text-sm text-base-950 font-normal">Annual</span>
              <span className="py-2 px-3.5 rounded-full bg-lime-100 text-lime-500 font-medium text-[0.6125rem] leading-none">20% Off . Save $199.98</span>
            </div>
          </header>
          <div className="flex flex-col gap-6 p-6 text-sm font-medium text-base-950">
            <div className="flex items-center gap-6">
              <DoubleCheckIcon />
              <span>
                Organise workflow and track shipments across multiple prep centers
              </span>
            </div>
            <div className="flex items-center gap-6">
              <DoubleCheckIcon />
              <span>
                Access to our partner prep centers with fixed prepping price
              </span>
            </div>
            <div className="flex items-center gap-6">
              <DoubleCheckIcon />
              <span>
                Automatic billing for your prepped services 
              </span>
            </div>
            <div className="flex items-center gap-6">
              <DoubleCheckIcon />
              <span>
                Helping delegate for <b>maximum growth!</b>
              </span>
            </div>
          </div>
          <footer className="pt-8 px-6 pb-6 flex flex-col gap-6">
            {sub ? (
              <button onClick={yearly !== !!sub?.yearly ? onSubscribe : undefined} className="button-orange !p-8 w-full !rounded-xl hover:!rounded-2xl hover:bg-primary-500 hover:text-white cursor-default">{yearly !== !!sub?.yearly ? `Change To ${yearly ? "Annual" : "Monthly"}` : "Current Plan"}</button>
            ) : (
              <button disabled={submitting} className="button-orange w-full !p-8" onClick={onSubscribe}>
                Subscribe
              </button>
            )}
            {trialAvailable !== false ? (
            <span className="text-sm text-base-950 text-center">
              {trialAvailable === undefined ? "..." : trialAvailable ? "Start 14 day trial" : ""}
            </span>
            ) : sub ? (
          <button className="button-dark !rounded-xl" disabled={submitting} onClick={() => areYouSure(onCancel)}>
            Cancel Subscription
          </button>
            ) : null}
          </footer>
        </div>
      </div>
  )
}

export default SubscriptionPage
