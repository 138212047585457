import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import Icon from './Icon'

interface props {
  page: number
  total: number
  setPage: Dispatch<SetStateAction<number>>
  loading: boolean
}

const Paginator: FC<props> = ({ setPage, page, total, loading }) => {
  const pagesLeft = total - page
  const leftDisabled = page - 1 < 0
  const rightDisabled = total <= page + 1
  const [pages, setPages] = useState<(number | null)[]>()

  useEffect(() => {
    const shown = Array(total).fill(0).map((_, i) => (Math.abs(i-page) < 2 || i === 0 || i === total-1) ? i : null)
    for(let i = 0; i < shown.length; i++) {
        if(shown[i] === null && shown[i+1] === null) {
            shown[i] = -1
        }
    }
    setPages(shown.filter((val) => val !== -1))
  }, [total, page])
  

  return (
    <div className="flex space-x-4 h-10 self-center">
      <button onClick={() => setPage(old => old - 1)} className="pagination-button" disabled={leftDisabled || loading}>
        <Icon size={24}  name="arrow-left" className={leftDisabled ? '!fill-base-400' : undefined} />
      </button>
      {!total && <span className="text-base-900">...</span>}
      {pages?.map((n, i) => (
        n === null ? <span key={i} className="text-base-900">...</span> : <button key={i} className={["pagination-button", page === n && "active"].filter(Boolean).join(" ")} disabled={loading} onClick={() => setPage(n)}>{n+1}</button>
      ))}
      <button onClick={() => setPage(old => old + 1)} className="pagination-button" disabled={rightDisabled || loading}>
        <Icon size={24}  name="arrow-right" className={rightDisabled ? '!fill-base-400' : undefined} />
      </button>
    </div>
  )
}

export default Paginator
