import { AxiosInstance } from 'axios'
import axios from './axios'
import { IBill, IInvoiceListed, IPaymentStructureData } from 'types/billing'
import Stripe from 'stripe'
import { IAmazonListing, IAmazonProductImage } from 'types/amazon'
import { IWarehouse } from 'types/warehouses'
import { IConnectedClient } from 'contexts/User/UserProvider'
import firebase from 'config/firebase'

class Api {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  signup = async (data: { email: string; password: string; referrer?: string; name: string, distribution?: boolean, affiliate?: string }) => {
    return this.axios.post('/user/signup', data)
  }

  prepSignup = async (data: {email: string; password: string; name: string; state: string; zipCode: string; publicName: string; city: string; streetAddress: string; referrer?: string; country: string}) => {
    return this.axios.post('/user/prepSignUp', data)
  }

  getReferralEmail = async (code: string) => {
    return this.axios.get('/invited/' + code).then((res) => res.data)
  }

  completeInitialSetup = async (data: any) => {
    /*return this.axios.post('/user/setup', data)*/
  }

  getUser = async (userId: string = 'me') => {
    if (userId === 'me' && Rewardful.referral) {
      return this.axios.get('/user/me?rewardfulReferralId=' + Rewardful.referral).then((res) => res.data)
    }
    return this.axios.get('/user/' + userId).then((res) => res.data)
  }

  createConnection = async (email: string) => {
    return this.axios.post('/connections/create', { email })
  }

  listPendingConnections = async (nextId?: string) => {
    return this.axios.get('/connections/pending/list', { params: { nextId } })
  }

  acceptConnection = async (userId: string) => {
    return this.axios.post('/connections/accept', { userId })
  }

  denyConnection = async (userId: string) => {
    return this.axios.post('/connections/deny', { userId })
  }

  listChats = async (nextId?: string) => {
    return this.axios.get('/chats/list', { params: { nextId } }).then((res) => res.data)
  }

  sendMessage = async (chatId: string, content: string, type: 'image' | 'text') => {
    return this.axios.put('/chats/' + chatId, { content, type })
  }

  createWarehouse = async (data: any) => {
    return this.axios.post('/warehouses', data).then((res) => res.data)
  }

  listWarehouses = async (nextId?: string) => {
    return this.axios.get('/warehouses', { params: { nextId } }).then((res) => res.data)
  }

  listApexWarehouses = async () => {
    return this.axios.get('/warehouses/apex').then((res) => res.data.filter((w: IWarehouse) => w.id !== "15686e3a-bffb-46c4-93b0-51f89d7c5b28" || window.location.hostname === 'localhost' || firebase.auth?.currentUser?.uid === "Ad2sXgG6jpdI5tTJ2pwGyYDWhVy1"))
  }

  setDefaultWarehouse = async (warehouse: string) => {
    return this.axios.put('/warehouses/default', {warehouse}).then((res) => res.data)
  }

  listAllWarehouses = async (nextId?: string) => {
    return this.axios.get('/warehouses/all', { params: { nextId } }).then((res) => res.data)
  }

  getWarehouse = async (warehouseId: string) => {
    return this.axios.get('/warehouses/warehouse/' + warehouseId).then((res) => res.data)
  }

  listNotifications = (nextId?: string) => {
    return this.axios.get('/notifications', { params: { nextId } }).then((res) => res.data)
  }

  deleteNotification = (notificationId: string) => {
    return this.axios.delete('/notifications/' + notificationId)
  }

  getDashboard = (dashboardId: string = '') => {
    const url = dashboardId ? '/dashboards/' + dashboardId : '/dashboards'
    return this.axios.get(url).then((res) => res.data?.dashboard)
  }

  listConnectedWarehouses = () => {
    return this.axios.get('/warehouses/connected').then((res) => res.data.filter((w: IWarehouse) => w.id !== "15686e3a-bffb-46c4-93b0-51f89d7c5b28" || window.location.hostname === 'localhost' || firebase.auth?.currentUser?.uid === "Ad2sXgG6jpdI5tTJ2pwGyYDWhVy1"))
  }

  connectWarehouse = (warehouseId: string) => {
    return this.axios.post('/warehouses/' + warehouseId).then((res) => res.data)
  }

  grantAccess = (email: string) => {
    return this.axios.put('/user/access/grant', { for: email }).then((res) => res.data)
  }

  revokeAccess = (id: string) => {
    return this.axios.delete('/user/access/' + id)
  }

  changeAccessName = (id: string, name: string) => {
    return this.axios.patch('/user/access/' + id, { name })
  }

  listAccesses = () => {
    return this.axios.get('/user/access/list').then((res) => res.data)
  }

  listGrantedAccesses = () => {
    return this.axios.get('/user/access/granted').then((res) => res.data)
  }

  getAmazonAuthUri = () => {
    return this.axios.post('/amazon/auth-uri').then((res) => res.data)
  }

  authorizeAmazon = (code: string, sellerId: string, state: string) => {
    return this.axios.put('/amazon/code', { code, sellerId, state }).then((res) => res.data)
  }

  getStripeAuthUri = () => {
    return this.axios.post('/stripe/connect').then((res) => res.data as string)
  }

  checkStripeConnection = () => {
    return this.axios.post('/stripe/connect/validate').then((res) => res.data as any)
  }

  getStripeAccount = () => {
    return this.axios.get('/stripe').then((res) => res.data as any)
  }

  putReferrer = (referrer?: string | null) => {
    if (!referrer) return Promise.resolve()
    return this.axios.put('/user/referrer', { referrer })
  }

  getConfig = () => {
    return this.axios.get('/config').then((res) => res.data)
  }

  getClients = async () => {
    return this.axios.get('/invited').then((res) => res.data)
  }

  getConnectedClients = async () => {
    return this.axios.get('/invited/connected').then((res) => res.data.sort((a: IConnectedClient, b: IConnectedClient) => a.id.localeCompare(b.client)))
  }

  inviteClient = async (firstName: string, lastName: string, email: string) => {
    return this.axios.post('/invited', { firstName, lastName, email, type: "SELLER" }).then((res) => res.data)
  }

  invitePrep = async (name: string, email: string) => {
    return this.axios.post('/invited', { name, email, type: "PREP" }).then((res) => res.data)
  }

  inviteEmployee = async (name: string, email: string, position: string) => {
    return this.axios.post('/invited', { name, email, type: "EMPLOYEE", position }).then((res) => res.data)
  }

  dropInvite = async (inviteId: string) => {
    return this.axios.delete('/invited/'+inviteId)
  }

  changeClientTier = async (inviteId: string, pricing: string) => {
    return this.axios.put(`/invited/${inviteId}/pricing`, {pricing})
  }

  getBills = async (userId?: string) => {
    return this.axios.get('/billing/bills/' + (userId || 'all')).then((res) => res.data)
  }

  getPaymentMethods = async () => {
    return this.axios.get('/stripe/billingMethods').then((res) => res.data)
  }

  getPaymentMethodSetupSecret = async () => {
    return this.axios.post('/stripe/billingMethods/add').then((res) => res.data)
  }

  deletePaymentMethod = async (id: string) => {
    return this.axios.delete('/stripe/billingMethods/'+id).then((res) => res.data)
  }

  setDefaultPaymentMethod = async (id: string) => {
    return this.axios.post(`/stripe/billingMethods/${id}/default`).then((res) => res.data)
  }

  getPaymentStructures = async () => {
    return this.axios.get('/billing/structures').then((res) => res.data)
  }

  addPaymentStructure = async (name: string, structure: IPaymentStructureData) => {
    return this.axios.post('/billing/structures', { name, structure }).then((res) => res.data)
  }

  removePaymentStructure = async (id: string) => {
    return this.axios.delete('/billing/structures/'+id)
  }

  getInvoices = async ({ user, status, from }: { user?: string; status?: string; from?: string }) => {
    const params = new URLSearchParams()
    user && params.append('customer', user)
    status && params.append('status', status)
    from && params.append('from', from)
    return this.axios.get(`/billing/invoices?${params.toString()}`).then((res) => res.data as IInvoiceListed[])
  }

  createInvoice = async (user: string, billIds: string[], pricingStructureName: string) => {
    return this.axios.post('/billing/invoice/' + user, { billIds, pricingStructureName }).then((res) => res.data)
  }

  getInvoice = async (invoiceId: string, prep?: string) => {
    const params = new URLSearchParams()
    prep && params.append('from', prep)
    return this.axios.get(`/billing/invoices/invoice/${invoiceId}?${params.toString()}`).then((res) => res.data as { invoice: Stripe.Invoice; bills: IBill[] })
  }

  updateInvoicePaymentStructure = async (invoiceId: string, pricingStructureName: string) => {
    return this.axios.put('/billing/invoices/invoice/' + invoiceId, { pricingStructureName }).then((res) => res.data as { invoice: Stripe.Invoice; bills: IBill[] })
  }

  sendInvoice = async (invoiceId: string) => {
    return this.axios.post('/billing/invoices/invoice/' + invoiceId + '/send').then((res) => res.data as { invoice: Stripe.Invoice })
  }

  getInvoiceSenders = async () => {
    return this.axios.get('/billing/invoiceOwners').then((res) => res.data)
  }

  getChat = async (otherUser: string) => {
    return this.axios.get('/chats?otherUser=' + otherUser).then((res) => res.data.id)
  }

  getSubscriptionInfo = async () => {
    return this.axios.get('/subscriptions').then((res) => res.data as {hadPreviousSubscription: boolean})
  }

  subscribe = async (plan: string, yearly: boolean) => {
    return this.axios.post('/subscriptions', { plan, yearly }).then((res) => res.data.session as string)
  }

  cancelSubscription = async () => {
    return this.axios.delete('/subscriptions')
  }

  inquire = async (data: any) => {
    return this.axios.post('/inquiries', data)
  }

  inquireSellerSignup = async (data: any) => {
    return this.axios.post('/inquiries/seller', data)
  }

  getListings = async (warehouse: string, nextId?: string) => {
    return this.axios.get('/amazon/listings', { params: { nextId, warehouse } }).then(
      (res) =>
        res.data as {
          listings: IAmazonListing[]
          nextToken?: string
        }
    )
  }

  getUserListings = async (user: string) => {
    return this.axios.get('/amazon/listings/'+user).then(
      (res) =>
        res.data as {
          listings: IAmazonListing[]
          nextToken?: string
        }
    )
  }

  subscribeClients = async () => {
    return this.axios.post('/subscriptions/client').then(res => res.data.session as string)
  }

  cancelClientSubscription = async () => {
    return this.axios.delete('/subscriptions/client')
  }

  makeClientPayForSub = async () => {
    return this.axios.put('/subscriptions/clientpaid')
  }

  updateConfig = async (config: {image?: string, color?: string}) => {
    return this.axios.put('/config', config).then(res => res.data as {logo?: string, primaryColor?: string})
  }

  getAmazonProductImage = async (asin: string) => {
    return this.axios.get('/amazon/products/pictures/'+asin).then(res => res.data.image as IAmazonProductImage)
  }
}

const api = new Api()

export default api
