import { CloseIcon } from 'assets/icons';
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'

const DimensionsModal: AwaitableModal<{ width: number; length: number; height: number; weight: number }> = ({ onCancel, open, resolve }) => {
  const [box, setBox] = useState({ width: undefined, length: undefined, height: undefined, weight: undefined })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    setBox((old) => ({ ...old, [name]: value ? Number(value) : undefined }))
  }

  return (
    <Modal open={open} close={onCancel}>
      <div className="modal relative max-h-[calc(100vh-64px)]">
        <header className="!items-center flex gap-3">
          <span className="grow text-xl">What is the box weight?</span>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        <div className="flex flex-col w-full overflow-y-auto p-6">
          <div className="text-xs text-base-900 p-4 flex items-center text-left grid gap-4 grid-cols-3">
            <div className="col-span-1 flex items-center relative">
              <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
                <span className="grow w-max text-sm text-base-400">W</span>
                <span className="grow w-max text-[7px] text-base-400">Inches</span>
              </div>
              <input name="width" min={0} type="number" className="text-right w-full" placeholder="-" value={box.width} onChange={onChange} />
            </div>
            <div className="col-span-1 flex items-center relative">
              <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
                <span className="grow w-max text-sm text-base-400">H</span>
                <span className="grow w-max text-[7px] text-base-400">Inches</span>
              </div>
              <input name="height" min={0} type="number" className="text-right w-full" placeholder="-" value={box.height} onChange={onChange} />
            </div>
            <div className="col-span-1 flex items-center relative">
              <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
                <span className="grow w-max text-sm text-base-400">L</span>
                <span className="grow w-max text-[7px] text-base-400">Inches</span>
              </div>
              <input name="length" min={0} type="number" className="text-right w-full" placeholder="-" value={box.length} onChange={onChange} />
            </div>
            <div className="col-span-3 flex items-center relative">
              <span className="grow w-max absolute pl-4 text-sm text-base-400 pointer-events-none">Weight - LBS</span>
              <input name="weight" min={0} type="number" className="text-right w-full" placeholder="-" value={box.weight} onChange={onChange} />
            </div>
          </div>
        </div>
      <footer className="bg-base-900 not-themed">
        <button className="button-dark" onClick={onCancel}>
          Go Back
        </button>
        <button className="button-primary" disabled={!box || Object.keys(box).some((key) => !box[key as keyof typeof box])} onClick={() => resolve(box as any)}>
          Print Box Labels
        </button>
      </footer>
      </div>
    </Modal>
  )
}

export default DimensionsModal
