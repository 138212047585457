import { User } from "firebase/auth"
import { useState, useEffect, FC, PropsWithChildren } from "react"
import firebase from "../../config/firebase"
import AuthContext from "./authContext"

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>()

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged((user) => {
      if (!user) {
        localStorage.clear()
      }
      setUser(user)
    })

    return unsubscribe
  }, [])

  return (
    <AuthContext.Provider value={user}>
        {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider