import TableSpanner from 'components/TableSpanner'
import useUserContext from 'contexts/User/useUserContext'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { IBill } from 'types/billing'

interface props {
  bill: IBill
  showAdd: boolean
  setSelectedBills?: Dispatch<SetStateAction<string[]>>
  checked?: boolean
  showTotal?: boolean
}

function extractPartBeforeFor(inputString: string) {
    const parts = inputString.split(' for ');
    parts.pop();
    return parts.join(' for ');
}


const formatByType = (bill: IBill) => {
  if (bill.forType === 'ORDER' || bill.forType === "ORDERV2") return 'Shipment #' + bill.forName?.toString().padStart(5, '0')
  return 'Unknown'
}

const getItemsFromBill = (bill: IBill) => {
  const items: Record<string, { quantity: number; service: string; price: number }[]> = {}
  const orderServices: { service: string; price: number; quantity: number }[] = []
  for (const item of bill.billableItems) {
    const type = item.type
    const price = item.price
    if (price === undefined) continue
    if (type.startsWith('ITEM_PREP_') && type.endsWith('_FNSKU')) {
      const itemKey = type.replace('ITEM_PREP_', '').replace('_FNSKU', '')
      items[itemKey] = (items[itemKey] || []).concat({
        price: price / item.quantity,
        quantity: item.quantity,
        service: 'Base prepping',
      })
    } else if (type.startsWith('ITEM_PREP_')) {
      const service = type.split('_').at(-1)
      if (!service) throw new Error('Invalid service type')
      const itemKey = type.replace('ITEM_PREP_', '').replace(`_${service}`, '')
      items[itemKey] = (items[itemKey] || []).concat({
        price: price / item.quantity,
        quantity: item.quantity,
        service: extractPartBeforeFor(item.name),
      })
    } else if (type.startsWith('ADDITIONAL_ORDER_SERVICE_')) {
      orderServices.push({
        price: price / item.quantity,
        quantity: item.quantity,
        service: extractPartBeforeFor(item.name),
      })
    }
  }

  return { items, orderServices }
}

const Bill: FC<props> = ({ bill, showAdd, setSelectedBills, checked, showTotal }) => {
  const total = bill.billableItems.some(({ price }) => price === undefined) ? undefined : bill.billableItems.reduce((acc, item) => acc + (item.price as number), 0)
  const [expanded, setExpanded] = useState(false)
  const { items, orderServices } = getItemsFromBill(bill)
  const user = useUserContext()

  return (
    <>
      <tr onClick={() => setExpanded((old) => Boolean(bill?.paymentPlan) && !old)}>
        {showAdd && (
          <td className="!p-0 relative">
            <div className="flex flex-grow items-center">
              <label htmlFor={bill.id} className="w-full h-12 flex items-center cursor-pointer px-8">
                <input
                  type="checkbox"
                  id={bill.id}
                  checked={checked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedBills?.((prev) => [...prev, bill.id])
                    } else {
                      setSelectedBills?.((prev) => prev.filter((id) => id !== bill.id))
                    }
                  }}
                />
              </label>
            </div>
          </td>
        )}
        <td>{formatByType(bill)}</td>
        {user?.isPrepCenter && <td>{bill.contact?.name || bill.contact?.email}</td>}
        {showTotal && <td>{total?.toFixed(2)}$</td>}
        <td>{new Date(bill.created_at).toLocaleDateString()}</td>
        {bill?.paymentPlan && (
          <td>
            <button className="button-secondary -mb-2.5 -mt-2">Details</button>
          </td>
        )}
      </tr>
      {expanded && (
        <TableSpanner cols={6} rows={5}>
          <div className="flex flex-col space-y-2 h-full">
            <div className="flex flex-col space-y-4">
              <span className="text-lg font-semibold text-base-900">Billable Items</span>
              <div className="flex flex-col space-y-2">
                {Object.entries(items).map(([key, value]) => (
                  <div className="flex flex-col space-y-1" key={key}>
                    <div className="flex space-between">
                      <span className="text-sm text-base-600 grow font-semibold">{key}</span>
                    </div>
                    <div className="flex flex-col space-y-1 pl-5">
                      {value.map(({ service, price, quantity }) => (
                        <div className="flex justify-between" key={service}>
                          <span className="text-sm text-base-600 grow">{service}</span>
                          <span className="text-sm text-base-600">
                            {price.toFixed(2)}$ x {quantity}
                          </span>
                          <span className="text-sm text-base-600">=</span>
                          <span className="text-sm text-base-600">{(price * quantity).toFixed(2)}$</span>
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-between pl-2.5 font-semibold">
                      <span className="text-sm text-base-600">Total for asin</span>
                      <span className="text-sm text-base-600">{value.reduce((acc, { price, quantity }) => acc + price * quantity, 0).toFixed(2)}$</span>
                    </div>
                  </div>
                ))}
              </div>
              <span className="text-lg font-semibold text-base-900 !-mb-2">Additional Services</span>
              <div className="flex flex-col space-y-1">
                {orderServices.map(({ service, price, quantity }) => (
                  <div className="flex justify-between pl-5" key={service}>
                    <span className="text-sm text-base-600 grow">{service}</span>
                    <span className="text-sm text-base-600">
                      {price.toFixed(2)}$ x {quantity}
                    </span>
                    <span className="text-sm text-base-600">=</span>
                    <span className="text-sm text-base-600">{(price * quantity).toFixed(2)}$</span>
                  </div>
                ))}
                <div className="flex justify-between pl-2.5 font-semibold">
                  <span className="text-sm text-base-600">Total for additional services</span>
                  <span className="text-sm text-base-600">{orderServices.reduce((acc, { price, quantity }) => acc + price * quantity, 0).toFixed(2)}$</span>
                </div>
              </div>
            </div>
          </div>
        </TableSpanner>
      )}
    </>
  )
}

export default Bill
