import { FC, useEffect, useState } from 'react'
import MultiSelect from './MultiSelect'
import Switch from './Sort'
import { ReactComponent as Icon } from 'assets/iconsax/outline/sort.svg'

export type IFilterOption = ISelectFilterOption | ISortFilterOption

interface ISelectFilterOption {
  name: string
  title: string
  selected: string[]
  type: 'select' | 'multi-select'
  options: string[]
  optionTitles?: Record<string, string>
}

interface ISortFilterOption {
  name: string
  title: string
  selected: string
  type: 'sort'
}

interface props {
  options: IFilterOption[]
  onChange: (name: string, selected: string[] | string) => void
  onDismiss: () => void
}

const Filter: FC<props> = ({ options, onChange, onDismiss }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!visible) {
      onDismiss()
    }
  }, [visible, onDismiss])

  useEffect(() => {
    const handle = () => setVisible(false)

    if (visible) {
      window.addEventListener('click', handle)

      return () => window.removeEventListener('click', handle)
    }
  }, [visible])

  const className = "h-12 w-12 p-3 rounded-full fill-primary-600 cursor-pointer transition-all"
  const combinedClass = [visible ? "fill-primary-600 bg-base-300" : `bg-white hover:bg-base-300`, className].filter(Boolean).join(" ")
  
  return (
    <div className="relative" onClick={e => e.eventPhase === 3 && e.stopPropagation()}>
      <Icon className={combinedClass} onClick={() => setVisible((old) => !old)} />
      {visible && (
        <div className="flex flex-col absolute z-[1000] right-full top-0 mr-2 mb-5 min-w-[300px] bg-white p-5 rounded drop-shadow-xl">
          {options.map((option) => {
            switch (option.type) {
              case 'sort':
                return <Switch title={option.title} selected={option.selected} onChange={(selected: string) => onChange(option.name, selected)} />
              case 'multi-select':
                return <MultiSelect title={option.title} selected={option.selected} onChange={(selected: string[]) => onChange(option.name, selected)} options={option.options} optionTitles={option.optionTitles} />
              default:
                return null
            }
          })}
        </div>
      )}
    </div>
  )
}

export default Filter
