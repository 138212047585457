import IconButton from 'components/buttons/IconButton'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import TextInput from 'components/TextInput'
import ChatPage from 'pages/app/Contacts/pages/Chat'
import { FC, createRef, useEffect, useImperativeHandle, useState } from 'react'
import OrderItems from './components/OrderItems'
import OrderActions from './components/OrderActions'
import OrderNotes from './components/OrderNotes'
import OrderUpdates from './components/OrderUpdates'
import { useNavigate } from 'react-router-dom'
import ServiceCharges from './components/ServiceCharges'
import useHideSupportChat from 'hooks/useHideSupportChat'
import useSelectedSeller from 'hooks/useSelectedSeller'
import ordersApi from 'api/orders'
import { IOrder } from 'types/orders'
import Shipment from './components/Shipment'
import useHasRole from 'hooks/useHasRole'
import * as xlsx from 'xlsx'
import { useOrder } from 'contexts/Order'

export const currentOrderRef = createRef<IOrder>()

const OrderPage: FC = () => {
  const { order, setOrder, seller, prepCenter, isPrepCenter, isApexOrder, warehouse, availableItemServices, chatId, workable, frozen } = useOrder()
  const [chatOpen, setChatOpen] = useState(false)
  const [editingName, setEditingName] = useState(false)
  const [customName, setCustomName] = useState(order?.data?.prepCenter?.id)
  const selectedSeller = useSelectedSeller()
  const navigate = useNavigate()
  const canBill = useHasRole('INVOICE')
  const canChat = useHasRole('MESSAGE')
  const [billing, setBilling] = useState(false)

  useEffect(() => {
    if (selectedSeller && order && selectedSeller !== order.from) {
      navigate(`/app/orders`)
    }
  }, [selectedSeller, order, navigate])

  useEffect(() => {
    if (!order) return
    setCustomName(order.data?.prepCenter?.id)
  }, [order])

  const saveCustomName = () => {
    setEditingName(false)
    if (!order || !order.id) return
    ordersApi.assignOrderId(order.id, customName || '').catch(() => setCustomName(order.data?.prepCenter?.id))
  }

  const setSellerNotes = (notes: string) => {
    setOrder((old) => old && { ...old, sellerNotes: notes })
  }

  const setPrepNotes = (notes: string) => {
    setOrder((old) => old && { ...old, prepCenterNotes: notes })
  }

  const onBill = () => {
    if (billing || !order) return
    setBilling(true)
    if (frozen || !isPrepCenter) {
      navigate(`/app/billing`)
    } else {
      ordersApi
        .bill(order.id)
        .then((billId) => setOrder((old) => old && { ...old, bill: billId }))
        .finally(() => setBilling(false))
    }
  }

  const downloadWorkflow = () => {
    if (!order) return
    const wb = xlsx.utils.book_new()
    const title = ['Workflow Name', 'SKUs', 'Units', 'Customer Name', 'Customer Email']
    const workflowData = ['#' + order.orderNumber.toString().padStart(5, '0'), order.asins, order.quantity, order.contact?.name, order.contact?.email]
    const blank = [] as string[]
    const header = ['SKU', 'Title', 'ASIN', 'FNSKU', 'Quantity']
    const items = order.itemsData?.map(({ sku, name, asin, fnsku, expected }) => [sku, name, asin, fnsku, expected]) || []
    const ws = xlsx.utils.aoa_to_sheet([title, workflowData, blank, header, ...items])
    xlsx.utils.book_append_sheet(wb, ws, 'Workflow')
    xlsx.writeFile(wb, `workflow_${'#' + order.orderNumber.toString().padStart(5, '0')}.xlsx`)
  }

  useHideSupportChat()

  useImperativeHandle(currentOrderRef, () => order as IOrder, [order])

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header />
      {!seller || !prepCenter || warehouse === undefined || !order ? (
        <FullLoader />
      ) : (
        <div className="flex flex-col grow overflow-hidden relative">
          <div className="w-full flex grow overflow-hidden relative">
            <div className="flex flex-col h-full grow overflow-y-auto">
              <header className="flex flex items-center w-full z-[10000]">
                <div className="flex space-x-4 items-center grow">
                  <span className="mb-0 grow">Shipment {!editingName && ((isPrepCenter ? '#' + order.orderNumber.toString().padStart(5, '0') : '| ' + order.data?.seller?.id) || order.id)}</span>
                  {false &&
                    isPrepCenter &&
                    (editingName ? (
                      <>
                        <TextInput value={customName} onChange={(e) => setCustomName(e.currentTarget.value)} />
                        <IconButton name="add" onClick={saveCustomName} tooltip="Save" tooltipLocation="right" />
                      </>
                    ) : (
                      <IconButton name="add" onClick={() => setEditingName(true)} tooltip="Add custom id" tooltipLocation="right" />
                    ))}
                  <span className="text-base-400 text-xl">{isPrepCenter ? 'Client ' + order.contact?.name : (isApexOrder && warehouse?.visibleToApexClients) || order.contact?.name}</span>
                  {!workable ? (
                    !frozen ? (
                      isPrepCenter ? (
                        canBill ? (
                          <button className="button-primary" onClick={onBill} disabled={billing}>
                            Bill Client
                          </button>
                        ) : null
                      ) : null
                    ) : (
                      ((!isPrepCenter && order.bill) || (isPrepCenter && canBill)) && (
                        <button className="button-primary" onClick={onBill}>
                          Go to Billing
                        </button>
                      )
                    )
                  ) : (
                    isPrepCenter && !!order && <IconButton name="printer" tooltip="Download Workflow" tooltipLocation="left" onClick={downloadWorkflow} />
                  )}
                </div>
              </header>
              <div className="grid grid-cols-2 gap-6 px-6 pb-6">
                <div className="rounded-2xl bg-base-50 col-span-2">
                  <Shipment />
                  <OrderItems availableItemServices={availableItemServices} />
                </div>
                {isPrepCenter && (
                  <>
                    {order.shipmentData && (
                      <>
                        <OrderActions loading={order === undefined} isPrepCenter={isPrepCenter} />
                        <ServiceCharges services={order.additionalServices} type="outbound" />
                      </>
                    )}
                    <OrderNotes isSeller={true} isOwner={false} editable={false} notes={order.data?.seller?.notes || ''} setNotes={() => {}} />
                    <ServiceCharges services={order.additionalServices} type="inbound" />
                    <OrderNotes isSeller={false} isOwner editable={!['SENT_TO_AMAZON', 'HANDLED'].includes(order.state)} notes={order.data?.prepCenter?.notes || ''} setNotes={setPrepNotes} />
                    <OrderUpdates orderId={order.id} />
                  </>
                )}
                {!isPrepCenter && (
                  <>
                    {order.state === 'IN_PROGRESS' && <OrderActions loading={order === undefined} isPrepCenter={isPrepCenter} />}
                    {!['SENT', 'IN_PROGRESS'].includes(order.state) &&
                      (!order.shipmentData ? (
                        <ServiceCharges services={order.additionalServices} type="inbound" cols={2} />
                      ) : (
                        <>
                          <ServiceCharges services={order.additionalServices} type="outbound" />
                          <ServiceCharges services={order.additionalServices} type="inbound" />
                        </>
                      ))}
                    <OrderNotes isSeller isOwner editable={['IN_PROGRESS', 'SENT'].includes(order.state)} notes={order.data?.seller?.notes || ''} setNotes={setSellerNotes} />
                    <OrderUpdates orderId={order.id} cols={order.state === 'IN_PROGRESS' ? 2 : 1} />
                  </>
                )}
              </div>
            </div>
          </div>
          {chatId && canChat && !order.apexClient && (
            <>
              <div className="absolute right-8 bottom-5 border-2 border-base-900 rounded-full">
                <IconButton name="messages-2" id={`chatbubble-${chatId}`} onClick={() => setChatOpen((old) => !old)} tooltip="Message" tooltipLocation="left" />
              </div>
              <div className={`w-[420px] absolute h-full flex transition-all space-x-12 z-[10000] p-5 bg-base-100 shadow-2xl`} style={{ right: chatOpen ? '0px' : '-450px' }}>
                <ChatPage data={{ user: order.contact as any, id: chatId, close: () => setChatOpen(false) }} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default OrderPage
