import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import Order from './components/Order'
import useUserContext from 'contexts/User/useUserContext'
import usePages from 'hooks/usePages'
import TableSpanner from 'components/TableSpanner'
import Empty from 'components/Empty'
import Paginator from 'components/Paginator'
import useSelectedSeller from 'hooks/useSelectedSeller'
import ordersApi from 'api/orders'
import { IOrder } from 'types/orders'

const OutboundPage: FC = () => {
  const navigate = useNavigate()
  const sellerFilter = useSelectedSeller()
  const user = useUserContext()
  const [page, setPage] = useState(0)
  const callback = useCallback(
    (page?: number) =>
      user?.isPrepCenter
        ? ordersApi.listOutboundPrepOrders(
            sellerFilter,
            {},
            page
          )
        : ordersApi.listOutboundSellersOrders(
            {},
            page
          ),
    [user?.isPrepCenter, sellerFilter]
  )
  const [orders, total, loading] = usePages<IOrder>(callback, page, 'orders')

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header
        additionalButtons={
          user?.isPrepCenter
            ? undefined
            : [
                {
                  id: 'add-order-button',
                  tooltip: 'Create a Shipment',
                  bigButton: true,
                  onClick: () => navigate('/app/orders/create'),
                },
              ]
        }
      />
      <div className="grow flex flex-col overflow-hidden pb-6">
        <header className="flex justify-between items-center z-[1000]">
          <div className="flex gap-1 flex-col">
            <span>Outbound Shipments</span>
          </div>
          {false && <button className="button-primary">
            Create New Shipment
          </button>}
        </header>
        <div className="flex flex-col grow overflow-y-auto mb-5 px-6">
          <table className="overflow-hidden">
            <colgroup>
              <col className="w-[200px]" />
              <col />
              <col />
              <col />
              <col className="w-[200px]" />
              <col className="w-[120px]" />
            </colgroup>
            <thead>
              <tr>
                <td>Title</td>
                <td>Date</td>
                <td>Status</td>
                <td>Unique SKUs</td>
                <td>Units</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {orders?.map((order) => (
                <Order key={order.id} {...order} />
              ))}
              {!orders?.length && (
                <TableSpanner rows={4} cols={6}>
                  {orders === undefined ? <FullLoader /> : <Empty text="No Shipments" />}
                </TableSpanner>
              )}
            </tbody>
          </table>
        </div>
        <Paginator page={page} setPage={setPage} loading={loading} total={Math.ceil((total || 0) / 10)} />
      </div>
    </div>
  )
}

export default OutboundPage
