import amazonItemImgUrl from 'helpers/amazonItemImgUrl'
import { FC, useState } from 'react'
import { IOrder, IShippingDataSPD } from 'types/orders'
import BoxConfig, { ISPDBoxConfig } from './BoxConfig'
import { v4 } from 'uuid'
import ordersApi from 'api/orders'
import { handleError } from 'helpers/errors'
import { printBoxLabels } from '../helpers/printBoxLabels'

interface props {
  item: IOrder['itemsData'][0]
  order: IOrder
  expiryDate?: string
  onCancel: () => void
  onDone: (data: any) => void
}

const transformBoxConfigsToBoxes = (boxes: ISPDBoxConfig[]) => {
  const transformed: IShippingDataSPD['boxes'] = []
  for (const box of boxes) {
    if (!box.quantity || !box.numBoxes || !box.length || !box.weight || !box.width || !box.height || !box.quantity) {
      handleError('Invalid box configuration')
      return []
    }
    for (let i = 0; i < box.numBoxes; i++) {
      transformed.push({
        boxId: v4(),
        items: [{ sku: box.sku, quantity: box.quantity, expiryDate: box.expiryDate }],
        dimensions: {
          length: box.length,
          width: box.width,
          height: box.height,
        },
        weight: box.weight,
      })
    }
  }
  return transformed
}

const transformBoxesToBoxConfigs = (boxes: IShippingDataSPD['boxes'], sku: string) => {
  const transformed: ISPDBoxConfig[] = []
  const otherBoxes: IShippingDataSPD['boxes'] = []
  for (const box of boxes) {
    if (box.items?.length !== 1 || box.items[0].sku !== sku) {
      otherBoxes.push(box)
      continue
    }
    const existing = transformed.find(
      (b) =>
        b.quantity === box.items[0].quantity &&
        b.expiryDate === box.items[0].expiryDate &&
        b.length === box.dimensions.length &&
        b.width === box.dimensions.width &&
        b.height === box.dimensions.height &&
        b.weight === box.weight
    )
    if (existing) {
      existing.numBoxes = (existing.numBoxes || 0) + 1
    } else {
      transformed.push({
        boxId: v4(),
        sku: box.items[0].sku,
        quantity: box.items[0].quantity,
        numBoxes: 1,
        expiryDate: box.items[0].expiryDate,
        length: box.dimensions.length,
        width: box.dimensions.width,
        height: box.dimensions.height,
        weight: box.weight,
      })
    }
  }
  return [transformed, otherBoxes] as const
}

const SPD: FC<props> = ({ item, order, onCancel, onDone }) => {
  const [submitting, setSubmitting] = useState(false)
  const initialBox = () => ({
    boxId: v4(),
    sku: item.sku,
    quantity: undefined,
    numBoxes: undefined,
    expiryDate: item.expiryDate,
    length: undefined,
    width: undefined,
    height: undefined,
    weight: undefined,
  })
  const [initialBoxes, otherBoxes] = transformBoxesToBoxConfigs((order.shipmentData?.boxes as any) || [], item.sku)
  const [boxes, setBoxes] = useState<ISPDBoxConfig[]>(initialBoxes.length ? initialBoxes : [initialBox()])

  const setBox = (box: ISPDBoxConfig) => {
    setBoxes((old) => {
      const newBoxes = [...old]
      const index = newBoxes.findIndex((b) => b.boxId === box.boxId)
      if (index === -1) return old
      newBoxes[index] = box
      return newBoxes
    })
  }

  const deleteBox = (boxId: string) => {
    setBoxes((old) => {
      const n = old.filter((b) => b.boxId !== boxId)
      if (n.length === 0) return [initialBox()]
      return n
    })
  }

  const onSubmit = () => {
    const transformed = transformBoxConfigsToBoxes(boxes)
    if (!order.shipmentData?.shipmentId || submitting || !transformed.length) return
    setSubmitting(true)
    const updated = [...otherBoxes, ...transformed]
    ordersApi
      .updateBoxes(order.id, updated)
      .then((updatedBoxes) => {
        const toPrint = updatedBoxes.filter((box) => box.items.length === 1 && box.items[0].sku === item.sku)
        printBoxLabels(order.shipmentData?.shipmentId || '', order.id, order.itemsData, toPrint, order.sellerName, order.warehouseAddress, order.shipmentData?.destinationAddress as any)
        onDone({ boxes: updatedBoxes })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <div>
        <div className="w-full gap-6 flex !flex-row">
          <div
            style={{
              backgroundImage: `url(${amazonItemImgUrl(item.asin, 256, false)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: '50% 50%',
            }}
            className="h-fill w-[256px] rounded-xl object-cover"
          />
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 border border-base-200 rounded-xl divide-y divide-y-base-200">
              <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">FNSKU</div>
              <div className="text-xs text-base-900 p-4 flex items-center text-left !border-0">{item.fnsku}</div>
              <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">SKU</div>
              <div className="text-xs text-base-900 p-4 flex items-center text-left">{item.sku}</div>
              <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Title</div>
              <div className="text-xs text-base-900 p-4 flex items-center text-left">{item.name}</div>
              <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Total Quantity</div>
              <div className="text-xs text-base-900 p-4 flex items-center text-left">{item.actual}</div>
              <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Box Configuration</div>
              <div className="text-xs text-base-900 divide-y divide-y-base-200">
                {boxes.map((box) => (
                  <BoxConfig key={box.boxId} box={box} item={item} setBox={setBox} onDelete={() => deleteBox(box.boxId)} />
                ))}
              </div>
            </div>
            <div className="flex justify-between">
              <button className="button-secondary" onClick={() => setBoxes((old) => [...old, initialBox()])}>
                Add Another Configuration Box
              </button>
              <div className="flex items-center space-x-1">
                <span className="text-xs font-medium text-base-400">Total</span>
                <span className="text-2xl font-medium text-base-900">{boxes.reduce((acc, box) => acc + (box.numBoxes || 0) * (box.quantity || 0), 0)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-base-900 not-themed">
        <button className="button-dark" onClick={onCancel}>
          Cancel
        </button>
        <button disabled={submitting} className="button-primary" onClick={onSubmit}>
          Print Box Labels
        </button>
      </footer>
    </>
  )
}

export default SPD
