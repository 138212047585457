import { useEffect, useState } from "react";

const useSelectedSeller = () => {
    const [selected, setSelected] = useState<string | undefined>(sessionStorage.getItem('filteredSeller') || undefined);

    useEffect(() => {
        const listener = (e: StorageEvent) => {
            if (e.key === 'filteredSeller') {
                let val = e.newValue
                if (e.newValue === "All") val = null
                setSelected(val || undefined)
            }
        }
        window.addEventListener('storage', listener)

        return () => window.removeEventListener('storage', listener)
    }, [])

    return selected
}

export default useSelectedSeller
