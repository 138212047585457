import api from 'api'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import { handleError } from 'helpers/errors'
import { IBill, IPaymentStructure } from 'types/billing'
import { useNavigate } from 'react-router-dom'
import FullLoader from 'components/loaders/FullLoader'
import Stripe from 'stripe'

const UpdateInvoicePaymentStructureModal: AwaitableModal<{ invoice: Stripe.Invoice, bills: IBill[]}, { structure?: string, invoiceId?: string }> = ({ open, resolve, onCancel, initialData }) => {
  const [sending, setSending] = useState(false)
  const [selectedStructure, setSelectedStructure] = useState<string | undefined>(initialData.structure)
  const [pricingStructures, setPricingStructures] = useState<IPaymentStructure[]>()
  const navigate = useNavigate()

  useEffect(() => {
    api.getPaymentStructures().then((ps) => {
      setSelectedStructure(ps[0].identifier)
      setPricingStructures(ps)
    })
  }, [])

  const onResolve = () => {
    navigate('/app/billing/invoices')
  }

  const handleSubmit = () => {
    if (!selectedStructure) return handleError('Please select a pricing structure')
    if (!initialData.invoiceId) return handleError('Please select an invoice')
    setSending(true)
    api
      .updateInvoicePaymentStructure(initialData.invoiceId, selectedStructure)
      .then(resolve)
      .finally(() => setSending(false))
  }

  return (
    <Modal open={open} close={onResolve}>
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>Update Payment Structure</h1>
            <h3>Select a pricing structure</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        <div className="w-full">
          {pricingStructures === undefined ? (
            <FullLoader />
          ) : (
            <select className="w-full" onChange={(e) => setSelectedStructure(e.currentTarget.value)}>
              {pricingStructures?.map((structure) => (
                <option key={structure.id} value={structure.identifier}>
                  {structure.identifier}
                </option>
              ))}
            </select>
          )}
        </div>
        <footer className="items-center">
          <button disabled={sending} className="button-primary" onClick={handleSubmit}>
            Update
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default UpdateInvoicePaymentStructureModal
