import { FC, useState } from 'react'
import useOrder from '../context/hook'
import Loader from 'components/loaders/Loader'
import { IDistributionOrder } from 'types/admin'
import adminApi from 'api/admin'

const OrderItem: FC<{
  item: IDistributionOrder['data'][0]
  onMarkFulfillable: (itemId: string, quantity: number) => void
}> = ({ item, onMarkFulfillable }) => {
  const { order } = useOrder()
  const { name, upc, id: itemId } = item
  const _fulfillable = order.shipmentData?.[itemId]?.fulfillable

  const [submitting, setSubmitting] = useState(false)
  const [fulfillable, setFulfillable] = useState<number>(_fulfillable || 0)

  const handleSubmit = () => {
    if (submitting) return
    setSubmitting(true)
    adminApi.putFulfillable(order.id, itemId, fulfillable)
    .then(() => {
      onMarkFulfillable(itemId, fulfillable)
    })
    .catch((e) => {
      setFulfillable(_fulfillable || 0)
    })
    .finally(() => setSubmitting(false))
  }

  return (
    <>
      {submitting && <div className="absolute inset-0 bg-gray-100 bg-opacity-50 flex items-center justify-center z-50"><Loader /></div>}
      <tr>
        <td>{(item.count * (item.caseQty || 1)).toLocaleString("en-US")}</td>
        <td>                
          <input type="number" placeholder="Enter Quantity..." className="input w-20" disabled={submitting} value={fulfillable} onChange={(e) => setFulfillable(Number(e.target.value))} onBlur={handleSubmit} />
        </td>
        <td className="select-text max-w-[200px] truncate" title={upc}>
          {upc}
        </td>
        <td className="select-text max-w-[200px] truncate" colSpan={2} title={name}>
          {name}
        </td>
      </tr>
    </>
  )
}

export default OrderItem
