import IconButton from "components/buttons/IconButton";
import { FC } from "react";

interface props {
    title: string
    selected: string
    onChange: (selected: string) => void
}

const Sort: FC<props> = ({ onChange, selected, title }) => {
    const toggle = () => {
        onChange(selected.includes("asc") ? "desc" : "asc")
    }

    return (
        <div className="flex justify-between items-center mb-5">
            <p>{title}</p>
            <IconButton className="-right-2 -top-1" size={16} name={selected.includes("asc") ? "arrow-up" : "arrow-down"} onClick={toggle} />
        </div>
    )
}

export default Sort