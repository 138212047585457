import { PaymentMethod } from '@stripe/stripe-js'
import { iconByPaymentMethod } from 'assets/icons/paymentMethods'
import { FC, useState } from 'react'
import {format} from "date-fns"
import IconButton from 'components/buttons/IconButton'
import api from 'api'
import useAwaitableModal from 'hooks/useAwaitableModal'
import AreYouSureModal from 'components/AreYouSureModal'

interface props {
  billingMethod: PaymentMethod
  onDelete: () => void
}

const getType = (billingMethod: PaymentMethod) => {
  if (billingMethod.type === 'card') return 'Card'
  if (billingMethod.type === 'sepa_debit') return 'SEPA Debit'
  if (billingMethod.type === 'us_bank_account') return 'US Bank Account'
  return 'Unknown'
}

const getIdentifier = (billingMethod: PaymentMethod) => {
  if (billingMethod.type === 'card') return billingMethod.card?.last4.padStart(16, '•').replace(/(.{4})/g, '$1 ')
  if (billingMethod.type === 'sepa_debit') return billingMethod.sepa_debit?.last4?.padStart(16, '•').replace(/(.{4})/g, '$1 ')
  if (billingMethod.type === 'us_bank_account') return billingMethod.us_bank_account?.routing_number.replace(/(.{3})/g, '$1 ')
  return 'Unknown'
}

const getImage = (billingMethod: PaymentMethod) => {
  if (billingMethod.type === 'card') {
    const brand = billingMethod.card?.brand
    if (brand) {
      return iconByPaymentMethod[brand]
    } else {
      return null
    }
  }
  if (billingMethod.type === 'sepa_debit') return iconByPaymentMethod['sepa']
  if (billingMethod.type === 'us_bank_account') return null
  return null
}

const BillingMethod: FC<props> = ({ billingMethod, onDelete }) => {
  const image = getImage(billingMethod)
  const dateString = format(new Date(billingMethod.created * 1000), "MMM do, yyyy")
  const [deleting, setDeleting] = useState(false)
  const [areYouSure, AreYouSure] = useAwaitableModal(AreYouSureModal, {text: "Are You Sure You Want to Delete This Payment Method?"})

  const handleDelete = () => {
    if (deleting) return
    setDeleting(true)
    api.deletePaymentMethod(billingMethod.id)
    .then(() => {onDelete()})
    .finally(() => {
      setDeleting(false)
    })
  }
  return (<>
  <AreYouSure />
    <tr>
      <td>
        <div className="flex items-center space-x-3">
          {!!image && <img className="w-10 h-6 rounded" src={image} />}
          <p>{getType(billingMethod)}</p>
        </div>
      </td>
      <td>{getIdentifier(billingMethod)}</td>
      <td>{dateString}</td>
      <td><IconButton name="trash" onClick={() => areYouSure().then(handleDelete)} disabled={deleting} /></td>
    </tr>
    </>
  )
}

export default BillingMethod
