import api from 'api'
import Header from 'components/layout/Header'
import { adminId } from 'constants/admin'
import useUserContext from 'contexts/User/useUserContext'
import { FC, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { IWarehouse } from 'types/warehouses'
import ApexWarehouse from './components/ApexWarehouse'
import FullLoader from 'components/loaders/FullLoader'
import Empty from 'components/Empty'
import useAwaitableModal from 'hooks/useAwaitableModal'
import PartneredPrepPricesModal from './modals/PartneredPrepPricesModal'
import InvitePrepModal from './modals/InvitePrepModal'

const WarehousesPage: FC = () => {
  const user = useUserContext()
  const [warehouses, setWarehouses] = useState<IWarehouse[]>()
  const [request, PricesModal] = useAwaitableModal(PartneredPrepPricesModal, {})
  const [invitePrep, InviteModal] = useAwaitableModal(InvitePrepModal, {})

  useEffect(() => {
    if (user && (user.isPrepCenter || (user.referrer && user.referrer !== adminId))) return
    api.listApexWarehouses().then(setWarehouses)
  }, [user])

  if (user && (user.isPrepCenter || (user.referrer && user.referrer !== adminId))) return <Navigate to="/app/dashboard" />

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header title="Warehouse" />
      <div className="grow flex flex-col overflow-hidden">
        <header className="flex justify-between items-center z-[1000]">
          <div className="flex gap-1 flex-col">
            <span>Warehouse</span>
          </div>
          <div className="flex items-center gap-4">
          <button onClick={() => invitePrep()} className="border border-amber-500 flex gap-2 px-5 py-3 rounded-full items-center hover:bg-amber-50 transition-all">
            <span className="text-amber-500 font-medium text-sm w-max">Invite Prep Center</span>
          </button>
          <button className="button-primary" onClick={request}>
            Prepping Price
          </button>
          </div>
        </header>
        <div className="flex flex-col grow overflow-y-auto">
          <div className="flex flex-col gap-4 h-max px-6 pb-6">
            {warehouses?.map((warehouse) => (
              <ApexWarehouse key={warehouse.id} warehouse={warehouse} />
            ))}
            {!warehouses?.length && (warehouses === undefined ? <FullLoader /> : <Empty text="No Warehouses" />)}
          </div>
        </div>
      </div>
      <PricesModal />
      <InviteModal />
    </div>
  )
}

export default WarehousesPage
