import employeesApi from 'api/employees'
import Switch from 'components/Switch'
import { availableRoles } from 'constants/roles'
import { FC, useState } from 'react'

interface props {
  id?: string
  name: string
  email: string
  position: string
  roles?: string[]
  createdAt?: string
}

const Employee: FC<props> = ({ id, name, email, position, roles: _roles, createdAt }) => {
  const [roles, setRoles] = useState<string[] | undefined>(_roles)
  const [toggling, setToggling] = useState(false)

  const toggleRole = (role: string) => {
    if (!id) return
    if (toggling) return
    if (!roles) return
    setToggling(true)
    const updated = roles?.includes(role) ? roles?.filter((r) => r !== role) : [...(roles || []), role]
    employeesApi
      .updateRoles(id, updated)
      .then(() => {
        setRoles((old) => (old?.includes(role) ? old?.filter((r) => r !== role) : [...(old || []), role]))
      })
      .finally(() => {
        setToggling(false)
      })
  }

  return (
    <section className="box">
      <header style={{ borderBottomColor: 'transparent' }}>
        <div className="flex flex-col gap-1.5">
          <span className="flex-grow">{name}</span>
          <span className="text-sm text-base-500">
            {email} • {position}
          </span>
        </div>
        <div className="bg-white flex">
          {Object.keys(availableRoles).map((role) => (
            <div className={['flex items-center justify-between gap-3 p-6 grow cursor-pointer', toggling && 'grayscale'].asClass} onClick={() => toggleRole(role)}>
              <Switch checked={roles?.includes(role as keyof typeof availableRoles) || roles?.includes('ADMIN') || false} />
              <span className="text-sm text-base-900">{availableRoles[role as keyof typeof availableRoles]}</span>
            </div>
          ))}
        </div>
        {!roles && <span className="text-[0.6125rem] leading-none text-yellow-600 uppercase font-medium py-2 px-3.5 rounded-full bg-yellow-100">Pending</span>}
      </header>
    </section>
  )
}

export default Employee
