import axios from 'api/axios'
import { AxiosInstance } from 'axios'
import { IContactInfo } from 'pages/app/InitialSetup/types'
import { IAdminClient, IDistributionOrder, IShipmentsOverview } from 'types/admin'
import { IChat } from 'types/chats'

class AdminApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }
  
  async getClients() {
    return this.axios.get('/admin/clients').then((res) => res.data as {users: IAdminClient[], page: number, count: number})
  }
  
  async getDistributionOrders({page = 0}: {page: number}) {
    return this.axios.get('/admin/dashboard/dist-orders?page='+page).then((res) => res.data as {orders: IDistributionOrder[], page: number, pages?: number})
  }

  async getDistributionOrder(id: string) {
    return this.axios.get('/admin/dist-orders/'+id).then((res) => res.data.order as IDistributionOrder)
  }

  async deleteDistributionOrder(id: string) {
    return this.axios.delete('/admin/dist-orders/'+id)
  }

  async putFulfillable(orderId: string, itemId: string, fulfillable: number) {
    return this.axios.put('/admin/dist-orders/'+orderId+'/'+itemId+'/fulfillable', {fulfillable})
  }

  async putDistOrderSkus(orderId: string, itemId: string, sku: {
        sku: string
        qty: number,
        bundle: number,
        toStore: number
      }[]) {
    return this.axios.put('/admin/dist-orders/'+orderId+'/'+itemId+'/skus', {sku})
  }

  async putNotes(id: string, notes: string) {
    return this.axios.put('/admin/dist-orders/'+id+'/notes', {notes})
  }

  async distOutForDelivery(id: string) {
    return this.axios.post('/admin/dist-orders/'+id+'/out-for-delivery')
  }

  async distArrived(id: string) {
    return this.axios.post('/admin/dist-orders/'+id+'/arrived')
  }

  async getChatWithClient(id: string) {
    return this.axios.get('/admin/chats/'+id).then((res) => res.data.chat as IChat)
  }

  async getShipmentsOverview(days = 30) {
    const sp = new URLSearchParams()
    sp.set('days', days.toString())
    return this.axios.get('/admin/shipments/overview?'+sp.toString()).then((res) => res.data as IShipmentsOverview)
  }

  async getPrepCenterContacts() {
    return this.axios.get('/admin/prep-centers').then((res) => res.data.contacts as (IContactInfo & {id: string})[])
  }
}

const adminApi = new AdminApi()

export default adminApi
