import api from 'api'
import AuthenticatedImage from 'components/AuthenticatedImage'
import ImageUpload from 'components/ImageUpload'
import Loader from 'components/loaders/Loader'
import firebase from 'config/firebase'
import useUserContext from 'contexts/User/useUserContext'
import { collection, doc, onSnapshot, updateDoc, getDocs, query, startAfter, limit, orderBy } from 'firebase/firestore'
import { ref, uploadBytes } from 'firebase/storage'
import useTopScrollListener from 'hooks/useTopScrollListener'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { IMessage } from 'types/chats'
import ChatBubble from './components/ChatBubble'
import IconButton from 'components/buttons/IconButton'
import { IContactInfo } from 'pages/app/InitialSetup/types'

const isDateLess = (a: string, b: string) => new Date(a) < new Date(b)

interface props {
  data?: {
    id: string
    user: IContactInfo & { id: string }
    close: () => void
  }
}

const ChatPage: FC<props> = ({ data }) => {
  const location = useLocation()
  const inputDivRef = useRef<HTMLDivElement>(null)
  const isChats = location.pathname.startsWith('/app/contacts')
  const { user, id } = data || (location.state as { user: IContactInfo & { id: string }; id: string })
  const me = useUserContext()
  const [messages, setMessages] = useState<IMessage[]>()
  const [image, setImage] = useState<File>()
  const [img, setImg] = useState<string>()
  const [modal, setModal] = useState(false)
  const [latestMessage, setLatestMessage] = useState<IMessage>()
  const [loadingMore, setLoadingMore] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [newMessage, setNewMessage] = useState<string>()
  const navigate = useNavigate()

  const handleSend = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!img) {
      if (newMessage) {
        api.sendMessage(id, newMessage.trim(), 'text').then((res) => {
          appendMessage(res.data.created, false)
        })
      }
      inputDivRef.current?.textContent && (inputDivRef.current.textContent = '')
      setNewMessage(undefined)
    } else {
      inputDivRef.current?.textContent && (inputDivRef.current.textContent = '')
      api.sendMessage(id, img, 'image').then((res) => {
        setImage(undefined)
        appendMessage(res.data.created, false)
      })
    }
    return false
  }

  const appendMessage = useCallback(
    (message: IMessage, onlyOther = true) => {
      setMessages((old) => {
        if (message.author === me?.id && onlyOther) return old
        if (old?.find(({ id }) => id === message.id)) return old
        if (old) {
          return [...old, message].sort((a, b) => (isDateLess(a.createdAt, b.createdAt) ? 1 : -1))
        } else {
          return [message].sort((a, b) => (isDateLess(a.createdAt, b.createdAt) ? 1 : -1))
        }
      })
    },
    [me]
  )

  useEffect(() => {
    if (!me) return
    if (!latestMessage) return
    const unsubscribe = onSnapshot(query(collection(firebase.db, 'chats', id, 'messages'), orderBy('createdAt'), startAfter(latestMessage.createdAt)), (updates) => {
      updates.forEach((changed) => {
        appendMessage(changed.data() as IMessage)
      })
    })

    return () => {
      unsubscribe()
    }
  }, [id, me, appendMessage, latestMessage])

  useEffect(() => {
    if (!messages) return
    if (messages[0] === latestMessage) return
    setLatestMessage(messages[0])
  }, [messages, latestMessage])

  useEffect(() => {
    if (!id || !me) return
    updateDoc(doc(firebase.db, 'chats', id), { [`lastSeen.${me.id}`]: new Date().toISOString() })

    return () => {
      updateDoc(doc(firebase.db, 'chats', id), { [`lastSeen.${me.id}`]: new Date().toISOString() })
    }
  }, [latestMessage, me, id])

  useEffect(() => {
    if (!id) return
    getDocs(query(collection(firebase.db, 'chats', id, 'messages'), orderBy('createdAt', 'desc'), limit(20))).then((data) => {
      if (data.empty || data.size < 20) setHasMore(false)
      setMessages(data.docs.map((val) => val.data() as IMessage))
    })
  }, [id])

  const loadMore = useCallback(() => {
    if (!messages) return
    if (loadingMore || !hasMore) return
    setLoadingMore(true)
    getDocs(query(collection(firebase.db, 'chats', id, 'messages'), orderBy('createdAt', 'desc'), startAfter(messages.at(-1)?.createdAt), limit(20)))
      .then((laterMessages) => {
        if (laterMessages.empty || laterMessages.size < 20) setHasMore(false)
        setMessages((old) => old && old.concat(laterMessages.docs.map((val) => val.data() as IMessage)))
      })
      .finally(() => setLoadingMore(false))
  }, [messages, id, loadingMore, hasMore])

  useEffect(() => {
    if (image && me) {
      setModal(false)
      uploadBytes(ref(firebase.storage, `temp/${me?.id}`), image).then((snapshot) => {
        setImg(snapshot.metadata.fullPath)
      })
    } else {
      setImg(undefined)
    }
  }, [image, me])

  const scrollRef = useTopScrollListener(loadMore)

  return (
    <div className="w-full flex flex-col h-full relative bg-base-100 rounded-lg">
      <section className="box w-full flex flex-col h-full relative bg-white !rounded-b-none border-b border-b-base-200">
        <header className="flex w-full items-center !justify-start space-x-2" style={{ paddingInline: !data ? '8px !important' : undefined }}>
          <IconButton
            name="arrow-left"
            className={[isChats ? 'xl:hidden' : '', '!fill-primary-600'].filter(Boolean).join('')}
            id={id}
            onClick={() => {
              if (data) {
                data.close()
              } else {
                navigate('/app/contacts')
              }
            }}
          />
          <span>{user.name}</span>
        </header>
        <div ref={scrollRef} className="flex flex-col-reverse grow overflow-y-auto py-3">
          {messages?.map((message) => (
            <ChatBubble key={message.id} {...message} mine={message.author !== user.id} />
          ))}
          {(hasMore || loadingMore) && (
            <div className="flex justify-center items-center py-2">
              <Loader />
            </div>
          )}
        </div>
        <ImageUpload image={image} setImage={setImage} modal={modal} closeModal={() => setModal(false)} />
      </section>
      <form className="flex w-full p-5 space-x-1 bg-white items-center rounded-b-lg" onSubmit={handleSend}>
        <div className="flex grow group">
          <div
            ref={inputDivRef}
            onInput={(e) => {
              setNewMessage(e.currentTarget.textContent || undefined)
            }}
            className={image ? 'hidden' : 'text-base text-base-950 outline-none p-0 resize-none min-h-4 max-h-40 w-full wrap break-all overflow-y-auto group relative'}
            contentEditable
          />
          <span className={!!newMessage || image ? 'hidden' : 'absolute text-base-500 pointer-events-none'}>Type something...</span>
        </div>
        {!!image && (
          <div className="flex grow">
            <div className="group relative w-48 h-48">
              <div className="absolute flex items-center justify-center w-full h-full opacity-0 transition group-hover:opacity-100 bg-semitransparent rounded-xl">
                <IconButton name="trash" className="pointer-events-auto cursor-pointer" onClick={() => setImage(undefined)} />
              </div>
              <AuthenticatedImage className="border-2 w-full h-full object-cover border-dark rounded-xl" src={img} />
            </div>
          </div>
        )}
        {!image && <IconButton name="gallery" className="!bg-transparent !fill-base-950 hover:!fill-primary-500" onClick={() => setModal(true)} />}
        <IconButton name="send-1" className="!bg-transparent !fill-base-950 hover:!fill-primary-500" type="submit" />
      </form>
    </div>
  )
}

export default ChatPage
