import { useCallback, useEffect, useRef } from "react"

const useBottomScrollListener = (callback: () => void, offsetBottom = 1000) => {
    const ref = useRef<HTMLDivElement>(null)

    const handleScroll = useCallback((e: Event) => {
        if (ref.current) {
            if (ref.current.scrollTop + ref.current.clientHeight > ref.current.scrollHeight - offsetBottom) {
                callback()
            }
        }
    }, [callback, offsetBottom])

    useEffect(() => {
      if (ref.current) {
        ref.current.onscroll = handleScroll
      }  
    }, [ref, handleScroll])

    return ref
}

export default useBottomScrollListener