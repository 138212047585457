import api from 'api'
import Empty from 'components/Empty'
import TableSpanner from 'components/TableSpanner'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import { useEffect, useState } from 'react'
import { IInvoiceListed } from 'types/billing'
import useUserContext from 'contexts/User/useUserContext'
import { useParams } from 'react-router-dom'
import SellerInvoice from '../components/SellerInvoice'

const SellerInvoicesPage = () => {
  const [invoices, setInvoices] = useState<IInvoiceListed[]>()
  const { prep } = useParams()

  const user = useUserContext()

  useEffect(() => {
    setInvoices(undefined)
    api.getInvoices({ from: prep }).then(setInvoices)
  }, [prep])

  const onViewInStripe = () => {
    if (!user?.stripeAccountId) return
    window.open(`https://dashboard.stripe.com/${user.stripeAccountId}/invoices`, '_blank')
  }

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header />
      <div className="grow flex flex-col overflow-hidden">
        <header className="flex justify-between items-center z-[1000]">
          <span>Invoices</span>
          {user?.isPrepCenter && user.stripeAccountId && (
            <button className="button-primary" onClick={onViewInStripe}>
              View in Stripe
            </button>
          )}
        </header>
        <div className="flex flex-col grow overflow-y-auto">
          <div className="flex flex-col grow px-8 h-max space-y-5 pb-8">
            <section className="box bg-white items-center overflow-y-hidden">
              <div className="w-full">
                <div className="rounded-xl overflow-y-hidden h-full">
                  <table className="w-full">
                    <colgroup>
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <td>Location</td>
                        <td>Date</td>
                        <td>Shipping Id</td>
                        <td>Amount</td>
                        <td>Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices?.map((invoice) => (
                        <SellerInvoice key={invoice.id} invoice={invoice} />
                      ))}
                      {!invoices?.length && (
                        <TableSpanner rows={4} cols={5}>
                          {invoices === undefined ? <FullLoader /> : <Empty text="No Invoices" />}
                        </TableSpanner>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellerInvoicesPage
