import Header from 'components/layout/Header'
import PaymentStructures from './components/PaymentStructures'
import ItemServices from './components/ItemServices'
import OrderServices from './components/OrderServices'
import api from 'api'
import servicesApi from 'api/services'
import { useState, useEffect } from 'react'
import { IPaymentStructure } from 'types/billing'
import { IService } from 'types/services'

const PaymentStructuresPage = () => {
  const [itemServices, setItemServices] = useState<IService[]>()
  const [orderServices, setOrderServices] = useState<IService[]>()
  const [paymentStructures, setPaymentStructures] = useState<IPaymentStructure[]>()


  useEffect(() => {
    servicesApi.getItemServices().then(setItemServices)
  }, [])

  useEffect(() => {
    servicesApi.getOrderServices().then(setOrderServices)
  }, [])


  useEffect(() => {
    api.getPaymentStructures().then(setPaymentStructures)
  }, [])

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header />
      <div className="grow flex flex-col overflow-hidden">
        <header className="flex justify-between items-center z-[1000]">
          <span>Payment Structures And Services</span>
        </header>
        <div className="px-6 grow overflow-y-auto pb-6">
          <div className="grid grid-cols-2 gap-6 rounded-2xl">
          <OrderServices orderServices={orderServices?.filter(s => s.type === "inbound")} setOrderServices={setOrderServices} type="inbound" />
          <OrderServices orderServices={orderServices?.filter(s => s.type === "outbound")} setOrderServices={setOrderServices} type="outbound" />
          <ItemServices itemServices={itemServices} setItemServices={setItemServices} />
          <PaymentStructures paymentStructures={paymentStructures} setPaymentStructures={setPaymentStructures} orderServices={orderServices} itemServices={itemServices} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentStructuresPage
