import { CloseIcon } from 'assets/icons'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

const SubscribeModal: FC = () => {
  const navigate = useNavigate()
  const onClick = () => {
    navigate('/app/settings/subscription')
  }

  return (
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>Subscription required</h1>
            <h3>Head to Subscription page and pick the right plan for you</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={() => navigate(-1)} />
        </header>
        <footer className="!border-t-0">
          <button className="button-primary w-full" onClick={onClick}>
            View Plans
          </button>
        </footer>
      </div>
  )
}

export default SubscribeModal
