import api from "api";
import Empty from "components/Empty";
import TableSpanner from "components/TableSpanner";
import FullLoader from "components/loaders/FullLoader";
import Loader from "components/loaders/Loader";
import useUserContext from "contexts/User/useUserContext";
import useAwaitableModal from "hooks/useAwaitableModal";
import useHasRole from "hooks/useHasRole";
import { IClient } from "pages/app/Dashboard/components/ClientList";
import ClientSubModal from "pages/app/Dashboard/modals/ClientSubModal";
import InviteClientModal from "pages/app/modals/InviteClientModal";
import { FC, useEffect, useState } from "react";
import { IPaymentStructure } from "types/billing";

const MerchantsSettingsPage: FC = () => {
  const user = useUserContext()
  const [clients, setClients] = useState<IClient[]>()
  const [inviteClient, Element] = useAwaitableModal<IClient[]>(InviteClientModal, {})
  const [clientSubModal, ClientSubElement] = useAwaitableModal(ClientSubModal, {})
  const [droppingClient, setDroppingClient] = useState<boolean>(false)
  const [changingStructure, setChangingStructure] = useState<boolean>(false)
  const [pricingStructures, setPricingStructures] = useState<IPaymentStructure[]>()
  const canInvite = useHasRole('INVITE_CLIENTS')
  const canDelete = useHasRole('ADMIN')

  useEffect(() => {
    api.getPaymentStructures().then(setPricingStructures)
  }, [])

  useEffect(() => {
    api.getClients().then(setClients)
  }, [])

  const onDrop = (id: string) => {
    if (droppingClient) return
    setDroppingClient(true)
    api
      .dropInvite(id)
      .then(() => setClients((old) => old?.filter((client) => client.id !== id)))
      .finally(() => setDroppingClient(false))
  }

  const onChangePaymentStructure = (id: string, pricing: string) => {
    if (changingStructure) return
    setChangingStructure(true)
    api
      .changeClientTier(id, pricing)
      .then(() => setClients((old) => old?.map((client) => (client.id !== id ? client : { ...client, data: { ...client.data, pricing } }))))
      .finally(() => setChangingStructure(false))
  }

  const clientsSortedByAccepted = clients?.sort((a, b) => (a.accepted === b.accepted ? 0 : a.accepted ? -1 : 1))
  const clientsByOrders = clientsSortedByAccepted?.sort((a, b) => (a.stats && b.stats) ? b.stats.orderCount - a.stats.orderCount : 0)

  const activeCount = clients?.filter(({ accepted }) => accepted).length

  const onInvite = () => {
    if (!user?.subscription && !user?.clientPaysSub) {
      clientSubModal()
    } else {
      inviteClient().then((invitations?: IClient[]) => invitations?.length && setClients((old) => old && [...invitations, ...old]))
    }
  }
    return (
    <div className="rounded-2xl bg-white flex flex-col divide-y divide-y-base-200 overflow-y-auto">
      <header className="p-6 text-base text-base-950 font-semibold bg-white flex items-center gap-3 border-b border-b-base-200">
        <span className="flex-grow">Merchants</span>
        <span className="bg-primary-100 text-primary-500 uppercase rounded-full py-2 px-3.5 font-medium text-[0.6125rem] leading-none">
          {clients === undefined ? '...' : activeCount} Merchant{activeCount === 1 ? '' : 's'}
        </span>
        {canInvite && (
          <button className="button-primary -my-3" onClick={onInvite}>
            <span>Invite a Merchant</span>
          </button>
        )}
        </header>
      <Element />
      <ClientSubElement />
      <table>
        <thead>
          <tr>
            <td>Title</td>
            <td>Email</td>
            <td>Store Name</td>
            <td>Status</td>
            <td>Shipments</td>
            <td>Units</td>
            <td>Pricing</td>
            {canDelete && <td>Action</td>}
          </tr>
        </thead>
        <tbody>
          {clientsByOrders?.map((client) => (
            <tr key={client.id}>
              <td className="select-text">{client.contact?.legalName || client.contact?.name}</td>
              <td className="select-text">{client.email}</td>
              <td className="select-text">{client.contact?.name}</td>
              <td>{client.accepted ? <span className="tag tag-lime">Active</span> : <span className="tag bg-yellow-100 text-yellow-600">Pending</span>}</td>
              <td>{client.stats.orderCount}</td>
              <td>{client.stats.itemCount}</td>
              <td>
                {pricingStructures ? (
                  canDelete ? (
                    <select value={client.data?.pricing} disabled={changingStructure} placeholder="DEFAULT" onChange={(e) => onChangePaymentStructure(client.id, e.target.value)}>
                      {pricingStructures.map(({ id, identifier }) => (
                        <option key={id} value={id}>
                          {identifier}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <span>{pricingStructures.find(({ id }) => id === client.data?.pricing)?.identifier || 'DEFAULT'}</span>
                  )
                ) : (
                  <Loader size={24} />
                )}
              </td>
              {canDelete && <td>{droppingClient ? <Loader size={24} /> : <button className="button-secondary" onClick={() => onDrop(client.id)}>Kick Merchant</button>}</td>}
            </tr>
          ))}
          {!clients?.length && (
            <TableSpanner rows={3} cols={8}>
              {clients === undefined ? <FullLoader /> : <Empty text="No Merchants" />}
            </TableSpanner>
          )}
        </tbody>
      </table>
      </div>
    )
}

export default MerchantsSettingsPage