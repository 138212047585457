import { WheelEvent } from 'react'

const numberInputOnWheelPreventChange = (e: WheelEvent<HTMLInputElement>) => {
    // Prevent the input value change
    e.currentTarget?.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
      setTimeout(() => {
        e.currentTarget?.focus()
    }, 0)
}

export default numberInputOnWheelPreventChange