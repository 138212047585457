import Empty from 'components/Empty'
import IconBig from 'components/IconBig'
import TableSpanner from 'components/TableSpanner'
import Header from 'components/layout/Header'
import { handleError } from 'helpers/errors'
import numberInputOnWheelPreventChange from 'helpers/numberInputOnWheelPreventChange'
import useAwaitableModal from 'hooks/useAwaitableModal'
import useShoppingCart, { ICart } from 'hooks/useShopingCart'
import { FC, useState } from 'react'
import ChoosePaymentMethod from '../../modals/ChoosePaymentMethod'

const availableCatalogues = {
  /*bucked_up: 'Bucked Up',
  natures_way: "Nature's Way",
  rule_one: 'Rule One',
  ryse: 'Ryse',
  sun_warrior: 'Sun Warrior',
  soloray: 'Soloray',*/
  end_of_2023_special: "End of 2023 Special",
  liquidation: 'Liquidation',
  spectrum: 'Catalog',
  special: 'Special',
}

const moqS = {
  soloray: 800000,
  spectrum: 1000000,
  liquidation: 2000,
  special: 1000000,
  end_of_2023_special: 1000000,
}

export const getMOQ = (brand: keyof typeof availableCatalogues) => {
  return moqS[brand as keyof typeof moqS] || defaultMOQ
}

const itemId = (brand: string, upc: string, caseQty: number) => `${brand}_${upc}_${caseQty}`

const defaultMOQ = 500000

const checkMOQ = (cart: ICart) => {
  const brands = Object.keys(cart.items)
  const totals = brands.reduce((acc, brand) => ({...acc, [brand]: 0}), {} as any)
  brands.forEach((brand) => {
    Object.keys(cart.items[brand]).forEach((upc) => {
      totals[brand] += cart.items[brand][upc] * cart.prices[brand + "_" + upc].caseQty * cart.prices[brand + "_" + upc].unitPrice
    })
  })
  let errMessage = ''
  Object.keys(totals).forEach((brand) => {
    if (totals[brand] < getMOQ(brand as keyof typeof availableCatalogues)) {
      errMessage += `MOQ not reached for ${availableCatalogues[brand as keyof typeof availableCatalogues]}: $${(totals[brand] / 100).toFixed(2)}\n`
    }
  })
  if (errMessage) {
    handleError(errMessage)
  }
  return Object.keys(totals).every((brand) => totals[brand] >= getMOQ(brand as keyof typeof availableCatalogues))
}

const CartPage: FC = () => {
  const { cart, deltaCart, changeCountCart } = useShoppingCart()
  const [buying, setBuying] = useState(false)
  const [buy, ChoosePaymentMethodModal] = useAwaitableModal(ChoosePaymentMethod, {})
  
  const cartItemsAsArray = Object.keys(cart.items).map((key) => (
    Object.keys(cart.items[key as keyof typeof availableCatalogues]).map((upc) => ({quantity: cart.items[key as keyof typeof availableCatalogues][upc], upc, brand: key, ...cart.prices[key + "_" + upc]}))
  )).flat()

  const totalPrice = cartItemsAsArray?.reduce((acc, item) => acc + item.quantity * item.caseQty * item.unitPrice, 0)

  const onBuy = () => {
    if (checkMOQ(cart)) {
      if (buying) return
      setBuying(true)
      buy(cartItemsAsArray.reduce((acc, item) => ({...acc, [itemId(item.brand, item.upc, item.caseQty)]: item.quantity}), {} as any))
      .finally(() => setBuying(false))
    }
  }

  return (
    <div className="flex flex-col grow">
      <Header title="Shop" />
      <ChoosePaymentMethodModal />
      <div className="grow flex flex-col overflow-y-hidden pb-8">
        <header className="z-[100] flex items-center justify-between">
          <span>Your Cart</span>
        </header>
        <div className="flex gap-8 px-8 overflow-y-auto">
          <section className="box bg-white h-full relative flex flex-col p-6 grow">
            <div className="border border-slate-200 rounded-xl h-full overflow-y-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    <td>Title</td>
                    <td>Brand</td>
                    <td>Unit Price</td>
                    <td>Case</td>
                    <td>Cases</td>
                  </tr>
                </thead>
                <tbody>
                  {cartItemsAsArray?.length ? cartItemsAsArray?.map((item) => (
                    <tr key={item.upc} className="even:bg-base-200 odd:bg-base-50">
                      <td>{item.name}</td>
                      <td>{availableCatalogues[item.brand as keyof typeof availableCatalogues]}</td>
                      <td>${(item.unitPrice / 100).toFixed(2)}</td>
                      <td>{item.caseQty}</td>
                      <td>
                        <div className="flex items-center gap-2">
                          <input
                            onWheel={numberInputOnWheelPreventChange}
                            className="text-sm text-center h-min px-0 outline-none py-2 w-10"
                            value={item.quantity}
                            type="number"
                            step={1}
                            min={0}
                            onChange={(e) => changeCountCart(item.brand, item.upc, e.currentTarget.valueAsNumber, item.caseQty, item.unitPrice, item.name)}
                          />
                        </div>
                      </td>
                      <td />
                    </tr>
                  )): <TableSpanner rows={3} cols={6}>
                      <Empty text='No Items in Cart' />
                    </TableSpanner>
                    }
                </tbody>
              </table>
            </div>
            <footer className="mt-6">
              <div className="border border-base-200 rounded-xl p-6">
                <div className="flex flex-col gap-1 mb-6">
                    <span className="text-sm text-base-400">Total Price</span>
                    <span className="text-3xl text-base-900 font-medium">{(totalPrice / 100).toFixed(2)} USD</span>
                </div>
                <button className="button-primary w-full" onClick={onBuy} disabled={buying}>
                  Checkout
                </button>
              </div>
            </footer>
          </section>
          <div className="flex flex-col rounded-xl bg-white gap-6 py-12 px-6 items-center h-max">
             <IconBig name="building" size={64} />
             <span className="text-slate-900 text-lg font-medium text-center">Apex New York Location</span>
             <span className="text-sm text-slate-400 text-center">855 Conklin st, Suite I, Farmingdale, 11735</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartPage
