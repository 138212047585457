import { useCallback, useEffect, useRef } from "react"

const useTopScrollListener = (callback: () => void, offsetTop = 1000) => {
    const ref = useRef<HTMLDivElement>(null)

    const handleScroll = useCallback(() => {
        if (ref.current) {
            if (ref.current.scrollTop < offsetTop) {
                callback()
            }
        }
    }, [callback, offsetTop])

    useEffect(() => {
      if (ref.current) {
        ref.current.onscroll = handleScroll
      }  
    }, [ref, handleScroll])

    return ref
}

export default useTopScrollListener