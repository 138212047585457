import { signInWithEmailAndPassword } from 'firebase/auth'
import { FormEvent, useState } from 'react'
import firebase from '../../../config/firebase'
import { FirebaseError } from 'firebase/app'
import { Link } from 'react-router-dom'

const AuthPage = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const emailValid = email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!emailValid) return setError('Please enter a valid email address')
    if (!password) return setError('Please enter a password')
    setError(undefined)
    setSubmitting(true)
    signInWithEmailAndPassword(firebase.auth, email, password).catch((err: FirebaseError) => {
      switch (err.code) {
        case 'auth/invalid-email':
          setError('Please enter a valid email address')
          break
        case 'auth/user-disabled':
          setError('This account has been disabled')
          break
        case 'auth/user-not-found':
          setError('This account does not exist')
          break
        case 'auth/wrong-password':
          setError('Incorrect password')
          break
        default:
          setError('An unknown error occurred')
      }
      setSubmitting(false)
    })

    return false
  }

  return (
    <>
      <div className="absolute rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] left-0 -top-16" />
      <div className="fixed rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] right-0 bottom-0" />
      <div className="flex flex-col mx-32 p-8 gap-8 rounded-lg pt-32 relative z-[100]">
        <h1 className="text-orange-500 text-center">Login</h1>
        <form className="flex flex-col w-full flex gap-8" name="login" onSubmit={handleSubmit}>
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
          />
          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
          />
          <button className="mt-4 button-orange" disabled={submitting} children={submitting ? '...' : 'Log in'} onSubmit={handleSubmit} />
        </form>
        {error && <p className="text-rose-500 text-center">{error}</p>}
        <div>
          <p className="text-base-950 text-lg text-center pb-2">
            Dont have an account?{' '}
            <Link className="text-orange-500" to="/auth/register">
              Sign up
            </Link>
          </p>
          <p className="text-base-950 text-center pt-2">
            Forgot password?{' '}
            <Link className="text-orange-500" to="/auth/reset-password">
              Reset
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default AuthPage
