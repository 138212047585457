import axios from 'api/axios'
import { AxiosInstance } from 'axios'
import { IAgencyClients } from 'types/agency'

class AgencyApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }
  
  async getClients() {
    return this.axios.get('/agencies/clients').then((res) => res.data as IAgencyClients)
  }
  
  async getAgency() {
    return this.axios.get('/agencies/agency').then((res) => res.data as {name: string, id: string} | null)
  }

  async updateAgency(agency?: string) {
    return this.axios.put('/agencies/agency', { agency }).then((res) => res.data as {name: string, id: string} | null)
  }
}

const agencyApi = new AgencyApi()

export default agencyApi
