import Header from 'components/layout/Header'
import { FC, useMemo, useState } from 'react'
import useShoppingCart from 'hooks/useShopingCart'
import useCatalogue, { ICatalogueItem } from 'hooks/useCatalogue'
import numberInputOnWheelPreventChange from 'helpers/numberInputOnWheelPreventChange'
import TableSpanner from 'components/TableSpanner'
import FullLoader from 'components/loaders/FullLoader'
import { getMOQ } from './pages/Cart'
import { TableVirtuoso } from 'react-virtuoso'
import Icon from 'components/Icon'

const availableCatalogues = {
  /*bucked_up: 'Bucked Up',
  natures_way: "Nature's Way",
  rule_one: 'Rule One',
  // ryse: 'Ryse',
  sun_warrior: 'Sun Warrior',
  soloray: 'Soloray',*/
  end_of_2023_special: "End of 2023 Special",
  liquidation: 'Liquidation',
  spectrum: 'Catalog',
  special: 'Special'
}

const Row: FC<{ item: ICatalogueItem; selectedCatalogue: string; index: number }> = ({ item, selectedCatalogue, index }) => {
  const { cart, deltaCart, changeCountCart } = useShoppingCart()
  const odd = index % 2 === 1

  const cl = odd ? "bg-slate-50" : "bg-slate-200"
  return (
    <>
      <td className={["w-24 select-text", cl].asClass}>{item.upc}</td>
      <td className={["select-text", cl].asClass}>{item.name}</td>
      <td className={cl}>${(item.price / 100).toFixed(2)}</td>
      <td className={cl}>{item.caseQty}</td>
      <td className={cl}>${((item.price * item.caseQty) / 100).toFixed(2)}</td>
      <td className={cl} colSpan={2}>
        <div className="flex items-center gap-2">
          <input
            onWheel={numberInputOnWheelPreventChange}
            className="text-sm text-center h-min px-0 outline-none py-2 w-10"
            value={cart.items[selectedCatalogue]?.[item.upc] || 0}
            type="number"
            step={1}
            min={0}
            onChange={(e) => changeCountCart(selectedCatalogue, item.upc, e.currentTarget.valueAsNumber, item.caseQty, item.price, item.name)}
          />
        </div>
      </td>
    </>
  )
}

const ShopPage: FC = () => {
  const { cart } = useShoppingCart()
  const [selectedCatalogue, setSelectedCatalogue] = useState(Object.keys(availableCatalogues)[0])
  const catalogue = useCatalogue(selectedCatalogue)
  const [search, setSearch] = useState("")

  const onDownload = () => {
    const a = document.createElement('a')
    const uri = new URL(`${window.location.origin}/catalogues/${selectedCatalogue}.xlsx`)
    a.href = uri.toString()
    a.download = selectedCatalogue + '_catalogue.xlsx'
    console.log(a.href)
    a.click()
  }

  const orderedPrice = Object.keys(cart.items[selectedCatalogue] || {}).reduce((acc, upc) => {
    const item = catalogue?.find((item) => item.upc === upc)
    if (!item) return acc
    return acc + item.price * cart.items[selectedCatalogue][upc] * item.caseQty
  }, 0)

  const renderRow = (index: number, item: any) => {
    return <Row item={item} selectedCatalogue={selectedCatalogue} index={index} />
  }

  const filteredItems = useMemo(() => catalogue?.filter((item) => item.upc.toLowerCase().startsWith(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase())), [catalogue, search])

  return (
    <div className="flex flex-col grow">
      <Header title="Shop" />
      <div className="grow flex flex-col overflow-y-hidden pb-8">
        <header className="z-[100] flex items-center justify-between">
          <span>Shop</span>
          <button onClick={onDownload} className="border border-emerald-500 flex gap-2 px-4 py-2 rounded-full items-center hover:bg-emerald-50 transition-all">
            <span className="text-emerald-500 font-medium text-sm w-max">Download Excel</span>
          </button>
        </header>
        <div className="flex flex-col gap-8 px-8 overflow-y-auto">
          <section className="box bg-white flex flex-col">
            <header>
              <span className="text-2xl font-medium">Brands</span>
              <span className="text-base text-slate-400 grow text-center">please make sure you are approved to be selling this brand on your seller central.</span>
              <span className="text-base text-slate-400">MOQ is ${(getMOQ(selectedCatalogue as any) / 100).toLocaleString()}</span>
            </header>
            <div className="flex w-full overflow-x-auto h-48 shrink-0 p-6 gap-4">
              {Object.keys(availableCatalogues).map((key) => (
                <button onClick={() => setSelectedCatalogue(key)} className={['button-secondary h-max', selectedCatalogue !== key && 'text-slate-900'].asClass}>
                  {availableCatalogues[key as keyof typeof availableCatalogues]}
                </button>
              ))}
            </div>
          </section>
          <section className="box bg-white h-full relative flex flex-col px-6 pb-6 pt-2 gap-2 [&_table]:w-full">
              <div className="relative">
                <Icon name="search-normal-1"  className="fill-base-400 absolute pointer-events-none left-6 top-1/2 -translate-y-1/2" />
                <input className="border-0 bg-white w-full rounded-none outline-none p-6 pl-16" placeholder="Search UPC or name..." value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
              </div>
            <div className="border border-slate-200 rounded-xl h-full relative overflow-hidden">
              {filteredItems === undefined ? (
                <table className="w-full">
                  <thead>
                    <tr>
                      <td>UPC</td>
                      <td>Title</td>
                      <td>Unit Price</td>
                      <td>Case</td>
                      <td>Case Price</td>
                      <td>Quantity</td>
                      <td>
                        <div className="flex gap-2 items-center">
                          <span>Total <span className="text-primary-500 min-w-max whitespace-nowrap">--&gt;</span></span>
                          <span className="text-slate-900 font-medium text-sm">${(orderedPrice / 100).toFixed(2)}</span>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <TableSpanner rows={3} cols={7}>
                      <FullLoader />
                    </TableSpanner>
                  </tbody>
                </table>
              ) : (
                <TableVirtuoso
                  data={filteredItems}
                  style={{minHeight: 250}}
                  totalCount={filteredItems?.length}
                  increaseViewportBy={80}
                  fixedHeaderContent={() => (
                    <tr>
                      <td>UPC</td>
                      <td>Title</td>
                      <td>Unit Price</td>
                      <td>Case</td>
                      <td>Case Price</td>
                      <td>Quantity</td>
                      <td>
                        <div className="flex gap-2 items-center">
                          <span>Total <span className="text-primary-500 min-w-max whitespace-nowrap">--&gt;</span></span>
                          <span className="text-slate-900 font-medium text-sm">${(orderedPrice / 100).toFixed(2)}</span>
                        </div>
                      </td>
                    </tr>
                  )}
                  itemContent={renderRow}
                  minLength={10}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ShopPage
