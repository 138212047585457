import Modal from 'components/Modal'
import amazonItemImgUrl from 'helpers/amazonItemImgUrl'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { IAmazonProductImage } from 'types/amazon'

const PreviewImageModal: AwaitableModal<{}, { image: IAmazonProductImage }> = ({ initialData: { image }, onCancel, open }) => {
  const url = amazonItemImgUrl(image?.url, 500, true)

  return (
    <Modal open={open} close={onCancel}>
      <img src={url} alt={image?.asin} className="rounded-xl drop-shadow-xl p-4 bg-white" />
    </Modal>
  )
}

export default PreviewImageModal
