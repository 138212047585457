import IconBig from 'components/IconBig'
import { FC } from 'react'

export const stats = ["SENT", "AT_PREP_CENTER", "SENT_TO_AMAZON", "HANDLED"] as const

interface props {
  itemStates?: Record<string, number>
  orderStates?: Record<string, number>
}

const prefixed = (base: string, number: number) => {
  if (number === 1) return base
  return base + "s"
}

const ItemStats: FC<props> = ({ itemStates, orderStates }) => {
  const loading = !orderStates || !itemStates
  const sent = orderStates?.sent || 0
  const sentItems = itemStates?.sent || 0
  const atPrep = orderStates?.atprep || 0
  const atPrepItems = itemStates?.atprep || 0
  const inbound = orderStates?.inbound || 0
  const inboundItems = itemStates?.inbound || 0
  const atAmazon = orderStates?.atamazon || 0
  const atAmazonItems = itemStates?.atamazon || 0

  return (
      <div className="flex items-center justify-start space-x-6 px-6">
        <div className="flex flex-col flex-grow items-start p-5 rounded-[12px] border border-base-200 grow">
          <IconBig name="group" active size={48} />
          <h2 className="mt-5 mb-0">
            In Transit
          </h2>
          <p className='desc'>
            {loading ? '...' : sent} {prefixed('Shipment', sent)} . {loading ? '...' : sentItems} {prefixed('Unit', sentItems)}
          </p>
        </div>
        <div className="flex flex-col flex-grow items-start p-5 rounded-[12px] border border-base-200 grow">
          <IconBig name="building" active size={48} />
          <h2 className="mt-5 mb-0">
            At Warehouse
          </h2>
          <p className='desc'>
            {loading ? '...' : atPrep} {prefixed('Shipment', atPrep)} . {loading ? '...' : atPrepItems} {prefixed('Unit', atPrepItems)}
          </p>
        </div>
        <div className="flex flex-col flex-grow items-start p-5 rounded-[12px] border border-base-200 grow">
          <IconBig name="group" active size={48} />
          <h2 className="mt-5 mb-0">
            Outbound
          </h2>
          <p className='desc'>
            {loading ? '...' : inbound} {prefixed('Shipment', inbound)} . {loading ? '...' : inboundItems} {prefixed('Unit', inboundItems)}
          </p>
        </div>
        <div className="flex flex-col flex-grow items-start p-5 rounded-[12px] border border-base-200 grow">
          <IconBig name="box-1" active size={48} />
          <h2 className="mt-5 mb-0">
            At Amazon
          </h2>
          <p className='desc'>
            {loading ? '...' : atAmazon} {prefixed('Shipment', atAmazon)} . {loading ? '...' : atAmazonItems} {prefixed('Unit', atAmazonItems)}
          </p>
        </div>
      </div>
  )
}

export default ItemStats
