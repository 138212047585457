import { CloseIcon } from 'assets/icons'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import useAwaitableModal, { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import { IOrder, IShippingData } from 'types/orders'
import Item from './Item'
import DimensionsModal from './DimensionsModal'
import { v4 } from 'uuid'
import { printBoxLabels } from '../LabelsModal/helpers/printBoxLabels'
import ordersApi from 'api/orders'
import { useOrder } from 'contexts/Order'

const MultiFNSKUModal: AwaitableModal<IShippingData['boxes'][0], { type: 'SPD' | 'LTL'; orderId: string; shipmentId: string; itemsData: (IOrder['itemsData'][0] & { done: number })[] }> = ({
  initialData: { itemsData, type, shipmentId, orderId },
  open,
  onCancel,
  resolve,
}) => {
  const [items, setItems] = useState<{ sku: string; quantity: number; expiryDate?: string }[]>(itemsData.map((item) => ({ sku: item.sku, quantity: 0, expiryDate: item.expiryDate })))
  const [requestDimensions, Dimensions] = useAwaitableModal(DimensionsModal, { initialData: {} })
  const [submitting, setSubmitting] = useState(false)
  const {order} = useOrder()

  useEffect(() => {
    setItems(itemsData.map((item) => ({ sku: item.sku, quantity: 0, expiryDate: item.expiryDate })))
  }, [itemsData])

  const onSubmit = () => {
    if (submitting) return
    setSubmitting(true)
    const notNullItems = items.filter((item) => item.quantity)
    if (type === 'SPD') {
      requestDimensions()
        .then(async ({ weight, ...dimensions }) => {
          const box = { items: notNullItems, boxId: v4(), dimensions, weight }
          return ordersApi.addBoxes(orderId, [box]).then((boxes) => {
            const numbered = boxes.find((b) => b.boxId === box.boxId)
            printBoxLabels(shipmentId, orderId, itemsData, [box], order.sellerName, order.warehouseAddress, order.shipmentData?.destinationAddress as any)
            resolve(numbered)
          })
        })
        .finally(() => setSubmitting(false))
    } else {
      const box = { items: notNullItems, boxId: v4() }
      return ordersApi.addBoxes(orderId, [box]).then((boxes) => {
        const numbered = boxes.find((b) => b.boxId === box.boxId)
        printBoxLabels(shipmentId, orderId, itemsData, [box], order.sellerName, order.warehouseAddress, order.shipmentData?.destinationAddress as any)
        resolve(numbered)
      })
    .finally(() => setSubmitting(false))
    }
  }

  return (
    <Modal open={open} close={onCancel}>
      <div className="modal relative max-h-[calc(100vh-64px)]">
        <Dimensions />
        <header className="!items-center flex gap-3">
          <Icon  name="3d-square" className="fill-blue-500" />
          <span className="grow text-xl">Multi SKU Box</span>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        <div className="flex flex-col w-full overflow-y-auto p-6">
          <div className="border border-base-200 rounded-xl">
            <table className="w-full rounded-xl overflow-hidden">
              <thead>
                <tr>
                  <td className="text-xs text-base-400">Units</td>
                  <td className="text-xs text-base-400 font-medium border-l">Expires</td>
                  <td className="text-xs text-base-400 font-medium border-l">Remaining</td>
                  <td className="text-xs text-base-400 font-medium border-l">Image</td>
                  <td className="text-xs text-base-400 font-medium border-l">SKU / Title</td>
                </tr>
              </thead>
              <tbody>
                {itemsData.map((item, i) => {
                  const data = items.find((it) => it.sku === item.sku)
                  const setCount = (quantity: number) => {
                    setItems((items) => items.map((it) => (it.sku === item.sku ? { ...it, quantity } : it)))
                  }
                  const setExpiryDate = (date?: string) => {
                    setItems((items) => items.map((it) => (it.sku === item.sku ? { ...it, expiryDate: date } : it)))
                  }

                  if (!data) return null
                  return item.actual - item.done > 0 ? <Item key={item.fnsku} item={item} count={data.quantity} expiryDate={data.expiryDate} setCount={setCount} setExpiryDate={setExpiryDate} /> : null
                })}
              </tbody>
            </table>
          </div>
        </div>
        <footer className="bg-base-900 not-themed">
          <button className="button-dark" onClick={onCancel}>
            Cancel
          </button>
          <button className="button-primary" disabled={items.every((item) => !item.quantity) || submitting} onClick={onSubmit}>
            Close Box and Print Box Labels
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default MultiFNSKUModal
