import { useCallback, useEffect, useState } from "react"

const useActAs = () => {
    const [actAs, _setActAs] = useState<string | null>(sessionStorage.getItem("act-as"))

    const setActAs = useCallback((as: string | null) => {
        if (as === null) sessionStorage.removeItem("act-as")
        else sessionStorage.setItem("act-as", as)
        window.dispatchEvent(new Event("storage"))
        _setActAs(as)
    }, [_setActAs])

    useEffect(() => {
        const listener = () => {
            _setActAs(sessionStorage.getItem("act-as"))
        }

        window.addEventListener("storage", listener)

        return () => {
            window.removeEventListener("storage", listener)
        }
    }, [])

    return {
        actAs,
        setActAs
    }
}

export default useActAs