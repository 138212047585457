import { useCallback, useEffect, useRef, useState } from 'react'

const usePages = <T = any>(endpoint: null | ((nextId?: number) => Promise<{ total: number } & { [key: string]: T[] }>), page = 0, dataKey = 'data') => {
  const [data, setData] = useState<T[]>()
  const loadingRef = useRef(false)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState<number>()
  const [error, setError] = useState()

  const load = useCallback(() => {
    if (loadingRef.current) return
    loadingRef.current = true
    setLoading(true)
    endpoint && endpoint(page)
      .then((data) => {
        setTotal(data.total)
        setData(data[dataKey])
      })
      .catch(setError)
      .finally(() => {
        setLoading(false)
        loadingRef.current = false
      })
  }, [dataKey, endpoint, page])

  useEffect(() => {
    load()
  }, [load])

  return [data, total, loading, setData, load] as const
}

export default usePages
