import { userData } from "App";
import api from "api";
import FullpageLoader from "components/loaders/FullpageLoader";
import { FC, useEffect } from "react";
import { useNavigate} from "react-router"

const StripeLoginSuccessPage: FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        api.checkStripeConnection().then((acc) => {
            navigate("/app/settings/stripe")
            userData.current?.setUser(old => old &&({...old, stripeConnected: true, stripeAccountId: acc.id}))
        })
    }, [])

    return (
        <FullpageLoader />
    )
}

export default StripeLoginSuccessPage;