import { PromoV2 } from 'assets/images/promo/v2'
import FAQQuestion from 'components/FAQQuestion'
import { FC } from 'react'
import { useNavigate } from 'react-router'

const HomePageV2: FC = () => {
  const navigate = useNavigate()
  const onCreateAccount = () => {
    navigate('/seller-signup')
  }

  return (
    <>
      <div className="p-32 pt-64 gap-16 flex relative">
        <div className="absolute rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] left-0 top-[-64px]" />
        <div className="overflow-visible z-10 flex flex-col gap-16 min-w-[560px] w-1/2">
          <div className="flex flex-col gap-8">
            <span className="text-[4rem] font-extrabold text-base-950 leading-[170%] whitespace-pre">
              The Best Third Party <br />
              Logistics Solution for Your <br />
              <span className="text-orange-500">Amazon Business</span>
            </span>
            <span className="text-lg leading-[200%]">
              Join the community of sellers who trust Apex <br />
              to grow their business
            </span>
          </div>
          <div className="flex flex-col gap-8">
            <button onClick={onCreateAccount} className="button-orange !font-extrabold !py-10 !text-lg !px-20 !rounded-2xl">Create a Free Account</button>
            <span className="text-slate-500 text-lg text-center">14 Day Free Trial</span>
          </div>
        </div>
        <PromoV2.first className="grow" />
      </div>
      <div className="p-32 pt-0 gap-16 flex relative flex-col items-center justify-start">
        <div className="absolute rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] right-0 top-0" />
        <div className="flex flex-col gap-8 items-center z-[100]">
          <span className="whitespace-pre text-5xl font-extrabold text-base-950 text-center leading-[170%]">
            More Than Just A<br />
            Prep Center
          </span>
          <span className="text-2xl text-slate-500 font-extrabold">
            ...It's <span className="text-orange-500">SIMPLE</span>
          </span>
          <span className="text-lg text-base-950 leading-[200%] text-center">
            We handpicked the best prep centers to work with us as partners in order to offer the amazon community with reliability, speed and efficiency all under one platform and one great price.
          </span>
        </div>
        <div className="flex gap-8 z-[100] mobile:flex-wrap mobile:gap-32">
          <div className="flex flex-col gap-8">
            <img src={PromoV2.secondSrc.left} alt="second" />
            <div className="flex grow gap-4 text-center flex-col">
              <span className="text-lg font-extrabold text-base-950">Multiple Locations</span>
              <span className="text-sm text-slate-500 leading-[200%]">Work with multiple prep centers using the same platform.</span>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <img src={PromoV2.secondSrc.center} alt="second" />
            <div className="flex grow gap-4 text-center flex-col">
              <span className="text-lg font-extrabold text-base-950">Effortless Workflow</span>
              <span className="text-sm text-slate-500 leading-[200%]">Keep a tab on all your open shipments and workflows.</span>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <img src={PromoV2.secondSrc.right} alt="second" />
            <div className="flex grow gap-4 text-center flex-col">
              <span className="text-lg font-extrabold text-base-950">Fixed Pricings</span>
              <span className="text-sm text-slate-500 leading-[200%]">Multiple locations under one great price.</span>
            </div>
          </div>
        </div>
        <button onClick={onCreateAccount} className="button-orange !font-extrabold !py-8 !text-lg !px-16 !rounded-2xl">Create a Free Account</button>
      </div>
      <div className="p-32 gap-16 flex relative flex-col items-center justify-start">
        <div className="absolute rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] left-0 top-0" />
        <span className="whitespace-pre text-5xl font-extrabold text-base-950 text-center leading-[170%]">
          Why Amazon Sellers
          <br />
          Choose Apex
        </span>
        <div className="grid grid-cols-3 mobile:grid-cols-1 gap-8 z-[100] mobile:flex-wrap mobile:gap-32">
          {/* review */}
          <div className="flex flex-col rounded-3xl border border-slate-200 bg-white">
            <div className="p-6 border-b border-b-slate-200 gap-4 flex items-center">
              <div className="text-orange-500 bg-orange-50 text-lg font-extrabold rounded-full flex items-center justify-center w-16 h-16">AH</div>
              <div className="flex flex-col gap-2">
                <span className="text-base text-base-950 font-extrabold">Adam H.</span>
                <span className="text-xs text-slate-500 font-medium">United States</span>
              </div>
            </div>
            <div className="flex flex-col p-6 gap-6 grow">
              <div className="flex items-center gap-6">
                <div className="flex gap-2">
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                </div>
                <div className="flex gap-2 items-center">
                  <PromoV2.check />
                  <span className="text-sm font-medium text-slate-500">Verified</span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-extrabold text-orange-500">Fast, simple and easy.</span>
                <span className="text-sm leading-[200%] text-base-500">
                  From the way I used to work with my old prep centers using google sheets and emails..this definitely turned things around for me.
                </span>
              </div>
            </div>
            <div className="border-t border-t-slate-200 p-6 text-slate-500 text-sm font-medium">August 16th, 2023</div>
          </div>
          {/* review */}
          <div className="flex flex-col rounded-3xl border border-slate-200 bg-white">
            <div className="p-6 border-b border-b-slate-200 gap-4 flex items-center">
              <div className="text-orange-500 bg-orange-50 text-lg font-extrabold rounded-full flex items-center justify-center w-16 h-16">MN</div>
              <div className="flex flex-col gap-2">
                <span className="text-base text-base-950 font-extrabold">Mary N.</span>
                <span className="text-xs text-slate-500 font-medium">United States</span>
              </div>
            </div>
            <div className="flex flex-col p-6 gap-6 grow">
              <div className="flex items-center gap-6">
                <div className="flex gap-2">
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                </div>
                <div className="flex gap-2 items-center">
                  <PromoV2.check />
                  <span className="text-sm font-medium text-slate-500">Verified</span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-extrabold text-orange-500">Can't express how much time this saves.</span>
                <span className="text-sm leading-[200%] text-base-500">I like how i can just check real time what stage my items are in the prepping process after they get checked in.</span>
              </div>
            </div>
            <div className="border-t border-t-slate-200 p-6 text-slate-500 text-sm font-medium">September 2nd, 2023</div>
          </div>
          {/* review */}
          <div className="flex flex-col rounded-3xl border border-slate-200 bg-white">
            <div className="p-6 border-b border-b-slate-200 gap-4 flex items-center">
              <div className="text-orange-500 bg-orange-50 text-lg font-extrabold rounded-full flex items-center justify-center w-16 h-16">RL</div>
              <div className="flex flex-col gap-2">
                <span className="text-base text-base-950 font-extrabold">Ricardo L.</span>
                <span className="text-xs text-slate-500 font-medium">United States</span>
              </div>
            </div>
            <div className="flex flex-col p-6 gap-6 grow">
              <div className="flex items-center gap-6">
                <div className="flex gap-2">
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                </div>
                <div className="flex gap-2 items-center">
                  <PromoV2.check />
                  <span className="text-sm font-medium text-slate-500">Verified</span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-extrabold text-orange-500">Amazing!</span>
                <span className="text-sm leading-[200%] text-base-500">It makes my amazon business feel more like a game and all I want to do is send more shipments.</span>
              </div>
            </div>
            <div className="border-t border-t-slate-200 p-6 text-slate-500 text-sm font-medium">September 4th, 2023</div>
          </div>
          {/* review */}
          <div className="flex flex-col rounded-3xl border border-slate-200 bg-white">
            <div className="p-6 border-b border-b-slate-200 gap-4 flex items-center">
              <div className="text-orange-500 bg-orange-50 text-lg font-extrabold rounded-full flex items-center justify-center w-16 h-16">MA</div>
              <div className="flex flex-col gap-2">
                <span className="text-base text-base-950 font-extrabold">Michael A.</span>
                <span className="text-xs text-slate-500 font-medium">United States</span>
              </div>
            </div>
            <div className="flex flex-col p-6 gap-6 grow">
              <div className="flex items-center gap-6">
                <div className="flex gap-2">
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                </div>
                <div className="flex gap-2 items-center">
                  <PromoV2.check />
                  <span className="text-sm font-medium text-slate-500">Verified</span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-extrabold text-orange-500">Best Alternative from your own warehouse.</span>
                <span className="text-sm leading-[200%] text-base-500">It's very simple to work with the warehouses and to even message them. Feels like they are your employees.</span>
              </div>
            </div>
            <div className="border-t border-t-slate-200 p-6 text-slate-500 text-sm font-medium">September 5th, 2023</div>
          </div>
          {/* review */}
          <div className="flex flex-col rounded-3xl border border-slate-200 bg-white">
            <div className="p-6 border-b border-b-slate-200 gap-4 flex items-center">
              <div className="text-orange-500 bg-orange-50 text-lg font-extrabold rounded-full flex items-center justify-center w-16 h-16">TG</div>
              <div className="flex flex-col gap-2">
                <span className="text-base text-base-950 font-extrabold">Tiffany G.</span>
                <span className="text-xs text-slate-500 font-medium">United States</span>
              </div>
            </div>
            <div className="flex flex-col p-6 gap-6 grow">
              <div className="flex items-center gap-6">
                <div className="flex gap-2">
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                </div>
                <div className="flex gap-2 items-center">
                  <PromoV2.check />
                  <span className="text-sm font-medium text-slate-500">Verified</span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-extrabold text-orange-500">Apex is an excellent tool for Amazon sellers.</span>
                <span className="text-sm leading-[200%] text-base-500">From what I've seen in this space, Apex is truly innovative to think and execute on what sellers really want. Good job!</span>
              </div>
            </div>
            <div className="border-t border-t-slate-200 p-6 text-slate-500 text-sm font-medium">September 7th, 2023</div>
          </div>
          {/* review */}
          <div className="flex flex-col rounded-3xl border border-slate-200 bg-white">
            <div className="p-6 border-b border-b-slate-200 gap-4 flex items-center">
              <div className="text-orange-500 bg-orange-50 text-lg font-extrabold rounded-full flex items-center justify-center w-16 h-16">SS</div>
              <div className="flex flex-col gap-2">
                <span className="text-base text-base-950 font-extrabold">Sam S.</span>
                <span className="text-xs text-slate-500 font-medium">United States</span>
              </div>
            </div>
            <div className="flex flex-col p-6 gap-6 grow">
              <div className="flex items-center gap-6">
                <div className="flex gap-2">
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                  <PromoV2.star />
                </div>
                <div className="flex gap-2 items-center">
                  <PromoV2.check />
                  <span className="text-sm font-medium text-slate-500">Verified</span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-extrabold text-orange-500">Very organized.</span>
                <span className="text-sm leading-[200%] text-base-500">Very neat and organized, can't wait for the report feature to drop!</span>
              </div>
            </div>
            <div className="border-t border-t-slate-200 p-6 text-slate-500 text-sm font-medium">September 9th, 2023</div>
          </div>
        </div>
      </div>
      <div className="p-32 gap-0 flex flex-col relative">
        <div className="absolute rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] right-0 top-0" />
        <FAQQuestion
          question="What is Apex?"
          answer="Apex is a logistics software designed to help amazon sellers that utilize prep centers be more efficient and streamlined to better keep track of their growth removing unwanted admin hours in everyday task."
        />
        <FAQQuestion question="Are you a Prep Center?" answer="We are a union of prep centers designed for amazon fba sellers." />
        <FAQQuestion question="What type of shipping do you support?" answer="Currently our system supports Partner SPD and LTL." />
        <FAQQuestion question="I am a Prep Center. How can I use Apex?" answer="Get in touch with one of our reps and watch us build your white label portal for your clients." />
        <FAQQuestion question="What is the memberships cost?" answer="Our membership fee is $49.99 a month with a discount for the annum selection." />
      </div>
      <div className="px-32 pb-32 relative">
        <div className="bg-orange-500 rounded-3xl p-16 gap-16 flex flex-col z-[100] relative">
            <span className="text-3xl font-extrabold text-white leading-[170%] text-center">
                See Why Our Customers Say Apex is Essential for Running Their Amazon Business
            </span>
            <div className="flex flex-col gap-8 items-center">
                <button onClick={onCreateAccount} className="button-orange !bg-white !text-orange-500 hover:!bg-orange-300 hover:drop-shadow-xl hover:!text-white !font-extrabold !py-10 !text-lg !px-20 !rounded-2xl">Create a Free Account</button>
                <span className="text-white text-lg text-center font-medium">14 Day Free Trial</span>
            </div>
        </div>
        <div className="h-64 bg-base-950 w-full absolute bottom-0 left-0" />
      </div>
    </>
  )
}

export default HomePageV2
