import { FC } from "react"

interface props {
    checked: boolean
}

const Checkbox: FC<props> = ({checked}) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="0.5" width="23" height="23" rx="3.5" fill="white" />
      {checked && <path d="M17.8333 8L10.5 15.3333L7.16663 12" stroke="#E11D48" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />}
      <rect x="1" y="0.5" width="23" height="23" rx="3.5" stroke="#E2E8F0" />
    </svg>
  )
}

export default Checkbox
