import api from "api";
import FullpageLoader from "components/loaders/FullpageLoader";
import { FC, useEffect } from "react";
import { useNavigate} from "react-router"

const AmazonLoginSuccessPage: FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const sellerId = params.get("selling_partner_id")
        const state = params.get("state")
        const code = params.get("spapi_oauth_code")
        if (!sellerId || !state || !code) {
            navigate("/app/settings/amazon")
            return
        }
        api.authorizeAmazon(code, sellerId, state).then(() => {
            navigate("/app/settings/amazon")
        })
    }, [])

    return (
        <FullpageLoader />
    )
}

export default AmazonLoginSuccessPage;