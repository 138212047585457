import { FC, useCallback, useEffect, useState } from 'react'
import useOpenModal from 'hooks/useOpenModal'
import { IChat } from 'types/chats'
import FullLoader from 'components/loaders/FullLoader'
import api from 'api'
import ContactChat from '../components/Contact'
import useUserContext from 'contexts/User/useUserContext'
import useBottomScrollListener from 'hooks/useBottomScrollListener'
import Loader from 'components/loaders/Loader'
import usePaginated from 'hooks/usePaginated'
import Header from 'components/layout/Header'
import ChatPage from './Chat'
import useHideSupportChat from 'hooks/useHideSupportChat'

const ContactList: FC = () => {
  const endpoint = useCallback(api.listChats, [])
  const me = useUserContext()
  const [chats, loadNext, loadingNext, hasMore] = usePaginated<IChat>(endpoint, 'chats')
  const scrollRef = useBottomScrollListener(loadNext, 100)
  const openModal = useOpenModal()
  const hasPending = Boolean(useUserContext()?.connectionRequests)
  const additionalButtons = [
    { id: 'pending-invites-button', tooltip: 'Pending connections', onClick: () => openModal('/app/contacts/pending', 'pending-invites-button'), icon: 'person_add', hasNotification: hasPending },
  ]
  const [selectedChat, setSelectedChat] = useState<IChat | null>()

  useEffect(() => {
    if (selectedChat !== undefined) return
    setSelectedChat(chats?.[0])
  }, [chats, selectedChat])

  useHideSupportChat()

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header title="Chat" additionalButtons={additionalButtons} />
      <div className="grow flex flex-col overflow-hidden">
        <header>Chat</header>
        <div className="flex grow px-6 pb-6 xl:space-x-6 space-x-0 overflow-hidden relative">
          <section className="box bg-white min-w-max grow xl:relative xl:relative xl:inset-0 -mt-6 xl:mt-0 absolute top-6 left-6 right-6 bottom-6">
            <header>Chats</header>
            <div className="flex grow overflow-y-auto h-full">
              <div className="flex flex-col h-full w-full">
                {chats === undefined ? (
                  <FullLoader />
                ) : chats.length ? (
                  chats.length === 1 && !me?.isPrepCenter ? null : (
                    chats.map((chat) => (
                        chat && <ContactChat selected={selectedChat?.id === chat.id} onClick={() => setSelectedChat(chat)} key={chat.id} {...chat} />
                    ))
                  )
                ) : (
                  <p className="text-center text-dark my-3">No chats</p>
                )}
                {(hasMore || loadingNext) && (
                  <div className="flex justify-center items-center py-2 h-full">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </section>
          {!!selectedChat && <ChatPage data={{ id: selectedChat.id, user: selectedChat.user, close: () => setSelectedChat(null) }} />}
        </div>
      </div>
    </div>
  )
}

export default ContactList
