import StateSelector from 'components/StateSelector'
import { ChangeEvent, FC } from 'react'
import { IWarehouseContactInfo } from 'types/warehouses'

interface props {
  contact: Omit<IWarehouseContactInfo, "name" | "country">
  setContact: (contact: Omit<IWarehouseContactInfo, "name" | "country">) => void
}

const WarehouseContactForm: FC<props> = ({ contact, setContact }) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    setContact({ ...contact, [target.name]: target.value })
  }

  return (
    <div className="flex p-5 items-center space-x-10">
      <div className="w-full flex-col flex border border-base-200 rounded-[8px] p-5 space-y-4">
        <input placeholder="Email" className="grow basis-1" name="email" value={contact.email} onChange={onChange} />
        <input placeholder="Phone" className="grow basis-1" name="phoneNumber" value={contact.phoneNumber} onChange={onChange} />
        <input placeholder="Street Address" className="grow basis-1" name="streetAddress" value={contact.streetAddress} onChange={onChange} />
        <div className="flex pb-5 w-full flex-wrap gap-5">
          <input placeholder="City" className="grow basis-2" name="city" value={contact?.city} onChange={onChange} />
          <input placeholder="Postal Code" className="grow basis-2" name="zipCode" value={contact?.zipCode} onChange={onChange} />
        </div>
        <StateSelector onChange={(e) => setContact({ ...contact, state: e.target.value })} />
      </div>
    </div>
  )
}

export default WarehouseContactForm
