import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ISidebarButton } from './types'
import useUserContext from 'contexts/User/useUserContext'
import useActAs from 'hooks/useActAs'
import { VectorLogo } from 'assets'

interface props {
  buttons?: ISidebarButton[]
}

const Sidebar: FC<props> = ({ buttons = [] }) => {
  const navigate = useNavigate()
  const user = useUserContext()
  const location = useLocation()
  const logo = user?.config?.logo

  const iconClass = 'color-base-950 group-hover:color-primary-600'

  const activeIconClass = 'color-primary-600'

  return (
    <div className="flex flex-col grow w-[246px] bg-white border-r border-r-base-200 bg-white overflow-hidden">
      {Boolean(logo) && location.pathname.startsWith('/app') ? (
        <div className="w-full p-6">
          <img src={logo} className="max-h-[200px] w-full h-auto" style={{ content: '--logo' }} />
        </div>
      ) : (
        <div className="h-[88px] p-6">
          <VectorLogo className="h-full" />
        </div>
      )}

      <div className="flex flex-col grow overflow-y-auto">
        <div className="flex flex-col">
          {buttons.map((button) => (
            <button
              key={button.name}
              className={`
            button-sidebar group
            ${location.pathname.startsWith(button.path) ? 'active' : ''}
          `}
              onClick={() => navigate(button.path)}
            >
              <button.icon className={[iconClass, location.pathname.startsWith(button.path) && activeIconClass].asClass} />
              <span>{button.name}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
