import api from 'api'
import Header from 'components/layout/Header'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IPrepDashboard } from 'types/dashboards'
import ClientList from './components/ClientList'
import ItemStatsSide from './components/ItemStatsSide'
import Stats from './components/Stats'

const PrepCenterDashboard: FC = () => {
  const navigate = useNavigate()
  const [dashboard, setDashboard] = useState<IPrepDashboard>()

  useEffect(() => {
    api.getDashboard().then(setDashboard)
  }, [])

  useEffect(() => {
    const isFirstOpen = localStorage.getItem('firstOpen') === 'true'
    if (isFirstOpen) {
      localStorage.setItem('firstOpen', 'false')
    }
  }, [])

  return (
    <div className="flex flex-col grow overflow-hidden w-full h-full">
      <Header
        title="Dashboard"
        additionalButtons={[
          {
            id: 'add-warehouse-button',
            tooltip: 'Add a Warehouse',
            bigButton: true,
            onClick: () => navigate('/app/dashboard/warehouses/add'),
          },
        ]}
      />
      <div className="grow flex flex-col overflow-y-auto overflow-x-hidden pb-8 w-full">
        <header className="z-[100]">Dashboard</header>
        <div className="flex gap-8 px-8 w-full overflow-x-hidden">
          <div className="grid grid-cols-2 gap-6 pb-6">
            <Stats type="inbound" />
            <Stats type="outbound" />
            <ClientList />
            </div>
          <ItemStatsSide itemStates={dashboard?.itemstates} orderStates={dashboard?.orderstates} />
        </div>
      </div>
    </div>
  )
}

export default PrepCenterDashboard
