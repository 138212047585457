
import Icon from 'components/Icon'
import formatAddress from 'helpers/formatAddress'
import { FC } from 'react'
import { IWarehouse } from 'types/warehouses'

interface props {
  warehouse: IWarehouse
}

const MyWarehouse: FC<props> = ({ warehouse }) => {
  return (
    <section className="box bg-white">
      <header>{warehouse.contact?.name} ({warehouse.identifier})</header>
      <div className="flex divide-x divide-x-base-200">
        <div className="flex p-6 items-center">
          <Icon type="bulk" name="building" size={48} />
        </div>
        <div className="gap-1 flex flex-col p-6 justify-center grow">
          <span className="text-sm font-medium text-base-900">Address</span>
          <span className="text-sm font-normal text-base-400">
            {formatAddress(warehouse.contact)}
          </span>
        </div>
        {/*<div className="gap-1 flex flex-col p-6 justify-center">
          <span className="text-sm font-medium text-base-900">Typical Turn Around</span>
          <span className="text-sm font-normal text-base-400">1 to 3 Days</span>
        </div>
        <div className="gap-3 grid grid-cols-2 p-6">
          <span className="text-[10px] font-medium text-base-400 rounded-full bg-base-100 px-3 py-2 text-center">Books</span>
          <span className="text-[10px] font-medium text-base-400 rounded-full bg-base-100 px-3 py-2 text-center">OA</span>
          <span className="text-[10px] font-medium text-base-400 rounded-full bg-base-100 px-3 py-2 text-center">Wholesale</span>
          <span className="text-[10px] font-medium text-base-400 rounded-full bg-base-100 px-3 py-2 text-center">Private Label</span>
        </div>
        <div className="flex items-center p-6">
          {user?.defaultWarehouse === warehouse.id ? (
            <span className="text-xs font-medium text-rose-600 rounded-full bg-rose-100 px-4 py-2.5 text-center">Default Warehouse</span>
          ) : (
            <button disabled={submitting} onClick={onMakeDefault} className="button-secondary">
              Set as Default
            </button>
          )}
          </div>*/}
      </div>
    </section>
  )
}

export default MyWarehouse
