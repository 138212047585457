import { useState, useEffect, PropsWithChildren, FC } from 'react'
import AgencyContext from './agencyContext'
import useActAs from 'hooks/useActAs'
import agencyApi from 'api/agency'
import useUserContext from 'contexts/User/useUserContext'
import FullLoader from 'components/loaders/FullLoader'

export interface IConnectedClient {
  id: string
  client: string
}

const AgencyProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useUserContext()
  const { actAs, setActAs } = useActAs()
  const [actAsOptions, setActAsOptions] = useState<Record<string, string>>()

  const isAgency = Boolean(user && (user?.isAgency))

  useEffect(() => {
    if (isAgency) {
      agencyApi.getClients().then(setActAsOptions)
    }
  }, [isAgency])

  useEffect(() => {
    if (!actAsOptions) return
    if (actAs && actAsOptions[actAs]) return
    if (Object.keys(actAsOptions).length === 0) return
    setActAs(Object.keys(actAsOptions)[0])
  }, [actAsOptions, actAs, setActAs])

  if (isAgency && !actAsOptions) return <FullLoader />

  return (
    <AgencyContext.Provider
      value={
        isAgency
          ? {
              isAgency,
              actAs,
              setActAs,
              actAsOptions: actAsOptions as Record<string, string>,
            }
          : {
              isAgency,
            }
      }
    >
      {children}
    </AgencyContext.Provider>
  )
}

export default AgencyProvider
