import api from 'api'
import { handleError } from 'helpers/errors'
import { FC, useState } from 'react'

const SellerSignupForm: FC = () => {
  const [companyName, setCompanyName] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [businessType, setBusinessType] = useState<string>('')
  const [monthlyVolume, setMonthlyVolume] = useState<string>('')
  const [workedWith3PL, setWorkedWith3PL] = useState<string>('')

  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = () => {
    if (!companyName || !emailAddress || !phoneNumber || !businessType || !monthlyVolume || !workedWith3PL) return handleError('Please fill out all fields')
    setSubmitting(true)
    api
      .inquireSellerSignup({ companyName, emailAddress, phoneNumber, businessType, monthlyVolume, workedWith3PL })
      .then(() => {
        setSubmitting(false)
        setSubmitted(true)
      })
      .catch(() => setSubmitting(false))
  }

  return (
    <>
      <div className="absolute rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] left-0 -top-16" />
      <div className="fixed rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] right-0 bottom-0" />
      <div className="flex flex-col gap-8 items-center z-[100] pt-48">
        <span className="whitespace-pre text-5xl font-extrabold text-base-950 text-center leading-[170%]">Apply Today</span>
        <span className="text-lg text-base-950 leading-[200%] text-center">Apex Logistics is pleased to work with you on your business journey succeeding every step of the way.</span>
      </div>
      <div className="flex flex-col px-32 mb-32 z-[100]">
        <div className="flex flex-col mx-32 p-8 gap-8 rounded-lg">
          {submitted ? (
            <>
              <span className="text-base-950 font-normal text-5xl text-center mt-12">
                Message <span className="text-base-950">Sent Successfully</span>
              </span>
              <span className="text-base-950-700 text-base text-center mb-16">One of our team members has been notified and will get back to you shortly.</span>
            </>
          ) : (
            <>
              <div className="grid grid-cols-2 gap-8 mobile:grid-cols-1">
                <input
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.currentTarget.value)}
                  className="grow p-5 basis-1 shrink-0 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                />
                <input
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                  className="grow p-5 basis-1 shrink-0 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                />
              </div>
              <div className="grid grid-cols-2 gap-8 mobile:grid-cols-1">
                <input
                  placeholder="Email Address"
                  type="email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.currentTarget.value)}
                  className="grow p-5 basis-1 shrink-0 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                />
                <input
                  placeholder="Type Of Business"
                  value={businessType}
                  onChange={(e) => setBusinessType(e.currentTarget.value)}
                  className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                />
              </div>
              <div className="grid grid-cols-2 gap-8 mobile:grid-cols-1">
                <input
                  placeholder="Average Monthly Units Shipped"
                  type="number"
                  step={1}
                  value={monthlyVolume}
                  onChange={(e) => setMonthlyVolume(e.currentTarget.valueAsNumber.toString())}
                  className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                />
                <select
                  className="p-5 rounded-lg border border-slate-200 grow"
                  placeholder="Have you worked with a 3PL before?"
                  value={workedWith3PL}
                  onChange={(e) => setWorkedWith3PL(e.target.value)}
                >
                  <option value="" disabled>
                    Have you worked with a 3PL before?
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <button disabled={submitting} className="button-orange w-max self-center !rounded-xl !px-16 !py-8 !text-lg" onClick={handleSubmit}>
                Send
              </button>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default SellerSignupForm
