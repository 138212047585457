import axiosBase, { AxiosError } from 'axios'
import firebase from '../config/firebase'
import { signOut } from 'firebase/auth'
import { handleError } from 'helpers/errors'

const axios = axiosBase.create({ baseURL: process.env.NODE_ENV === "development" ? 'http://localhost:5001/apex-mgt/us-central1/api' : 'https://apexmgt.us/api' })

axios.interceptors.request.use(async (config) => {
    const bearer = await firebase.auth.currentUser?.getIdToken()
    const actingAs = sessionStorage.getItem('act-as')

    const headers: Record<string, any> = {
        ...config.headers
    }

    if (bearer) {
        headers.Authorization = `Bearer ${bearer}`
    }

    if (actingAs) {
        headers['x-agency-as'] = actingAs
    }

    return { ...config, headers }
})

axios.interceptors.response.use((res) => {
    return res
}, async (err: AxiosError<({ type?: string} & Record<string, unknown>) | string>) => {
    if (err.status === 401) {
      await signOut(firebase.auth)
      return
    } else if (err.response?.data && typeof err.response?.data !== "string" && err.response.data.type) {
      throw err.response.data
    } else {
      handleError(err.response?.data as string || "An unknown error occurred")
      throw err
    }
})

export default axios