import { FC } from "react";
import useOrder from "../context/hook";
import useUserContext from "contexts/User/useUserContext";

const OtherNotes: FC = () => {
  const user = useUserContext()
  const { order } = useOrder()
  const distributor  = order.user !== user?.id
  const notes = (!distributor ? order.distributorNotes : order.clientNotes) || ''

    return (
    <section className="box grow min-w-[500px] bg-white box h-[245px] overflow-hidden relative">
      <header>
        <span>Notes from {distributor ? "client" : "distributor"}</span>
      </header>
      <div className="flex flex-col w-full h-[188px] px-5 py-4 relative">
        {notes ? (
          <div className="w-full h-full overflow-y-auto p-2 mb-4">
            <span>{notes}</span>
          </div>
        ) : (
          <div className="w-full h-full overflow-y-auto p-2 mb-4 text-base-300 items-center flex justify-center">No notes</div>
        )}
      </div>
    </section>
    )
}

export default OtherNotes