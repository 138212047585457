import api from 'api'
import { FC, SetStateAction, useEffect, useState, Dispatch } from 'react'
import { IConnectedWarehouse } from 'types/warehouses'
import FullLoader from 'components/loaders/FullLoader'
import IconBig from 'components/IconBig'
import useUserContext from 'contexts/User/useUserContext'

interface props {
  onChange: Dispatch<SetStateAction<string | undefined>>
  serviceFilters?: string[]
  owner?: string
}

const Button: FC<{ name: string; onClick: () => void; active: boolean; street: string; city: string; zip: string }> = ({ name, onClick, active, street, city, zip }) => (
  <button onClick={onClick} className={["flex flex-col pt-6 pb-7 transition-all items-center border rounded-[8px]", active ? 'border-primary-600 bg-primary-50 text-primary-600' : 'border-base-200 text-base-900'].asClass}>
    <span className="text-xs font-medium opacity-[70%]">{street}</span>
    <span className="text-xs font-medium opacity-[70%] mb-4">{city}, {zip}</span>
    <IconBig active={active} name="building" size={48} />
    <span className="text-sm font-medium mt-4">{name}</span>
  </button>
)

const WarehouseSelect: FC<props> = ({ onChange, serviceFilters, owner }) => {
  const [warehouses, setWarehouses] = useState<IConnectedWarehouse[]>()
  const [selectedWarehouse, setSelectedWarehouse] = useState<string>()
  const [newWarehouse, setNewWarehouse] = useState<string>('')
  const [addingWarehouse, setAddingWarehouse] = useState(false)
  const [unselected, setUnselected] = useState(false)
  const me = useUserContext()

  useEffect(() => {
    api.listConnectedWarehouses().then(setWarehouses)
  }, [])

  useEffect(() => {
    if (!warehouses?.length || !me || selectedWarehouse || unselected) return
    const defaultWarehouse = warehouses.find((warehouse) => warehouse.id === me.defaultWarehouse)
    if (defaultWarehouse) setSelectedWarehouse(defaultWarehouse.id)
    if (warehouses.length === 1) setSelectedWarehouse(warehouses[0].id)
  }, [me, warehouses, selectedWarehouse, unselected])

  useEffect(() => {
    onChange(selectedWarehouse)
  }, [selectedWarehouse, onChange])

  const addWarehouse = () => {
    if (!newWarehouse || addingWarehouse) return
    setAddingWarehouse(true)
    api
      .connectWarehouse(newWarehouse)
      .then((warehouse) => {
        setWarehouses((old) => [warehouse, ...(old || [])])
        setSelectedWarehouse(warehouse.id)
        setNewWarehouse('')
      })
      .finally(() => setAddingWarehouse(false))
  }

  const filteredWarehouses = warehouses?.filter((warehouse) => {
    if (owner && warehouse.owner !== owner) return false
    if (!serviceFilters?.length) return true
    return serviceFilters.some((filter) => warehouse.data?.services?.includes(filter))
  })

  useEffect(() => {
    if (filteredWarehouses?.length === 1) setSelectedWarehouse(filteredWarehouses[0].id)
  }, [filteredWarehouses])

  const selected = warehouses?.find((warehouse) => warehouse.id === selectedWarehouse)

  return (
    <div className={owner !== undefined && selected ? "" : "overflow-y-auto grow"}>
      {warehouses === undefined ? (
        <div className="flex flex-col justify-center items-center h-full p-5">
          <FullLoader />
        </div>
      ) : (
        <div className={[
          "w-full h-full flex flex-col space-y-5 p-5",
          owner !== undefined ? "" : "overflow-y-auto"
        ].asClass}>
          {selected ? 
              <Button
                key={selected.id}
                street={selected.contact.streetAddress}
                city={selected.contact.city}
                zip={selected.contact.zipCode}
                name={selected.contact.name}
                active={true}
                onClick={owner ? () => {} : (() => {
                  setSelectedWarehouse("")
                  setUnselected(true)
                })}
              /> :
          filteredWarehouses?.length ? (
            filteredWarehouses.map((warehouse) => (
              <Button
                key={warehouse.id}
                street={warehouse.contact.streetAddress}
                city={warehouse.contact.city}
                zip={warehouse.contact.zipCode}
                name={warehouse.contact.name}
                active={selectedWarehouse === warehouse.id}
                onClick={() => setSelectedWarehouse(warehouse.id)}
              />
            ))
          ) : (
            <div className="flex flex-col h-full items-center justify-center space-y-5">
              <IconBig name="building" size={48} />
              <span className="text-sm text-base-500">No warehouses available</span>
            </div>
          )
          /*<h4 className="pt-3">Add a Warehouse</h4>
          <div className="relative">
            <input type="text" placeholder="Warehouse ID" className="w-full" onChange={(e) => setNewWarehouse(e.currentTarget.value)} value={newWarehouse} />
            <div className="absolute top-0 bottom-0 flex items-center right-3">
              <IconButton name="add" className="!fill-primary-600" />
            </div>
          </div> */
        }
        </div>
      )}
    </div>
  )
}

export default WarehouseSelect
