import { createRef, useEffect, Dispatch, SetStateAction } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import PublicRouter from 'routes/PublicRouter'
import { IUser } from 'types/user'
import FullpageLoader from './components/loaders/FullpageLoader'
import useAuthContext from './contexts/Auth/useAuthContext'
import UserProvider from './contexts/User/UserProvider'
import AuthorizedRouter from './routes/AuthorizedRouter'
import UnauthorizedRouter from './routes/UnauthorizedRouter'
import AgencyProvider from 'contexts/Agency/AgencyProvider'
import useSimulateTab from 'hooks/useSimulateTab'

export const userData = createRef<{ setUser: Dispatch<SetStateAction<IUser | null>>, setConfig: Dispatch<SetStateAction<any>> }>()

function App() {
  const user = useAuthContext()
  const path = useLocation().pathname
  useSimulateTab()

  useEffect(() => {
    window.document.title = 'Apex Management'
  }, [])

  if (user === undefined) return <FullpageLoader className={path.startsWith('/app') || path.startsWith('/auth') ? 'bg-white' : 'bg-[#0a0402]'} />

  return (
    <UserProvider ref={userData}>
      <AgencyProvider>
        <Routes>
          <Route path="app/*" element={user !== null ? <AuthorizedRouter /> : <Navigate to="/auth" replace />} />
          <Route path="auth/*" element={user === null ? <UnauthorizedRouter /> : <Navigate to="/app" replace />} />
          <Route path="*" element={<PublicRouter />} />
        </Routes>
      </AgencyProvider>
    </UserProvider>
  )
}

export default App
