import shopApi from 'api/shop'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'

const processingFees = {
  card: 0.0349,
  wire: 0.0102,
}

const ChoosePaymentMethod: AwaitableModal<{}, Record<string, number>> = ({ open, resolve, onCancel, initialData }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'card' | 'wire'>('card')
  const [buying, setBuying] = useState(false)

  const onBuy = () => {
    if (buying) return
    setBuying(true)
    shopApi
      .order(initialData, selectedPaymentMethod)
      .then((url) => window.open(url, '_self'))
      .finally(() => setBuying(false))
  }

  return (
    <Modal open={open} close={resolve}>
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>Select Payment Method</h1>
            <h3>Compare rates of different methods</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={resolve} />
        </header>
        <div className="w-full">
          <p className='text-base-400 text-xs'>Payment Method: </p>
          <select
            onChange={(e) => setSelectedPaymentMethod(e.target.value as any)}
            value={selectedPaymentMethod}
            className="peer h-full w-full bg-transparent cursor-pointer text-xl !text-base-900 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500"
          >
            <option value="card">Credit Card</option>
            <option value="wire">Wire Transfer</option>
          </select>
          <span className="text-base-600 text-base pt-4">
            Processing Fee: {processingFees[selectedPaymentMethod] * 100}%
          </span>
        </div>
        <footer className="items-center">
          <button disabled={buying} className="button-secondary" onClick={resolve}>
            Back to Cart
          </button>
          <button disabled={buying} className="button-primary" onClick={onBuy}>
            Place Order and Pay
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default ChoosePaymentMethod
