import { FC, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { IDistributionOrder } from 'types/admin'
import orderContext from './context/context'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import IconButton from 'components/buttons/IconButton'
import ChatPage from 'pages/app/Contacts/pages/Chat'
import { IChat } from 'types/chats'
import Shipments from './components/Shipments'
import OrderItems from './components/OrderItems'
import MyNotes from './components/MyNotes'
import OtherNotes from './components/OtherNotes'
import OrderItemsSent from './components/OrderItemsSent'
import distributionApi from 'api/distribution'
import useUserContext from 'contexts/User/useUserContext'

const DistOrderPage: FC = () => {
  const id = useParams().id as string
  const _order = useLocation().state as IDistributionOrder
  const user = useUserContext()
  const [order, setOrder] = useState<IDistributionOrder>(_order)
  const [chatOpen, setChatOpen] = useState(false)
  const [chat, setChat] = useState<IChat>()
  
  const totalAmount = order?.data.reduce((acc, item) => acc + item.price * item.count * (item.caseQty || 1), 0) / 100
  const fulfillableAmount = order?.data.reduce((acc, item) => {
    const casePrice = item.price;
    const fulfillable = order?.shipmentData?.[item.id]?.fulfillable || 0;
    return acc + casePrice * fulfillable
}, 0) / 100

  useEffect(() => {
    if (!order) {
      distributionApi.getDistributionOrder(id).then(setOrder)
    }
  }, [order, id])

  useEffect(() => {
    if (!order) return
    distributionApi.getChatForOrder(order.id).then(setChat)
  }, [order])

  const pastSent = !["init", "processing"].includes(order?.status)

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header />
      {!order ? (
        <FullLoader />
      ) : (
        <orderContext.Provider value={{ order, setOrder, frozen: ['cancelled', 'delivered'].includes(order.status) }}>
          <div className="flex flex-col grow overflow-hidden relative">
            <div className="w-full flex grow overflow-hidden relative">
              <div className="flex flex-col h-full grow overflow-y-auto">
                <header className="flex flex items-center w-full z-[10000]">
                  <div className="flex space-x-4 items-center grow">
                    <span className="mb-0 grow">Distribution Order ID: {order.id}</span>
                    <div className="flex flex-col gap-1.5 items-end">
                        {order.user !== user?.id && <span className="text-base-400 text-base">Client {order.userContact}</span>}
                        <span className="text-xs text-base-400 font-medium">Paid amount: {totalAmount?.toLocaleString("en-US", {style: "currency",currency: "USD", currencyDisplay: "symbol"})}</span>
                        <span className="text-xs text-base-950 font-medium">Fulfillable order value: {fulfillableAmount?.toLocaleString("en-US", {style: "currency",currency: "USD", currencyDisplay: "symbol"})}</span>
                    </div>
                  </div>
                </header>
                <div className="grid grid-cols-2 gap-6 px-6 pb-6">
                  <div className="rounded-2xl bg-base-50 col-span-2">
                    <Shipments />
                    {pastSent ? <OrderItemsSent /> : <OrderItems />}
                  </div>
                  <MyNotes />
                  <OtherNotes />
                      {/*<OrderNotes isSeller={true} isOwner={false} editable={false} notes={order.data?.seller?.notes || ''} setNotes={() => {}} />
                      <ServiceCharges services={order.additionalServices} type="inbound" />*/}
                </div>
              </div>
            </div>
          {chat && (
            <>
              <div className="absolute right-8 bottom-5 border-2 border-base-900 rounded-full">
                <IconButton name="messages-2" id={`chatbubble-${chat.id}`} onClick={() => setChatOpen((old) => !old)} tooltip="Message" tooltipLocation="left" />
              </div>
              <div className={`w-[420px] absolute h-full flex transition-all space-x-12 z-[10000] p-5 bg-base-100 shadow-2xl`} style={{ right: chatOpen ? '0px' : '-450px' }}>
                <ChatPage data={{ user: order.user as any, id: chat.id, close: () => setChatOpen(false) }} />
              </div>
            </>
          )}
          </div>
        </orderContext.Provider>
      )}
    </div>
  )
}

export default DistOrderPage
