import { useEffect, ReactSVGElement, SVGProps, FC, useState } from "react";

export default function useIconsax(path: string) {
	const [Icon, setIcon] = useState<FC<SVGProps<ReactSVGElement>> | null>(null);
	useEffect(() => {
        import(`../assets/iconsax/${path}`).then((component) => {
            setIcon(component.ReactComponent);
        });
	}, [path]);

	return Icon;
}