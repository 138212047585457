import ordersApi from 'api/orders'
import AmazonProductImage from 'components/AmazonProductImage'
import Icon from 'components/Icon'
import IconButton from 'components/buttons/IconButton'
import Loader from 'components/loaders/Loader'
import { getAsinBySKU, printBoxLabels } from 'pages/app/Orders/modals/LabelsModal/helpers/printBoxLabels'
import { FC, useState } from 'react'
import { IShippingData } from 'types/orders'
import useHasRole from 'hooks/useHasRole'
import { useOrder } from 'contexts/Order'

interface props {
  box: IShippingData['boxes'][0]
  index: number
  remove: () => void
}

const Box: FC<props> = ({ box, index, remove }) => {
  const [removing, setRemoving] = useState(false)
  const { order, workable, frozen, setOrder } = useOrder()
  const canPrep = useHasRole("PREP")
  const shipmentId = order.shipmentData?.shipmentId || ''

  const onDelete = () => {
    if (removing) return
    setRemoving(true)
    ordersApi
      .deleteBox(order.id, box.boxId)
      .then((boxes) => {
        setOrder((old) => old && { ...old, shipmentData: { ...old.shipmentData, boxes } } as any)
      })
      .finally(() => setRemoving(false))
  }

  return (
    <tr>
      <td className="p-3 text-sm">
        <div className="flex gap-2 items-center">
          {box.items.length > 1 ? <Icon  name="3d-square" className="fill-blue-500" /> : <Icon  name="box-1" />}
          <span>{(box.boxNumber || index).toString().padStart(2, '0')}</span>
        </div>
      </td>
      <td className="border-l p-3">
        <div className="gap-2 flex">
          {box.items.map((item) => {
            const asin = getAsinBySKU(order.itemsData, item.sku)
            if (!asin) return null
            return <AmazonProductImage key={item.sku} asin={asin} size={64} imageSize={128} className="w-6 h-6 rounded object-cover cursor-pointer" />
          })}
        </div>
      </td>
      <td className="p-3 text-xs border-l">{box.items.reduce((acc, { quantity }) => acc + quantity, 0)}</td>
      {!frozen && canPrep && <td className="p-3 border-l">
        <div className="flex items-center gap-2 -my-2">
          <IconButton name="printer" onClick={() => printBoxLabels(shipmentId, order.id, order.itemsData, [box], order.sellerName, order.warehouseAddress, order.shipmentData?.destinationAddress as any)} />
          {workable && (removing ? <Loader size={24} /> : <IconButton name="trash" className="!fill-red-500" onClick={onDelete} />)}
        </div>
      </td>}
    </tr>
  )
}

export default Box
