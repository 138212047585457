import ordersApi from 'api/orders'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import Chart from 'react-apexcharts'

interface props {
  type: 'inbound' | 'outbound'
}

const Stats: FC<props> = ({ type }) => {
  const [days, setDays] = useState<number>(7)
  const [data, setData] = useState<any[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (loading) return
    setLoading(true)
    if (type === 'inbound') {
      ordersApi.getInboundItemsGraph(days).then(setData).finally(() => setLoading(false))
    } else {
      ordersApi.getOutboundItemsGraph(days).then(setData).finally(() => setLoading(false))
    }
  }, [type, days])

  const formattedData = useMemo(
    () =>
      Array(days)
        .fill(null)
        .map((_, i) => {
          const date = new Date()
          date.setHours(0, 0, 0, 0)
          date.setDate(date.getDate() - i - 1)
          const day = date.toISOString().split('T')[0]
          const dayData = data?.find((item) => item.date.split('T')[0] === day)
          return { x: date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(), time: date.toLocaleDateString(), y: parseInt(dayData?.quantity || '0', 10) }
        }).reverse(),
    [days, data]
  )

  const onlyShow = days !== 30 ? "" : formattedData.at(-1)?.x

  // @ts-ignore
  const primaryVar = ref?.current?.computedStyleMap().get('--color-primary-500')?.toString()
  // @ts-ignore
  const baseVar = ref?.current?.computedStyleMap().get('--color-base-500')?.toString()

  const primaryColor = `rgb(${primaryVar?.split(' ').join(',')})`
  const baseColor = `rgb(${baseVar?.split(' ').join(',')})`
  
  const units = formattedData.reduce((acc, item) => acc + item.y, 0)

  const width = days === 7 ? 22 : days === 14 ? 11 : 8

  return (
    <section className="box overflow-hidden bg-white h-[20rem]">
      <header className="capitalize">
        <span>{type} Units</span>
        <div>
          <span className="tag-orange text-[0.625rem] rounded-full py-2 px-3.5">{loading ? "..." : units} UNIT{units === 1 ? "" : "S"}</span>
          <select
            className="px-1 outline-none border-none py-1 text-xs border rounded-md"
            value={days}
            onChange={(e) => setDays(parseInt(e.target.value, 10))}
            >
            <option value={7}>Last 7 days</option>
            <option value={14}>Last 14 days</option>
            <option value={30}>Last 30 days</option>
            </select>
        </div>
        </header>
      <div ref={ref} className="fill-primary-500 px-6">
        <Chart
          type="bar"
          options={{
            chart: {
              id: type,
              background: 'white',
              toolbar: {
                show: false,
              },
              redrawOnParentResize: true,
              redrawOnWindowResize: true,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: width,
                borderRadius: width/2,
                borderRadiusApplication: 'end',
                barHeight: '100%',
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: false,
            },
            states: {
              hover: {
                filter: {
                  type: 'none',
                },
              },
              active: {
                filter: {
                  type: 'none',
                },
              },
            },
            xaxis: {
              categories: formattedData.map((item) => item.x),
              axisTicks: {
                show: false,
              },
              labels: {
                hideOverlappingLabels: true,
                style: {
                  colors: baseColor,
                  fontSize: '10px',
                  fontFamily: 'Inter',
                  fontWeight: 500,
                },
                formatter: (value: string) => {
                  return onlyShow ? value === onlyShow ? value : '' : value
                }
              },
              axisBorder: {
                strokeWidth: 1,
              },
            },
            yaxis: {
              show: true,
              labels: {
                show: true,
                style: {
                  colors: baseColor,
                  fontSize: '10px',
                  fontFamily: 'Inter',
                  fontWeight: 500,
                },
                offsetX: -12,
                align: 'center',
              },
              axisBorder: {
                show: true,
                width: 1,
                offsetX: 12,
              },
              max: formattedData.some((item) => Boolean(item.y)) ? Math.max(...formattedData.map((item) => item.y)) * 1.2 : undefined,
              showForNullSeries: true,
            },
            grid: {
              show: false,
            },
            fill: {
              opacity: 1,
              colors: [primaryColor],
            },
            tooltip: {
              enabled: true,
              enabledOnSeries: undefined,
              shared: true,
              followCursor: false,
              intersect: false,
              inverseOrder: false,
              x: {
                formatter: (_,b) => {
                  if (!formattedData) return ""
                  return formattedData[b.dataPointIndex]?.time ? formattedData[b.dataPointIndex]?.time : ""
                }
              },
              marker: {
                show: false,
              },
              y: {
                formatter: (value: any) => {
                  return value + ' unit' + (value === 1 ? '' : 's')
                },
                title: {
                  formatter: () => '',
                },
              },
            },
          }}
          series={[
            {
              data: formattedData.map((item) => item.y),
            },
          ]}
        />
      </div>
    </section>
  )
}

export default Stats
