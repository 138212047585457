import api from 'api'
import Header from 'components/layout/Header'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Stripe from 'stripe'
import { IBill } from 'types/billing'
import Bill from '../components/Bill'
import TableSpanner from 'components/TableSpanner'
import FullLoader from 'components/loaders/FullLoader'
import Empty from 'components/Empty'
import InvoiceStatus from '../components/InvoiceStatus'
import ChangePaymentStructure from '../components/ChangePaymentStructure'
import useUserContext from 'contexts/User/useUserContext'
import IconButton from 'components/buttons/IconButton'

const getUnitsHandled = (bills: IBill[]) => {
  let unitsHandled = 0
  bills.forEach((bill) => {
    bill.billableItems
      .filter((item) => item.type.startsWith('ITEM_PREP_') && item.type.endsWith('_FNSKU'))
      .forEach((item) => {
        let quantity = item.quantity
        unitsHandled += quantity
      })
  })
  return unitsHandled
}

const InvoicePage: FC = () => {
  const { invoiceId, prep } = useParams()
  const user = useUserContext()
  const [invoice, setInvoice] = useState<Stripe.Invoice>()
  const [bills, setBills] = useState<IBill[]>()
  const [sending, setSending] = useState(false)

  useEffect(() => {
    if (!invoiceId) return
    api.getInvoice(invoiceId, prep).then((data) => {
      setInvoice(data.invoice)
      setBills(data.bills)
    })
  }, [invoiceId, prep])

  const onSend = () => {
    if (!invoice || sending) return
    setSending(true)
    api
      .sendInvoice(invoice.id)
      .then(({ invoice }) => setInvoice(invoice))
      .finally(() => setSending(false))
  }

  const onPay = () => {
    if (!invoice?.hosted_invoice_url) return
    window.open(invoice.hosted_invoice_url)
  }

  const onDownload = () => {
    if (!invoice?.invoice_pdf) return
    window.open(invoice.invoice_pdf, '_blank')
  }

  const onViewInStripe = () => {
    if (!user?.stripeAccountId) return
    window.open(`https://dashboard.stripe.com/${user.stripeAccountId}/invoices/${invoiceId}`, '_blank')
  }

  const unitsHandled = bills ? getUnitsHandled(bills) : undefined

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header />
      <div className="grow flex flex-col overflow-hidden">
        <header className="flex items-center justify-between z-[1000]">
          <div className="flex items-center space-x-2 relative">
            {invoice?.id && user?.isPrepCenter && invoice.status !== 'draft' && <IconButton name="link-21" onClick={onViewInStripe} />}
            <span>Invoice {!invoice ? '...' : invoice.number}</span>
            {invoice && invoice.status && <InvoiceStatus invoice={invoice} />}
          </div>
          <div className="flex space-x-3">
            {invoice &&
              invoice?.status !== 'paid' &&
              (user?.isPrepCenter ? (
                <button className="button-primary" onClick={onSend}>
                  Send Invoice
                </button>
              ) : (
                invoice.hosted_invoice_url && (
                  <button className="button-primary" onClick={onPay}>
                    Pay
                  </button>
                )
              ))}
            {invoice && invoice.invoice_pdf && (
              <button className="button-secondary" onClick={onDownload}>
                Download Invoice
              </button>
            )}
          </div>
        </header>
        <div className="flex flex-col grow overflow-y-auto">
          <div className="h-max flex flex-col space-y-5 px-6 pb-6">
            {bills !== undefined && invoice !== undefined && user?.isPrepCenter && (
              <ChangePaymentStructure
                setBills={setBills}
                setInvoice={setInvoice}
                invoiceId={invoiceId}
                paymentStructure={bills[0]?.paymentPlan}
                canChange={user?.isPrepCenter && invoice.status === 'draft'}
              />
            )}
            <section className="box">
              <header>Invoice breakdown</header>
              <table className="overflow-hidden">
                <colgroup>
                  <col className="w-[200px]" />
                  <col />
                  <col />
                  <col className="w-[200px]" />
                  <col className="w-[200px]" />
                </colgroup>
                <thead>
                  <tr>
                    <td>For</td>
                    {user?.isPrepCenter && <td>Client</td>}
                    <td>Total</td>
                    <td>Created on</td>
                    <td>Details</td>
                  </tr>
                </thead>
                <tbody>
                  {bills?.map((bill) => (
                    <Bill key={bill.id} bill={bill} showAdd={false} showTotal />
                  ))}
                  {!bills?.length && (
                    <TableSpanner rows={4} cols={6}>
                      {bills === undefined ? <FullLoader /> : <Empty text="No Bills" />}
                    </TableSpanner>
                  )}
                </tbody>
                {unitsHandled !== undefined &&
                  (user?.isPrepCenter ? (
                    <tfoot>
                      <tr className="font-semibold text-sm border-t border-t-base-200">
                        <td colSpan={4}>
                          Subtotal <span className="text-base-600 text-sm vertical-align-center">(Client charge)</span>
                        </td>
                        <td className="text-right">{invoice?.total ? (invoice.total / 100).toFixed(2) + '$' : null}</td>
                      </tr>
                      <tr className="font-semibold text-sm">
                        <td colSpan={4}>Apex Management fees</td>
                        <td className="text-right">
                          {invoice?.application_fee_amount && (
                            <div className="flex flex-col">
                              <span>${(invoice?.application_fee_amount / 100).toFixed(2)}</span>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr className="font-semibold text-md border-t border-t-base-200">
                        <td colSpan={4}>
                          Total <span className="text-base-600 text-sm vertical-align-center">(Without Stripe fees)</span>
                        </td>
                        <td className="text-right">{invoice?.total ? ((invoice.total - (invoice?.application_fee_amount || 0)) / 100).toFixed(2) + '$' : null}</td>
                      </tr>
                    </tfoot>
                  ) : (
                    <tfoot>
                      <tr className="font-semibold text-md border-t border-t-base-200">
                        <td colSpan={4}>Total</td>
                        <td className="text-right">{invoice?.total ? (invoice.total / 100).toFixed(2) + '$' : null}</td>
                      </tr>
                    </tfoot>
                  ))}
              </table>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoicePage
