import api from 'api'
import Icon from 'components/Icon'
import useUserContext from 'contexts/User/useUserContext'
import { useEffect, useState } from 'react'

const StripeAccountSettings = () => {
  const user = useUserContext()
  const [email, setEmail] = useState<string>()

  useEffect(() => {
    if (!user?.stripeAccountId) return
    api.getStripeAccount().then((account) => {
      setEmail(account.email)
    })
  }, [user?.stripeAccountId])

  const onClick = () => {
    api.getStripeAuthUri().then((uri) => {
      window.open(uri, '_self')
    })
  }
  return (
    <section className="box w-full flex flex-col h-full relative bg-white">
      <header>Stripe Account</header>
      <div className="flex flex-col grow overflow-y-auto px-4 w-full items-center justify-center py-8">
        <Icon name="user-octagon" type="bulk" size={128} />
        {user?.stripeConnected ? (<>
          <h2 className="mt-4 mb-2">Stripe Account Connected</h2>
          <p className="text-primary-600">{email ? email : "..."}</p>
          </>
        ) : (
          <>
            <h2 className="mt-4 mb-2">Connect Your Account</h2>
            <button className="button-primary" children="Connect Stripe account" onClick={onClick} />
          </>
        )}
      </div>
      <footer className="bg-base-900 not-themed flex items-center justify-center py-10">
        <img src={require('../../../../assets/stripe.png')} className="h-8 w-auto" alt="Stripe" />
      </footer>
    </section>
  )
}

export default StripeAccountSettings
