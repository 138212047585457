import firebase from 'config/firebase'
import useUserContext from 'contexts/User/useUserContext'
import { FirebaseError } from 'firebase/app'
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { handleError } from 'helpers/errors'
import { FC, useState } from 'react'

const SecuritySettingsPage: FC = () => {
  const user = useUserContext()
  const [oldPass, setOldPass] = useState<string>("")
  const [newPass, setNewPass] = useState<string>("")
  const [confirmPass, setConfirmPass] = useState<string>("")
  const [updating, setUpdating] = useState(false)

  const onEdit = () => {
    if (updating) return
    if (!oldPass) return handleError("Please enter your old password")
    if (!newPass) return handleError("Please enter your new password")
    if (!confirmPass) return handleError("Please confirm your new password")
    if (newPass !== confirmPass) return handleError("Passwords do not match")
    setUpdating(true)
    reauthenticateWithCredential(firebase.auth.currentUser!, EmailAuthProvider.credential(user?.email!, oldPass))
      .then(() => 
        updatePassword(firebase.auth.currentUser!, newPass)
      )
      .catch((err: FirebaseError) => {
        switch (err.code) {
          case 'auth/wrong-password':
            handleError('Wrong password')
            break
          default:
            handleError(err.message)
            break
        }
      })
      .finally(() => {
        setOldPass("")
        setNewPass("")
        setConfirmPass("")
        setUpdating(false)
      })

  }

  return (
    <div className="rounded-2xl bg-white flex flex-col divide-y divide-y-base-200 overflow-y-auto">
      <header className="p-6 text-base text-base-950 font-semibold bg-white">Update Password</header>
      <div className="p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <span className="text-base-500 font-semibold text-xs">Current Password</span>
          <input className="border-none" type="password" value={oldPass} disabled={updating} onChange={(e) => setOldPass(e.currentTarget.value)} />
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-base-500 font-semibold text-xs">New Password</span>
          <input className="border-none" type="password" value={newPass} disabled={updating} onChange={(e) => setNewPass(e.currentTarget.value)} />
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-base-500 font-semibold text-xs">Confirm Password</span>
          <input className="border-none" type="password" value={confirmPass} disabled={updating} onChange={(e) => setConfirmPass(e.currentTarget.value)} />
        </div>
      </div>
      <footer className="flex p-6 justify-end">
        <button className="button-primary" onClick={onEdit} disabled={updating}>Save Changes</button>
      </footer>
    </div>
  )
}

export default SecuritySettingsPage
