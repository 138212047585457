import { AwaitableModal } from "hooks/useAwaitableModal";
import Modal from "./Modal";
import { CloseIcon } from "assets/icons";

const AreYouSureModal: AwaitableModal<undefined, {text?: string}> = ({ open, onCancel, resolve, initialData: {text}}) => {
    return (
        <Modal open={open} close={onCancel}>
      <div className="modal w-[512px] relative">
        <header className="!items-center flex flex">
          <h1>Are You Sure?</h1>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        {!!text && (
        <div className="w-full text-slate-400 text-sm font-medium text-center">
          {text}
        </div>
        )}
        <footer className="items-center flex justify-between">
          <button className="button-secondary" onClick={onCancel}>
            Cancel
          </button>
          <button className="button-primary" onClick={() => resolve()}>
            Continue
          </button>
        </footer>
      </div>
        </Modal>
    )
}

export default AreYouSureModal