import axios from 'api/axios'
import { AxiosInstance } from 'axios'
import { IInventory, IInventorySummary } from 'types/inventory'

class InventoryApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }
  
  async getSummaries() {
    return this.axios.get('/inventory').then((res) => res.data.summaries as IInventorySummary[])
  }
  
  async getInventory(user: string) {
    return this.axios.get('/inventory/'+user).then((res) => res.data as {inventory: IInventory[], name: string})
  }
  
  async updateInventoryItem(id: string, data: {quantity?: number, location?: string, notes?: string}) {
    return this.axios.patch(`/inventory/items/${id}`, data)
  }

  async addToInventory(user: string, data: {quantity: number, item: {asin: string, sku: string, name: string, fnsku: string}}[]) {
    return this.axios.post(`/inventory/${user}`, data)
  }
}

const inventoryApi = new InventoryApi()

export default inventoryApi
