import api from 'api'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'
import { IPaymentStructure, IPaymentStructureData } from 'types/billing'
import { IService } from 'types/services'

type response = IPaymentStructure

interface initial {
  name?: string
  pricingStructure?: IPaymentStructureData
  itemServices: IService[]
  orderServices: IService[]
}

const orderServiceNames = {
  PALLET_HANDLING: 'Pallet Receiving/Shipping',
  MEDIUM_BOX: 'Medium Box',
  LARGE_BOX: 'Large Box',
}

const itemServiceNames = {
  polybag: 'Polybagging',
  bubble_wrap: 'Bubble wrapping',
  oversize: 'Oversized',
}

const initialPricingStructure = (itemServices: IService[], orderServices: IService[], paymentStructure?: IPaymentStructureData) => ({
  basePrepping: paymentStructure?.basePrepping || 0.75,
  itemServices: {
    ...itemServices.reduce((acc, service) => ({ ...acc, [service.id]: service.basePrice }), {}),
    ...paymentStructure?.itemServices,
  },
  orderServices: {
    ...orderServices.reduce((acc, service) => ({ ...acc, [service.id]: service.basePrice }), {}),
    ...paymentStructure?.orderServices,
  },
})

const AddPaymentStructureModal: AwaitableModal<response, initial | undefined> = ({ open, resolve, onCancel, initialData }) => {
  const [sending, setSending] = useState(false)
  const [name, setName] = useState<string | undefined>(initialData?.name)
  const [pricingStructure, setPricingStructure] = useState<IPaymentStructureData>(
    initialPricingStructure(initialData?.itemServices || [], initialData?.orderServices || [], initialData?.pricingStructure)
  )

  const handleSubmit = async () => {
    if (sending) return
    if (!name) throw new Error('Name is required')
    if (!pricingStructure.basePrepping) throw new Error('Base Prepping price is required')
    if (!Object.values(pricingStructure.itemServices).every((v) => v !== undefined)) throw new Error('Item Services prices are required')
    if (!Object.values(pricingStructure.orderServices).every((v) => v !== undefined)) throw new Error('Shipment Services prices are required')
    setSending(true)
    api
      .addPaymentStructure(name, pricingStructure)
      .then(resolve)
      .finally(() => setSending(false))
  }

  const isChange = Boolean(initialData?.name)

  return (
    <Modal open={open} close={resolve}>
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>{isChange ? `Change payment structure ${name}` : 'Add a payment structure'}</h1>
            <h3>Select prices for this tier</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        <div className="w-full flex flex-col gap-2 max-h-[512px]">
          <h4>Basic</h4>
          <input type="text" disabled={isChange} value={name} placeholder="Structure Name" onChange={(e) => setName(e.target.value)} />
          <input
            type="number"
            min={0.01}
            step={0.01}
            value={pricingStructure?.basePrepping}
            placeholder="Base Prepping price"
            onChange={(e) => setPricingStructure((old) => ({ ...old, basePrepping: e.target.valueAsNumber }))}
          />
          <h4>Item Services</h4>
          <div className="flex flex-col gap-2">
            {Object.entries(pricingStructure?.itemServices).map(([key, value]) => (
              <div className="col-span-3 flex items-center relative">
                <span className="grow w-max absolute pl-4 text-sm text-base-400 pointer-events-none">
                  {itemServiceNames[key as keyof typeof itemServiceNames] || initialData?.itemServices?.find((service) => service.id === key)?.title}
                </span>
                <input
                  min={0}
                  step={0.01}
                  type="number"
                  className="text-right w-full"
                  placeholder="-"
                  value={value}
                  onChange={(e) => setPricingStructure((old) => ({ ...old, itemServices: { ...old.itemServices, [key]: e.target.valueAsNumber } }))}
                />
              </div>
            ))}
          </div>
          <h4>Order Services</h4>
          <div className="flex flex-col gap-2">
            {Object.entries(pricingStructure?.orderServices).map(([key, value]) => (
              <div className="col-span-3 flex items-center relative">
                <span className="grow w-max absolute pl-4 text-sm text-base-400 pointer-events-none">
                  {orderServiceNames[key as keyof typeof orderServiceNames] || initialData?.orderServices?.find((service) => service.id === key)?.title}
                </span>
                <input
                  min={0}
                  step={0.01}
                  type="number"
                  className="text-right w-full"
                  placeholder="-"
                  value={value}
                  onChange={(e) => setPricingStructure((old) => ({ ...old, orderServices: { ...old.orderServices, [key]: e.target.valueAsNumber } }))}
                />
              </div>
            ))}
          </div>
        </div>
        <footer className="items-center">
          <button disabled={sending} className="button-primary w-full" onClick={() => handleSubmit().catch(handleError)}>
            Submit
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default AddPaymentStructureModal
