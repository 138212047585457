import agencyApi from 'api/agency'
import Icon from 'components/Icon'
import IconButton from 'components/buttons/IconButton'
import Loader from 'components/loaders/Loader'
import { useEffect, useState } from 'react'

const AgencyAccountSettings = () => {
  const [agency, setAgency] = useState<string | null>()
  const [email, setEmail] = useState<string>()
  const [adding, setAdding] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    agencyApi.getAgency().then((agency) => {
      setAgency(agency === null ? agency : agency.name)
    })
  }, [])

  const onClick = () => {
    setAdding(true)
  }

  const onAdd = () => {
    if (!email) return
    if (submitting) return
    setSubmitting(true)
    agencyApi
      .updateAgency(email)
      .then((res) => {
        setAdding(false)
        setAgency(res?.name || null)
      })
      .finally(() => setSubmitting(false))
  }

  const onDelete = () => {
    if (submitting) return
    setSubmitting(true)
    agencyApi
      .updateAgency()
      .then(() => {
        setAgency(null)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <section className="box w-full flex flex-col h-full relative bg-white">
      <header>Agency Account</header>
      <div className="flex flex-col grow overflow-y-auto px-4 w-full items-center justify-center py-8">
        {!adding && <Icon name="user-octagon" type="bulk" size={128} />}
        {agency ? (
          <>
            <div className="flex flex-col items-center relative mt-4">
              <h2 className="mb-2">Agency Account Connected</h2>
              <p className="text-primary-600">{agency}</p>
              <div className="absolute -right-4 translate-x-full top-1/2 -translate-y-1/2">
                {submitting ? <Loader /> : <IconButton name="trash" tooltip="Disconnect" onClick={onDelete} disabled={submitting} />}
              </div>
            </div>
          </>
        ) : (
          <>
            <h2 className="mt-4 mb-2">Connect An Agency</h2>
            {!adding && <button className="button-primary" children="Add Agency Account" onClick={onClick} />}
          </>
        )}
        {adding && (
          <div className="flex flex-col gap-4 mt-4">
            <input type="text" className="input-primary w-[300px]" placeholder="Agency Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <div className="flex justify-between items-center px-6">
              <button className="button-secondary" onClick={() => setAdding(false)}>
                Cancel
              </button>
              <button className="button-primary" disabled={submitting} onClick={onAdd}>
                Add
              </button>
            </div>
          </div>
        )}
      </div>
      <footer className="bg-base-900 not-themed flex items-center justify-center py-10">
        <Icon name="key-square" type="bulk" size={40} />
      </footer>
    </section>
  )
}

export default AgencyAccountSettings
