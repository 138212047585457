import { availableRoles } from "constants/roles";
import useUserContext from "contexts/User/useUserContext"

const useHasRole = (...roles: (keyof typeof availableRoles)[]) => {
    const me = useUserContext();

    if (!me) return false;

    if (me.isPrepCenter && !me.isEmployee) return true;

    if (me.isEmployee && me.roles && me.roles.includes("ADMIN")) return true;
    
    if (me.isEmployee && me.roles && roles.every((role) => me.roles?.includes(role))) return true;

    return false;
}

export default useHasRole