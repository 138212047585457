import api from 'api'
import { CloseIcon } from 'assets/icons'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const AmazonConnectModal: FC = () => {
  const [sending, setSending] = useState(false)
  const navigate = useNavigate()
  const onClick = () => {
    if (sending) return
    setSending(true)
    api.getAmazonAuthUri().then((uri) => {
        window.location.replace(uri)
    }).finally(() => setSending(false))
  }

  return (
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>Connect Amazon Account</h1>
            <h3>Please connect your Amazon Seller Account to continue.</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={() => navigate(-1)} />
        </header>
        <footer className="!border-t-0">
          <button disabled={sending} className="button-primary w-full" onClick={onClick}>
            Connect
          </button>
        </footer>
      </div>
  )
}

export default AmazonConnectModal
