import { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'floatthead';

function useFloatThead({
  position = 'auto',
  scrollContainer,
  responsiveContainer,
  headerCellSelector = 'thead:first>td:visible',
  floatTableClass = 'floatThead-table',
  floatContainerClass = 'floatThead-container',
  top = 0,
  bottom = 0,
  zIndex = 1001,
  debug = false,
  getSizingRow,
  copyTableClass = true,
  autoReflow = false,
  cellTag = "td",
}: floatTheadOptions & { cellTag?: string } = {}) {
  const tableRef = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    const el = tableRef.current;
    if (!el) return;
    const table = $(el);

    const options = {
      position,
      scrollContainer,
      responsiveContainer,
      headerCellSelector,
      floatTableClass,
      floatContainerClass,
      top,
      bottom,
      zIndex,
      debug,
      getSizingRow,
      copyTableClass,
      autoReflow,
      cellTag,
    };

    const destroy = table.floatThead(options).trigger.bind(table, "destroy");

    return () => {destroy()};
  }, [tableRef, autoReflow, bottom, copyTableClass, debug, floatContainerClass, cellTag, floatTableClass, getSizingRow, headerCellSelector, position, responsiveContainer, scrollContainer, top, zIndex]);

  useEffect(() => {
    const el = tableRef.current;
    if (!el) return;
    const mo = new MutationObserver(() => {
      const selection = tableRef?.current?.querySelectorAll('thead > tr') as NodeListOf<HTMLTableRowElement>;
      Array.from(selection || []).forEach((row) => {
        if (row.style.height==="0px") row.parentElement?.style.setProperty("border", "0px");
      })
    })

    mo.observe(el as Node, { childList: true, subtree: true });

    return () => mo.disconnect();
  }, [])

  return [tableRef, tableRef.current?.tHead?.clientHeight || 0] as const;
}

export default useFloatThead;
