import inventoryApi from 'api/inventory'
import { AsinsIcon, BoxIcon, ClientsIcon } from 'assets/icons'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import { FC, useEffect, useState } from 'react'
import { IInventorySummary } from 'types/inventory'
import Storage from './components/Storage'
import Empty from 'components/Empty'
import useSelectedSeller from 'hooks/useSelectedSeller'
import useUserContext from 'contexts/User/useUserContext'

const InventoryPage: FC = () => {
  const [summaries, setSummaries] = useState<IInventorySummary[]>()
  const selectedSeller = useSelectedSeller()

  const isPrepCenter = useUserContext()?.isPrepCenter

  useEffect(() => {
    inventoryApi.getSummaries().then(setSummaries)
  }, [])

  const asins = summaries ? summaries.reduce((acc, summary) => acc + summary.asins, 0) : '...'
  const quantity = summaries ? summaries?.reduce((acc, summary) => acc + summary.quantity, 0) : '...'
  const clients = summaries ? summaries?.length : '...'

  const summariesFiltered = isPrepCenter ? summaries?.filter((summary) => !selectedSeller || summary.user === selectedSeller) : summaries

  return (
    <div className="grow overflow-y-auto gr auto-rows-max	gap-6 justify-start grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
      <div className="grid grid-cols-1 lg:grid-cols-2 rounded-lg bg-white lg:divide-x divide-x-base-200 xl:col-span-4 lg:col-span-3 md:col-span-2 h-max">
        {!isPrepCenter && (
          <div className="flex items-center p-6 gap-4">
            <AsinsIcon className="fill-primary-500" />
            <span className="text-sm text-base-950 grow">Total ASINS</span>
            <span className="text-base text-base-950 font-semibold text-right">
              {asins} ASIN{asins === 1 ? '' : 'S'}
            </span>
          </div>
        )}
        <div className="flex items-center p-6 gap-4">
          <BoxIcon className="fill-primary-500" />
          <span className="text-sm text-base-950 grow">Total Units</span>
          <span className="text-base text-base-950 font-semibold text-right">
            {quantity} Unit{quantity === 1 ? '' : 's'}
          </span>
        </div>
        {isPrepCenter && (
          <div className="flex items-center p-6 gap-4">
            <ClientsIcon className="fill-primary-500" />
            <span className="text-sm text-base-950 grow">Total Clients</span>
            <span className="text-base text-base-950 font-semibold text-right">
              {clients} Client{clients === 1 ? '' : 's'}
            </span>
          </div>
        )}
      </div>
      {summariesFiltered === undefined ? (
        <div className="xl:col-span-4 lg:col-span-3 md:col-span-2">
          <FullLoader />
        </div>
      ) : summariesFiltered.length ? (
        summariesFiltered.map((summary) => <Storage key={summary.user} storage={summary} />)
      ) : (
        <div className="xl:col-span-4 lg:col-span-3 md:col-span-2">
          <Empty text="No Items In Inventory" />
        </div>
      )}
    </div>
  )
}

export default InventoryPage
