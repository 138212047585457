import axios from 'api/axios'
import { AxiosInstance } from 'axios'

class ShopApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }
  
  async order(items: Record<string, number>, method?: "card" | "wire") {
    return this.axios.post('/shop/order', {items, method}).then((res) => res.data as string)
  }

  async success(sessionId: string) {
    return this.axios.get('/shop/success/'+sessionId)
  }
}

const shopApi = new ShopApi()

export default shopApi
