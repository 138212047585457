import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/tagcolors.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import AuthProvider from './contexts/Auth/AuthProvider'
import ScrollToTop from 'components/ScrollToTop'
import BlobStream from 'blob-stream'

declare global {
  var Rewardful: any
  var rewardful: any
  var PDFDocument: PDFKit.PDFDocument
  var blobStream: typeof BlobStream.prototype
}

declare global {
    interface Array<T> {
        asClass: string;
    }
    interface Number {
      between: (min: number, max: number) => number
    }
}

Object.defineProperty(Array.prototype, "asClass", {
  configurable: true,
  get() {
    return this.filter(Boolean).join(' ')
  },
});

Object.defineProperty(Number.prototype, "between", {
  configurable: true,
  value(min: number, max: number) {
    return Math.min(Math.max(this, min), max)
  }
})

rewardful('ready', function () {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <React.StrictMode>
      <AuthProvider>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
