import { useCallback, useEffect, useState } from 'react'
import * as xlsx from 'xlsx'

const baseUri = '/catalogues/'

export interface ICatalogueItem {
  upc: string
  name: string
  caseQty: number
  price: number
}

type ICatalogue = ICatalogueItem[]

const useCatalogue = (brand: string) => {
  const [data, setData] = useState<ICatalogue>()
  const [fetching, setFetching] = useState(false)

  const fetchCatalogue = useCallback(async (brand: string) => {
    const uri = baseUri + brand + ".xlsx"
    const response = await fetch(uri)
    const raw = await response.arrayBuffer()
    const workbook = xlsx.read(raw, { type: 'array' })
    const sheet = workbook.Sheets[workbook.SheetNames[0]]
    const csv = xlsx.utils.sheet_to_csv(sheet, { blankrows: false, FS: ';', RS: '\n' })
    const lines = csv.split('\n')
    setData(
      lines.map((line) => {
        const split = line.split(';')
        const upc = split[0]
        const name = split[1]
        const caseQty = parseInt(split[2], 10)
        const price = Math.round(parseFloat(split[3]) * 100)
        return {
          upc,
          name,
          caseQty,
          price,
        }
      })
    )
  }, [])

  useEffect(() => {
    setData(undefined)
  }, [brand])

  useEffect(() => {
    if (fetching) return
    if (data) return
    if (!brand) return
    setFetching(true)
    fetchCatalogue(brand)
      .finally(() => {
        setFetching(false)
      })
  }, [fetching, fetchCatalogue, brand, data])

  return data
}

export default useCatalogue
