import { FC, InputHTMLAttributes } from 'react'

interface props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  secure?: boolean
  title?: string
}

const TextInput: FC<props> = ({ secure, title, ...props }) => {
  return (
    <label htmlFor={props.name} className="text-dark">
      {title && <span className="leading-[3]">{title}</span>}
      <input className="h-12 input w-full outline-none rounded-xl text-xl px-4 text-table" type={secure ? 'password' : 'text'} {...props} />
    </label>
  )
}

export default TextInput
