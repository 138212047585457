import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface props {
  onSelectedChange: (selected: string) => void
}

const InvoiceStateSwitch: FC<props> = ({ onSelectedChange }) => {
  const location = useLocation()
  const pathName = location.pathname.split('/').pop()
  const navigate = useNavigate()

  const selected = pathName === 'draft' ? 'draft' : pathName === 'sent' ? 'open' : pathName === 'paid' ? 'paid' : 'draft'

  useEffect(() => {
    onSelectedChange(selected)
  }, [selected, onSelectedChange])

  return (
    <div className="flex space-x-5 p-5 bg-white">
      <button onClick={() => navigate('/app/billing/invoices/draft')} className={selected === 'draft' ? 'button-primary grow basis-1' : 'button-secondary h-12 grow basis-1'}>
        Draft
      </button>
      <button onClick={() => navigate('../sent', { relative: 'path' })} className={selected === 'open' ? 'button-primary grow basis-1' : 'button-secondary h-12 grow basis-1'}>
        Sent
      </button>
      <button onClick={() => navigate('../paid', { relative: 'path' })} className={selected === 'paid' ? 'button-primary grow basis-1' : 'button-secondary h-12 grow basis-1'}>
        Paid
      </button>
    </div>
  )
}

export default InvoiceStateSwitch
