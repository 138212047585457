import amazonItemImgUrl from 'helpers/amazonItemImgUrl'
import { FC, useEffect, useRef } from 'react'
import { IOrder } from 'types/orders'
import bwipjs from 'bwip-js'
import { jsPDF } from 'jspdf'
import ordersApi from 'api/orders'
import { print } from 'helpers/print'

interface props {
  orderId: string
  item: IOrder['itemsData'][0]
  onNext: () => void
  expiryDate?: string
  setExpiryDate: (date: string | undefined) => void
}

const FNSKU: FC<props> = ({ item, onNext, orderId, expiryDate, setExpiryDate }) => {
  const fnskuCanvas = useRef<HTMLCanvasElement>(null)

  function wrap(context: CanvasRenderingContext2D, text: string, maxWidth: number) {
    let width = context.measureText(text).width
    if (width <= maxWidth) return text
    let a1 = text.substring(0, text.length / 2)
    let a2 = text.substring(a1.length)
    let i = 0
    while (width > maxWidth) {
      if (i % 2) {
        a1 = a1.substring(0, a1.length - 1)
      } else {
        a2 = a2.substring(1)
      }

      text = a1 + '...' + a2
      width = context.measureText(text).width
      i++
    }
    return text
  }

  useEffect(() => {
    if (!fnskuCanvas.current) return
    const canvas = bwipjs.toCanvas(fnskuCanvas.current, {
      bcid: 'code128',
      text: item.fnsku,
      scale: 3,
      height: 10,
      textxalign: 'center',
      textsize: 10,
      textyoffset: 5,
      paddingwidth: 10,
      paddingheight: 10,
      paddingbottom: 30,
      backgroundcolor: '#FFFFFF',
      textcolor: '#000000',
      monochrome: true,
      rotate: 'N',
    })
    const context = canvas.getContext('2d')
    if (!context) return
    context.font = '14px Arial'
    context.textAlign = 'center'
    context.fillText(item.fnsku, canvas.width / 2, 67 * 2)
    context.font = '14px Arial'
    context.textAlign = 'center'
    const wrappedName = wrap(context, item.name, canvas.width - 3 * 10 * 2)
    context.fillText(wrappedName, canvas.width / 2, 66 * 2 + 20)
    if (expiryDate) {
      context.font = '12px Arial'
      context.textAlign = 'left'
      context.fillText(expiryDate.toString(), 10 * 3, 72 * 2 + 20)
    }
    context.font = '12px Arial'
    context.textAlign = 'right'
    context.fillText((item.condition || 'NewItem').split(/(?=[A-Z])/).join(' '), canvas.width - 10 * 3, 72 * 2 + 20)
  }, [item.fnsku, expiryDate, item.name, item.condition])

  const onDownload = () => {
    ordersApi.updateProductExpiryDate(orderId, item.sku, expiryDate).then(() => {
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [2.5, 0.9],
      })
      if (!fnskuCanvas.current) return
      var imgData = fnskuCanvas.current.toDataURL('image/png', 1)
      for (let i = 0; i < item.actual; i++) {
        i && doc.addPage()
        doc.addImage(imgData, 0, 0, 2.5, 0.9)
      }

      const uri = doc.output('bloburi')

      print(uri.toString())
    })
  }

  return (
    <>
      <div>
        <div className="w-full gap-6 flex !flex-row">
          <div
            style={{
              backgroundImage: `url(${amazonItemImgUrl(item.asin, 1024, false)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: '50% 50%',
            }}
            className="h-fill w-[256px] rounded-xl object-cover"
          />
          <div className="grid grid-cols-2 border border-base-200 rounded-xl divide-y divide-y-base-200">
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">FNSKU</div>
            <div className="p-4">
              <canvas className="object-contain w-full h-full" ref={fnskuCanvas} />
            </div>
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">SKU</div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left">{item.sku}</div>
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Title</div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left">{item.name}</div>
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Amazon Prep Instructions</div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left">tbd</div>
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Quantity Remaining</div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left">{item.actual}</div>
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Labels To Print</div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left">{item.actual}</div>
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Expiration Date</div>
            <div className="p-4 flex items-center">
              <input type="date" className="text-xs text-base-900 w-full" value={expiryDate} onChange={(e) => setExpiryDate(e.currentTarget.value || undefined)} />
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-base-900 not-themed">
        <button className="button-dark" onClick={onDownload}>
          Print Item Labels
        </button>
        <button className="button-primary" onClick={onNext}>
          Next
        </button>
      </footer>
    </>
  )
}

export default FNSKU
