import { FC, useEffect } from 'react'
import Loader from '../../../../components/loaders/Loader'
import api from 'api'
import { IContactInfo } from 'pages/app/InitialSetup/types'
import Icon from 'components/Icon'
import { useNavigate } from 'react-router-dom'
import Empty from 'components/Empty'

interface props {
  senders?: { id: string; contact: IContactInfo }[]
  setSenders: (senders: { id: string; contact: IContactInfo }[]) => void
  sender?: string
  setSender: (sender: string) => void
}

const InvoiceSenderSelector: FC<props> = ({ senders, setSenders, setSender }) => {
  useEffect(() => {
    api.getInvoiceSenders().then((senders) => {
      setSenders(senders)
      if (senders?.length && senders.length > 0) {
        setSender(senders[0].id)
      }
    })
  }, [setSender, setSenders])
  const navigate = useNavigate()

  return (
    <>
      <div className="flex flex-col w-full divide-y divide-y-slate-100 h-full">
        {senders !== undefined ? (
          senders.length ? (
            senders?.map((option) => (
              <a
                key={option.id}
                className="cursor-pointer group w-full flex items-center hover:opacity-90 text-sm font-medium transition-all justify-between p-5 rounded"
                onClick={() => navigate('/app/billing/invoices/' + option.id )}
              >
                <span className="grow">{option.contact?.name || option.contact.email}</span>
                <Icon name="arrow-right" className="fill-slate-500 group-hover:fill-primary-500"  />
              </a>
            ))
          ) : <div className='w-full p-5'><Empty text="No Invoices" /></div>
        ) : (
          <div className="p-6 flex items-center justify-center">
          <Loader />
          </div>
        )}
      </div>
    </>
  )
}

export default InvoiceSenderSelector
