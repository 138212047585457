import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/layout/Sidebar'
import useUserContext from '../../contexts/User/useUserContext'
import NoOrdersImg from 'assets/no_orders.png'
import { adminId } from 'constants/admin'
import useHasRole from 'hooks/useHasRole'
import useAgencyContext from 'contexts/Agency/useAgencyContext'
import Icon from 'components/Icon'
import Empty from 'components/Empty'
import useCanShop from 'hooks/useCanShop'
import { SidebarIcons } from 'assets/icons'

const MainLayout: FC = () => {
  const user = useUserContext()
  const isAwaitingPrepCenterApproval = !user?.isPrepCenter && user?.wantsToBePrepCenter
  const canBill = useHasRole('INVOICE')
  const { isAgency, actAs, actAsOptions, setActAs } = useAgencyContext()
  const hasDistributionAccess = useCanShop()
  const isApexClient = user?.referrer === adminId || !user?.referrer

  if (isAgency && !actAs)
    return (
      <div className="h-screen flex">
        {actAsOptions && Object.keys(actAsOptions).length > 0 ? (
          Object.keys(actAsOptions).map((id) => (
            <span key={id} className="cursor-pointer group w-full flex items-center hover:opacity-90 text-sm font-medium transition-all justify-between p-5 rounded" onClick={() => setActAs(id)}>
              <span className="grow">{actAsOptions[id]}</span>
              <Icon name="arrow-right" className="fill-slate-500 group-hover:fill-primary-500" />
            </span>
          ))
        ) : (
          <div className="flex items-center justify-center w-full">
            <Empty text="No Accounts Connected" />
          </div>
        )}
      </div>
    )

  const prepCenterIcons = [
    { icon: SidebarIcons.DashboardIcon, name: 'Dashboard', path: '/app/dashboard' },
    ...(user?.isPrepCenter ? [{ icon: SidebarIcons.InventoryIcon, name: 'Shipments', path: '/app/orders' }] : []),
    { icon: SidebarIcons.OutboundIcon, name: 'Outbound Shipments', path: '/app/outbound' },
    { icon: SidebarIcons.WarehouseIcon, name: 'Inventory', path: '/app/inventory' },
    ...(user?.id === "KydYecXlOOeVSABzkoRtv5UlzZj1" ? [{ icon: SidebarIcons.DistributionIcon, name: 'Distribution Orders', path: '/app/dist-orders' }]: []),
    // ...(user && ((user?.isPrepCenter && !user.isEmployee) || (user.isEmployee && user.roles?.includes('ADMIN'))) ? [{ icon: 'award', name: 'Staff Members', path: '/app/staff' }] : []),
    { icon: SidebarIcons.ChatsIcon, name: 'Chats', path: '/app/contacts' },
    { icon: SidebarIcons.ReportsIcon, name: 'Reports - Coming Soon', path: '/app/reports' },
    ...(user?.isPrepCenter
      ? canBill
        ? [
            { icon: SidebarIcons.CardIcon, name: 'Billing', path: user.stripeAccountId ? '/app/billing' : '/app/settings/stripe' },
            // { icon: 'wallet-2', name: 'Payment Structures', path: '/app/paymentStructures' },
          ]
        : []
      : [{ icon: SidebarIcons.CardIcon, name: 'Billing', path: '/app/billing/invoices' }]),
  ]

  const sellerIcons = [
    { icon: SidebarIcons.DashboardIcon, name: 'Dashboard', path: '/app/dashboard' },
    { icon: SidebarIcons.OutboundIcon, name: 'Outbound Shipments', path: '/app/outbound' },
    { icon: SidebarIcons.InventoryIcon, name: 'Inventory', path: '/app/inventory' },
    ...(hasDistributionAccess
      ? [
          {
            icon: SidebarIcons.DistributionIcon,
            name: 'Distribution Orders',
            path: '/app/dist-orders',
          },
          {
            icon: SidebarIcons.ShopIcon,
            name: 'Shop',
            path: '/app/shop',
          },
        ]
      : []),
    ...(!isApexClient ? [{ icon: SidebarIcons.ChatsIcon, name: 'Chats', path: '/app/contacts' }] : []),
    ...(user?.referrer === adminId || !user?.referrer ? [{ icon: SidebarIcons.WarehouseIcon, name: 'Warehouse', path: '/app/warehouses' }] : []),
    { icon: SidebarIcons.ReportsIcon, name: 'Reports - Coming Soon', path: '/app/reports' },
    { icon: SidebarIcons.CardIcon, name: 'Billing', path: '/app/billing/invoices' },
  ]

  const adminIcons = [
    { icon: SidebarIcons.DashboardIcon, name: 'Dashboard', path: '/app/dashboard' },
    { icon: SidebarIcons.DistributionIcon, name: 'Shipments', path: '/app/shipments' },
  ]

  return (
    <div className="h-screen flex">
      <div className="h-full flex flex-col">
        <Sidebar buttons={user?.id === adminId ? adminIcons : user?.isPrepCenter ? prepCenterIcons : sellerIcons} />
      </div>
      {!isAwaitingPrepCenterApproval ? (
        <Outlet />
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <img src={NoOrdersImg} className="h-[20vh] mb-10" />
          <h3>Our administrators have been notified and will begin working on your verification shortly.</h3>
        </div>
      )}
    </div>
  )
}

export default MainLayout
