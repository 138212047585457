import { FC } from 'react'

interface props {
  title: string
  selected: string[]
  onChange: (selected: string[]) => void
  options: string[]
  optionTitles?: Record<string, string>
}

const MultiSelect: FC<props> = ({ onChange, selected, title, options, optionTitles }) => {
  const all = selected.length === 0
  const toggle = (option: string) => {
    const newSelect = selected.includes(option) ? selected.filter((s) => s !== option) : [...selected, option]
    onChange(newSelect)
  }

  return (
    <form className="flex flex-col w-full">
      <p className="mb-2">{title}</p>
      <div className="flex items-center">
        <label className="grow mb-0" htmlFor="all">
          All
        </label>
        <input type="checkbox" className="checkbox-square" value="all" checked={all} onChange={(e) => e.currentTarget.checked && onChange([])} />
      </div>
      {options.map((option) => (
        <div className="flex items-center">
          <label className="grow mb-0" key={option} htmlFor={option}>
            {optionTitles ? optionTitles[option] : option}
          </label>
          <input type="checkbox" id={option} checked={selected.includes(option)} onChange={(e) => toggle(option)} />
        </div>
      ))}
    </form>
  )
}

export default MultiSelect
