import { FC, FormEvent, useState } from 'react'
import { useNavigate } from 'react-router'
import api from 'api'
import TextInput from 'components/TextInput'

const AddContactPage: FC = () => {
  const [email, setEmail] = useState<string>('')
  const [connecting, setConnecting] = useState<boolean>(false)
  const [error, setError] = useState()
  const navigate = useNavigate()

  const emailValid = email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setConnecting(true)
    api.createConnection(email)
        .then(() => navigate(-1))
        .catch((e) => setError(e.response?.data?.error))
        .finally(() => setConnecting(false))
    return false
  }

  return (
    <div className="max-h-[70vw] max-w-[768px] bg-white px-16 py-8 rounded-xl">
      <h4>Add a contact</h4>
      <form className="flex flex-col items-center space-y-3 min-w-[400px] mt-8" name="login" onSubmit={handleSubmit}>
        <TextInput placeholder="Email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
        {error && <p className="text-red-500">{error}</p>}
        {emailValid && <button className="button-primary" children={connecting ? "..." : "Connect"} disabled={connecting} onSubmit={handleSubmit} />}
      </form>
    </div>
  )
}

export default AddContactPage
