import { FC } from "react";
import IconBig from "./IconBig";

interface props {
    text: string
    action?: () => void
    actionText?: string
}

const Empty: FC<props> = ({text, action, actionText}) => (
    <div className="flex flex-col items-center">
        <IconBig name="bag-cross" size={48} />
        <span className="font-normal text-sm text-base-900 my-4">{text}</span>
        {Boolean(action) && Boolean(actionText) && <button className="button-primary" onClick={action}>{actionText}</button>}
    </div>
)

export default Empty