import IconButton from 'components/buttons/IconButton'
import { FC } from 'react'
import { IPalletConfig } from '.'
import Switch from 'components/Switch'

interface props {
  pallet: IPalletConfig
  setPallet: (box: IPalletConfig) => void
  onDelete: () => void
}
const PalletConfig: FC<props> = ({ pallet, setPallet, onDelete }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.type === 'number' ? Number(e.currentTarget.value) : e.currentTarget.value
    setPallet({ ...pallet, [e.currentTarget.name]: value })
  }

  return (
    <div className="text-xs text-base-900 p-4 text-left grid gap-4 grid-cols-6">
      <span className="text-base font-medium w-max col-span-6">Pallet Configuration</span>
      <span className="text-sm font-medium w-max col-span-6 text-base-400">Dimensions</span>
      <div className="col-span-2 flex items-center relative">
        <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
          <span className="grow w-max text-sm text-base-400">W</span>
          <span className="grow w-max text-[7px] text-base-400">Inches</span>
        </div>
        <input name="width" min={0} type="number" className="text-right w-full" placeholder="-" value={pallet.width} onChange={onChange} />
      </div>
      <div className="col-span-2 flex items-center relative">
        <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
          <span className="grow w-max text-sm text-base-400">H</span>
          <span className="grow w-max text-[7px] text-base-400">Inches</span>
        </div>
        <input name="height" min={0} type="number" className="text-right w-full" placeholder="-" value={pallet.height} onChange={onChange} />
      </div>
      <div className="col-span-2 flex items-center relative">
        <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
          <span className="grow w-max text-sm text-base-400">L</span>
          <span className="grow w-max text-[7px] text-base-400">Inches</span>
        </div>
        <input name="length" min={0} type="number" className="text-right w-full" placeholder="-" value={pallet.length} onChange={onChange} />
      </div>
      <div className="col-span-6 flex items-center relative">
        <span className="grow w-max absolute pl-4 text-sm text-base-400 pointer-events-none">Weight - LBS</span>
        <input name="weight" min={0} type="number" className="text-right w-full" placeholder="-" value={pallet.weight} onChange={onChange} />
      </div>
      <div className="col-span-2 flex items-center relative cursor-pointer border border-base-200 rounded-lg p-4" onClick={() => setPallet({...pallet, isStacked: !pallet.isStacked})}>
        <span className="grow w-max text-sm text-base-400 pointer-events-none">Stacked</span>
        <Switch checked={Boolean(pallet.isStacked)} />
      </div>
      <div className="col-span-3 flex items-center relative">
        <span className="grow w-max absolute pl-4 text-sm text-base-400 pointer-events-none">Pallets</span>
        <input name="numPallets" min={0} type="number" className="text-right w-full" placeholder="-" value={pallet.numPallets} onChange={onChange} />
      </div>
      <div className="col-span-1 flex items-center justify-center">
        <IconButton name="trash" onClick={onDelete} />
      </div>
    </div>
  )
}

export default PalletConfig
