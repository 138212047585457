import shopApi from 'api/shop'
import Icon from 'components/Icon'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import useShoppingCart from 'hooks/useShopingCart'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const ShopOrderSuccessPage: FC = () => {
  const { clear } = useShoppingCart()
  const { session_id: sessionId } = useParams()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!sessionId) return
    if (submitting) return
    setSubmitting(true)
    shopApi.success(sessionId).finally(() => setSubmitting(false))
  }, [submitting, sessionId])

  useEffect(() => {
    clear()
  }, [])

  return (
    <div className="flex flex-col grow">
      <Header title="Shop" />
      <div className="grow flex flex-col overflow-y-hidden pb-8 items-center justify-center">
        {submitting ? (
          <FullLoader />
        ) : (
          <>
            <Icon name="bag" size={64}  className="fill-primary-500 mb-4"></Icon>
            <h1>Order Has Been Successfully Placed</h1>
            <h3 className="text-slate-400">It will be processed shortly</h3>
          </>
        )}
      </div>
    </div>
  )
}

export default ShopOrderSuccessPage
