import api from "api";
import Header from "components/layout/Header";
import useUserContext from "contexts/User/useUserContext";
import { FC, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { IWarehouse } from "types/warehouses";
import FullLoader from "components/loaders/FullLoader";
import Empty from "components/Empty";
import MyWarehouse from "./components/MyWarehouse";

const MyWarehousesPage: FC = () => {
    const user = useUserContext()
    const [warehouses, setWarehouses] = useState<IWarehouse[]>()

    useEffect(() => {
        if (user && !user.isPrepCenter) return
        api.listWarehouses().then((data) => setWarehouses(data?.warehouses))
    }, [user])

    if (user && !user.isPrepCenter) return <Navigate to="/app/dashboard" />

    return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header title="Warehouse"/>
      <div className="grow flex flex-col overflow-hidden">
        <header className="flex justify-between items-center z-[1000]">
          <div className="flex gap-1 flex-col">
            <span>My Warehouses</span>
          </div>
        </header>
        <div className="flex flex-col grow overflow-y-auto">
            <div className="flex flex-col gap-4 h-max px-6 pb-6">
              {warehouses?.map((warehouse) => (
                <MyWarehouse key={warehouse.id} warehouse={warehouse} />
              ))}
              {!warehouses?.length && (
                  warehouses === undefined ? <FullLoader /> : <Empty text="No Warehouses" />
              )}
              </div>
        </div>
      </div>
    </div>
    )
}

export default MyWarehousesPage