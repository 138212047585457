import servicesApi from 'api/services'
import IconButton from 'components/buttons/IconButton'
import Loader from 'components/loaders/Loader'
import { FC, useState } from 'react'
import { IService } from 'types/services'

interface props {
  service: IService
  remove: () => void
}

const OrderService: FC<props> = ({ service, remove }) => {
  const [removing, setRemoving] = useState(false)
  const onDelete = () => {
    if (removing) return
    setRemoving(true)
    servicesApi.deleteOrderService(service.id).then(remove)
    .finally(() => setRemoving(false))
  }
  return (
    <tr>
      <td className="p-3">{service.title}</td>
      <td className="p-3 text-xs border-l">{service.identifier}</td>
      <td className="p-3 text-xs border-l">${service.basePrice.toFixed(2)}</td>
      <td className="p-3 text-xs border-l">{(new Date(service.created_at)).toLocaleDateString()}</td>
      <td className="border-l">
        <div className="flex items-center gap-2 -my-2">
          {removing ? <Loader size={24} /> : <IconButton name="trash" className="!fill-red-500" onClick={onDelete} />}
        </div>
      </td>
    </tr>
  )
}

export default OrderService
