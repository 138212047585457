import api from 'api'
import FullLoader from 'components/loaders/FullLoader'
import Loader from 'components/loaders/Loader'
import useBottomScrollListener from 'hooks/useBottomScrollListener'
import usePaginated from 'hooks/usePaginated'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { INotification } from 'types/notifications'

const NotificationsPage: FC = () => {
  const endpoint = useCallback(api.listNotifications, [])
  const [notifications, loadMore, loadingMore, hasMore, setNotifications] = usePaginated<INotification>(endpoint, 'notifications')
  const navigate = useNavigate()

  const onClick = (type: string, resource?: string) => {
    switch (type) {
      case 'orderStateChange':
        if (!resource) return
        navigate(`/app/orders/${resource}`, { replace: true })
        return
      case 'NEW_ORDER':
        if (!resource) return
        navigate(`/app/orders/${resource}`, { replace: true })
        return
      case 'order-handled':
        if (!resource) return
        navigate(`/app/orders/${resource}`, { replace: true })
        return
      case 'connectionAccepted':
        navigate('/app/contacts', { replace: true })
        return
      case 'NEW_INVOICE':
        const invoiceId = resource?.split(' ')[1]
        navigate('/app/billing/invoices/invoice/' + invoiceId, { replace: true })
        return
      default:
        return
    }
  }

  const onDelete = (_id: string) => {
    api.deleteNotification(_id).then(() => setNotifications((old) => old && old.filter(({ id }) => id !== _id)))
  }

  const scrollRef = useBottomScrollListener(loadMore, 100)

  return (
    <div className="px-2 py-1 border-b border-b-base-200 flex flex-col bg-base-50 rounded-md min-h-[300px] max-h-[600px] w-[500px]">
      {!notifications ? (
        <FullLoader />
      ) : notifications.length ? (
        <div className="flex-col grow overflow-y-auto my-2" ref={scrollRef}>
          {notifications?.map(({ id, title, body, type, resource }) => (
            <div key={id} className="flex w-full items-center space-x-1 border-b border-b-base-200 py-1 px-2 cursor-pointer">
              <div className="grow" onClick={() => onClick(type, resource)}>
                <p className="grow font-semibold">{title}</p>
                {body && <p className="grow text-xs">{body}</p>}
              </div>
              <button className="button rounded-full text-base-900 hover:bg-secondary flex items-center p-1">
                <i className="material-symbols-rounded color-white w-min" onClick={() => onDelete(id)}>
                  cancel
                </i>
              </button>
            </div>
          ))}
          {(hasMore || loadingMore) && (
            <div className="flex justify-center items-center py-2">
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <p className="text-center text-base-900 my-3 grow">No new notifications</p>
      )}
    </div>
  )
}

export default NotificationsPage
