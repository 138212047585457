import axios from 'api/axios'
import { AxiosInstance } from 'axios'
import { TransportContent } from 'types/amazon'
import { IOrder, IPartneredLtlPackagingDetails, IPartneredSPDPackagingDetails, IShippingData, LTLData } from 'types/orders'
import { IAdditionalServiceV0 } from 'types/orders'

class OrderApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  createOrder = async (warehouse: string, identifier: string, items: {
        sku: string,
        fnsku: string,
        asin: string,
        expected: number,
        name: string}[], additional?: {tracking?: string, notes?:string}) => {
          return this.axios.put('/orders/'+warehouse, { identifier, items, ...additional }).then((res) => res.data)
  }

  createClientOrder = async (client: string, warehouse: string, identifier: string, items: {
        sku: string,
        fnsku: string,
        asin: string,
        expected: number,
        name: string}[], additional?: {tracking?: string, notes?:string}) => {
          return this.axios.put('/orders/'+warehouse + "/client/" + client, { identifier, items, ...additional }).then((res) => res.data)
  }

  createOrderFromInventory = async (warehouse: string, identifier: string, items: {
        storageId: string, quantity: number}[], additional?: {tracking?: string, notes?:string}) => {
          return this.axios.put('/orders/inventory/'+warehouse, { identifier, items, ...additional }).then((res) => res.data)
  }

  editOrder = async (orderId: string, identifier: string, items: {
        sku: string,
        fnsku: string,
        asin: string,
        expected: number,
        name: string}[], additional?: {tracking?: string, notes?:string}) => {
          return this.axios.patch('/orders/'+orderId, { identifier, items, ...additional }).then((res) => res.data)
        }

  getOrder = async (orderId: string) => {
    return this.axios.get('/orders/' + orderId).then((res) => ({...res.data, itemsData: res.data.itemsData.sort((a: any, b: any) => a.name.localeCompare(b.name))}))
  }

  getOrderItems = async (orderId: string) => {
    return this.axios.get(`/orders/${orderId}/items`).then((res) => res.data.items)
  }

  getOrderPaymentUrl = (orderId: string) => {
    return this.axios.get('/orders/' + orderId + '/payment').then((res) => res.data)
  }

  listSellersOrders = async (queryParams = {}, page?: number) => {
    return this.axios.get('/orders/seller', { params: { page, ...queryParams } }).then((res) => res.data)
  }

  listPrepOrders = async (seller?: string, queryParams = {}, page?: number) => {
    return this.axios.get('/orders/prepCenter', { params: { seller, page, ...queryParams } }).then((res) => res.data)
  }

  listOutboundSellersOrders = async (queryParams = {}, page?: number) => {
    return this.axios.get('/orders/seller/outbound', { params: { page, ...queryParams } }).then((res) => res.data)
  }

  listOutboundPrepOrders = async (seller?: string, queryParams = {}, page?: number) => {
    return this.axios.get('/orders/prepCenter/outbound', { params: { seller, page, ...queryParams } }).then((res) => res.data)
  }

  assignOrderId = (orderId: string, id: string) => {
    return this.axios.post('/orders/' + orderId + '/assignId', { id })
  }

  deleteOrder = (orderId: string) => {
    return this.axios.delete('/orders/' + orderId)
  }

  markReady = async (orderId: string) => {
    return this.axios.post(`/orders/${orderId}/status/send`).then((res) => res.data)
  }

  markStored = async (orderId: string) => {
    return this.axios.post(`/orders/${orderId}/status/done`).then((res) => res.data)
  }

  markArrived = async (orderId: string, itemId: string, quantity: number, missingReason?: string, storage?: {location: string, qty: number}) => {
    return this.axios.post(`/orders/${orderId}/status/atPrep/${itemId}`, { quantity, missingReason, storage }).then((res) => res.data)
  }

  updateServices = async (orderId: string, itemId: string, services: string[]) => {
    return this.axios.put(`/orders/${orderId}/items/${itemId}/services`, { services }).then((res) => res.data)
  }

  markPaid = async (orderId: string) => {
    return this.axios.post(`/orders/${orderId}/status/paid`).then((res) => res.data)
  }

  payByCard = async (orderId: string) => {
    return this.axios.get(`/orders/${orderId}/status/pay/card`).then((res) => res.data as string)
  }

  downloadFNSKULabels = async (orderId: string, itemId: string) => {
    return this.axios.get(`/orders/${orderId}/status/fnsku/${itemId}`).then((res) => res.data as string)
  }

  putPackagingDetails = async (orderId: string, itemId: string, details: IPartneredSPDPackagingDetails | IPartneredLtlPackagingDetails) => {
    return this.axios.post(`/orders/${orderId}/status/shipment/${itemId}/packaging`, { details }).then((res) => res.data as string)
  }

  estimateTransport = async (orderId: string) => {
    return this.axios.post(`/orders/${orderId}/shipment/estimate`).then((res) => res.data as TransportContent)
  }

  confirmTransport = async (orderId: string) => {
    return this.axios.post(`/orders/${orderId}/shipment/confirm`).then((res) => res.data as TransportContent)
  }

  voidTransport = async (orderId: string) => {
    return this.axios.post(`/orders/${orderId}/shipment/void`).then((res) => res.data as TransportContent)
  }

  downloadShippingLabels = async (orderId: string, palletCount?: number) => {
    return this.axios.get(`/orders/${orderId}/shipment/labels` + (palletCount ? `?palletCount=${palletCount}` : '')).then((res) => res.data as string)
  }

  setOrderNotes = async (orderId: string, seller: boolean, note: string) => {
    return this.axios.post(`/orders/${orderId}/notes/` + (seller ? 'seller' : 'prep'), { note }).then((res) => res.data)
  }

  addAdditionalServices = async (orderId: string, services: Record<IAdditionalServiceV0, number>) => {
    return this.axios.put(`/orders/${orderId}/services`, { services }).then((res) => res.data)
  }

  getOrderUpdates = async (orderId: string) => {
    return this.axios.get(`/orders/${orderId}/updates`).then((res) => res.data)
  }

  createAmazonShipment = async (orderId: string, type: "SPD" | "LTL") => {
    return this.axios.post(`/orders/${orderId}/createAmazon`, {type}).then((res) => res.data as {
      shipmentId: string,
      destinationAddress: {
        Name: string
        AddressLine1: string
        AddressLine2?: string
        City: string
        StateOrProvinceCode: string
        CountryCode: string
        PostalCode: string
        fulfillmentCenterId: string
      },
      items: IOrder["itemsData"],
      issues: IOrder["issues"],
      asins: number,
      quantity: number,
    }[])
  }

  updateProductExpiryDate = async (orderId: string, sku: string, expiryDate: string | undefined) => {
    return this.axios.put(`/orders/${orderId}/items/${sku}/expiryDate`, {expiryDate}).then((res) => res.data)
  }

  updateBoxes = async (orderId: string, boxes: IShippingData["boxes"]) => {
    return this.axios.post(`/orders/${orderId}/boxes`, {boxes}).then((res) => res.data as IShippingData["boxes"])
  }

  addBoxes = async (orderId: string, boxes: IShippingData["boxes"]) => {
    return this.axios.put(`/orders/${orderId}/boxes`, {boxes}).then((res) => res.data as IShippingData["boxes"])
  }

  deleteBox = async (orderId: string, boxId: string) => {
    return this.axios.delete(`/orders/${orderId}/box/${boxId}`).then((res) => res.data as IShippingData["boxes"])
  }

  getAmazonShipment = async (orderId: string) => {
    return this.axios.get(`/orders/${orderId}/amazonShipment`).then((res) => res.data)
  }

  putTransportDetails = async (orderId: string, ltlData?: LTLData) => {
    return this.axios.put(`/orders/${orderId}/transport`, { ltlData }).then((res) => res.data as TransportContent)
  }

  bill = async (orderId: string) => {
    return this.axios.post(`/orders/${orderId}/bill`).then((res) => res.data as string)
  }

  getInboundItemsGraph = async (days: number) => {
    return this.axios.get("/dashboards/inbound?days=" + days).then((res) => res.data)
  }

  getOutboundItemsGraph = async (days: number) => {
    return this.axios.get("/dashboards/outbound?days=" + days).then((res) => res.data)
  }
}

const ordersApi = new OrderApi()

export default ordersApi
