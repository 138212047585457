import api from "api"
import { useEffect, useState } from "react"
import { IAmazonProductImage } from "types/amazon"

const useAmazonProductImage = (asin: string) => {
    const [image, setImage] = useState<IAmazonProductImage | null>(null)

    useEffect(() => {
        if (!asin) return
        api.getAmazonProductImage(asin).then(setImage)
    }, [asin])

    return image
}

export default useAmazonProductImage