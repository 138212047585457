import servicesApi from 'api/services'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'
import { IService } from 'types/services'

const AddItemServiceModal: AwaitableModal<IService, {}> = ({ open, onCancel, resolve }) => {
  const [service, setService] = useState<Omit<IService, 'id' | 'created_at' | 'prepCenter' | "type">>({ title: '', identifier: '', basePrice: 0 })
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = () => {
    if (submitting) return
    setSubmitting(true)
    if (!service.identifier || !service.title || !service.basePrice) {
      setSubmitting(false)
      handleError('Please fill all fields')
      return
    }
    servicesApi
      .addItemService(service as unknown as any)
      .then(resolve)
      .finally(() => setSubmitting(false))
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.type === "number" ? Number(e.currentTarget.value) : e.currentTarget?.value
    const name = e.currentTarget?.name
    if (!name) return
    setService((old) => ({ ...old, [name]: !value ? undefined : value }))
  }

  return (
    <Modal open={open} close={onCancel}>
      <div className="modal relative max-h-[calc(100vh-64px)]">
        <header className="!items-center flex gap-3">
          <span className="grow text-xl">Add Service</span>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        <div className="flex flex-col w-full overflow-y-auto min-w-[400px] p-6">
          <input name="title" className="w-full" placeholder="Service Title" value={service.title} onChange={onChange} />
          <input name="identifier" className="w-full" placeholder="Identifier (internal)" value={service.identifier} onChange={onChange} />
          <div className="w-full flex items-center relative">
            <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
              <span className="grow w-max text-sm text-base-400">Base Price</span>
            </div>
            <input name="basePrice" min={0} type="number" className="text-right w-full" placeholder="-" value={service.basePrice} onChange={onChange} />
          </div>
        </div>
        <footer className="bg-base-900 not-themed">
          <button onClick={onCancel} className="button-dark">
            Cancel
          </button>
          <button onClick={onSubmit} disabled={submitting || !service.identifier || !service.title} className="button-primary">
            Add
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default AddItemServiceModal
