import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import ModalRoutes from '../../components/ModalRoutes'
import AddContactPage from '../../pages/app/Contacts/pages/Add'
import PendingContacts from '../../pages/app/Contacts/pages/Pending'
import MainLayout from '../../layouts/Main'
import useUserContext from 'contexts/User/useUserContext'
import FullpageLoader from 'components/loaders/FullpageLoader'
import NotificationsPage from 'pages/app/Notifications'
import AmazonConnectModal from 'pages/app/modals/AmazonConnect'
import SubscribeModal from 'pages/app/modals/Subscribe'
import AdminDashboard from 'pages/app/admin/Dashboard'
import AdminDistOrderPage from 'pages/app/admin/DistOrder'
import AdminShipmentsPage from 'pages/app/admin/Shipments'

const AdminRouter = () => {
  const user = useUserContext()
  const location = useLocation()
  const background = location.state && location.state.background

  document.querySelector('html')?.classList.toggle('dark', localStorage.getItem('darkMode') === 'true')

  if (!user) return <FullpageLoader />

  return (
    <>
      <Routes location={background || location}>
        <Route path="*" element={user.initialSetupCompleted ? <MainLayout /> : <Navigate to="/app/setup" replace />}>
          <Route index element={<Navigate to="/app/dashboard" replace />} />
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="shipments" element={<AdminShipmentsPage />} />
          <Route path="dist-orders/:id" element={<AdminDistOrderPage />} />
        </Route>
      </Routes>
      <ModalRoutes>
        <Route path="/contacts/add" element={<AddContactPage />} />
        <Route path="/contacts/pending" element={<PendingContacts />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/settings/amazon/connect" element={<AmazonConnectModal />} />
        <Route path="/subscription/required" element={<SubscribeModal />} />
      </ModalRoutes>
    </>
  )
}

export default AdminRouter
