import axios from 'api/axios'
import { AxiosInstance } from 'axios'
import { IDistributionOrder } from 'types/admin'
import { IChat } from 'types/chats'

class DistributionApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }
  
  async getDistributionOrders({page = 0}: {page: number}) {
    return this.axios.get('/dist-orders?page='+page).then((res) => res.data as {orders: IDistributionOrder[], page: number, pages?: number})
  }

  async getDistributionOrder(id: string) {
    return this.axios.get('/dist-orders/'+id).then((res) => res.data.order as IDistributionOrder)
  }

  async putFulfillable(orderId: string, itemId: string, fulfillable: number) {
    return this.axios.put('/dist-orders/'+orderId+'/'+itemId+'/fulfillable', {fulfillable})
  }

  async putDistOrderSkus(orderId: string, itemId: string, sku: {
        sku: string
        qty: number,
        bundle: number,
        toStore: number
      }[]) {
    return this.axios.put('/dist-orders/'+orderId+'/'+itemId+'/skus', {sku})
  }

  async putNotes(id: string, notes: string) {
    return this.axios.put('/dist-orders/'+id+'/notes', {notes})
  }

  async distArrived(id: string) {
    return this.axios.post('/dist-orders/'+id+'/arrived')
  }

  async getChatForOrder(id: string) {
    return this.axios.get('/dist-orders/'+id+'/chat').then((res) => res.data.chat as IChat)
  }
}

const distributionApi = new DistributionApi()

export default distributionApi
