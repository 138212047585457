import api from 'api'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'
import { useNavigate } from 'react-router'

const ClientSubModal: AwaitableModal = ({ open, onCancel, resolve }) => {
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()

  const onPayForClient = () => {
    if (submitting) return
    setSubmitting(true)
    api.makeClientPayForSub().then(() => window.location.reload()).finally(() => setSubmitting(false))
  }

  const onSubscribe = () => {
    navigate('/app/subscription')
  }

  return (
    <Modal open={open} close={onCancel}>
      <div className="modal w-[512px] relative">
        <header className="items-center flex">
          <h1>You're missing a subscription</h1>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={resolve} />
        </header>
        <div className="w-full text-slate-400 text-sm font-medium text-center">
          To use the software, your clients will need to be subscribe. You can either have them pay for their own subscription, or you can pay for them at discounted rates.
        </div>
        <footer className="items-center flex justify-between">
          <button disabled={submitting} className="button-secondary" onClick={onPayForClient}>
            Client Paid
          </button>
          <button disabled={submitting} className="button-primary" onClick={onSubscribe}>
            Subscribe
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default ClientSubModal
