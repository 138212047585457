import api from 'api'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'
import { handleError } from 'helpers/errors'
import { IPendingEmployee } from 'types/employee'

const InviteEmployeeModal: AwaitableModal<IPendingEmployee> = ({ open, resolve }) => {
  const [result, setResult] = useState<IPendingEmployee>()
  const [sending, setSending] = useState(false)
  const [name, setName] = useState('')
  const [position, setPosition] = useState('')
  const [email, setEmail] = useState('')

  const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

  const onResolve = () => {
    resolve(result)
  }

  const handleSubmit = () => {
    if (sending) return
    let error
    if (!name) error = 'Please enter the employee name'
    if (!position) error = 'Please enter your employees position'
    else if (!email) error = 'Please enter an email address'
    else if (!emailValid) error = 'Please enter a valid email address'
    if (error) return handleError(error)

    setSending(true)
    api
      .inviteEmployee(name, email, position)
      .then(([created]) => {
        setResult({
          name,
          email,
          position,
          createdAt: created.created_at,
          id: created.id,
          pending: true,
        })
      })
      .catch(() => {
        handleError('An error occurred while sending the invitation')
      })
      .finally(() => {
        setSending(false)
      })
  }
  return (
    <Modal open={open} close={onResolve}>
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>{result ? 'Invitation Sent' : 'Invite an Employee'}</h1>
            <h3>{result ? 'The employee will receive an email' : 'Please enter the details to continue.'}</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onResolve} />
        </header>
        {!result && (
          <>
            <div className="w-full">
              <input type="text" placeholder="Employee Name" className="w-full" value={name} onChange={(e) => setName(e.currentTarget.value)} />
              <input type="text" placeholder="Email Address" className="w-full" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
              <input type="text" placeholder="Position" className="w-full" value={position} onChange={(e) => setPosition(e.currentTarget.value)} />
            </div>
            <footer className="items-center">
              <button disabled={sending} className="button-primary" onClick={handleSubmit}>
                Send Invitation
              </button>
            </footer>
          </>
        )}
      </div>
    </Modal>
  )
}

export default InviteEmployeeModal
