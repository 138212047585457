import { FC } from 'react'
import { Outlet } from 'react-router'

const ContactsPage: FC = () => {
  return (
    <div className="flex flex-col grow">
      <div className="flex w-full grow overflow-hidden">
        <Outlet />
      </div>
    </div>
  )
}

export default ContactsPage
