import axios from 'api/axios'
import { AxiosInstance } from 'axios'

class ProfileApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }
  
  async updateProfilePicture(image: string) {
    return this.axios.put('/profile/profile-picture', {image}).then((res) => res.data as {image: string})
  }
  
  async updateContactInfo(data: {name: string, phone: string}) {
    return this.axios.put('/profile/contact', data).then((res) => res.data as {name: string, phone: string})
  }
}

const profileApi = new ProfileApi()

export default profileApi
