import Icon from 'components/Icon'
import { FC } from 'react'

const PrepFeaturesPage: FC = () => {
  return (
    <div className="flex flex-col">
      <div className="flex w-screen py-32 px-32 bg-black-orange items-center">
        <Icon type="bulk" name="speedometer" className="fill-orange-500 h-full grow shrink blur-[256px]" />
        <div className="flex flex-col w-max">
          <span className="text-white font-normal text-[4rem] leading-[6rem] mb-2 text-center">
            For <span className="text-orange-500">Prep Center</span>
          </span>
          <span className="text-white-700 text-base text-center mb-16">Enjoy managing your prep business like never before.</span>
          <a className="button-orange" href="#getstarted">
            Get Started Today
          </a>
        </div>
        <Icon type="bulk" name="speedometer" className="fill-orange-500 h-full grow shrink blur-[256px]" />
      </div>
      <div className="flex w-screen overflow-hidden relative w-screen justify-between">
        <div className="w-[624px] flex flex-col shrink-0 mr-16 px-24 ml-32 pt-[248px] border-l-[1.5px] border-l-orange-500 justify-center">
          <span className="font-normal text-[2.5rem] leading-[3.75rem] text-white mb-8">
            <span className="text-orange-500">Why Prep Centers</span>
            <br /> Work With Us
          </span>
          <span className="text-white font-normal text-base">
            We interviewed numerous prep centers to understand their operations. Many still rely on shared Google Sheets, causing inefficiencies and wasting valuable employee time. Our API-based
            Straight to Amazon connection enables the generation of various labels for all clients within a user-friendly interface, making it easy for new hires to adapt.
          </span>
        </div>
        <div className="hidden lg:flex">
          <img src={require('assets/images/promo/prep/warehouse.png')} className="w-full !aspect-square object-contain object-right-top" />
        </div>
      </div>
      <div className="flex flex-row-reverse w-screen overflow-hidden relative w-screen justify-between">
        <div className="w-[624px] flex flex-col shrink-0 ml-16 px-24 mr-32 pt-[248px] border-r-[1.5px] border-r-orange-500 justify-center">
          <span className="font-normal text-[2.5rem] leading-[3.75rem] text-white mb-8">
            <span className="text-orange-500">Grow Smoother</span>
            <br /> and Faster
          </span>
          <span className="text-white font-normal text-base">
            Existing clients often face challenges in scaling their businesses due to financial constraints, logistics management, and comfortability. While we cannot directly provide them with more
            money, we have developed a seamless workflow that tracks their orders from purchase order placement to outbound shipping, allowing them to focus on high-leverage activities that generate
            more revenue and confidently scale their businesses. Resulting in more business back to you.{' '}
          </span>
        </div>
        <div className="hidden lg:flex">
          <img src={require('assets/images/promo/prep/packaging.png')} className="w-full !aspect-square object-contain object-right-top" />
        </div>
      </div>
      <div className="flex w-screen overflow-hidden relative w-screen justify-between">
        <div className="w-[624px] flex flex-col shrink-0 mr-16 px-24 ml-32 pt-[248px] border-l-[1.5px] border-l-orange-500 justify-center">
          <span className="font-normal text-[2.5rem] leading-[3.75rem] text-white mb-8">
            <span className="text-orange-500">Your Clients</span>
            <br /> Your Branding
          </span>
          <span className="text-white font-normal text-base">
            Our white label service caters specifically to prep centers, providing them with a customized solution. Through our service, prep centers can have their logo, branding, and pricing
            integrated into a secure and exclusive portal accessible only to their clients. This closed-end portal ensures privacy and confidentiality while offering a seamless and personalized
            experience.
          </span>
        </div>
        <div className="hidden lg:flex">
          <img src={require('assets/images/promo/prep/branded.png')} className="w-full !aspect-square object-contain object-right-top" />
        </div>
      </div>
      <div className="flex flex-row-reverse w-screen overflow-hidden relative w-screen bg-black-orange justify-between">
        <div className="w-[624px] flex flex-col shrink-0 ml-16 px-24 mr-32 pt-[248px] border-r-[1.5px] border-r-orange-500 justify-center">
          <span className="font-normal text-[2.5rem] leading-[3.75rem] text-white mb-8">
            <span className="text-orange-500">Billing</span>
            <br /> Automated
          </span>
          <span className="text-white font-normal text-base">
            Billing for prep centers doesn't have to be complex. We recognize the various factors involved, and our system is specifically designed to comprehend and capture every charge and material,
            effectively communicating that information to your clients. Our automated billing features have proven to save countless hours for our customers, streamlining the billing process and
            ensuring accuracy and efficiency.
          </span>
        </div>

        <div className="hidden lg:flex">
          <img src={require('assets/images/promo/prep/earnings.png')} className="w-full !aspect-square object-contain object-top-right" />
        </div>
      </div>
      <div className="p-32 flex" id="getstarted">
        <div className="bg-white-50 p-16 rounded-xl grow flex flex-col items-center relative">
          <Icon type="bulk" preserveAspectRatio="none" name="speedometer" className="absolute w-64 top-0 object-cover -left-48 fill-orange-500 h-full grow shrink blur-[256px] z-10" />
          <Icon type="bulk" preserveAspectRatio="none" name="speedometer" className="absolute w-64 top-0 -right-48 fill-orange-500 h-full grow shrink blur-[256px] z-10" />
          <span className="font-normal text-[2.5rem] leading-[3.75rem] text-white text-center">
            <span className="text-orange-500">You Grow</span> or You Don't Pay
          </span>
          <span className="text-white-700 text-base text-center mb-16">We are here to provide an unbeatable value.</span>
          <button className="button-orange w-max" onClick={() => window.open("https://calendly.com/apex-management", "_blank")}>
            Book a Setup Call
          </button>
        </div>
      </div>
      <div className="px-32 flex flex-col items-center w-full gap-8">
        <div className="rounded-xl bg-white-50 p-8 w-[560px]">
          <span className="font-medium text-2xl text-white">How It Works</span>
        </div>
        <img className="w-[560px]" src={require('assets/images/promo/prep/howitworks.png')} />
        <img className="w-[560px]" src={require('assets/images/promo/prep/designedfor.png')} />
      </div>
      <div className="flex w-screen py-32 px-32 bg-black-orange justify-center relative">
        <Icon type="bulk" name="speedometer" preserveAspectRatio="none" className="fill-orange-500 blur-[256px] pointer-events-none top-0 h-full w-full absolute -left-1/2 scale-x-[0.5]" />
        <div className="flex flex-col w-full bg-white-50 rounded-xl p-16 gap-24 z-10">
          <div className='flex items-start gap-16'>
            <Icon  size={64} name="quote-up" className="fill-orange-500 shrink-0" />
            <p className="text-2xl font-normal text-white">
              Apex Logistics has completely transformed our prep center and left our clients delighted! With its advanced features and seamless functionality, we have experienced remarkable
              improvements in our operations and client satisfaction.
              <br />
              <br />
              By adopting Apex Logistics, our tasks have become more efficient, saving us valuable time and resources. The software's streamlined processes and accurate billing have eliminated errors
              and accelerated payments, boosting our bottom line.
              <br />
              <br />
              But the benefits extend beyond our own operations. Our clients have experienced enhanced convenience and transparency, thanks to Apex Logistics' intuitive tracking, real-time updates,
              and comprehensive reporting. It has empowered them to run their businesses more smoothly and make informed decisions.
              <br />
              <br />
              Apex Logistics has truly elevated our prep center to new heights, revolutionizing our workflow and fostering stronger relationships with our clients.
            </p>
          </div>
          <div className="flex items-center gap-8">
            <img src={require('assets/images/promo/seller/tony.png')} className="h-24 w-24" />
            <div className='gap-2'>
            <p className="text-2xl font-normal text-white">
              Sam
            </p>
            <p className='text-base font-medium text-white-700'>New York Prep Center Owner</p>
            </div>
          </div>
        </div>
        <Icon type="bulk" name="speedometer" preserveAspectRatio="none" className="fill-orange-500 blur-[256px] pointer-events-none top-0 h-full w-full absolute -right-1/2 scale-x-[0.5]" />
      </div>
    </div>
  )
}

export default PrepFeaturesPage
