import { useElements, useStripe } from '@stripe/react-stripe-js'
import { FC, useState } from 'react'

interface props {
  onSuccess: () => void
}

const Submit: FC<props> = ({ onSuccess }) => {
  const [sending, setSending] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const onClick = async () => {
    if (!stripe || !elements) return
    if (sending) return
    setSending(true)
    const { error } = await stripe
      .confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: 'https://apexmgt.us/app/settings/billing',
        },
      })
      .finally(() => setSending(false))
  }

  return (
    <button disabled={sending} className="button-primary w-full" onClick={onClick}>
      Connect
    </button>
  )
}

export default Submit
