import Header from 'components/layout/Header'
import { FC, useState } from 'react'
import api from 'api'
import useUserContext from 'contexts/User/useUserContext'
import { VectorLogo } from 'assets'
import Modal from 'components/Modal'
import { TickIcon } from 'assets/icons'

const pricing = [
  { to: 5, price: 0 },
  { to: 15, price: 149.99 },
  { to: 30, price: 299.99 },
  { to: 49, price: 499.99 },
  { to: 75, price: 749.99 },
  { to: 100, price: 999.99 },
  { to: 149, price: 1499.99 },
  { price: 1999.99 },
]

const SubscriptionSettingsPage: FC = () => {
  const [submitting, setSubmitting] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const user = useUserContext()

  const onSubscribe = () => {
    if (submitting) return
    setSubmitting(true)
    api
      .subscribeClients()
      .then((url) => {
        window.open(url, '_self')
      })
      .finally(() => setSubmitting(false))
  }

  const cancelSubscription = () => {
    setCancelling(false)
    if (submitting) return
    setSubmitting(true)
    api
      .subscribeClients()
      .then((url) => {
        window.open(url, '_self')
      })
      .finally(() => setSubmitting(false))
  }

  const currentTier = pricing.reduce((tier: number | undefined, { to }, i) => {
    if (tier !== undefined) return tier
    if (to !== undefined ? (user?.sellerCount || 0) < to + 1 : true) {
      return i
    }
    return tier
  }, undefined)

  return (
    <>
      <Modal close={() => setCancelling(false)} open={cancelling}>
        <div className="flex flex-col gap-4 p-8">
          <h2 className="text-2xl font-semibold">Cancel Subscription</h2>
          <p className="text-base">Are you sure you want to cancel your subscription? Your clients will lose their subscription</p>
          <div className="flex gap-4">
            <button className="button-primary" onClick={cancelSubscription}>
              Yes, Cancel
            </button>
            <button className="button-dark" onClick={() => setCancelling(false)}>
              No, Keep Subscription
            </button>
          </div>
        </div>
      </Modal>
    <div className="rounded-2xl bg-white flex flex-col divide-y divide-y-base-200 overflow-y-auto w-max">
          <header className="bg-primary-50 p-12 flex items-center justify-center">
            <VectorLogo height={48} />
          </header>
          <div className="overflow-y-scroll">
            <div className="flex flex-col h-full divide-y divide-y-slate-200">
              {pricing.map((p, i) => (
                <div key={i + p.price} className={['text-sm font-semibold flex gap-2 justify-start p-4 items-center', currentTier === i ? 'bg-primary-100' : ''].asClass}>
                  {currentTier === i && user?.subscription && <TickIcon className="fill-primary-600" />}
                  <span className="text-slate-400 grow">
                    Clients {pricing.at(i - 1)?.to ? (pricing.at(i - 1)?.to as number) + 1 : 0}
                    {p.to ? ` - ${p.to}` : '+'}
                  </span>
                  <span className="text-slate-900 ml-14">{p.price ? `$${p.price.toFixed(2)}` : 'Free'}</span>
                </div>
              ))}
            </div>
          </div>
          <footer className="p-6 text-sm font-medium text-slate-400 text-center justify-center flex">
            {user?.subscription || true ? (
              <button disabled={submitting} className="button-dark" onClick={() => setCancelling(true)}>
                Unsubscribe
              </button>
            ) : (
              <div className="flex gap-6 items-center flex-col justify-center">
                <p className="text-slate-400 font-medium">Subscription price will be automatically upgraded and downgraded</p>
                <button disabled={submitting} className="button-primary" onClick={() => onSubscribe()}>
                  Subscribe
                </button>
              </div>
            )}
          </footer>
        </div>
    </>
  )
}

export default SubscriptionSettingsPage
