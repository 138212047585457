import { useState, useEffect, useCallback } from 'react'

const useWindowDrag = () => {
  const [isDragging, setIsDragging] = useState(false)

  const onDragOver = useCallback((e: DragEvent) => {
    setIsDragging(true)
  }, [])

  const onDragLeave = useCallback((e: DragEvent) => {
    if (e.clientX > 0 && e.clientX < document.body.clientWidth && e.clientY > 0 && e.clientY < document.body.clientHeight) {
    } else {
      setIsDragging(false)
    }
  }, [])

  const onDrop = useCallback((e: DragEvent) => {
    setIsDragging(false)
  }, [])

  useEffect(() => {
    document.addEventListener('dragenter', onDragOver)
    document.addEventListener('dragover', onDragOver)
    document.addEventListener('dragleave', onDragLeave)
    document.addEventListener('drop', onDrop)

    return () => {
      document.removeEventListener('dragenter', onDragOver)
      document.removeEventListener('dragover', onDragOver)
      document.removeEventListener('dragleave', onDragLeave)
      document.removeEventListener('drop', onDrop)
    }
  }, [onDragLeave, onDragOver, onDrop])

  return isDragging
}

export default useWindowDrag
