import Empty from 'components/Empty'
import TableSpanner from 'components/TableSpanner'
import FullLoader from 'components/loaders/FullLoader'
import useUserContext from 'contexts/User/useUserContext'
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'
import OrderItem from './OrderItem'
import { IOrder } from 'types/orders'
import Icon from 'components/Icon'
import getDoneCountFromBoxes from '../../helpers/getDoneFromBoxes'
import useAwaitableModal from 'hooks/useAwaitableModal'
import MultiFNSKUModal from '../../modals/MultiFNSKUModal'
import { IService } from 'types/services'
import { InboxIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import { printFNSKULabels, printFNSKULabelsByConfig } from 'helpers/labels'
import ordersApi from 'api/orders'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@material-tailwind/react'
import { useOrder } from 'contexts/Order'
import { twoByOneUsers } from 'constants/labels'

const OrderItems: FC<{ availableItemServices?: IService[] }> = ({ availableItemServices }) => {
  const { order, setOrder, frozen, workable } = useOrder()
  const user = useUserContext()
  const items = order?.itemsData
  const doneCounts = getDoneCountFromBoxes(order.shipmentData?.boxes || [])
  const [search, setSearch] = useState('')
  const [storing, setStoring] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const someArrived = items?.some((item) => (item.actual + (item.storage?.qty || 0)) > 0)
  const allArrivedStored = items?.every((item) => !item.actual)
  const navigate = useNavigate()

  const showHandled = someArrived && allArrivedStored && user?.isPrepCenter

  const inbound = !order.shipmentData

  const [requestMultiFnskuBase, MultiFNSKU] = useAwaitableModal(MultiFNSKUModal, {
    itemsData: items?.map((item) => ({ ...item, done: doneCounts[item.sku] || 0 })),
    type: order.shipmentData?.type === 'LTL' ? ('LTL' as const) : ('SPD' as const),
    orderId: order.id,
    shipmentId: order.shipmentData?.shipmentId || '',
  })

  const requestMultiFnsku = useCallback(
    () =>
      requestMultiFnskuBase({
        itemsData: items?.map((item) => ({ ...item, done: doneCounts[item.sku] || 0 })),
        type: order.shipmentData?.type === 'LTL' ? ('LTL' as const) : ('SPD' as const),
        orderId: order.id,
        shipmentId: order.shipmentData?.shipmentId || '',
      }),
    [items, order, doneCounts, requestMultiFnskuBase]
  )

  const setItems: Dispatch<SetStateAction<IOrder['itemsData'] | undefined>> = (items) => {
    if (!order) return
    if (typeof items === 'function')
      return setOrder((old) => {
        const transformed = items(old?.itemsData)
        if (!transformed || !old) return old
        return { ...old, itemsData: transformed }
      })
    items && setOrder((old) => old && { ...old, itemsData: items })
  }

  const onMarkArrived = (sku: string, quantity: number, issue?: string, storage?: any) => {
    setItems((items) =>
      items?.map((item) =>
        item.sku === sku ? { ...item, actual: quantity - (storage?.qty || 0), storage, missingReasons: issue ? [{ explanation: issue, number: item.expected - quantity }] : [] } : item
      )
    )
    setOrder((order) => order && (order.state === 'SENT' ? { ...order, state: 'AT_PREP_CENTER' } : order))
  }

  const onUpdateServices = (sku: string, services: string[]) => {
    setItems((items) => items?.map((item) => (item.sku === sku ? { ...item, services } : item)))
  }

  const filteredItems = items?.filter(
    (item) =>
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.sku.toLowerCase().includes(search.toLowerCase()) ||
      item.fnsku.toLowerCase().includes(search.toLowerCase()) ||
      item.asin.toLowerCase().includes(search.toLowerCase())
  )

  const downloadAllFNSKUS = () => {
    if (twoByOneUsers.includes(user?.id || '')) {
      printFNSKULabels(
        order.itemsData.map((item) => ({
          fnsku: item.fnsku,
          name: item.name,
          quantity: item.actual || item.expected,
          condition: item.condition,
          expiryDate: item.expiryDate,
        }))
      )
      return
    }
    printFNSKULabelsByConfig(
      order.itemsData.map((item) => ({
        fnsku: item.fnsku,
        name: item.name,
        quantity: item.actual || item.expected,
        condition: item.condition,
        expiryDate: item.expiryDate,
      })),
      "USLetter30"
    )
  }

  const onHandleAndDelete = () => {
    if (deleting) return
    setDeleting(true)
    ordersApi.markStored(order.id).then(() => {
      navigate('/app/orders')
    })
  }

  return (
    <>
      <MultiFNSKU />
      <div className="p-6">
        <section className="box border border-base-200">
          <div className="relative border-b border-base-200">
            <Icon name="search-normal-1" className="fill-base-400 absolute pointer-events-none left-6 top-1/2 -translate-y-1/2" />
            <input className="border-0 bg-white w-full rounded-none outline-none p-6 pl-16" placeholder="Search Items..." value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
          </div>
          <div className="overflow-x-auto min-w-full overflow-y-visible">
            <table className="w-full rounded-t-[0px] overflow-visible">
              <thead>
                <tr>
                  <td>
                    <div className="flex items-center gap-2">
                      <span>Image</span>
                      {user?.isPrepCenter && <IconButton name="printer" className="!fill-primary-500" tooltip="Print All FNSKUs" tooltipLocation="right" onClick={downloadAllFNSKUS} />}
                    </div>
                  </td>
                  <td>Item Name</td>
                  <td>
                    <div className="flex flex-col text-xs">
                      <span>FNSKU</span>
                      <span>SKU</span>
                      <span>ASIN</span>
                    </div>
                  </td>
                  <td>Services</td>
                  {inbound ? (
                    <>
                      <td>Expected</td>
                      <td>Arrived</td>
                      <td>
                        <div className="flex items-center gap-5">
                        <div className="flex gap-4 items-center hover:text-primary-500 group cursor-pointer transition-all" onClick={() => setStoring((old) => !old)}>
                          <span>Store</span>
                          <InboxIcon className={storing ? 'stroke-primary-500' : 'stroke-base-950 group-hover:stroke-primary-500'} />
                        </div>
                          {showHandled && (
                            <Tooltip content="All Items Are in Your Inventory" placement="left">
                            <button className="button-secondary" onClick={onHandleAndDelete} disabled={deleting}>
                              Mark Handled
                            </button>
                            </Tooltip>
                          )}
                          </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>Quantity</td>
                      <td>Done</td>
                    </>
                  )}
                  {user?.isPrepCenter && order.state !== 'IN_PROGRESS' && workable && order.shipmentData?.shipmentId && (
                    <>
                      <td>Actions</td>
                      <td>
                        <button
                          onClick={() =>
                            requestMultiFnsku().then((box) =>
                              setOrder((old) => old && ({ ...old, shipmentData: { ...old?.shipmentData, boxes: [box, ...(old?.shipmentData?.boxes || [])] } } as IOrder))
                            )
                          }
                          className="border border-blue-500 flex gap-2 px-4 py-2 rounded-full items-center hover:bg-blue-50 transition-all"
                        >
                          <Icon name="3d-square" size={24} className="fill-blue-500" />
                          <span className="text-blue-500 font-medium text-sm w-max">Multi SKU Box</span>
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="min-h-full relative">
                {filteredItems?.map((item) => (
                  <OrderItem
                    key={item.sku}
                    item={item}
                    done={doneCounts[item.sku] || 0}
                    onMarkArrived={(quantity: number, explanation?: string, storage?: any) => onMarkArrived(item.sku, quantity, explanation, storage)}
                    onUpdateServices={(services: string[]) => onUpdateServices(item.sku, services)}
                    frozen={frozen}
                    availableItemServices={availableItemServices}
                    storing={storing}
                  />
                ))}
                {!filteredItems?.length && (
                  <TableSpanner rows={6} cols={6}>
                    {items === undefined ? <FullLoader /> : <Empty text={search ? 'No Results' : 'No Items'} />}
                  </TableSpanner>
                )}
                {}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </>
  )
}

export default OrderItems
