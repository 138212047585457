import useUserContext from 'contexts/User/useUserContext'
import { FC, useState } from 'react'
import { IOrder } from 'types/orders'
import AmazonProductImage from 'components/AmazonProductImage'
import IconButton from 'components/buttons/IconButton'
import useAwaitableModal from 'hooks/useAwaitableModal'
import AddServicesModal from '../../modals/AddServicesModal'
import LabelsModal from '../../modals/LabelsModal'
import { IItemService } from 'types/services'
import { apexItemServices } from 'constants/apexServices'
import { handleError } from 'helpers/errors'
import ordersApi from 'api/orders'
import { printFNSKULabels, printFNSKULabelsByConfig } from 'helpers/labels'
import Loader from 'components/loaders/Loader'
import { useOrder } from 'contexts/Order'
import { twoByOneUsers } from 'constants/labels'

const availableApexServices = (type: 'Wholesale' | 'OA') =>
  Object.entries(apexItemServices[type]).map(([key, val]) => ({
    id: key,
    title: val,
    created_at: new Date(),
    prepCenter: 'APEX',
    identifier: key,
    basePrice: 0,
  }))

const OrderItem: FC<{
  item: IOrder['itemsData'][0]
  availableItemServices?: IItemService[]
  onUpdateServices: (services: string[]) => void
  onMarkArrived: (quantity: number, issue?: string, storage?: any) => void
  frozen?: boolean
  done: number
  storing?: boolean
}> = ({ item, frozen, onUpdateServices, onMarkArrived, done, availableItemServices: _availableItemServices, storing }) => {
  const { order, workable } = useOrder()
  const availableItemServices = order.apexClient ? availableApexServices(order.apexWarehouseType || 'Wholesale') : _availableItemServices
  const user = useUserContext()
  const isSeller = user && !user.isPrepCenter
  const { name, asin, services: _services, actual, expected } = item

  const _arrived: number = (actual || 0) + (item.storage?.qty || 0)
  const stored = item.storage?.qty || 0

  const [sending, setSending] = useState(false)
  const [arrived, setArrived] = useState<number>(_arrived)
  const [storedQty, setStoredQty] = useState(stored)
  const [location, setLocation] = useState(item.storage?.location || '')

  const services = _services.filter((service) => availableItemServices?.find((i) => i.id === service))
  const [requestServices, ServiceModal] = useAwaitableModal(AddServicesModal, { services: item.services, availableItemServices, sku: item.sku, orderId: order.id })
  const [requestLabelModal, LabelsModalComponent] = useAwaitableModal(LabelsModal, { order, item })

  const inbound = !order.shipmentData

  const handleSubmit = () => {
    if (sending) return
    let error
    if (arrived === undefined) error = 'Please enter a valid quantity'
    if (error) return handleError(error)

    setSending(true)
    ordersApi
      .markArrived(order.id, item.sku, arrived, undefined, storing ? { qty: storedQty, location } : undefined)
      .then(() => {
        onMarkArrived(arrived, '', { qty: storedQty, location })
      })
      .catch(() => {
        setArrived(_arrived)
        setStoredQty(stored)
        setLocation(item.storage?.location || '')
        handleError('Error marking arrived, sku: ' + item.sku)
      })
      .finally(() => {
        setSending(false)
      })
  }

  const downloadFNSKU = () => {
    if (twoByOneUsers.includes(user?.id || '')) {
      printFNSKULabels(
        [{
        fnsku: item.fnsku,
        name: item.name,
        quantity: item.actual || item.expected,
        condition: item.condition,
        expiryDate: item.expiryDate,
      }]
      )
      return
    }
    printFNSKULabelsByConfig([
      {
        fnsku: item.fnsku,
        name: item.name,
        quantity: item.actual || item.expected,
        condition: item.condition,
        expiryDate: item.expiryDate,
      },
    ],
      "USLetter30"
    )
  }

  return (
    <>
      <ServiceModal />
      <LabelsModalComponent />
      {sending && <div className="absolute inset-0 bg-gray-100 bg-opacity-50 flex items-center justify-center z-50"><Loader /></div>}
      <tr>
        <td>
          <div className="flex items-center gap-2">
            <AmazonProductImage
              size={64}
              imageSize={128}
              asin={asin}
              className="object-cover aspect-square w-8 max-w-8 transition-all rounded-md cursor-pointer hover:scale-[1.1] hover:drop-shadow-md"
            />
            {user?.isPrepCenter && <IconButton name="printer" className="!fill-primary-500" tooltip="Print FNSKUs" tooltipLocation="right" onClick={downloadFNSKU} />}
          </div>
        </td>
        <td className="select-text max-w-[200px] truncate" title={name}>
          {name}
        </td>
        <td>
          <div className="flex flex-col gap-0.5">
            <span className="text-xs select-text">{item.fnsku}</span>
            <span className="text-xs select-text">{item.sku}</span>
            <span className="text-xs select-text">{item.asin}</span>
          </div>
        </td>
        {user?.isPrepCenter && !frozen ? (
          <>
            <td className="cursor-pointer" onClick={() => requestServices().then(({ services }) => services && onUpdateServices(services))}>
              {services?.length ? (
                <div className="flex flex-col space-y-2 -my-2">
                  {services?.map((service) => (
                    <span key={service} className="tag bg-blue-100 text-blue-500">
                      {availableItemServices?.find((i) => i.id === service)?.title}
                    </span>
                  ))}
                </div>
              ) : (
                <div className="flex items-center w-max">
                  <span className="tag tag-orange w-max">Only FNSKU +</span>
                </div>
              )}
            </td>
          </>
        ) : (
          <td>
            {services?.length ? (
              <div className="flex flex-col space-y-2 -my-2">
                {services?.map((service) => (
                  <span key={service} className="tag tag-blue">
                    {availableItemServices?.find((i) => i.id === service)?.title}
                  </span>
                ))}
              </div>
            ) : (
              <div className="flex items-center w-max">
                <span className="tag tag-orange w-max">Only FNSKU</span>
              </div>
            )}
          </td>
        )}
        {inbound ? (
          <>
            <td>{expected}</td>
            <td>
              {isSeller ? (
                arrived
              ) : (
                <input type="number" placeholder="Enter Quantity..." className="input w-20" value={arrived} onChange={(e) => setArrived(Number(e.target.value))} onBlur={handleSubmit} />
              )}
            </td>
            {storing || isSeller ? (
              <>
                <td>
                  <div className="flex gap-4">
                    {isSeller ? (
                      stored ? (
                        <span>{stored} Stored</span>
                      ) : (
                        <span>None</span>
                      )
                    ) : (
                      <>
                        <input type="number" placeholder="Enter Quantity..." className="input w-20" value={storedQty} onChange={(e) => setStoredQty(Number(e.target.value))} onBlur={handleSubmit} />
                        <input type="text" placeholder="Enter Location..." className="input w-20" value={location} onChange={(e) => setLocation(e.target.value)} onBlur={handleSubmit} />
                      </>
                    )}
                  </div>
                </td>
              </>
            ) : (
              <>
                <td></td>
              </>
            )}
          </>
        ) : (
          <>
            <td>{actual}</td>
            <td>{done}</td>
          </>
        )}
        {user?.isPrepCenter &&
          (order.shipmentData?.shipmentId && workable ? (
            <td>
              <div className="flex gap-2 items-center">
                <IconButton
                  name="barcode"
                  onClick={() =>
                    requestLabelModal({order, item})
                  }
                />
                <IconButton
                  name="box-1"
                  onClick={() =>
                    requestLabelModal({ step: 1, order, item })
                  }
                />
              </div>
            </td>
          ) : (
            <></>
          ))}
      </tr>
    </>
  )
}

export default OrderItem
