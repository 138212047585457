import adminApi from 'api/admin'
import Paginator from 'components/Paginator'
import Header from 'components/layout/Header'
import { FC, useEffect, useState } from 'react'
import { IDistributionOrder } from 'types/admin'
import DistOrder from './components/DistOrder'
import TableSpanner from 'components/TableSpanner'
import Empty from 'components/Empty'
import FullLoader from 'components/loaders/FullLoader'

const AdminDashboard: FC = () => {
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>()
  const [distOrders, setDistOrders] = useState<IDistributionOrder[]>()
  const [loading, setLoading] = useState<number | undefined>()

  useEffect(() => {
    setLoading(page)
    setDistOrders(undefined)
    adminApi.getDistributionOrders({page: page}).then((res) => {
        res.pages !== undefined && setTotal(res.pages)
        setDistOrders(res.orders)
    })
    .finally(() => setLoading(undefined))
  }, [page, total])

  useEffect(() => {
    const isFirstOpen = localStorage.getItem("firstOpen") === "true"
    if (isFirstOpen) {
      localStorage.setItem("firstOpen", "false")
    }
  }, [])

  const onDelete = (order: IDistributionOrder) => {
    setDistOrders((old) => old?.filter((o) => o.id !== order.id))
  }

  return (
    <div className="flex flex-col grow overflow-hidden">
      <Header
        title="Dashboard"
      />
      <div className="grow flex flex-col overflow-hidden pb-6">
        <header className="flex justify-between items-center z-[1000]">
          <div className="flex gap-1 flex-col">
          <span>CEO Dashboard</span>{/*<Link to="/app/ordersv0" className="text-xs font-semibold text-primary-600">Shipments V0</Link>*/}
          </div>
        </header>
        <div className="flex flex-col grow overflow-y-auto mb-5 px-6 gap-5">
          <table className="overflow-hidden grow">
            <colgroup>
              <col className="w-[200px]" />
              <col />
              <col />
              <col className="w-[120px]" />
            </colgroup>
            <thead>
              <tr>
                <td>Name</td>
                <td>Sent At</td>
                <td>UPCs</td>
                <td>Total Units</td>
                <td>Status</td>
                <td>Progress</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {distOrders?.map((order) => (
                <DistOrder key={order.id} order={order} onDelete={onDelete} />
              ))}
              {!distOrders?.length && (
                <TableSpanner rows={4} cols={7}>
                  {distOrders === undefined ? <FullLoader /> : <Empty text="No Shipments" />}
                </TableSpanner>
              )}
              </tbody>
          </table>
        <Paginator page={page} setPage={setPage} loading={loading !== undefined} total={total || 0} />
      </div>
    </div>
    </div>
  )
}

export default AdminDashboard
