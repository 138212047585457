import { FC } from "react";

interface props {
    checked?: boolean
}

const Radio: FC<props> = ({checked}) => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.5" width="15" height="15" rx="7.5" fill="white"/>
            {checked && <circle cx="8.25" cy="8" r="2" fill="#E11D48"/>}
            <rect x="0.75" y="0.5" width="15" height="15" rx="7.5" stroke="#E2E8F0"/>
        </svg>
    )
}

export default Radio