import { useEffect } from "react"

const useHideSupportChat = () => 
  useEffect(() => {
    const styleSheet = new CSSStyleSheet()
    styleSheet.replaceSync(`
      #drift-frame-controller {
        display: none !important;
      }
    `)
    document.adoptedStyleSheets = [...document.adoptedStyleSheets, styleSheet]
    return () => {
      document.adoptedStyleSheets = document.adoptedStyleSheets.filter((s) => s !== styleSheet)
    }
  }, [])

export default useHideSupportChat
