import { FC } from "react";
import Loader from "./Loader";

interface props {
  size?: number
  colorClass?: string
  className?: string
}

const FullLoader: FC<props> = ({className, ...props}) => {
    return (
      <div className={["w-full h-full grow flex items-center justify-center", className].asClass}>
        <Loader {...props} />
      </div>
    )
}

export default FullLoader
