import { FC, useLayoutEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const BillingSwitch: FC = () => {
  const location = useLocation()
  const pathName = location.pathname
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const selected = pathName === '/app/billing' ? 'bills' : pathName?.startsWith('/app/billing/invoices') ? 'invoices' : undefined

  useLayoutEffect(() => {
    ref.current?.style.setProperty("padding", "0px", "important");
    
  }, [ref])

  return (
      <header ref={ref} className="flex space-x-0 !justify-start">
        <button onClick={() => navigate('/app/billing')} className={selected === 'bills' ? 'text-primary-600 p-5 border-b-2 border-b-primary-600' : 'text-base-900 p-5'}>
          Outstanding bills
        </button>
        <button onClick={() => navigate('/app/billing/invoices')} className={selected === 'invoices' ? 'text-primary-600 p-5 border-b-2 border-b-primary-600' : 'text-base-900 p-5'}>
          Invoices
        </button>
      </header>
  )
}

export default BillingSwitch
