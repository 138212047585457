import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import ContactsPage from '../../pages/app/Contacts'
import ModalRoutes from '../../components/ModalRoutes'
import AddContactPage from '../../pages/app/Contacts/pages/Add'
import PendingContacts from '../../pages/app/Contacts/pages/Pending'
import MainLayout from '../../layouts/Main'
import useUserContext from 'contexts/User/useUserContext'
import FullpageLoader from 'components/loaders/FullpageLoader'
import ChatPage from 'pages/app/Contacts/pages/Chat'
import AddOrderModal from 'pages/app/Orders/CreateOrder'
import ContactList from 'pages/app/Contacts/pages/ContactList'
import PrepCenterDashboard from 'pages/app/Dashboard/PrepCenterDashboard'
import NotificationsPage from 'pages/app/Notifications'
import AccountSettingsPage from 'pages/app/Settings/pages/AccountSettings'
import AmazonAccountSettings from 'pages/app/Settings/pages/AmazonAccount'
import AmazonLoginSuccessPage from 'pages/app/Amazon/Success'
import SellerDashboard from 'pages/app/Dashboard/SellerDashboard'
import OrdersPage from 'pages/app/Orders'
import OrderPage from 'pages/app/Orders/Order'
import AddWarehousePage from 'pages/app/Warehouses/Add'
import AmazonConnectModal from 'pages/app/modals/AmazonConnect'
import StripeAccountSettings from 'pages/app/Settings/pages/StripeAccount'
import StripeLoginSuccessPage from 'pages/app/Stripe/Success'
import BillingPage from 'pages/app/Billing'
import BillingMethodsPage from 'pages/app/Settings/pages/BillingMethods'
import PaymentStructuresPage from 'pages/app/PaymentStructures'
import InvoicesPage from 'pages/app/Billing/Invoices'
import InvoicePage from 'pages/app/Billing/Invoice'
import { adminId } from 'constants/admin'
import InvoicesAllPage from 'pages/app/Billing/InvoicesAll'
import SettingsLayout from 'layouts/Settings'
import SubscriptionPage from 'pages/app/Subscription'
import SubscribeModal from 'pages/app/modals/Subscribe'
import WarehousesPage from 'pages/app/Warehouses'
import MyWarehousesPage from 'pages/app/Warehouses/My'
import StaffPage from 'pages/app/Staff'
import useHasRole from 'hooks/useHasRole'
import SellerInvoicesPage from 'pages/app/Billing/SellerInvoices'
import AgencyAccountSettings from 'pages/app/Settings/pages/AgencyAccount'
import PrepSubscriptionPage from 'pages/app/PrepSubscription'
import useCanShop from 'hooks/useCanShop'
import ShopPage from 'pages/app/Shop'
import CartPage from 'pages/app/Shop/pages/Cart'
import ShopOrderSuccessPage from 'pages/app/Shop/pages/Success'
import BasicSettingsPage from 'pages/app/Settings/pages/prepCenter/Basic'
import MerchantsSettingsPage from 'pages/app/Settings/pages/prepCenter/Merchants'
import WarehousesSettingsPage from 'pages/app/Settings/pages/prepCenter/Warehouses'
import SubscriptionSettingsPage from 'pages/app/Settings/pages/prepCenter/Subscription'
import OutboundPage from 'pages/app/Outbound'
import ProfileLayout from 'layouts/Profile'
import ProfileInfoSettingsPage from 'pages/app/Profile/ProfileInfo'
import SecuritySettingsPage from 'pages/app/Profile/Security'
import InventoryPage from 'pages/app/Inventory'
import UserInventoryPage from 'pages/app/Inventory/UserInventory'
import ShipFromInventory from 'pages/app/Inventory/Ship'
import InventoryLayout from 'layouts/Inventory'
import UserCataloguePage from 'pages/app/Inventory/UserCatalogue'
import CreatePrepOrderPage from 'pages/app/Orders/CreatePrepOrder'
import AdminRouter from './admin'
import DistOrderPage from 'pages/app/DistributionOrders/DistOrder'
import DistributionOrders from 'pages/app/DistributionOrders'
import OrderProvider from 'contexts/Order'

const AuthorizedRouter = () => {
  const user = useUserContext()
  const location = useLocation()
  const background = location.state && location.state.background
  const canBill = useHasRole('INVOICE')
  const notEmployee = useHasRole()
  const canShop = useCanShop()

  document.querySelector('html')?.classList.toggle('dark', localStorage.getItem('darkMode') === 'true')

  if (!user) return <FullpageLoader />

  if (user.id === adminId) {
    return <AdminRouter />
  }

  return (
    <>
      <Routes location={background || location}>
        <Route path="*" element={user.initialSetupCompleted ? <MainLayout /> : <Navigate to="/app/setup" replace />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          {(user.id === 'KydYecXlOOeVSABzkoRtv5UlzZj1' || user.distributionAccess) && (
            <Route path="dist-orders" element={<Outlet />}>
              <Route index element={<DistributionOrders />} />
              <Route path=":id" element={<DistOrderPage />} />
            </Route>
          )}
          {user.isPrepCenter ? (
            <>
              <Route path="dashboard">
                <Route index element={<PrepCenterDashboard />} />
              </Route>
              <Route path="orders" element={<Outlet />}>
                <Route path="create/:client" element={<CreatePrepOrderPage />} />
              </Route>
              {notEmployee && user.id !== 'KydYecXlOOeVSABzkoRtv5UlzZj1' && <Route path="subscription" element={<PrepSubscriptionPage />} />}
              {canBill && (
                <Route path="billing" element={<Outlet />}>
                  <Route index element={<BillingPage />} />
                  <Route path="invoices" element={<Outlet />}>
                    <Route index element={<Navigate to="/app/billing/invoices/draft" replace />} />
                    <Route path="draft" element={<InvoicesPage />} />
                    <Route path="sent" element={<InvoicesPage />} />
                    <Route path="paid" element={<InvoicesPage />} />
                    <Route path="invoice/:invoiceId" element={<InvoicePage />} />
                  </Route>
                </Route>
              )}
              <Route path="warehouses" element={<MyWarehousesPage />} />
              {canBill && <Route path="paymentStructures" element={<PaymentStructuresPage />} />}
              <Route path="warehouses">
                <Route path="add" element={<AddWarehousePage />} />
              </Route>
            </>
          ) : (
            <>
              <Route path="dashboard">
                <Route index element={<SellerDashboard />} />
              </Route>
              <Route path="subscription" element={<SubscriptionPage />} />
              {canShop && (
                <Route path="shop" element={<Outlet />}>
                  <Route index element={<ShopPage />} />
                  <Route path="cart" element={<CartPage />} />
                  <Route path="success" element={<ShopOrderSuccessPage />} />
                </Route>
              )}
              {user.referrer === adminId || !user.referrer ? (
                <>
                  <Route path="billing" element={<Outlet />}>
                    <Route path="invoices" element={<Outlet />}>
                      <Route index element={<InvoicesAllPage />} />
                      <Route path="sent" element={<Navigate to="/app/billing/invoices" replace />} />
                      <Route path=":prep" element={<Outlet />}>
                        <Route index element={<SellerInvoicesPage />} />
                        <Route path="invoice/:invoiceId" element={<InvoicePage />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="warehouses" element={<WarehousesPage />} />
                </>
              ) : (
                <Route path="billing" element={<Outlet />}>
                  <Route path="invoices" element={<Outlet />}>
                    <Route index element={<SellerInvoicesPage />} />
                    <Route path="invoice/:invoiceId" element={<InvoicePage />} />
                  </Route>
                </Route>
              )}
            </>
          )}
          {!user?.isPrepCenter || (user.isEmployee && !user.roles?.includes('ADMIN')) ? null : <Route path="staff" element={<StaffPage />} />}
          <Route path="contacts">
            <Route path="" element={<ContactsPage />}>
              <Route index element={<ContactList />} />
              <Route path="chat" element={<ChatPage />} />
            </Route>
          </Route>
          <Route path="outbound" element={<OutboundPage />} />
          <Route path="orders">
            <Route path="" element={<Outlet />}>
              <Route index element={<OrdersPage />} />
              <Route
                path=":orderId"
                element={
                  <OrderProvider>
                    <OrderPage />
                  </OrderProvider>
                }
              />
              <Route path="create" element={<AddOrderModal />} />
            </Route>
          </Route>
          <Route path="inventory" element={<InventoryLayout />}>
            <Route index element={<InventoryPage />} />
            <Route path="catalog" element={<UserCataloguePage />} />
          </Route>
          <Route path="inventory/:user/ship" element={<ShipFromInventory />} />
          <Route path="inventory/:user" element={<UserInventoryPage />} />
          <Route path="settings" element={<SettingsLayout />}>
            <Route index element={<Outlet />} />
            {user.isPrepCenter ? (
              <>
                <Route path="basic" element={<BasicSettingsPage />} />
                <Route path="merchants" element={<MerchantsSettingsPage />} />
                <Route path="warehouses" element={<WarehousesSettingsPage />} />
                <Route path="subscription" element={<SubscriptionSettingsPage />} />
              </>
            ) : (
              <>
                <Route path="profile" element={<ProfileInfoSettingsPage />} />
                <Route path="security" element={<SecuritySettingsPage />} />
                <Route path="management" element={<AgencyAccountSettings />} />
                <Route path="warehouses" element={<WarehousesSettingsPage />} />
                <Route path="subscription" element={<SubscriptionPage />} />
                <Route path="billing" element={<BillingMethodsPage />} />
                <Route path="amazon" element={<AmazonAccountSettings />} />
              </>
            )}
            <Route path="access" element={<AccountSettingsPage />} />
            <Route path="stripe" element={<StripeAccountSettings />} />
          </Route>
          {user.isPrepCenter && (
            <Route path="profile" element={<ProfileLayout />}>
              <Route index element={<Navigate to="/app/profile/profile" replace />} />
              <Route path="profile" element={<ProfileInfoSettingsPage />} />
              <Route path="security" element={<SecuritySettingsPage />} />
            </Route>
          )}
          <Route path="reports" element={<div className="flex h-full items-center justify-center w-full text-2xl font-semibold">Coming Soon</div>} />
          <Route path="other" element={<div>Other page</div>} />
          <Route path="amazon" element={<Outlet />}>
            <Route path="success" element={<AmazonLoginSuccessPage />} />
            <Route path="products" element={<div>Amazon products</div>} />
          </Route>
          <Route path="stripe" element={<Outlet />}>
            <Route path="auth" element={<StripeLoginSuccessPage />} />
            <Route path="refresh" element={<StripeLoginSuccessPage />} />
          </Route>
          <Route path="*" element={<div>not found</div>} />
        </Route>
      </Routes>
      <ModalRoutes>
        <Route path="/contacts/add" element={<AddContactPage />} />
        <Route path="/contacts/pending" element={<PendingContacts />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/settings/amazon/connect" element={<AmazonConnectModal />} />
        <Route path="/subscription/required" element={<SubscribeModal />} />
      </ModalRoutes>
    </>
  )
}

export default AuthorizedRouter
