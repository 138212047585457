import { IOrder } from "types/orders";

const getDataByState = (state: IOrder["state"], hasIssues: boolean, isPrepCenter: boolean, isPaid: boolean) => {
    if (hasIssues && ["IN_PROGRESS", "SENT"].includes(state) && !isPrepCenter) return ["Needs Attention", "tag-orange"];
    // if (state === "AT_PREP_CENTER" && !isPaid) return ["Not Paid", "bg-red-50 text-red-600"];
    switch (state) {
        case "IN_PROGRESS":
            return ["Ready", "tag-green"]
        case "SENT":
            return [isPrepCenter ? "Incoming" : "Sent", "tag-indigo"]
        case "AT_PREP_CENTER":
            return ["Ready for Prepping", "bg-base-100 text-base-400"]
        case "PREPPING":
            return ["Being Prepped", "tag-cyan"]
        case "SENT_TO_AMAZON":
            return ["Sent to Amazon", "bg-base-100 text-base-400"]
        case "HANDLED":
            return ["Handled", "bg-base-100 text-base-400"]
    }
}

const OrderStatusTag = ({ status, hasIssues, isPaid, isPrepCenter }: { status: IOrder["state"], hasIssues: boolean, isPaid: boolean, isPrepCenter: boolean }) => {
    const [formatted, className] = getDataByState(status, hasIssues, isPrepCenter, isPaid)
    return (
        <span className={`tag ${className}`}>
        {formatted}
        </span>
    );
}

export default OrderStatusTag;