import { userData } from 'App'
import api from 'api'
import Icon from 'components/Icon'
import useUserContext from 'contexts/User/useUserContext'
import formatAddress from 'helpers/formatAddress'
import { FC, useState } from 'react'
import { IWarehouse } from 'types/warehouses'

interface props {
  warehouse: IWarehouse
}

const ApexWarehouse: FC<props> = ({ warehouse }) => {
  const user = useUserContext()
  const [submitting, setSubmitting] = useState(false)

  const onMakeDefault = () => {
    if (!warehouse.id || !user) return
    setSubmitting(true)
    api
      .setDefaultWarehouse(warehouse.id)
      .then(() => {
        userData.current?.setUser({ ...user, defaultWarehouse: warehouse.id })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <section className="box bg-white">
      <header>{warehouse.contact?.name}</header>
      <div className="flex divide-x divide-x-base-200">
        <div className="flex p-6 items-center">
          <Icon type="bulk" name="building" size={48} />
        </div>
        <div className="gap-1 flex flex-col p-6 justify-center grow">
          <span className="text-sm font-medium text-base-900">Address</span>
          <span className="text-sm font-normal text-base-400">
            {formatAddress(warehouse.contact)}
          </span>
        </div>
        <div className="gap-1 flex flex-col p-6 justify-center">
          <span className="text-sm font-medium text-base-900">Typical Turn Around</span>
          <span className="text-sm font-normal text-base-400">{warehouse.data?.turnAround}</span>
        </div>
        <div className="gap-3 grid grid-cols-2 items-center p-6">
          {warehouse.data?.services?.map((service) => (
            <span className="text-[10px] font-medium h-min text-base-400 rounded-full bg-base-100 px-3 py-2 text-center">{service}</span>
          ))}
        </div>
        <div className="flex items-center p-6">
          {user?.defaultWarehouse === warehouse.id ? (
            <span className="text-xs font-medium text-primary-500 rounded-full bg-primary-100 px-4 py-2.5 text-center">Default Warehouse</span>
          ) : (
            <button disabled={submitting} onClick={onMakeDefault} className="button-secondary">
              Set as Default
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default ApexWarehouse
