import amazonItemImgUrl from "helpers/amazonItemImgUrl";
import useAwaitableModal from "hooks/useAwaitableModal";
import { FC } from "react";
import PreviewImageModal from "./PreviewImageModal";
import useAmazonProductImage from "hooks/useAmazonProductImage";
import Loader from "components/loaders/Loader";

interface props extends Omit<React.HTMLAttributes<HTMLImageElement>, "onClick" | "alt"> {
    asin: string,
    size: number,
    imageSize?: number,
}

const AmazonProductImage: FC<props> = ({asin, size, imageSize, style, ...props}) => {
    const [request, Modal] = useAwaitableModal(PreviewImageModal, {} as any)

     const image = useAmazonProductImage(asin)

     const url = amazonItemImgUrl(image?.url, imageSize || size, false)

     if (!url) return <Loader />

    return (
    <>
        <Modal />
        <img src={url} onClick={() => request({image})} alt={asin} style={{...style, width: size, height: size}} {...props} />
    </>
    )
}

export default AmazonProductImage