import Modal from "components/Modal";
import { FC, useEffect, useRef, useState } from "react";
import TOS from "./TOS";

interface props {
    onClose: () => void;
    open: boolean;
    onAccept: () => void;
}

const PrepTOSModal: FC<props> = ({onClose, onAccept, open}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [atBottom, setAtBottom] = useState<boolean>(false)

    useEffect(() => {
        if (!ref.current) return
        const e = ref.current
        const handleScroll = () => {
            const {scrollTop, scrollHeight, clientHeight} = e
            setAtBottom(Math.abs(scrollHeight - clientHeight - scrollTop) < 100)
        }
        e.addEventListener("touchmove", handleScroll)
        e.addEventListener('scroll', handleScroll)
        return () => {
            e.removeEventListener('scroll', handleScroll)
            e.removeEventListener("touchmove", handleScroll)
        }
    }, [])

    return (
        <Modal open={open} close={onClose}>
            <div className="bg-black max-w-[700px] p-5 rounded-xl flex flex-col h-full max-h-[calc(100vh-64px)] margin-8 overscroll-none">
                <h1 className="text-orange-500 text-center">Terms of Service</h1>
                <h3 className="text-slate-200 text-center pb-5">Read and Accept Apex Terms of Service</h3>
                <div ref={ref} className="overflow-y-auto mb-5 overscroll-contain">
                <TOS />
                </div>
                <button className="button-orange" onClick={onAccept}>Accept Terms and Sign Up</button>
            </div>
        </Modal>
    )
}

export default PrepTOSModal;