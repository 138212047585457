import { VectorLogo } from 'assets'
import useAuthContext from 'contexts/Auth/useAuthContext'
import { FC } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

const PublicLayoutV2: FC = () => {
  const path = useLocation().pathname
  const user = useAuthContext()
  return (
    <div className="overflow-y-auto overflow-x-hidden flex flex-col bg-white min-h-screen">
      <div className="py-8 px-32 flex items-center w-full justify-between bg-base-950 fixed top-0 z-[1000]">
        <Link className="flex items-center" to="/">
          <VectorLogo />
        </Link>
        <div className="space-x-8 mr-16">
          <Link to="/contact" children="Contact Us" className={path.startsWith('/contact') ? 'link active !font-extrabold' : 'link !font-extrabold'} />
          {!user && <Link className="link !font-extrabold" to="/auth/login" children="Sign in" />}
          <Link to={!!user ? "/auth/register" : "/seller-signup"} className="link !font-extrabold" children={!!user ? 'Dashboard' : 'Create an Account'} />
        </div>
      </div>
      <Outlet />
      <div className="grow" />
    </div>
  )
}

export default PublicLayoutV2
