import { FC, useEffect, useState } from "react";
import useOrder from "../context/hook";
import useUserContext from "contexts/User/useUserContext";
import distributionApi from "api/distribution";

const MyNotes: FC = () => {
  const user = useUserContext()
  const { frozen, order, setOrder } = useOrder()
  const distributor  = order.user !== user?.id
  const notes = (distributor ? order.distributorNotes : order.clientNotes) || ''
  const [unsaved, setUnsaved] = useState(notes)
  const [saving, setSaving] = useState(false)
  const editable = !frozen && !distributor

  useEffect(() => {
    setUnsaved(notes)
  }, [notes])

  const onSave = () => {
    setSaving(true)
    const toSave = unsaved.trim()
    if (toSave === notes) {
      setUnsaved(notes)
      setSaving(false)
    } else {
      if (!distributor) {
      distributionApi
        .putNotes(order.id, toSave)
        .then(() => {
          setUnsaved(toSave)
          setOrder((old) => old && { ...old, clientNotes: toSave })
        })
        .finally(() => setSaving(false))
      } else {
        setSaving(false)
      }
    }
  }

    return (
    <section className="box grow min-w-[500px] bg-white box h-[245px] overflow-hidden relative">
      <header>
        <span>Notes to {distributor ? "client" : "distributor"}</span>
        {editable && (
          <button className="button-secondary -mb-3 -mt-2" onClick={onSave} disabled={unsaved === notes || (!unsaved && !notes) || saving}>
            {saving ? 'Saving...' : 'Save Notes'}
          </button>
        )}
      </header>
      <div className="flex flex-col w-full h-[188px] px-5 py-4 relative">
        {editable ? (
          <textarea className="w-full mb-4 grow p-2 resize-none" placeholder="Write something..." value={unsaved} onChange={(e) => setUnsaved(e.target.value)} />
        ) : notes ? (
          <div className="w-full h-full overflow-y-auto p-2 mb-4">
            <span>{notes}</span>
          </div>
        ) : (
          <div className="w-full h-full overflow-y-auto p-2 mb-4 text-base-300 items-center flex justify-center">No notes</div>
        )}
      </div>
    </section>
    )
}

export default MyNotes