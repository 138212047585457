import api from "api"
import Icon from "components/Icon"
import useUserContext from "contexts/User/useUserContext"

const AmazonAccountSettings = () => {
  const user = useUserContext()

  const onClick = () => {
    api.getAmazonAuthUri().then((uri) => {
        window.open(uri, '_self')
    })
  }

  const onSettingsClick = () => {
    window.open("https://sellercentral.amazon.com/fba/settings/index.html#/inbound-settings", '_blank')
  }

  return (
    <section className="box w-full flex flex-col h-full relative bg-white">
      <header>Amazon Account</header>
      <div className="flex flex-col grow overflow-y-auto px-4 w-full items-center justify-center py-8">
        <Icon name="user-octagon" type="bulk" size={128} />
        {user?.amazonConnected ? (<>
          <h2 className="mt-4 mb-2">Amazon Account Connected</h2>
          <p className="text-primary-600">{user?.contact?.name}</p>
            <button className="button-primary" children="2D Barcode Settings" onClick={onSettingsClick} />
          </>
        ) : (
          <>
            <h2 className="mt-4 mb-2">Connect Your Account</h2>
            <button className="button-primary" children="Connect Your account" onClick={onClick} />
          </>
        )}
      </div>
      <footer className="bg-base-900 not-themed flex items-center justify-center py-10">
        <img src={require('../../../../assets/amazon.png')} className="h-8 w-auto" alt="Stripe" />
      </footer>
    </section>
  )
}

export default AmazonAccountSettings