import inventoryApi from 'api/inventory'
import AmazonProductImage from 'components/AmazonProductImage'
import useUserContext from 'contexts/User/useUserContext'
import { FC, useState } from 'react'
import { IInventory } from 'types/inventory'

interface props {
  item: IInventory
  onUpdate?: (data: { quantity?: number; location?: string; notes?: string }) => void
  shipmentQty?: number
  onChangeShipmentQuantity?: (quantity: number) => void
}

const Item: FC<props> = ({ item, onUpdate, shipmentQty, onChangeShipmentQuantity }) => {
  const prep = useUserContext()?.isPrepCenter
  const [quantity, setQuantity] = useState<number>(item.quantity)
  const [location, setLocation] = useState<string>(item.location)
  const [notes, setNotes] = useState<string | undefined>(item.item.notes)
  const [editing, setEditing] = useState(false)

  const saveChanges = () => {
    if (editing) return
    setEditing(true)
    inventoryApi
      .updateInventoryItem(item.id, { quantity, location, notes })
      .then(() => {
        onUpdate?.({ quantity, location, notes })
      })
      .finally(() => setEditing(false))
  }

  return (
    <tr className={[editing && 'grayscale'].asClass}>
      <td>
        <input type="number" disabled={editing} placeholder="Qty" className="w-20 text-center" value={shipmentQty} onChange={(e) => onChangeShipmentQuantity?.(parseInt(e.currentTarget.value))} onBlur={(e) => onChangeShipmentQuantity?.(parseInt(e.currentTarget.value).between(0, quantity))} />
      </td>
      <td>
        <AmazonProductImage asin={item.item.asin} size={64} imageSize={128} className="cursor-pointer rounded-lg" />
      </td>
      <td>
        <div className="flex flex-col gap-4">
          <span className="text-sm text-base-950 font-semibold select-text">{item.item.name}</span>
          <div className="flex flex-col gap-2">
            <span className="text-xs text-base-500 font-medium flex gap-2">
              <span className="select-text">{item.item.asin}</span>
              <span className="text-base-200">|</span>
              <span className="select-text">{item.item.upc || 'No UPC'}</span>
            </span>
            <span className="text-xs text-base-500 font-medium">Stored On {new Date(item.createdAt).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</span>
          </div>
        </div>
      </td>
      <td>{item.quantity}</td>
      {prep && (
        <>
          <td>
            <input type="text" disabled={editing} placeholder="Location Descriptor (internal)" value={location} onChange={(e) => setLocation(e.currentTarget.value)} onBlur={saveChanges} />
          </td>
          <td>
            <input type="text" disabled={editing} placeholder="Write Something... (internal)" value={notes} onChange={(e) => setNotes(e.currentTarget.value)} onBlur={saveChanges} />
          </td>
        </>
      )}
    </tr>
  )
}

export default Item
