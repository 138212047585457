import api from 'api'
import Empty from 'components/Empty'
import TableSpanner from 'components/TableSpanner'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import { useEffect, useState } from 'react'
import BillingSwitch from '../components/BillingSwitch'
import { IInvoiceListed } from 'types/billing'
import Invoice from '../components/Invoice'
import InvoiceStateSwitch from '../components/InvoiceStateSwitch'
import useSelectedSeller from 'hooks/useSelectedSeller'
import useUserContext from 'contexts/User/useUserContext'
import { useParams } from 'react-router-dom'
import SellerInvoiceStateSwitch from '../components/SellerInvoiceStateSwitch'

const InvoicesPage = () => {
  const [invoices, setInvoices] = useState<IInvoiceListed[]>()
  const selectedUser = useSelectedSeller()
  const { prep } = useParams()
  const [selectedState, setSelectedState] = useState<string>()

  const user = useUserContext()

  useEffect(() => {
    if (!selectedState) return
    setInvoices(undefined)
    api.getInvoices({ user: selectedUser, from: prep, status: selectedState }).then(setInvoices)
  }, [selectedUser, selectedState, prep])

  const isPrepCenter = user?.isPrepCenter

  const onViewInStripe = () => {
    if (!user?.stripeAccountId) return
    window.open(`https://dashboard.stripe.com/${user.stripeAccountId}/invoices`, '_blank')
  }

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header />
      <div className="grow flex flex-col overflow-hidden">
        <header className="flex justify-between items-center z-[1000]">
          <span>Invoices</span>
          {user?.isPrepCenter && user.stripeAccountId && (
            <button className="button-primary" onClick={onViewInStripe}>
              View in Stripe
            </button>
          )}
        </header>
        <div className="flex flex-col grow overflow-y-auto">
          <div className="flex flex-col grow px-6 h-max space-y-5 pb-6">
            <section className="box bg-white items-center overflow-y-hidden">
              {user?.isPrepCenter ? (
                <>
                  <BillingSwitch />
                  <InvoiceStateSwitch onSelectedChange={setSelectedState} />
                </>
              ) : (
                <SellerInvoiceStateSwitch onSelectedChange={setSelectedState} />
              )}
              <div className="px-5 pb-5 w-full">
                <div className="border border-base-200 rounded-xl overflow-y-hidden h-full">
                  <table className="w-full">
                    <colgroup>
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <td>Number</td>
                        <td>{isPrepCenter ? 'Customer Email' : 'Prep Center'}</td>
                        <td>Total</td>
                        <td>Created at</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices?.map((invoice) => (
                        <Invoice key={invoice.id} invoice={invoice} />
                      ))}
                      {!invoices?.length && (
                        <TableSpanner rows={4} cols={5}>
                          {invoices === undefined ? <FullLoader /> : <Empty text="No Invoices" />}
                        </TableSpanner>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoicesPage
