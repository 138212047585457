import useIconsax from 'hooks/useIconsax'
import { FC } from 'react'

interface props {
  name?: string
  size?: number
  type?: "bulk" | "outline"
  className?: string
  button?: boolean
  active?: boolean
  preserveAspectRatio?: string
}

const Icon: FC<props> = ({ name, type, size=24, className, active, button, preserveAspectRatio }) => {
  const _type = type || "outline"
  const SVG = useIconsax(`${_type}/${name}.svg`)

  const combinedClass = [active ? "fill-primary-600 stroke-none" : `fill-base-300 stroke-none ${button && 'group-hover:fill-primary-600'}`, className].filter(Boolean).join(" ")

  if (!SVG) return null

  return <SVG preserveAspectRatio={preserveAspectRatio} width={size} height={size} className={combinedClass}/>
}

export default Icon
