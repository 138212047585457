import { Address, IOrder, IShippingDataLTL, IShippingDataSPD } from 'types/orders'
import bwipjs from 'bwip-js'
import { print } from 'helpers/print'
import { IWarehouseContactInfo } from 'types/warehouses'

export const getAsinBySKU = (orderItems: IOrder['itemsData'], sku: string) => {
  return orderItems.find((item) => item.sku === sku)?.asin
}

const formatExpiry = (expiry: string) => {
  const [_year, month, day] = expiry.split('-')
  const year = _year.substring(2, 4)
  return year + month + day
}

export const printBoxLabels = async (shipmentId: string, orderId: string, orderItems: IOrder['itemsData'], boxes: (IShippingDataLTL['boxes'][0] | IShippingDataSPD['boxes'][0])[], sellerName: string, fromAddress: IWarehouseContactInfo, toAddress: Address) => {
  const from = [fromAddress.name, fromAddress.streetAddress, `${fromAddress.city}, ${fromAddress.state} ${fromAddress.zipCode}`, fromAddress.country]
  const to = [`FBA: ${sellerName}`, toAddress.Name, toAddress.AddressLine1, `${toAddress.City}, ${toAddress.StateOrProvinceCode} ${toAddress.PostalCode}`, toAddress.CountryCode]
  const doc = new PDFDocument({ size: [288, 432], font: 'Helvetica', margin: 2 })
  const out = blobStream()
  doc.pipe(out)
  const writingPromise = new Promise((resolve) => {
    doc.on('end', resolve)
  })
  boxes.forEach((box, i) => {
    if (i > 0){
      doc.addPage({ size: [288, 432], font: 'Helvetica', margin: 2 })
    }
    let data = `AMZN,PO:${shipmentId}`
    box.items.forEach((item) => {
      const asin = getAsinBySKU(orderItems, item.sku)
      if (!asin) return
      data += `,ASIN:${asin},QTY:${item.quantity}`
      if (item.expiryDate) data += `,EXP:${formatExpiry(item.expiryDate)}`
    })
    const barcode2D = get2DBarcode(data) as string
    if (!barcode2D) return
    generateBoxLabel(doc, box.boxNumber || 0, shipmentId, new Date().toLocaleDateString(), orderId, from, to, barcode2D)
  })

  doc.end()

  await writingPromise

  const uri = out.toBlobURL('application/pdf')

  print(uri.toString())
}

const getBoxBarcode = (boxId: string) => {
  let canvas = document.createElement('canvas')
  canvas = bwipjs.toCanvas(canvas, {
    bcid: 'code128',
    text: boxId,
    textfont: 'Arial',
    textsize: 8,
    scale: 10,
    height: 60,
    textyoffset: 1,
    includetext: false,
  })
  return canvas.toDataURL('image/png', 1)
}

const getBox2D = (boxId: string) => {
  let canvas = document.createElement('canvas')
  canvas = bwipjs.toCanvas(canvas, {
    bcid: 'pdf417',
    text: boxId,
    textfont: 'Arial',
    textsize: 8,
    scale: 10,
    height: 15,
    textyoffset: 1,
    includetext: false,
  })
  return canvas.toDataURL('image/png', 1)
}

const get2DBarcode = (data: string) => {
  let canvas = document.createElement('canvas')
  canvas = bwipjs.toCanvas(canvas, {
    bcid: 'pdf417',
    text: data,
    scale: 3,
    textyoffset: 5,
    paddingwidth: 10,
    paddingheight: 10,
    paddingbottom: 30,
    width: 60,
    height: 40,
    backgroundcolor: '#FFFFFF',
    textcolor: '#000000',
    monochrome: true,
    rotate: 'N',
  })
  return canvas.toDataURL('image/png', 1)
}

const generateBoxLabel = async (doc: PDFKit.PDFDocument, boxNumber: number, fbaId: string, createdAt: string, shipmentId: string, from: string[], to: string[], barcode2D: string) => {
  const areaWidth = 268
  const areaHeight = 412
  doc.translate(10, 10)
  const scale = Math.min(areaWidth / doc.page.width, areaHeight / doc.page.height)
  doc.scale(scale, scale, { origin: [0, 0] })

  const fbaBoxNumber = `${fbaId}U${boxNumber.toString().padStart(6, '0')}`

  const barcode1 = getBoxBarcode(fbaBoxNumber)
  const barcode2d = getBox2D(fbaBoxNumber)
  //add header
  doc.font('Helvetica-Bold')
  doc.fontSize(8)
  doc.text('PLEASE LEAVE THIS LABEL UNCOVERED', 0, 0, { width: 288, align: 'center' })

  doc.translate(0, 10)
  doc.fontSize(14)
  doc.font('Helvetica-Bold')
  doc.text('FBA', 0, 0, { width: 288, align: 'left' })
  doc.translate(0, 14)
  doc.strokeColor('black').lineWidth(2)
  doc.moveTo(0, 0).lineTo(288, 0).stroke('black').lineWidth(2)

  doc.translate(0, 4)

  // add addresses
  doc.fontSize(8)
  doc.font('Helvetica')
  doc.text('SHIP FROM:', 0, 0, { width: 140, align: 'left' })

  let address1Height = 0

  from.forEach((line, i) => {
    doc.text(line, 0, 10 + i * 10, { width: 140, align: 'left' })
    address1Height = 10 + i * 10
  })

  doc.fontSize(8)
  doc.font('Helvetica')
  doc.text('SHIP TO:', 144, 0, { width: 140, align: 'left' })

  let address2Height = 0

  to.forEach((line, i) => {
    doc.text(line, 144, 10 + i * 10, { width: 140, align: 'left' })
    address2Height = 10 + i * 10
  })

  // add shipment name and created at

  doc.translate(0, Math.max(address1Height, address2Height) + 10)

  doc.strokeColor('black').lineWidth(10)
  doc.moveTo(0, 3).lineTo(288, 3).stroke('black').lineWidth(10)

  doc.fillColor('white')

  doc.text(shipmentId, 2, 0, { width: 288, align: 'left' })

  doc.text(createdAt, 0, 0, { width: 286, align: 'right' })

  doc.translate(0, 12)

  // barcode 1

  doc.image(barcode1, 4, 0, { width: 200, height: 60 })
  doc.fillColor('black')
  doc.text(fbaBoxNumber, 4, 62, { width: 200, align: 'center' })

  // barcode 2
  doc.rotate(-90, { origin: [0, 0] })
  doc.image(barcode2d, -120, 223, { width: 120, height: 60 })
  doc.rotate(90, { origin: [0, 0] })

  // box number

  doc.translate(0, 80)
  doc.fontSize(14)
  doc.text(`BOX ${boxNumber}`, 0, 0, { width: 210, align: 'center' })
  doc.translate(0, 14)

  // add footer
  doc.lineWidth(2)
  doc.moveTo(0, 0).lineTo(210, 0).stroke('black').lineWidth(2)

  // ad 2d barcode

  doc.translate(0, 20)

  doc.image(barcode2D, 0, 4, { width: 288, height: 178 })

  doc.translate(0, 158)
  doc.fontSize(14)
  doc.text(`PO: ${fbaId}`, 0, 0, { width: 288, align: 'center' })

  // make smaller
  doc.scale(0.5)
}
