import api from 'api'
import IconButton from 'components/buttons/IconButton'
import TextInput from 'components/TextInput'
import { FC, useState } from 'react'
import { IAccess } from 'types/access'

const MyAccess: FC<IAccess & { updateAccessName: (name: string) => void }> = ({ updateAccessName, id, contact, accessType, name }) => {
  const [editingName, setEditingName] = useState(false)
  const [customName, setCustomName] = useState(name || contact.legalName)

  const saveCustomName = () => {
    setEditingName(false)
    api.changeAccessName(id, customName || '').then(() => updateAccessName(customName || ''))
  }

  return (
    <tr key={id}>
      <td>
        <div className="flex justify-between items-center">
          {!editingName && <span className="mb-0">{customName}</span>}
          {editingName ? (
            <>
              <TextInput value={customName} onChange={(e) => setCustomName(e.currentTarget.value)} />
              <IconButton name="done" onClick={saveCustomName} tooltip="Save" tooltipLocation="right" />
            </>
          ) : (
            <IconButton name="edit" onClick={() => setEditingName(true)} tooltip="Rename" tooltipLocation="right" />
          )}
        </div>
      </td>
      <td>{accessType}</td>
    </tr>
  )
}

export default MyAccess
