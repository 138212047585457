import { useEffect } from 'react'

const useSimulateTab = () => {
  useEffect(() => {
    function simulateTab(obj: any) {
      let found = false
      let end = false
      while (found === false && end === false) {
        if (obj.firstChild) {
          obj = obj.firstChild
        } else if (obj.nextSibling) {
          obj = obj.nextSibling
        } else if (obj.parentNode && obj.parentNode.nextSibling) {
          obj = obj.parentNode.nextSibling
        } else {
          end = true
        }
        if (obj && obj.contentEditable === 'true') found = true
        if (
          obj &&
          (obj.tagName === 'INPUT' ||
            obj.tagName === 'TEXTAREA' ||
            obj.tagName === 'A' ||
            obj.tagName === 'AUDIO' ||
            obj.tagName === 'VIDEO' ||
            obj.tagName === 'SELECT' ||
            obj.tagName === 'BUTTON' ||
            obj.tagName === 'PROGRESS')
        )
          found = true
        if (found) {
          // tab don't stop on hidden objects
          if (obj.style.display === 'none') found = false
        }
      }
      if (found) {
        obj.focus()
        // if the obj accept text input, move the cursor to the end.
        // This is not standard tab behavior, but should be.
        if (obj.contentEditable === 'true' || obj.tagName === 'INPUT' || obj.tagName === 'TEXTAREA') {
          if (obj.childNodes.length > 0) {
            const range = document.createRange()
            const selection = window.getSelection()
            range.setStart(obj.lastChild, obj.lastChild.length)
            range.collapse(true)
            selection?.removeAllRanges()
            selection?.addRange(range)
          }
          if (obj.tagName === 'INPUT' || obj.tagName === 'TEXTAREA') {
            obj.selectionStart = obj.selectionEnd = obj.value.length
          }
        }
      }
    }

    const onKeydown = (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        simulateTab(e.target)
      }
    }
    
    /*document.addEventListener('keydown', onKeydown)

    return () => {
      document.removeEventListener('keydown', onKeydown)
    }*/
  }, [])
}

export default useSimulateTab
