import IconButton from 'components/buttons/IconButton'
import { FC, useState, MouseEvent } from 'react'
import { useNavigate } from 'react-router'
import OrderStatusTag from './OrderStatusTag'
import useUserContext from 'contexts/User/useUserContext'
import Progress from './Progress'
import ordersApi from 'api/orders'
import { IOrder } from 'types/orders'

interface props extends IOrder {
  onDelete: (id: string) => void
}

const Order: FC<props> = ({ onDelete, ...order }) => {
  const { id, data, state: orderState, asins, quantity, bill, created_at, inboundAt } = order
  const navigate = useNavigate()
  const [deleting, setDeleting] = useState(false)
  const [editing, setEditing] = useState(false)
  const user = useUserContext()

  const handleDelete = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (deleting) return
    setDeleting(true)
    ordersApi
      .deleteOrder(id)
      .then(() => {
        onDelete(id)
      })
      .catch(() => setDeleting(false))
  }

  const handleEdit = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (editing) return
    ordersApi.getOrder(id).then((order) => navigate('/app/orders/create', {state: {initialOrder: order}})).finally(() => setEditing(false))
  }

  const prepOrderNumber = order.orderNumber ? '#' + order.orderNumber.toString().padStart(5, '0') : undefined

  const progress = order.state === "IN_PROGRESS" ? 0 : ["HANDLED", "SENT_TO_AMAZON"].includes(order.state) ? 1 : 0.05 + order.progress*0.0085

  return (
    <tr className={["cursor-pointer hover:opacity-80 border-x-4", order.apexClient ? "border-orange-500" : "border-transparent"].asClass} onClick={() => navigate('/app/orders/' + id)}>
      <td>{user?.isPrepCenter ? prepOrderNumber : `${data?.seller?.id} ${prepOrderNumber ? `(${prepOrderNumber})` : ""}`}</td>
      <td>
        {user?.isPrepCenter && inboundAt ? new Date(inboundAt).toLocaleDateString() : new Date(created_at).toLocaleDateString()}
      </td>
      {user?.isPrepCenter && <td>{order.contact?.name}{order.apexClient && <span className="text-orange-500"> (Apex Client)</span>}</td>}
      <td>
        {asins} Asin{asins === 1 ? '' : 's'}
      </td>
      <td>
        {quantity} Unit{quantity === 1 ? '' : 's'}
      </td>
      <td>
        <OrderStatusTag status={orderState} isPaid={Boolean(bill)} isPrepCenter={user?.id === order.to} hasIssues={Boolean(order.issues?.length)} />
      </td>
      <td>
        <Progress progress={progress} />
      </td>
      {!user?.isPrepCenter && (
        <td className="py-0">
          {["IN_PROGRESS", "SENT"].includes(orderState) ? (
            <div className="flex items-center">
            <IconButton name="trash" tooltip="Delete shipment" onClick={handleDelete} disabled={deleting || editing} tooltipLocation="right" className="mx-2 my-2" />
            <IconButton name="edit-2" tooltip="Edit shipment" onClick={handleEdit} disabled={editing || deleting} tooltipLocation="right" className="mx-2 my-2" />
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              {order.issues?.length ? order.issues?.map(({ type, message }) => (
                <span key={type} className="tag bg-rose-100 text-rose-600">
                  {message}
                </span>
              )) : (
                <span className="tag bg-green-100 text-green-600">
                  No issues
                </span>
              )}
            </div>
          )}
        </td>
      )}
    </tr>
  )
}

export default Order
