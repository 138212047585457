import formatAddress from "helpers/formatAddress";
import { FC } from "react";
import { IWarehouseContactInfo } from "types/warehouses";

const WarehouseInfo: FC<{ contact: IWarehouseContactInfo }> = (props) => {
    if (!props?.contact) return null
    const { contact } = props

    return (
        <>
        <div className="flex flex-col bg-white space-y-4">
            <div className="flex flex-col">
                <p>Email</p>
                <p className="select-text">{contact.email}</p>
            </div>
            <div className="flex flex-col">
                <p>Phone</p>
                <p className="select-text">{contact.phoneNumber}</p>
            </div>
            <div className="flex flex-col">
                <p>Address</p>
                <p className="select-text">{formatAddress(contact)}</p>
            </div>
        </div>
        </>
        )
}

export default WarehouseInfo