import { Stack, defaultModules } from '@pnotify/core'
import { alert } from '@pnotify/core'
import '@pnotify/core/dist/PNotify.css'

const stack = new Stack({
  push: 'top',
  dir1: 'down',
  dir2: 'left',
  firstpos1: 25,
  firstpos2: 25,
  spacing1: 36,
  spacing2: 36,
  maxOpen: 1,
  context: document.getElementById('root') || undefined,
  maxStrategy: 'close',
})

export const handleError = (message: string, anchor?: string) => {
  stack.close()
  alert({
    text: message,
    stack,
    type: 'error',
    remove: true,
    delay: 2000,
    modules: defaultModules,
    addClass: 'pnotify-error',
    icon: true,
  })
}
