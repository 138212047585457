import employeesApi from 'api/employees'
import Empty from 'components/Empty'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import useUserContext from 'contexts/User/useUserContext'
import useAwaitableModal from 'hooks/useAwaitableModal'
import { FC, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { IEmployee, IPendingEmployee } from 'types/employee'
import InviteEmployeeModal from './modals/InviteEmployeeModal'
import Employee from './components/Employee'

const StaffPage: FC = () => {
  const me = useUserContext()
  const [employees, setEmployees] = useState<IEmployee[]>()
  const [pending, setPending] = useState<IPendingEmployee[]>()
  const [invite, InviteModal] = useAwaitableModal(InviteEmployeeModal, {})

  useEffect(() => {
    employeesApi.getEmployees().then(setEmployees)
    employeesApi.getPendingEmployees().then(setPending)
  }, [])

  const onInvite = () => {
    invite().then((employee) => employee && setPending((old) => old && [employee, ...old]))
  }

  const combined = [...(employees || []).map((e) => ({ ...e, pending: false })), ...(pending || []).map((e) => ({ ...e, pending: true }))].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)) as (
    | IEmployee
    | IPendingEmployee
  )[]

  if (me && (!me.isPrepCenter || (me.isEmployee && !me.roles?.includes('ADMIN')))) return <Navigate to="/app" replace />

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <InviteModal />
      <Header />
      <div className="grow flex flex-col overflow-hidden pb-6">
        <header className="flex justify-between items-center z-[1000]">
          <div className="flex gap-1 flex-col">
            <span>Staff Members</span>
          </div>
          <button onClick={onInvite} className="button-primary">
            Invite Employee
          </button>
        </header>
        <div className="flex flex-col grow overflow-y-auto mb-5 px-6 grow gap-4">
          {!employees || !pending ? (
            <FullLoader />
          ) : combined.length ? (
            combined.map((employee: IEmployee | IPendingEmployee) =>
              employee.pending === true ? (
                <Employee email={employee.email} name={employee.name} position={employee.position} />
              ) : employee.pending === false ? (
                <Employee id={employee.id} name={employee.contact.legalName || (employee.contact.name as string)} email={employee.contact.email as string} position={employee.position} roles={employee.roles} />
              ) : null
            )
          ) : (
            <Empty text="No Employees Yet" />
          )}
        </div>
      </div>
    </div>
  )
}

export default StaffPage
