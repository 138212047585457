import axios from 'api/axios'
import { AxiosInstance } from 'axios'
import { IService } from 'types/services'

class ServicesApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }
  
  async getServices(prep?: string) {
    return this.axios.get('/services' + (prep ? `?prep=${prep}` : '')).then((res) => res.data as IService[])
  }
  
  async getItemServices(prep?: string) {
    return this.axios.get('/services/items' + (prep ? `?prep=${prep}` : '')).then((res) => res.data as IService[])
  }
  
  async getOrderServices(prep?: string) {
    return this.axios.get('/services/order' + (prep ? `?prep=${prep}` : '')).then((res) => res.data as IService[])
  }
  
  async addItemService({ title, identifier, basePrice }: { title: string, identifier: string, basePrice: number }) {
    return this.axios.post('/services/items', {title, identifier, basePrice}).then((res) => res.data as IService)
  }
  
  async addOrderService({ title, identifier, basePrice, type }: { title: string, identifier: string, basePrice: number, type: string }) {
    return this.axios.post('/services/order', {title, identifier, basePrice, type}).then((res) => res.data as IService)
  }

  async deleteItemService(id: string) {
    return this.axios.delete(`/services/items/${id}`)
  }

  async deleteOrderService(id: string) {
    return this.axios.delete(`/services/order/${id}`)
  }

}

const servicesApi = new ServicesApi()

export default servicesApi
