import { FC, useEffect, useLayoutEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface props {
  onSelectedChange: (selected: string) => void
}

const SellerInvoiceStateSwitch: FC<props> = ({onSelectedChange}) => {
  const location = useLocation()
  const pathName = location.pathname.split('/').pop()
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)

  const selected = pathName === 'draft' ? 'draft' : pathName === 'sent' ? 'open' : pathName === 'paid' ? 'paid' : 'draft'

  useEffect(() => {
    onSelectedChange(selected)
  }, [selected, onSelectedChange])

  useLayoutEffect(() => {
    ref.current?.style.setProperty("padding", "0px", "important");
  }, [ref])

  return (
      <header ref={ref} className="flex space-x-0 !justify-start !mb-5">
        <button onClick={() => navigate('../sent', {relative: "path"})} className={selected === 'open' ? 'text-primary-600 p-5 border-b-2 border-b-primary-600' : 'text-base-900 p-5'}>
          Unpaid
        </button>
        <button onClick={() => navigate('../paid', {relative: "path"})} className={selected === 'paid' ? 'text-primary-600 p-5 border-b-2 border-b-primary-600' : 'text-base-900 p-5'}>
          Paid
        </button>
      </header>
  )
}

export default SellerInvoiceStateSwitch
