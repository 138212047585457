import api from 'api'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import { handleError } from 'helpers/errors'
import { IPaymentStructure } from 'types/billing'
import { useNavigate } from 'react-router-dom'

const CreateInvoiceModal: AwaitableModal<undefined, {billIds?: string[], userId?: string}> = ({ open, resolve, initialData }) => {
  const [sending, setSending] = useState(false)
  const [selectedStructure, setSelectedStructure] = useState<string>()
  const [pricingStructures, setPricingStructures] = useState<IPaymentStructure[]>()
  const navigate = useNavigate()

  useEffect(() => {
    api.getPaymentStructures().then((ps) => {
      setSelectedStructure(ps[0].identifier)
      setPricingStructures(ps)
    })
  }, [])

  const onResolve = () => {
    navigate('/app/billing/invoices')
  }

  const handleSubmit = () => {
    if (!initialData?.billIds) return handleError("Please select a bill")
    if (!initialData?.userId) return handleError("Please select a user")
    if (!selectedStructure) return handleError("Please select a pricing structure")
    setSending(true)
    api.createInvoice(initialData.userId, initialData.billIds, selectedStructure).then(onResolve).finally(() => setSending(false))
  }

  return (
    <Modal open={open} close={onResolve}>
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>Create an invoice</h1>
            <h3>Select a pricing structure</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onResolve} />
        </header>
            <div className="w-full">
              <select className="w-full" onChange={(e) => setSelectedStructure(e.currentTarget.value)}>
                {pricingStructures?.map((structure) => (
                  <option key={structure.id} value={structure.identifier}>{structure.identifier}</option>
                ))}
              </select>
            </div>
            <footer className="items-center">
              <button disabled={sending} className="button-primary" onClick={handleSubmit}>
                Create invoice
              </button>
            </footer>
      </div>
    </Modal>
  )
}

export default CreateInvoiceModal
