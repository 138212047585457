import axios from 'api/axios'
import { AxiosInstance } from 'axios'
import { IEmployee, IPendingEmployee } from 'types/employee'

class EmployeesApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }
  
  async getEmployees() {
    return this.axios.get('/employees').then((res) => res.data as IEmployee[])
  }
  
  async getPendingEmployees() {
    return this.axios.get('/employees/pending').then((res) => res.data as IPendingEmployee[])
  }

  async updateRoles(employeeId: string, roles: string[]) {
    return this.axios.put('/employees/'+employeeId+'/roles', { roles })
  }
}

const employeesApi = new EmployeesApi()

export default employeesApi
