import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import ordersApi from 'api/orders'
import { IItemService } from 'types/services'
import { apexItemServices } from 'constants/apexServices'
import { useOrder } from 'contexts/Order'

interface response {
  services: string[]
}

interface input {
  services?: string[]
  sku: string
  orderId: string
  availableItemServices?: IItemService[]
}

const availableApexServices = (type: "Wholesale" | "OA") => Object.entries(apexItemServices[type]).map(([key, val]) => ({
  id: key,
  title: val,
  created_at: new Date(),
  prepCenter: "APEX",
  identifier: key,
  basePrice: 0,
}))

const AddServicesModal: AwaitableModal<response, input> = ({ open, resolve, initialData }) => {
  const {order: {apexClient, apexWarehouseType}} = useOrder()
  const [sending, setSending] = useState(false)
  const [services, setServices] = useState<string[]>(initialData?.services || [])
  const [availableItemServices, setAvailableItemServices] = useState(initialData?.availableItemServices || [])

  const onResolve = () => {
    resolve({ services })
  }

  useEffect(() => {
    if (apexClient && apexWarehouseType) {
      setAvailableItemServices(availableApexServices(apexWarehouseType))
    }
  }, [apexClient, apexWarehouseType])

  const handleSubmit = () => {
    if (sending) return
    setSending(true)
    ordersApi
      .updateServices(initialData.orderId, initialData.sku, services)
      .then(() => {
        resolve({ services })
      })
      .finally(() => {
        setSending(false)
      })
  }

  const onRadioUpdate = (e: any) => {
    const value = e.currentTarget.value
    if (e.currentTarget.checked === false) {
      setServices((old) => old.filter((s) => s !== value))
    } else {
      setServices((old) => [...old, value])
    }
  }

  return (
    <Modal open={open} close={onResolve}>
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>Add additional services</h1>
            <h3>Select required packaging services</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onResolve} />
        </header>
        <div className="w-full">
          <h3>Required services</h3>
          {availableItemServices.map(({id, title}) => (
            <label key={id} className="flex items-center">
              <input type="checkbox" name="services" value={id} checked={services.includes(id)} className="mr-2" onChange={onRadioUpdate} />
              {title}
            </label>
          ))}
        </div>
        <footer className="items-center">
          <button disabled={sending} className="button-primary" onClick={handleSubmit}>
            Save
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default AddServicesModal
