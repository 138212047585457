import AmazonProductImage from 'components/AmazonProductImage'
import IconButton from 'components/buttons/IconButton'
import numberInputOnWheelPreventChange from 'helpers/numberInputOnWheelPreventChange'
import { FC } from 'react'
import { IOrder } from 'types/orders'

interface props {
  item: IOrder['itemsData'][0] & { done: number }
  count: number
  setCount: (count: number) => void
  expiryDate?: string
  setExpiryDate: (date?: string) => void
}

const Item: FC<props> = ({ item, count, setCount, expiryDate, setExpiryDate }) => {
  return (
    <tr>
      <td className="p-4 text-sm">
        <div className="flex gap-2.5 items-center">
          <input
            onWheel={numberInputOnWheelPreventChange}
            className="text-sm text-center h-min px-0 outline-none py-2 w-10"
            value={count}
            type="number"
            step={1}
            min={0}
            onChange={(e) => setCount(Math.max(Math.min(e.currentTarget.valueAsNumber, item.actual - item.done), 0))}
          />
        </div>
      </td>
      <td className="p-4 text-xs border-l">{item.expiryDate || 'Never'}</td>
      <td className="p-4 text-xs border-l">{item.actual - item.done}</td>
      <td className="p-4 gap-2 border-l">
        <AmazonProductImage asin={item.asin} size={64} imageSize={128} className="w-16 h-16 rounded-lg object-cover cursor-pointer" />
      </td>
      <td className="p-4 border-l">
        {item.sku} <br /> {item.name}
      </td>
    </tr>
  )
}

export default Item
