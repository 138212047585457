import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import Empty from 'components/Empty'
import TableSpanner from 'components/TableSpanner'
import FullLoader from 'components/loaders/FullLoader'
import useAwaitableModal from 'hooks/useAwaitableModal'
import { IService } from 'types/services'
import AddItemServiceModal from './AddItemServiceModal'
import ItemService from './ItemService'

interface props {
  itemServices?: IService[]
  setItemServices: Dispatch<SetStateAction<IService[] | undefined>>
}

const ItemServices: FC<props> = ({itemServices, setItemServices}) => {
  const [request, Element] = useAwaitableModal(AddItemServiceModal, {})

  return (
    <section className="box w-full flex flex-col relative bg-white">
      <Element />
      <header className="flex justify-between items-center z-[1000]">
        <span>Item Services</span>
        <button className="button-primary relative -my-2" onClick={() => request().then((created) => setItemServices((old) => old && [created, ...old]))}>
          New Item Service
        </button>
      </header>
      <div className="b-secondary grow flex flex-col overflow-hidden py-4">
        <div className="flex flex-col grow -mt-4 overflow-y-auto p-6">
          <div className="border border-base-200 rounded-xl">
            <table className="w-full rounded-xl overflow-hidden">
              <thead>
                <tr>
                  <td className="text-xs text-base-400">Title</td>
                  <td className="text-xs text-base-400 font-medium border-l">Identifier</td>
                  <td className="text-xs text-base-400 font-medium border-l">Base Price</td>
                  <td className="text-xs text-base-400 font-medium border-l">Added on</td>
                  <td className="text-xs text-base-400 font-medium border-l">Actions</td>
                </tr>
              </thead>
              <tbody>
                {itemServices?.map((service) => (
                  <ItemService key={service.id} service={service} remove={() => setItemServices(old => old?.filter(s => s.id !== service.id))} />
                ))}
                {!itemServices?.length && (
                  <TableSpanner rows={4} cols={5}>
                    {itemServices === undefined ? <FullLoader /> : <Empty text="No Services Added" />}
                  </TableSpanner>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ItemServices
