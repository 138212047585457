import FilterSeller from 'components/FilterSeller'
import IconButton from 'components/buttons/IconButton'
import useUserContext from 'contexts/User/useUserContext'
import { FC, PropsWithChildren, ReactNode, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'
import useOpenModal from '../../../hooks/useOpenModal'
import { DefaultProfilePic } from 'assets'
import Icon from 'components/Icon'
import { signOut } from 'firebase/auth'
import firebase from 'config/firebase'
import DarkModeToggle from 'components/DarkModeToggle'
import ActAsSelector from 'components/ActAsSelector'
import useShoppingCart from 'hooks/useShopingCart'
import useCanShop from 'hooks/useCanShop'
import { GearIcon, SettingsIcons } from 'assets/icons'
import useActAs from 'hooks/useActAs'
import { adminId } from 'constants/admin'

interface props {
  title?: string
  hideActAs?: boolean
  shipButton?: ReactNode
  additionalButtons?: (
    | {
        id: string
        onClick: () => void
        icon: string
        hasNotification?: boolean
        tooltip?: string
        bigButton?: false
      }
    | {
        id: string
        onClick: () => void
        icon?: string
        hasNotification?: boolean
        tooltip?: string
        bigButton: true
        disabled?: boolean
      }
  )[]
}

const Header: FC<PropsWithChildren<props>> = ({ title, children, additionalButtons, hideActAs, shipButton }) => {
  const openModal = useOpenModal()
  const navigate = useNavigate()
  const me = useUserContext()
  const location = useLocation()
  const profileDropdown = useRef<HTMLDivElement>(null)
  const { totalItems } = useShoppingCart()
  const canShop = useCanShop()
  const { actAs } = useActAs()
  const isAgency = me?.isAgency || actAs

  useEffect(() => {
    window.document.title = title ? `${title} | Apex Management` : 'Apex Management'
    return () => {
      window.document.title = 'Apex Management'
    }
  }, [title])

  const onToggleProfileDropdown = () => {
    const isToggled = profileDropdown.current?.classList.contains('opacity-1')
    if (isToggled) {
      profileDropdown.current?.classList.replace('opacity-1', 'opacity-0')
      profileDropdown.current?.classList.replace('translate-y-2', '-translate-y-10')
      profileDropdown.current?.classList.replace('pointer-events-auto', 'pointer-events-none')
    } else {
      profileDropdown.current?.classList.replace('opacity-0', 'opacity-1')
      profileDropdown.current?.classList.replace('-translate-y-10', 'translate-y-2')
      profileDropdown.current?.classList.replace('pointer-events-none', 'pointer-events-auto')
    }
  }

  useEffect(() => {
    const cb = () => {
      const isToggled = profileDropdown.current?.classList.contains('opacity-1')
      if (isToggled) {
        profileDropdown.current?.classList.replace('opacity-1', 'opacity-0')
        profileDropdown.current?.classList.replace('translate-y-2', '-translate-y-10')
        profileDropdown.current?.classList.replace('pointer-events-auto', 'pointer-events-none')
      }
    }

    window.addEventListener('click', cb)

    return () => {
      window.removeEventListener('click', cb)
    }
  }, [])

  const toSettings = () => {
    onToggleProfileDropdown()
    navigate('/app/settings')
  }

  const toPaymentStructures = () => {
    onToggleProfileDropdown()
    navigate('/app/paymentStructures')
  }

  const toProfile = () => {
    onToggleProfileDropdown()
    navigate('/app/profile')
  }

  const toStaff = () => {
    onToggleProfileDropdown()
    navigate('/app/staff')
  }

  const onLogout = () => {
    onToggleProfileDropdown()
    signOut(firebase.auth)
  }

  return (
    <div className="h-max flex w-full items-center p-5 border-b border-b-base-200 bg-white overflow-visible h-[88px]">
      {!hideActAs && me?.isPrepCenter && (
        me.id !== adminId && (
        <div className="flex grow max-w-max">
          <FilterSeller />
        </div>
        )
      )}
      {me?.isAgency && (
        <div className="flex grow max-w-max">
          <ActAsSelector />
        </div>
      )}
      <div className="flex grow">{children ? children : null}</div>
      <div className="flex space-x-3 items-center">
        {!me?.isPrepCenter && !location.pathname.startsWith('/app/orders/create') && !location.pathname.startsWith('/app/inventory') && !shipButton && (
          <button
            className="button-primary"
            children="Ship to Prep Center"
            // todo subscription is not required at this point, since stripe is fucked
            onClick={me?.amazonConnected ? ((me?.subscription || true) ? () => navigate('/app/orders/create') : () => openModal('/app/subscription/required')) : () => openModal('/app/settings/amazon/connect')}
          />
        )}
        <div id="shipButton" />
        {false && me?.isPrepCenter && !location.pathname.startsWith('/app/warehouses/add') && (
          <button className="button-primary" children="Add a Warehouse" onClick={() => navigate('/app/warehouses/add')} />
        )}
        <div className="flex items-center gap-2">
          <DarkModeToggle />
          <span className="font-medium text-slate-950 text-xs">Dark Mode</span>
        </div>
        {canShop && (
          <div className="relative">
            <IconButton active={window.location.pathname.startsWith('/app/shop/cart')} hasNotification={Boolean(me?.unreadNotifications)} onClick={() => navigate('/app/shop/cart')} name="bag" />
            {Boolean(totalItems) && (
              <span className="text-[10px] pointer-events-none text-primary-500 bg-primary-100 rounded-full absolute bottom-0 text-center px-2 font-medium border border-white left-1/2 -translate-x-1/2">
                {totalItems}
              </span>
            )}
          </div>
        )}
        <IconButton
          id="notification-button"
          active={window.location.pathname.startsWith('/app/notifications')}
          hasNotification={Boolean(me?.unreadNotifications)}
          onClick={() => openModal('/app/notifications', 'notification-button')}
          name="notification-bing"
        />
      </div>
      <div className="relative ml-4 z-[100000000]" onClick={(e) => e.stopPropagation()}>
        <div className="w-10 cursor-pointer hover:opacity-95 hover:p-px transition-all rounded-full" onClick={onToggleProfileDropdown}>
          {me?.image ? <img src={me.image} className="w-full h-full rounded-full" /> : <DefaultProfilePic className="w-full h-full" />}
        </div>
        <div
          ref={profileDropdown}
          className="absolute top-full right-0 drop-shadow-xl border border-base-200 transition-all divide-y divide-y-base-200 z-10 bg-white rounded-xl opacity-0 -translate-y-10 pointer-events-none"
        >
          {me?.isPrepCenter && (
            <>
              <button onClick={toProfile} className="w-full px-4 py-2 text-base-400 min-w-max w-full text-xs font-medium items-center group flex gap-2 hover:text-primary-500 transition-all">
                <SettingsIcons.Profile className="color-base-400 group-hover:color-primary-500 transition-all" />
                <span>Your Profile</span>
              </button>
              <button onClick={toStaff} className="w-full px-4 py-2 text-base-400 min-w-max w-full text-xs font-medium items-center group flex gap-2 hover:text-primary-500 transition-all">
                <SettingsIcons.Merchants className="color-base-400 group-hover:color-primary-500 transition-all" />
                <span>Staff Members</span>
              </button>
              <button onClick={toPaymentStructures} className="w-full px-4 py-2 text-base-400 min-w-max w-full text-xs font-medium items-center group flex gap-2 hover:text-primary-500 transition-all">
                <SettingsIcons.Dollar className="color-base-400 group-hover:color-primary-500 transition-all" />
                <span>Payment Structures</span>
              </button>
            </>
          )}
          {!isAgency && (
            <button onClick={toSettings} className="w-full px-4 py-2 text-base-400 min-w-max w-full text-xs font-medium items-center group flex gap-2 hover:text-primary-500 transition-all">
              <GearIcon className="color-base-400 group-hover:color-primary-500 transition-all" />
              <span>Settings</span>
            </button>
          )}
          <button onClick={onLogout} className="w-full px-4 py-2 text-base-400 text-xs min-w-max w-full font-medium items-center group flex gap-2 hover:text-primary-500 transition-all">
            <Icon name="logout-1" className="fill-base-400 group-hover:fill-primary-500 transition-all" />
            <span>Sign Out</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
