import { FC } from "react";

interface props {
    error: Error;
    onRetry?: () => void;
}

const FullscreenError: FC<props> = ({ error, onRetry = () => window.location.reload() }) => {
    return (
        <div className="flex flex-col items-center justify-center h-screen space-y-2">
            <h3>{error.message}</h3>
            <button className="button-primary" onClick={onRetry}>Reload</button>
        </div>
    )
};

export default FullscreenError;
