import { FC, useState } from 'react'
import Loader from './loaders/Loader'
import useUserContext from 'contexts/User/useUserContext'

const FilterSeller: FC = () => {
  const [seller, setSeller] = useState<string | undefined>(sessionStorage.getItem('filteredSeller') || "All")
  const sellerOptions = useUserContext()?.clients?.sort((a, b) => a.client.localeCompare(b.client))

  const onChange = (value: string) => {
    setSeller(value)
    if (value !== "All") {
      sessionStorage.setItem('filteredSeller', value)
      window.dispatchEvent(new StorageEvent('storage', { key: 'filteredSeller', newValue: value, storageArea: sessionStorage }))
    } else {
      sessionStorage.removeItem('filteredSeller')
      window.dispatchEvent(new StorageEvent('storage', { key: 'filteredSeller', newValue: null, storageArea: sessionStorage }))
    }
  }

  if (!sellerOptions) return (
    <div className="h-12 w-[200px]">
      <Loader />
    </div>
  )

  return (
    <>
      <div className="h-12 bg-base-50 px-4 w-full outline-none rounded-xl min-w-[200px]">
        <select
          name="state"
          onChange={(e) => onChange(e.target.value)}
          value={seller}
          className="peer h-full w-full bg-transparent cursor-pointer text-xl !text-base-900 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500"
        >
          <option value="All">All Sellers</option>
          {sellerOptions?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.client}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

export default FilterSeller
