import { FC, useMemo } from "react";
import useOrder from "../context/hook";
import { DistributionIcons } from "assets/icons";

const Shipments: FC = () => {
    const {order, setOrder} = useOrder()
    const state = order.status

    const stateNumber = useMemo(() => {
        if(state === "processing") {
            return 0
        }
        if (state === "sent") {
            return 1
        }
        if (state === "delivered") {
            return 2
        }

        return -1
    }, [state])
    
    if (state === "init") {
        return (
        <div className="flex gap-6 items-center bg-orange-200 rounded-t-2xl p-6">
            <span>
                Waiting for payment
            </span>
        </div>
        )
    }
    
    if (state === "cancelled") {
        return (
        <div className="flex gap-6 items-center bg-red-200 rounded-t-2xl p-6">
            <span>
                Order Cancelled
            </span>
        </div>
        )
    }

    const activeStroke = "stroke-primary-500"
    const inactiveStroke = "stroke-base-500"
    const activeText = "text-primary-500"
    const inactiveText = "text-base-500"
    const activeBorder = "border-primary-500"
    const inactiveBorder = "border-base-500"

    return (
        <div className="flex gap-6 items-center bg-white rounded-t-2xl p-6">
            <div className="flex text-xs font-medium gap-3 items-center">
                <DistributionIcons.Progress className="stroke-primary-500" />
                <span className="text-primary-500">Processing</span>
            </div>
            <div className="border border-primary-500 grow border-dashed" />
            <div className="flex text-xs font-medium gap-3 items-center">
                <DistributionIcons.Transit className={stateNumber > 0 ? activeStroke : inactiveStroke} />
                <span className={stateNumber > 0 ? activeText : inactiveText}>Out For Delivery</span>
            </div>
            <div className={["border border-primary-500 grow border-dashed", stateNumber > 0 ? activeBorder : inactiveBorder].asClass} />
            <div className="flex text-xs font-medium gap-3 items-center">
                <DistributionIcons.Arrived className={stateNumber > 1 ? activeStroke : inactiveStroke} />
                <span className={stateNumber > 1 ? activeText : inactiveText}>Arrived</span>
            </div>
        </div>
    )
}

export default Shipments