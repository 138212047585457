import api from "api";
import Header from "components/layout/Header";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ISellerDashboard } from "types/dashboards";
import ItemStats from "./components/ItemStats";
import useAwaitableModal from "hooks/useAwaitableModal";
import OnboardingVideoModal from "../modals/OnboardingVideo";
import Paginator from "components/Paginator";
import ordersApi from "api/orders";
import usePages from "hooks/usePages";
import { IOrder } from "types/orders";
import Order from "../Orders/components/Order";
import TableSpanner from "components/TableSpanner";
import FullLoader from "components/loaders/FullLoader";
import Empty from "components/Empty";

const SellerDashboard: FC = () => {
  const navigate = useNavigate()
  const [dashboard, setDashboard] = useState<ISellerDashboard>()
  const [request, Element] = useAwaitableModal(OnboardingVideoModal, {})
  const [page, setPage] = useState(0)
  const callback = useCallback(
    (page?: number) =>
    
    ordersApi.listSellersOrders(
            {},
            page
          ),
    []
  )
  const [orders, total, loading, setOrders] = usePages<IOrder>(callback, page, 'orders')

  const onDelete = (id: string) => {
    setOrders((old) => old?.filter(({ id: _id }) => id !== _id))
  }

  useEffect(() => {
    api.getDashboard().then(setDashboard)
  }, [])

  useEffect(() => {
    const isFirstOpen = localStorage.getItem("firstOpen") === "true"
    if (isFirstOpen) {
      localStorage.setItem("firstOpen", "false")
      //request()
    }
  }, [request])

  return (
    <div className="flex flex-col grow">
      <Element />
      <Header
        title="Dashboard"
        additionalButtons={[
          {
            id: 'add-warehouse-button',
            tooltip: "Add a Warehouse",
            bigButton: true,
            onClick: () => navigate('/app/dashboard/warehouses/add'),
          },
        ]}
      />
      <div className="grow flex flex-col overflow-y-auto pb-6">
        <header>Dashboard</header>
        <ItemStats itemStates={dashboard?.itemstates} orderStates={dashboard?.orderstates} />
        <div className="flex flex-col grow overflow-y-auto mb-5 px-6 mt-6">
          <table className="overflow-hidden">
            <colgroup>
              <col className="w-[200px]" />
              <col />
              <col />
              <col />
              <col className="w-[200px]" />
              <col className="w-[120px]" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <td>Name</td>
                <td>Sent At</td>
                <td>Asins</td>
                <td>Total Units</td>
                <td>Status</td>
                <td>Progress</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {orders?.map((order) => (
                <Order key={order.id} {...order} onDelete={onDelete} />
              ))}
              {!orders?.length && (
                <TableSpanner rows={4} cols={7}>
                  {orders === undefined ? <FullLoader /> : <Empty text="No Shipments" />}
                </TableSpanner>
              )}
            </tbody>
          </table>
        </div>
        <Paginator page={page} setPage={setPage} loading={loading} total={Math.ceil((total || 0) / 10)} />
      </div>
    </div>
  )
}

export default SellerDashboard