import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'

const pricing = {
  OA: {
    'All Inclusive': '$1.25',
    Bundle: '$0.25',
    'Additional Bundle': '$0.15 per Additional Bundle',
    'Medium Box': '$3.00',
    'Large Box': '$4.00',
  },
  Wholesale: {
    FNSKU: '$0.55',
    Polybag: '$0.20',
    'Bubble Wrap': '$0.25',
    'Oversize (greater than 5lb or one side longer than 12 inch)': '$0.50', 
    'Bundle 2': '$0.20',
    'Bundle 3-4': '$0.35',
    'Bundle 5-8': '$0.50',
    'Bundle 9-12': '$0.75',
    'Small Box': '$1.50',
    'Medium Box': '$2.50',
    'Large Box': '$3.50',
    'Pallet Handling': '$20.00',
  },
}

const PartneredPrepPricesModal: AwaitableModal = ({ open, resolve }) => {
  const [tab, setTab] = useState<'OA' | 'Wholesale'>('Wholesale')
  const prices = pricing[tab]

  return (
    <Modal open={open} close={resolve}>
      <div className="modal w-[512px] relative max-h-[calc(100vh-64px)]">
        <header className="!items-center">
          <h1 className="!leading-none p-3">Prepping Price</h1>
        </header>
        <div className="w-full overflow-y-auto !p-0">
        <div className="w-full flex border-b border-base-200">
          <button className={[tab === "Wholesale" ? "text-primary-500 border-b-primary-500" : "text-base-500 border-b-transparent", "grow py-5 px-6 font-medium text-sm hover:text-primary-500 border-b-2 hover:border-b-primary-500 transition-colors"].asClass} onClick={() => setTab("Wholesale")}>
            Wholesale / Private Label
          </button>
          <button className={[tab === "OA" ? "text-primary-500 border-b-primary-500" : "text-base-500 border-b-transparent", "grow py-5 px-6 font-medium text-sm hover:text-primary-500 border-b-2 hover:border-b-primary-500 transition-colors"].asClass} onClick={() => setTab("OA")}>
            Online Arbitrage
          </button>
        </div>
          <div className="flex flex-col h-max p-6">
            <div className="rounded-xl border border-base-200 divide-y divide-y-base-200 overflow-hidden">
              {Object.entries(prices).map(([key, value], i) => (
                <div key={tab + key} className="flex items-center justify-between text-sm p-5">
                  <span className="font-medium text-base-500">{key}</span>
                  <span className="text-base-950">{value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <footer className="p-6 flex items-center !justify-center w-full">
          <button className="button-secondary" onClick={resolve}>Close Window</button>
        </footer>
      </div>
    </Modal>
  )
}

export default PartneredPrepPricesModal
