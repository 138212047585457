import AmazonProductImage from 'components/AmazonProductImage'
import { FC } from 'react'
import { IAmazonListing } from 'types/amazon'

interface props {
  item: IAmazonListing
  shipmentQty?: number
  onChangeShipmentQuantity?: (quantity: number) => void
}

const Item: FC<props> = ({ item, shipmentQty, onChangeShipmentQuantity }) => {
  return (
    <tr>
      <td>
        <input type="number" placeholder="Qty" className="w-20 text-center" value={shipmentQty} onChange={(e) => onChangeShipmentQuantity?.(parseInt(e.currentTarget.value))} onBlur={(e) => onChangeShipmentQuantity?.(Math.max(parseInt(e.currentTarget.value), 0))} />
      </td>
      <td>
        <div className="flex items-center gap-6">
        <AmazonProductImage asin={item.asin} size={64} imageSize={128} className="cursor-pointer rounded-lg" />
        <div className="flex flex-col gap-2">
          <span className="text-sm text-base-950 font-semibold select-text">{item.name}</span>
          <div className="flex flex-col gap-2">
            <span className="text-xs text-base-500 font-medium flex gap-2">
              <span className="select-text">{item.fnsku}</span>
              <span className="text-base-200">|</span>
              <span className="select-text">{item.sku}</span>
              <span className="text-base-200">|</span>
              <span className="select-text">{item.asin}</span>
            </span>
          </div>
        </div>
        </div>
      </td>
    </tr>
  )
}

export default Item
