import Empty from 'components/Empty'
import FullLoader from 'components/loaders/FullLoader'
import { FC, useEffect, useRef, useState } from 'react'
import Item from './components/Item'
import TableSpanner from 'components/TableSpanner'
import { Tooltip } from '@material-tailwind/react'
import { createPortal } from 'react-dom'
import useSelectedSeller from 'hooks/useSelectedSeller'
import Icon from 'components/Icon'
import { IAmazonListing } from 'types/amazon'
import api from 'api'
import { useNavigate } from 'react-router-dom'
import inventoryApi from 'api/inventory'
import useFloatThead from 'hooks/useFloatThead'
import $ from 'jquery'

const getKey = (item: IAmazonListing) => item.fnsku + item.asin + item.sku

const UserCataloguePage: FC = () => {
  const selectedSeller = useSelectedSeller()
  const [search, setSearch] = useState('')
  const [catalog, setCatalog] = useState<IAmazonListing[]>()
  const [added, setAdded] = useState<{ id: string; quantity: number }[]>([])
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [tableRef, headHeight] = useFloatThead({ autoReflow: true, headerCellSelector: '#floathead td' })

  useEffect(() => {
    if (!selectedSeller) return
    setCatalog(undefined)
    setAdded([])
    api.getUserListings(selectedSeller).then(({ listings }) => setCatalog(listings))
  }, [selectedSeller])

  const getShipmentQuantity = (id: string) => {
    const item = added.find((i) => i.id === id)
    return item ? item.quantity : 0
  }

  const setShipmentQuantity = (id: string, quantity: number) => {
    setAdded((prev) => prev.filter((i) => i.id !== id).concat({ id, quantity }))
  }

  const onAddToInventory = () => {
    if (!added.length) return
    if (!catalog) return
    if (!selectedSeller) return
    if (submitting) return
    setSubmitting(true)
    const formatted = added
      .map(({ id, quantity }) => {
        const listing = catalog.find((l) => getKey(l) === id)
        if (!listing) return undefined
        const { asin, sku, name, fnsku } = listing
        const formatted = { quantity, item: { asin, sku, name, fnsku } }
        return listing && quantity ? formatted : undefined
      })
      .filter(Boolean) as any

    inventoryApi
      .addToInventory(selectedSeller, formatted)
      .then(() => {
        navigate('/app/inventory/' + selectedSeller)
      })
      .finally(() => setSubmitting(false))
  }

  const onCreateOrder = () => {
    if (!catalog) return
    const formatted = catalog.map((listing) => {
      const id = getKey(listing)
      const item = added.find((i) => i.id === id)
      return item?.quantity ? { ...listing, count: item.quantity, selected: true } : listing
    })

    navigate('/app/orders/create/' + selectedSeller, { state: { listings: formatted } })
  }

  const somethingSelected = added.reduce((acc, cur) => acc + cur.quantity, 0) > 0

  const searchedItems = search ? catalog?.filter(({ name, sku, fnsku, asin }) => [name, sku, fnsku, asin].some((i) => i.toLowerCase().startsWith(search.toLowerCase()))) : catalog

  return (
    <div className="overflow-y-hidden gap-6 justify-start flex flex-col grow">
      {!!document.getElementById('inventoryButtonsAnchor') &&
        createPortal(
          <>
            <Tooltip placement="left" content={somethingSelected ? '' : 'Add Items To Continue'}>
              <button className="button-primary" children="Add To Inventory" disabled={!somethingSelected || submitting} onClick={onAddToInventory} />
            </Tooltip>
            <button className="button-primary" children="Create New Shipment" onClick={onCreateOrder} />
          </>,
          document.getElementById('inventoryButtonsAnchor') as HTMLElement
        )}
      {!selectedSeller && <Empty text="No Client Selected, Select a Client to Continue" />}
      {selectedSeller && (
        <section className="box bg-white overflow-y-hidden grow flex flex-col">
          <div className="p-6 bg-white border-b border-base-200">
            <div className="relative">
              <Icon name="search-normal-1" className="fill-base-950 absolute pointer-events-none right-4 top-1/2 -translate-y-1/2" />
              <input
                className="border-0 bg-base-100 w-full rounded-lg outline-none p-4 pr-14 text-base-950 placeholder:text-base-950"
                placeholder="Search something..."
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="flex grow w-full overflow-auto">
            <table ref={tableRef} className="w-full" style={{ paddingTop: headHeight }}>
              <thead>
                <tr id="floathead">
                  <td className="w-20">Quantity</td>
                  <td>Title / SKU / FNSKU / ASIN</td>
                </tr>
              </thead>
              <tbody>
                {searchedItems === undefined ? (
                  <TableSpanner cols={3} rows={2}>
                    <FullLoader />
                  </TableSpanner>
                ) : searchedItems.length ? (
                  searchedItems.map((item) => (
                    <Item key={getKey(item)} item={item} shipmentQty={getShipmentQuantity(getKey(item))} onChangeShipmentQuantity={(qty: number) => setShipmentQuantity(getKey(item), qty)} />
                  ))
                ) : (
                  <Empty text="No Items In Catalog" />
                )}
              </tbody>
            </table>
          </div>
        </section>
      )}
    </div>
  )
}

export default UserCataloguePage
