export const print = (src: string, dimensions = [2.5, 0.9]) => {
  function setPrint({ currentTarget: e }: { currentTarget: HTMLIFrameElement }) {
    const closePrint = () => {
      document.body.removeChild(e as HTMLIFrameElement)
    }

    if (!e.contentWindow) return
    e.contentWindow.onbeforeunload = closePrint
    e.contentWindow.onafterprint = closePrint
    e.contentWindow.print()
  }

  const hideFrame = document.createElement('iframe') as HTMLIFrameElement
  hideFrame.onload = setPrint as any
  hideFrame.style.display = 'none' // hide iframe
  hideFrame.style.position = 'fixed'
  hideFrame.style.top = '0'
  hideFrame.style.left = '0'
  hideFrame.src = src
  hideFrame.style.width = dimensions[0] + 'in'
  hideFrame.style.height = dimensions[1] + 'in'
  hideFrame.setAttribute("data-print", "true")
  const previousPrint = document.querySelector('iframe[data-print="true"]')
  if (previousPrint) document.body.removeChild(previousPrint)
  document.body.appendChild(hideFrame)
}
