import { userData } from 'App'
import { FC } from 'react'
import Loader from './loaders/Loader'
import useAgencyContext from 'contexts/Agency/useAgencyContext'

const ActAsSelector: FC = () => {
  const {actAs, setActAs, actAsOptions} = useAgencyContext()

  const onChange = (value: string) => {
    if (!actAsOptions) return
    setActAs(value)
    userData.current?.setUser(null)
  }

  if (!actAsOptions) return (
    <div className="h-12 w-[200px]">
      <Loader />
    </div>
  )

  return (
    <>
      <div className="h-12 bg-slate-100 w-full px-4 outline-none rounded-xl min-w-[200px]">
        <select
          name="state"
          onChange={(e) => onChange(e.target.value)}
          value={actAs || "Me"}
          className="peer h-full w-full bg-transparent cursor-pointer text-xl !text-dark outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500"
        >
          {Object.entries(actAsOptions).map(([id, name]) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

export default ActAsSelector
