import { FC } from 'react'
import Stripe from 'stripe'

const getClass = (status: Stripe.Invoice.Status | 'automatic charge failed' | null) => {
  switch (status) {
    case 'draft':
      return 'bg-base-200 text-base-900'
    case 'open':
      return 'bg-yellow-200 text-yellow-900'
    case 'paid':
      return 'bg-green-200 text-green-900'
    case 'void':
      return 'bg-red-200 text-red-900'
    case 'uncollectible':
      return 'bg-red-200 text-red-900'
    case 'automatic charge failed':
        return 'bg-orange-200 text-orange-900'
    default:
      return ''
  }
}

const InvoiceStatus: FC<{ invoice: Stripe.Invoice }> = ({ invoice }) => {
    const status = (invoice.attempted && !invoice.paid && invoice.status === "open") ? 'automatic charge failed' : invoice.status 
  return <span className={`tag capitalize !text-md ${getClass(status)}`}>{status === 'open' ? 'Sent' : status}</span>
}

export default InvoiceStatus
