import { ReactComponent as First } from './first.svg'
import { ReactComponent as Star } from './star.svg'
import { ReactComponent as Check } from './check.svg'
import { default as SecondLeft } from './left.png'
import { default as SecondCenter } from './center.png'
import { default as SecondRight } from './right.png'

export const PromoV2 = {
  first: First,
  secondSrc: {
    left: SecondLeft,
    center: SecondCenter,
    right: SecondRight,
  },
  star: Star,
  check: Check,
}
