export const iconByPaymentMethod = {
    'visa': require('./1.png'),
    'mastercard': require('./2.png'),
    'maestro': require('./3.png'),
    'cirrus': require('./4.png'),
    'paypal': require('./5.png'),
    'westernunion': require('./6.png'),
    'visaelectron': require('./7.png'),
    'amazon': require('./8.png'),
    'worldpay': require('./9.png'),
    'dinersclub': require('./10.png'),
    'webmoney': require('./11.png'),
    'skrill': require('./12.png'),
    'sage': require('./13.png'),
    'discover': require('./14.png'),
    'moneybookers': require('./15.png'),
    'jcb': require('./16.png'),
    'ebay': require('./17.png'),
    'eway': require('./18.png'),
    'stripe': require('./19.png'),
    'solo': require('./20.png'),
    'directdebit': require('./21.png'),
    'americanexpress': require('./22.png'),
    'shopify': require('./23.png'),
    'sepa': require('./sepa.webp'),
} as Record<string, any>