import Header from 'components/layout/Header'
import { FC, FormEvent, useState } from 'react'
import WarehouseContactForm from './components/WarehouseContactForm'
import { IWarehouseContactInfo } from 'types/warehouses'
import { useNavigate } from 'react-router-dom'
import api from 'api'

const AddWarehousePage: FC = () => {
  const [contact, setContact] = useState<Omit<IWarehouseContactInfo, "name" | "country">>({
    city: '',
    email: '',
    phoneNumber: '',
    state: '',
    streetAddress: '',
    zipCode: '',
  })
  const [name, setName] = useState<string>("")
  const [publicName, setPublicName] = useState<string>("")
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const dataFilledOut = Object.entries(contact).every(([, value]) => value?.length > 0)
  
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!dataFilledOut || submitting || !publicName.length || !name.length) return false
    setSubmitting(true)
    api
      .createWarehouse({ name, publicName, contactInfo: { name: publicName, country: "US", ...contact} })
      .catch(() => setSubmitting(false))

    return false
  }

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header title="Create a shipment" />
      <div className="flex flex-col grow overflow-y-hidden">
        <header>Add a Warehouse</header>
        <div className="flex overflow-y-hidden w-full grow overflow-x-auto pb-5 px-8 -mt-3 space-x-5">
            <section className="box bg-white flex flex-col w-[270px] min-w-[270px] h-full pb-5">
              <header>
                <span className="flex-grow">New Warehouse</span>
              </header>
            <input name="name" placeholder="Internal Name" value={name} className="mx-5 mt-5" onChange={(e) => setName(e.target.value)} />
            <input name="publicName" placeholder="Public Facing Name" value={publicName} className="mx-5 mt-3" onChange={(e) => setPublicName(e.target.value)} />
          </section>
          <div className="flex flex-col h-full overflow-hidden grow min-w-[400px]">
            <section className="box bg-white flex flex-col h-full mb-5">
              <header>
                <span className="flex-grow">Contact Info</span>
              </header>
              <div className="overflow-y-auto overflow-x-hidden w-full">
                <WarehouseContactForm
                  contact={contact}
                  setContact={setContact}
                />
              </div>
            </section>
            <div className="bg-base-900 flex items-center p-5 rounded-[12px] justify-between">
              <button className="button-dark" onClick={() => navigate(-1)}>
                Cancel
              </button>
              <div>
                <span />
                <button className="button-primary" disabled={!dataFilledOut || submitting} onClick={handleSubmit}>
                  {submitting ? 'Adding...' : 'Add a Warehouse'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddWarehousePage
