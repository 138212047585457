import { handleError } from 'helpers/errors'
import { FC, useState } from 'react'
import { IDistributionOrder } from 'types/admin'
import Progress from './Progress'
import IconButton from 'components/buttons/IconButton'
import adminApi from 'api/admin'
import { useNavigate } from 'react-router-dom'
import useAreYouSure from 'hooks/useAreYouSure'

interface props {
  order: IDistributionOrder
  onDelete: (order: IDistributionOrder) => void
}

const statuses = ['init', 'processing', 'sent', 'delivered']

const statusColors = {
  init: 'bg-red-100 text-red-800',
  processing: 'bg-blue-100 text-blue-800',
  sent: 'bg-green-100 text-green-800',
  delivered: 'bg-green-100 text-green-800',
  cancelled: 'bg-red-100 text-red-800',
}

const statusText = {
  init: 'Awaiting Payment',
  processing: 'Processing',
  sent: 'Sent',
  delivered: 'Delivered',
  cancelled: 'Cancelled',
}

const DistOrder: FC<props> = ({ order, onDelete }) => {
  const [deleting, setDeleting] = useState(false)
  const [areYouSure, AreYouSureComponent] = useAreYouSure("This order will be deleted.")
  const navigate = useNavigate()

  const handleDelete = async () => {
    if (deleting) return
    areYouSure(() => {
    setDeleting(true)
    adminApi
      .deleteDistributionOrder(order.id)
      .then(() => {
        onDelete(order)
      })
      .finally(() => setDeleting(false))
  })
  }

  const onCopy = () => {
    navigator.clipboard.writeText(order.id)
    handleError('Copied to clipboard')
  }

  const toOrder = () => {
    navigate(`/app/dist-orders/${order.id}`)
  }

  const upcs = Array.from(new Set(order.data.map((item) => item.upc))).length
  const totalUnits = order.data.reduce((acc, item) => acc + item.count * (item.caseQty || 1), 0)
  const progress = order.status === "cancelled" ? 1 : (statuses.indexOf(order.status) + 1) / (statuses.length)

  return (
    <tr>
      <AreYouSureComponent />
      <td>
        <div className="flex flex-col gap-1">
          <span className="text-base-500 text-xs font-semibold">{order.userContact}</span>
          <span className="text-base-950 text-sm font-medium whitespace-nowrap">
            <span className="font-normal">ID:</span>{' '}
            <span className="cursor-pointer" onClick={onCopy}>
              {order.id}
            </span>
          </span>
          <span className="text-base-500 text-[0.6125rem]">PO Number</span>
        </div>
      </td>
      <td>
        <div className="flex flex-col gap-1">
          <span className="text-base-950 text-sm font-medium">{new Date(order.createdAt).toLocaleDateString()}</span>
          <span className="text-base-500 text-[0.6125rem]">Purchase Order Date</span>
        </div>
      </td>
      <td>
        {upcs} UPC{upcs === 1 ? '' : 's'}
      </td>
      <td>
        {totalUnits.toLocaleString('en-US')} Total Unit{totalUnits === 1 ? '' : 's'}
      </td>
      <td>
        <span className={['tag', statusColors[order.status]].asClass}>{statusText[order.status]}</span>
      </td>
      <td>
        <Progress progress={progress} />
      </td>
      <td>
        <div className="flex items-center">
          <IconButton name="edit-2" tooltip="Manage Order" onClick={toOrder} disabled={deleting} tooltipLocation="right" className="mx-2 my-2 !p-0" />
          <IconButton name="trash" tooltip="Delete Order" onClick={handleDelete} disabled={deleting} tooltipLocation="right" className="mx-2 my-2 !p-0" />
        </div>
      </td>
    </tr>
  )
}

export default DistOrder
