import IconButton from 'components/buttons/IconButton'
import { FC } from 'react'
import { IOrder } from 'types/orders'

export interface ISPDBoxConfig {
  boxId: string
  sku: string
  quantity: number | undefined
  numBoxes: number | undefined
  expiryDate: string | undefined
  length: number | undefined
  width: number | undefined
  height: number | undefined
  weight: number | undefined
}

interface props {
  item: IOrder['itemsData'][0]
  box: ISPDBoxConfig
  setBox: (box: ISPDBoxConfig) => void
  onDelete: () => void
}
const BoxConfig: FC<props> = ({ item, box, setBox, onDelete }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.type === "number" ? Number(e.currentTarget.value) : e.currentTarget.value
    setBox({ ...box, [e.currentTarget.name]: value })
  }

  return (
    <div className="text-xs text-base-900 p-4 flex items-center text-left grid gap-4 grid-cols-6">
      <div className="col-span-3 flex items-center relative">
        <span className="grow w-max absolute pl-4 text-sm text-base-400 pointer-events-none">Units Per Box</span>
        <input name="quantity" min={0} type="number" className="text-right w-full" placeholder="-" value={box.quantity} onChange={onChange} />
      </div>
      <div className="col-span-3 flex items-center relative">
        <span className="grow w-max absolute pl-4 text-sm text-base-400 pointer-events-none">No. of Boxes</span>
        <input name="numBoxes" min={0} type="number" className="text-right w-full" placeholder="-" value={box.numBoxes} onChange={onChange} />
      </div>
      <div className="col-span-2 flex items-center relative">
        <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
          <span className="grow w-max text-sm text-base-400">W</span>
          <span className="grow w-max text-[7px] text-base-400">Inches</span>
        </div>
        <input name="width" min={0} type="number" className="text-right w-full" placeholder="-" value={box.width} onChange={onChange} />
      </div>
      <div className="col-span-2 flex items-center relative">
        <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
          <span className="grow w-max text-sm text-base-400">H</span>
          <span className="grow w-max text-[7px] text-base-400">Inches</span>
        </div>
        <input name="height" min={0} type="number" className="text-right w-full" placeholder="-" value={box.height} onChange={onChange} />
      </div>
      <div className="col-span-2 flex items-center relative">
        <div className="flex items-start h-min absolute pl-4 space-x-1 pointer-events-none">
          <span className="grow w-max text-sm text-base-400">L</span>
          <span className="grow w-max text-[7px] text-base-400">Inches</span>
        </div>
        <input name="length" min={0} type="number" className="text-right w-full" placeholder="-" value={box.length} onChange={onChange} />
      </div>
      <div className="col-span-3 flex items-center relative">
        <span className="grow w-max absolute pl-4 text-sm text-base-400 pointer-events-none">Weight</span>
        <input name="weight" min={0} type="number" className="text-right w-full" placeholder="-" value={box.weight} onChange={onChange} />
      </div>
      <div className="col-span-3 flex items-center relative border border-base-200 h-full px-4 rounded-lg text-sm">
        <span className="grow w-max absolute text-sm text-base-400 pointer-events-none">Total Quantity</span>
        <span className="text-right w-full">{(box.numBoxes || 0) * (box.quantity || 0)}</span>
      </div>
      <div className="col-span-6 flex items-center gap-4">
        <input name="expiryDate" type="date" className="grow" value={item.expiryDate} onChange={onChange} />
        <IconButton name="trash" onClick={onDelete} />
      </div>
    </div>
  )
}

export default BoxConfig
