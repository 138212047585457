import { FC, PropsWithChildren } from 'react'
import { Routes, useLocation, useNavigate } from 'react-router'

const ModalRoutes: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const closeModal = () => {
    navigate(-1)
  }

  if (!location?.state?.background) return null

  const calcPosition = (anchor?: string) => {
    if (!anchor) return
    const el = document.getElementById(anchor)
    if (!el) return
    const rect = el.getBoundingClientRect()
    return {
      top: rect.bottom + 8,
      right: window.innerWidth - rect.right,
      boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.3)',
    }
  }

  return (
    <div
      className="fixed z-[10000] w-screen h-screen flex items-center justify-center left-0 top-0"
      style={{
        ...(!location.state?.anchor && { backgroundColor: 'rgba(0,0,0,0.7)' }),
      }}
      onClick={closeModal}
    >
      <div onClick={(e) => e.stopPropagation()} style={{ ...calcPosition(location.state?.anchor), position: 'absolute' }} className="rounded-xl">
        <Routes>{children}</Routes>
      </div>
    </div>
  )
}

export default ModalRoutes
