import { IShippingData } from "types/orders"

const getDoneCountFromBoxes = (boxes: IShippingData["boxes"]) => {
    const done: Record<string, number> = {}
    boxes.forEach((box) => {
        box.items.forEach((item) => { 
        if (!done[item.sku]) done[item.sku] = 0
        done[item.sku] += item.quantity
        })
    })
    return done
}

export default getDoneCountFromBoxes