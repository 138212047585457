import { Navigate, Route, Routes } from 'react-router-dom'
import AuthPage from '../../pages/auth/Auth'
import RegisterPage from '../../pages/auth/Register'
import PublicLayout from 'layouts/Public'
import PasswordResetPage from 'pages/auth/PasswordReset'
import RegisterPrepPage from 'pages/auth/RegisterPrep'

const UnauthorizedRouter = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route index element={<AuthPage />} />
        <Route path="login" element={<AuthPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="register/prep">
          <Route index element={<RegisterPrepPage />} />
          <Route path=":referrer" element={<RegisterPrepPage />} />
        </Route>
        <Route path="register/:referrer" element={<RegisterPage />} />
        <Route path="reset-password" element={<PasswordResetPage />} />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Route>
    </Routes>
  )
}

export default UnauthorizedRouter
