import ordersApi from 'api/orders'
import Loader from 'components/loaders/Loader'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { TransportContent } from 'types/amazon'
import { IOrder } from 'types/orders'
import SPDFlowModal from './SPDFlowModal'
import useAwaitableModal from 'hooks/useAwaitableModal'
import LTLFlowModal from './LTL'

interface props {
  order: IOrder
  setOrder: Dispatch<SetStateAction<IOrder | undefined>>
}

const BuyShipping: FC<props> = ({ order, setOrder }) => {
  const [transportDetails, setTransportDetails] = useState<TransportContent | {} | undefined>()
  const [buying, setBuying] = useState(false)
  const [requestSPD, SPD] = useAwaitableModal(SPDFlowModal, { order, transport: Object.keys(transportDetails || {}).length ? (transportDetails as TransportContent) : undefined })
  const [requestLTL, LTL] = useAwaitableModal(LTLFlowModal, { order, transport: Object.keys(transportDetails || {}).length ? (transportDetails as TransportContent) : undefined })
  const state = (transportDetails as TransportContent)?.TransportResult?.TransportStatus || ''
  const buttonText = state === 'CONFIRMED' ? 'View Shipping' : 'Buy Shipping'

  const requestParams =  { order, transport: Object.keys(transportDetails || {}).length ? (transportDetails as TransportContent) : undefined }
  const allBoxesPresent = requestParams.transport?.TransportDetails?.PartneredSmallParcelData?.PackageList?.length === order.shipmentData?.boxes?.length

  useEffect(() => {
    ordersApi
      .getAmazonShipment(order.id)
      .then(setTransportDetails)
      .catch(() => setTransportDetails({}))
  }, [order.id])

  const onBuyShipping = () => {
    if (!order.shipmentData || buying) return
    setBuying(true)
    if (order.shipmentData.type === 'SPD') {
      if (transportDetails && Object.keys(transportDetails).length && allBoxesPresent) return requestSPD(requestParams).finally(() => setBuying(false))
      ordersApi.putTransportDetails(order.id).then(() =>
        requestSPD(requestParams).then((updatedState) => {
          setTransportDetails(updatedState)
          setOrder(
            (old) =>
              old &&
              old.shipmentData && {
                ...old,
                shipmentData: {
                  ...old.shipmentData,
                  transportState: updatedState.TransportResult.TransportStatus,
                },
              }
          )
          setBuying(false)
        })
      )
    } else {
      requestLTL(requestParams).then((updatedState) => {
        if (!updatedState) return
        setTransportDetails(updatedState)
        setOrder(
          (old) =>
            old &&
            old.shipmentData && {
              ...old,
              shipmentData: {
                ...old.shipmentData,
                transportState: updatedState.TransportResult.TransportStatus,
              },
            }
        )
      }).finally(() => setBuying(false))
    }
  }

  if (order.bill) return null

  return transportDetails === undefined ? (
    <Loader size={20} />
  ) : (
    <>
      <SPD />
      <LTL />
      <button disabled={buying} onClick={onBuyShipping} className="border border-amber-500 flex gap-2 px-4 py-2 rounded-full items-center hover:bg-amber-50 transition-all">
        <span className="text-amber-500 font-medium text-sm w-max">{buttonText}</span>
      </button>
    </>
  )
}

export default BuyShipping
