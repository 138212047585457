import { useEffect, useState } from "react";

export interface ICart {
    prices: {
        [brandUpc: string]: {
            unitPrice: number,
            caseQty: number
            name: string
        }
    },
    items: {
        [brand: string]: {
        [upc: string]: number
    }
}
}

const emptyCart = '{"prices":{},"items":{}}';

const useShoppingCart = () => {
    const [cart, setCart] = useState<ICart>(JSON.parse(localStorage.getItem("shoppingCart") || emptyCart))

    const deltaCart = (brand: string, upc: string, cases: number, caseQty: number, unitPrice: number, name: string) => {
        const newCart = { ...cart }
        if (!newCart.items[brand]) {
            newCart.items[brand] = {}
        }
        if (!newCart.items[brand][upc]) {
            newCart.items[brand][upc] = 0
        }
        newCart.items[brand][upc] += cases
        if (newCart.items[brand][upc] <= 0) {
            delete newCart.items[brand][upc]
            delete newCart.prices[`${brand}_${upc}`]
        } else {
            newCart.prices[`${brand}_${upc}`] = { unitPrice, caseQty, name }
        }
        if (Object.keys(newCart.items[brand]).length === 0) {
            delete newCart.items[brand]
        }
        setCart(newCart)
        localStorage.setItem("shoppingCart", JSON.stringify(newCart))
        window.dispatchEvent(new Event("storage"))
    }

    const changeCountCart = (brand: string, upc: string, cases: number, caseQty: number, unitPrice: number, name: string) => {
        const newCart = { ...cart }
        if (!newCart.items[brand]) {
            newCart.items[brand] = {}
        }
        newCart.items[brand][upc] = cases
        if (newCart.items[brand][upc] <= 0) {
            delete newCart.items[brand][upc]
            delete newCart.prices[`${brand}_${upc}`]
        } else {
            newCart.prices[`${brand}_${upc}`] = { unitPrice, caseQty, name }
        }
        if (Object.keys(newCart.items[brand]).length === 0) {
            delete newCart.items[brand]
        }
        setCart(newCart)
        localStorage.setItem("shoppingCart", JSON.stringify(newCart))
        window.dispatchEvent(new Event("storage"))
    }

    const clear = () => {
        localStorage.setItem("shoppingCart", emptyCart)
        window.dispatchEvent(new Event("storage"))
    }

    useEffect(() => {
        window.addEventListener("storage", () => {
            setCart(JSON.parse(localStorage.getItem("shoppingCart") || emptyCart))
        })
    })

    const totalItems = Object.keys(cart.items).reduce((acc, brand) => {
        return acc + Object.keys(cart.items[brand]).reduce((acc, upc) => {
            return acc + cart.items[brand][upc] * cart.prices[`${brand}_${upc}`].caseQty
        }, 0)
    }, 0)

    return { cart, deltaCart, changeCountCart, totalItems, clear }
}

export default useShoppingCart