import { ArrowMoreIcon } from 'assets/icons'
import { FC } from 'react'

interface props {
  question: string
  answer: string
}

const FAQQuestion: FC<props> = ({ question, answer }) => {
  return (
    <div className="relative faq overflow-hidden h-full w-full text-base-950 px-12">
      <div onClick={(e) => {
            const elements = e.currentTarget.parentElement?.parentElement?.getElementsByClassName('faq')
            if (!elements) return
            for (let i = 0; i < elements?.length; i++) {
                if (elements[i] === e.currentTarget.parentElement) continue
                elements[i].classList.remove('open')
            }
            e.currentTarget.parentElement?.classList.toggle('open')
        }} className="flex pt-6 mobile:pt-12 items-center justify-between cursor-pointer text-lg mobile:text-xl font-extrabold">
        <span>{question}</span>
        <ArrowMoreIcon />
      </div>
      <div className="relative mobile:text-lg text-sm">
        <div className="pt-2 mobile:py-3 absolute" dangerouslySetInnerHTML={{__html: answer}}/>
        <div className="pt-2 mobile:py-3 text-transparent" dangerouslySetInnerHTML={{__html: answer}}/>
      </div>
    </div>
  )
}

export default FAQQuestion
