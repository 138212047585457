import ordersApi from 'api/orders'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import { TransportContent } from 'types/amazon'
import { IOrder } from 'types/orders'
import Icon from 'components/Icon'
import FullLoader from 'components/loaders/FullLoader'
import { print } from 'helpers/print'

const SPDFlowModal: AwaitableModal<TransportContent, { order: IOrder; transport?: TransportContent }> = ({ initialData: { order, transport: _transport }, open, resolve }) => {
  const [transport, setTransport] = useState<TransportContent | undefined>(_transport)
  const shippingCostUnformatted = transport?.TransportDetails?.PartneredSmallParcelData?.PartneredEstimate?.Amount?.Value
  const shippingCost = shippingCostUnformatted ? `$${shippingCostUnformatted.toFixed(2)}` : 'Not Estimated'
  const state = transport?.TransportResult?.TransportStatus || ''
  const buttonText = ['WORKING', 'ERROR_ON_ESTIMATING', 'VOIDED'].includes(state)
    ? 'Estimate Shipping Cost'
    : ['ESTIMATED', 'ERROR_ON_CONFIRMING'].includes(state)
    ? 'Accept Amazon Terms & Confirm Charges'
    : ['CONFIRMED', 'ERROR_ON_VOIDING'].includes(state)
    ? 'Void Charges'
    : 'Estimate Shipping Cost'
  const [submitting, setSubmitting] = useState(false)
  const spdData = transport?.TransportDetails?.PartneredSmallParcelData

  useEffect(() => {
    if (!transport) {
      ordersApi.getAmazonShipment(order.id).then(setTransport)
    }
  }, [transport, order.id])

  const onSubmit = () => {
    if (submitting) return
    setSubmitting(true)
    switch (buttonText) {
      case 'Estimate Shipping Cost':
        ordersApi
          .estimateTransport(order.id)
          .then(setTransport)
          .finally(() => setSubmitting(false))
        break
      case 'Accept Amazon Terms & Confirm Charges':
        ordersApi
          .confirmTransport(order.id)
          .then(setTransport)
          .finally(() => setSubmitting(false))
        break
      case 'Void Charges':
        ordersApi
          .voidTransport(order.id)
          .then(setTransport)
          .finally(() => setSubmitting(false))
        break
    }
  }

  const onPrint = () => {
    if (submitting) return
    setSubmitting(true)
    ordersApi
      .downloadShippingLabels(order.id)
      .then((url) => {
        fetch(url)
          .then((res) => res.blob())
          .then((res) => print(URL.createObjectURL(res)))
      })
      .finally(() => setSubmitting(false))
  }

  const onBack = () => {
    resolve(transport)
  }

  return (
    <Modal open={open} close={onBack}>
      <div className="modal relative max-h-[calc(100vh-64px)] min-w-[500px]">
        <header className="!items-center flex gap-3">
          <span className="grow text-xl">Buy Shipping and Complete Shipment</span>
        </header>
        <div className="flex flex-col w-full overflow-y-auto p-6 gap-3">
          <span className="text-base font-medium">Shipping Charges</span>
          {!transport ? (
            <FullLoader />
          ) : (
            <div className="grid grid-cols-2 border border-base-200 rounded-xl divide-y divide-y-base-200">
              <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Summary</div>
              <div className="text-xs text-base-900 p-4 flex items-center justify-center text-left border-t-transparent">
                <div className="flex flex-col items-center">
                  <Icon type="bulk" name="box-1" size={48} />
                  <span className="text-sm text-base-900 font-medium mt-3 mb-1 min-w-[130px] text-center">Total Boxes</span>
                  <span className="text-xs text-base-400 font-medium">{order.shipmentData?.boxes?.length || 0}</span>
                </div>
              </div>
              <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Estimates</div>
              <div className="text-xs text-base-900 p-4 flex items-center justify-center text-left">
                <div className="flex flex-col items-center">
                  <Icon type="bulk" name="cards" size={48} />
                  <span className="text-sm text-base-900 font-medium mt-3 mb-1 min-w-[130px] text-center">Shipping Cost</span>
                  <span className="text-xs text-base-400 font-medium">{shippingCost}</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <footer className="bg-base-900 not-themed gap-4">
          <div className="flex gap-4">
            <button className="button-dark" onClick={onBack}>
              Cancel
            </button>
            {state === 'CONFIRMED' ? (
              <button className="button-dark" disabled={submitting} onClick={onPrint}>
                Print All
              </button>
            ) : null}
          </div>
          <div className="flex gap-4 text-rose-600 items-center">
            {
              buttonText === "Void Charges" ? <span>Void by {spdData?.PartneredEstimate?.VoidDeadline ? new Date(spdData?.PartneredEstimate?.VoidDeadline || '').toLocaleString() : ''}</span> : null
            }
          <button disabled={submitting} onClick={onSubmit} className="button-primary">
            {buttonText}
          </button>
          </div>
        </footer>
      </div>
    </Modal>
  )
}

export default SPDFlowModal
