import { FC } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import useUserContext from '../../contexts/User/useUserContext'
import Header from 'components/layout/Header'
import Icon from 'components/Icon'
import IconButton from 'components/buttons/IconButton'
import useAuthContext from 'contexts/Auth/useAuthContext'
import useAgencyContext from 'contexts/Agency/useAgencyContext'
import { SettingsIcons } from 'assets/icons'

const SettingsLayout: FC = () => {
  const user = useUserContext()
  const location = useLocation()
  const navigate = useNavigate()
  const { isAgency } = useAgencyContext()

  if (location.pathname === '/app/settings') {
    if (user?.isPrepCenter) {
      return <Navigate to="/app/settings/basic" replace />
    } else {
      return <Navigate to="/app/settings/amazon" replace />
    }
  }

  const isActive = (path: string) => location.pathname.startsWith(path)

  const activeClass = 'color-primary-500 border-b-2 border-primary-500 text-primary-500'

  const inactiveClass = 'color-base-500 hover:color-primary-500 border-b border-base-200 text-base-500 hover:text-primary-500'

  const prepCenterSettings = [
    {
      name: 'Basic',
      path: 'basic',
      icon: SettingsIcons.Basic,
    },
    {
      name: 'Merchants',
      path: 'merchants',
      icon: SettingsIcons.Merchants,
    },
    {
      name: 'Warehouses',
      path: 'warehouses',
      icon: SettingsIcons.Building,
    },
    {
      name: 'Subscription',
      path: 'subscription',
      icon: SettingsIcons.Card,
    },
  ] as const

  const sellerSettings = [
    {
      name: 'Profile',
      path: 'profile',
      icon: SettingsIcons.Profile,
    },
    {
      name: 'Security',
      path: 'security',
      icon: SettingsIcons.Security,
    },
    {
      name: 'Amazon',
      path: 'amazon',
      icon: SettingsIcons.Box,
    },
    {
      name: 'Billing Method',
      path: 'billing',
      icon: SettingsIcons.Card,
    },
    {
      name: 'Subscription',
      path: 'subscription',
      icon: SettingsIcons.Card,
    },
    {
      name: 'Management',
      path: 'management',
      icon: SettingsIcons.Merchants,
    },
  ] as const

  const settings = user?.isPrepCenter ? prepCenterSettings : sellerSettings

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header title="Settings" />
      <div className="grow flex flex-col overflow-hidden gap-6">
        <header>Settings</header>
        <div className="border-t border-t-base-200 flex items-center justify-start mx-6 -mt-6">
          {settings.map((route) => (
            <div
              style={{ height: '-webkit-fill-available' }}
              className={['flex items-center p-5 items-center cursor-pointer transition-colors box-content', isActive(`/app/settings/${route.path}`) ? activeClass : inactiveClass].asClass}
              onClick={() => navigate(`/app/settings/${route.path}`, { replace: true })}
            >
              <route.icon className="w-6 h-6 color-inherit" />
              <span className="font-medium grow text-base w-max pl-4 pr-2">{route.name}</span>
            </div>
          ))}
          <div className="grow border-b border-b-base-200 h-full" />
        </div>
        <div className="flex grow px-6 pb-6 xl:gap-6 space-x-0 overflow-hidden relative">
          <div className="w-full flex flex-col h-full relative space-y-6 bg-base-100 rounded-lg">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsLayout
