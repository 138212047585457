import useUserContext from 'contexts/User/useUserContext'
import { FC } from 'react'
import { IChat } from 'types/chats'
import { formatDistance } from 'date-fns'

const ContactChat: FC<IChat & { onClick: () => void; selected: boolean }> = ({ id, user, latestMessage, latestMessageAt, lastSeen, onClick, selected }) => {
  const userId = useUserContext()?.id
  const isActive = userId && new Date(lastSeen[userId]).getTime() < new Date(latestMessageAt).getTime()

  const latestDate = new Date(latestMessageAt)
  const dateString = formatDistance(latestDate, new Date(), { addSuffix: true })

  const truncated = (latestMessage?.content.length || 100) > 50 ? latestMessage?.content?.substring(0, 50) + "..." : latestMessage?.content

  return (
    <div
      key={id}
      className={`flex w-full relative items-center space-x-8 ${selected ? 'bg-base-50' : 'bg-white'} border-b border-b-base-200 py-5 px-5 space-x-2 cursor-pointer hover:opacity-90 w-full min-w-[288px]`}
      onClick={onClick}
    >
      <div className="flex flex-col justify-center grow">
        <p className="font-medium text-sm text-base-900">{user.name}</p>
        <div className="flex font-normal text-xs text-base-400 justify-between gap-3">
          <span>{latestMessage ? (latestMessage.type === 'image' ? 'Sent an image' : truncated) : 'You are now connected'}</span>
          <span>{dateString}</span>
        </div>
      </div>
      {isActive && <div className='w-1 h-1 rounded-full bg-primary-600 absolute right-5 top-5' />}
    </div>
  )
}

export default ContactChat
