import Modal from 'components/Modal'
import useAwaitableModal, { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import { IPartneredLtlDataInput, TransportContent } from 'types/amazon'
import PalletInputModal from '../PalletInputModal'
import Icon from 'components/Icon'
import ordersApi from 'api/orders'
import { handleError } from 'helpers/errors'
import { LTLData } from 'types/orders'
import { useOrder } from 'contexts/Order'

const ReviewModal: AwaitableModal<TransportContent | undefined, { ltlData?: IPartneredLtlDataInput }> = ({ open, onCancel, resolve, initialData: { ltlData: _ltlData } }) => {
  const { order } = useOrder()
  const [pallets, setPallets] = useState(_ltlData?.PalletList)
  const [requestPallets, PalletsInput] = useAwaitableModal(PalletInputModal, { pallets })
  const [rawData, setRawData] = useState({
    name: _ltlData?.Contact?.Name,
    email: _ltlData?.Contact?.Email,
    phone: _ltlData?.Contact?.Phone,
    readyBy: _ltlData?.FreightReadyDate ? new Date(_ltlData?.FreightReadyDate).toISOString().split('T')[0] : undefined,
    value: _ltlData?.SellerDeclaredValue?.Value,
  })
  const [submitting, setSubmitting] = useState(false)
  const palletsMissing = !pallets?.length

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: _value, name, type } = e.currentTarget
    const value = type === 'number' ? Number(_value) : _value
    setRawData((old) => ({ ...old, [name]: value }))
  }

  useEffect(() => {
    if (palletsMissing) {
      requestPallets()
        .then((res) => {
          if (!res) onCancel()
          else setPallets(res.pallets)
        })
        .catch(onCancel)
    }
  }, [palletsMissing, onCancel])

  const onEditPallets = () => {
    requestPallets()
      .then((res) => {
        if (!res) onCancel()
        else setPallets(res.pallets)
      })
      .catch(onCancel)
  }

  const onSubmit = () => {
    if (submitting) return
    if (!pallets?.length) return
    if (!rawData.name || !rawData.email || !rawData.phone || !rawData.readyBy || !rawData.value) return handleError('Please fill out all fields')
    const transformed = {
      contact: {
        Name: rawData.name,
        Email: rawData.email,
        Phone: rawData.phone,
      },
      readyBy: rawData.readyBy,
      declaredValue: rawData.value,
      pallets,
    } as LTLData
    setSubmitting(true)
    ordersApi
      .putTransportDetails(order.id, transformed)
      .then(resolve)
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={onCancel}>
      <PalletsInput />
      <div className="modal relative max-h-[calc(100vh-64px)] min-w-[500px]">
        <header className="!items-center flex gap-3">
          <span className="grow text-xl">Buy Shipping and Complete Shipment</span>
        </header>
        <div className="flex flex-col w-full overflow-y-auto p-6 gap-3">
          <span className="text-base font-medium">Shipping Charges</span>
          <div className="grid grid-cols-2 border border-base-200 rounded-xl divide-y divide-y-base-200">
            <div className="text-xs font-medium text-base-400 p-4 row-span-3 flex items-center text-left border-r border-r-base-200">Contact Info</div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left border-t-transparent">
              <input name="name" placeholder="Name" className="w-full" value={rawData.name} onChange={onChange} />
            </div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left">
              <input name="email" placeholder="Email Address" className="w-full" value={rawData.email} onChange={onChange} />
            </div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left">
              <input name="phone" placeholder="Phone" className="w-full" value={rawData.phone} onChange={onChange} />
            </div>
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Declared Value</div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left">
              <input name="value" placeholder="Declared Value" type="number" step={0.01} min={0} className="w-full" value={rawData.value} onChange={onChange} />
            </div>
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Freight Ready Date</div>
            <div className="text-xs text-base-900 p-4 flex items-center text-left">
              <input name="readyBy" type="date" className="w-full" value={rawData.readyBy} onChange={onChange} />
            </div>
            <div className="text-xs font-medium text-base-400 p-4 flex items-center text-left border-r border-r-base-200">Summary</div>
            <div className="text-xs text-base-900 p-4 flex items-center justify-center text-left">
              <div className="flex flex-col items-center px-4">
                <Icon type="bulk" name="weight" size={48} />
                <span className="text-sm text-base-900 font-medium mt-3 mb-1 min-w-[130px] text-center">Shipment Weight</span>
                <span className="text-xs text-base-400 font-medium">{pallets?.reduce((acc, p) => acc + p.Weight.Value, 0) || 0} LBS</span>
              </div>
              <div className="flex flex-col items-center px-4">
                <Icon type="bulk" name="align-horizontally" size={48} />
                <span className="text-sm text-base-900 font-medium mt-3 mb-1 min-w-[130px] text-center">Total Pallets</span>
                <span className="text-xs text-base-400 font-medium">{pallets?.length || 0} Pallets</span>
              </div>
              <div className="flex flex-col items-center px-4">
                <Icon type="bulk" name="box-1" size={48} />
                <span className="text-sm text-base-900 font-medium mt-3 mb-1 min-w-[130px] text-center">Total Boxes</span>
                <span className="text-xs text-base-400 font-medium">{order.shipmentData?.boxes?.length || 0} Boxes</span>
              </div>
            </div>
          </div>
        </div>
        <footer className="bg-base-900 not-themed gap-4">
          <div className="flex gap-4">
            <button className="button-dark" onClick={onCancel}>
              Cancel
            </button>
            <button className="button-dark" onClick={onEditPallets}>
              Edit Pallets
            </button>
          </div>
          <button disabled={submitting} onClick={onSubmit} className="button-primary">
            Review And Submit
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default ReviewModal
