import IconBig from 'components/IconBig'
import { FC } from 'react'

export const stats = ['SENT', 'AT_PREP_CENTER', 'SENT_TO_AMAZON', 'HANDLED'] as const

interface props {
  itemStates?: Record<string, number>
  orderStates?: Record<string, number>
}

const prefixed = (base: string, number: number) => {
  if (number === 1) return base
  return base + 's'
}

const ItemStatsSide: FC<props> = ({ itemStates, orderStates }) => {
  const loading = !orderStates || !itemStates
  const sent = orderStates?.sent || 0
  const sentItems = itemStates?.sent || 0
  const atPrep = orderStates?.atprep || 0
  const atPrepItems = itemStates?.atprep || 0
  const inbound = orderStates?.inbound || 0
  const inboundItems = itemStates?.inbound || 0
  const atAmazon = orderStates?.atamazon || 0
  const atAmazonItems = itemStates?.atamazon || 0

  return (
    <section className="box bg-white flex flex-col mb-8 w-[256px] shrink-0 h-max">
      <header>Delivery Summary</header>
      <div className="flex flex-col p-5">
        <div className="flex flex-col w-full items-start p-5 rounded-t-[12px] border border-b-0 border-base-200 grow space-y-5">
          <span className="tag text-primary-600 bg-primary-50">In Transit</span>
          <div className="flex items-center space-x-3">
            <IconBig name="group" active size={32} />
            <div className="flex flex-col">
            <span className="desc !text-lg !font-medium !text-base-900">
              {loading ? '...' : sent} {prefixed('Shipment', sent)}
            </span>
            <p className="desc">
              {loading ? '...' : sentItems} {prefixed('Unit', sentItems)}
            </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full items-start p-5 border border-b-0 border-base-200 grow space-y-5">
          <span className="tag text-primary-600 bg-primary-50">At Warehouse</span>
          <div className="flex items-center space-x-3">
            <IconBig name="building" active size={32} />
            <div className="flex flex-col">
            <span className="desc !text-lg !font-medium !text-base-900">
              {loading ? '...' : atPrep} {prefixed('Shipment', atPrep)}
            </span>
            <p className="desc">
              {loading ? '...' : atPrepItems} {prefixed('Unit', atPrepItems)}
            </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full items-start p-5 border border-b-0 border-base-200 grow space-y-5">
          <span className="tag text-primary-600 bg-primary-50">Outbound</span>
          <div className="flex items-center space-x-3">
            <IconBig name="group" active size={32} />
            <div className="flex flex-col">
            <span className="desc !text-lg !font-medium !text-base-900">
              {loading ? '...' : inbound} {prefixed('Shipment', inbound)}
            </span>
            <p className="desc">
              {loading ? '...' : inboundItems} {prefixed('Unit', inboundItems)}
            </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full items-start p-5 rounded-b-[12px] border border-base-200 grow space-y-5">
          <span className="tag text-primary-600 bg-primary-50">At Amazon</span>
          <div className="flex items-center space-x-3">
            <IconBig name="box-1" active size={32} />
            <div className="flex flex-col">
            <span className="desc !text-lg !font-medium !text-base-900">
              {loading ? '...' : atAmazon} {prefixed('Shipment', atAmazon)}
            </span>
            <p className="desc">
              {loading ? '...' : atAmazonItems} {prefixed('Unit', atAmazonItems)}
            </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ItemStatsSide
