import { PaymentMethod } from "@stripe/stripe-js";
import api from "api";
import Empty from "components/Empty";
import TableSpanner from "components/TableSpanner";
import FullLoader from "components/loaders/FullLoader";
import { useEffect, useState } from "react";
import BillingMethod from "./components/BillingMethod";
import useAwaitableModal from "hooks/useAwaitableModal";
import AddPaymentMethod from "pages/app/modals/AddPaymentMethod";

const BillingMethodsPage = () => {
    const [billingMethods, setBillingMethods] = useState<PaymentMethod[]>()
    const [request, Element] = useAwaitableModal(AddPaymentMethod, {})

    useEffect(() => {
        api.getPaymentMethods().then(setBillingMethods)
    }, [])

    const onDelete = (id: string) => {
        setBillingMethods(billingMethods?.filter((billingMethod) => billingMethod.id !== id))
    }

    return (
    <section className="box w-full flex flex-col h-full relative bg-white">
      <Element />
        <header className="flex justify-between items-center z-[1000]">
          <span>Payment Methods</span>
              <button className="button-primary relative" onClick={() => request()}>
                New Payment Method
              </button>
        </header>
      <div className="b-secondary grow flex flex-col overflow-hidden py-4">
        <div className="flex flex-col grow overflow-y-auto">
          <table className="overflow-hidden">
            <colgroup>
              <col className="w-[200px]" />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <td>Type</td>
                <td>Identifier</td>
                <td>Added on</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {billingMethods?.map((bill) => (
                <BillingMethod key={bill.id} billingMethod={bill} onDelete={() => onDelete(bill.id)} />
              ))}
              {!billingMethods?.length && (
                <TableSpanner rows={4} cols={6}>
                  {billingMethods === undefined ? <FullLoader /> : <Empty text="No Payment Methods" />}
                </TableSpanner>
              )}
            </tbody>
          </table>
        </div>
        </div>
    </section>
    );
};

export default BillingMethodsPage