import Header from 'components/layout/Header'
import { useState } from 'react'
import useUserContext from 'contexts/User/useUserContext'
import { IContactInfo } from 'pages/app/InitialSetup/types'
import InvoiceSenderSelector from '../components/InvoiceSenderSelector'

const InvoicesAllPage = () => {
  const user = useUserContext()
  const [sender, setSender] = useState<string | undefined>()
  const [senders, setSenders] = useState<{id: string, contact:IContactInfo}[]>()

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header />
      <div className="grow flex flex-col overflow-hidden pb-6">
        <header className="flex justify-between items-center z-[1000] !mb-2">
          <span>Billing</span>
        </header>
        <div className="flex flex-col grow overflow-y-auto px-6">
          <section className="box w-full bg-white">
            <header>Select Prep Center</header>
          {user && !user.isPrepCenter && <InvoiceSenderSelector setSender={setSender} setSenders={setSenders} sender={sender} senders={senders} />}
          </section>
        </div>
      </div>
    </div>
  )
}

export default InvoicesAllPage
