import useUserContext from 'contexts/User/useUserContext'
import useAwaitableModal from 'hooks/useAwaitableModal'
import UpdateInvoicePaymentStructureModal from 'pages/app/modals/UpdateInvoicePaymentStructureModal'
import { FC } from 'react'
import Stripe from 'stripe'
import { IBill } from 'types/billing'

interface props {
  paymentStructure?: string
  setBills: (bills: IBill[]) => void
  setInvoice: (invoice: Stripe.Invoice) => void
  invoiceId?: string
  canChange?: boolean
}

const ChangePaymentStructure: FC<props> = ({ paymentStructure, setBills, setInvoice, invoiceId, canChange: _canChange }) => {
  const [changeStructure, Element] = useAwaitableModal(UpdateInvoicePaymentStructureModal, { invoiceId, structure: paymentStructure })
  const isPrepCenter = useUserContext()?.isPrepCenter
  const canChange = _canChange && paymentStructure !== 'APEX'

  return (
    <section className="box">
      {isPrepCenter && <Element />}
      <header className="">
        <span>Payment Structure for Invoice</span>
        <div className="flex space-x-4 items-center">
          <span className={['tag !text-xl bg-primary-200 text-primary-600 !px-4', paymentStructure !== 'APEX' ? 'bg-primary-200 text-primary-600' : 'bg-orange-500 text-orange-100'].asClass}>
            {paymentStructure}
          </span>
          {canChange && (
            <button
              className="button-secondary"
              onClick={() =>
                changeStructure().then(({ bills, invoice }) => {
                  setBills(bills)
                  setInvoice(invoice)
                })
              }
            >
              Change
            </button>
          )}
        </div>
      </header>
    </section>
  )
}

export default ChangePaymentStructure
