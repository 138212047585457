import { FirebaseOptions, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging } from 'firebase/messaging'
import { isDev, isLocal } from 'config/environment'

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyBBmro_IQuZmw6-tTuaC1W7_n089cdQOWg',
  authDomain: 'apex-mgt.firebaseapp.com',
  projectId: 'apex-mgt',
  storageBucket:  'apex-mgt.appspot.com',
  messagingSenderId: '327054622269',
  appId: '1:327054622269:web:09b76e2b4c0dacb5b97454',
  measurementId: "G-TW27WCDF2R"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
isLocal && isDev && connectAuthEmulator(auth, 'http://localhost:9099')
const db = getFirestore(app)
isLocal && isDev && connectFirestoreEmulator(db, 'localhost', 8080)
const storage = getStorage(app)
isLocal && isDev && connectStorageEmulator(storage, 'localhost', 9199)
const messaging = getMessaging(app)
const analytics = getAnalytics(app)

const firebase = { app, auth, db, storage, messaging, analytics }

export default firebase
