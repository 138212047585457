import { userData } from 'App'
import api from 'api'
import { SettingsIcons } from 'assets/icons'
import useUserContext from 'contexts/User/useUserContext'
import { handleError } from 'helpers/errors'
import { ChangeEvent, FC, useEffect, useState } from 'react'

const BasicSettingsPage: FC = () => {
  const user = useUserContext()
  const [email, setEmail] = useState<string>()
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    if (!user?.stripeAccountId || !user.stripeConnected) return
    api.getStripeAccount().then((account) => {
      setEmail(account.email)
    })
  }, [user?.stripeAccountId, user?.stripeConnected])

  const onClick = () => {
    if (!user) return
    if (!user.stripeAccountId || !user.stripeConnected) {
      api.getStripeAuthUri().then((uri) => {
        window.open(uri, '_self')
      })
    } else {
      window.open(`https://dashboard.stripe.com/${user.stripeAccountId}`, '_blank')
    }
  }

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (updating) return
    const _file = e.target.files?.[0]
    if (_file) {
      if (_file.size > 1024 * 1024 * 1) return handleError('File size must be less than 1MB')
      const fr = new FileReader()
      fr.onload = () => {
        setUpdating(true)
        api
          .updateConfig({ image: fr.result as string })
          .then((res) => {
            userData.current?.setConfig((prev: any) => prev && { ...prev, ...res })
          })
          .finally(() => setUpdating(false))
      }

      fr.readAsDataURL(_file)
    }
  }

  const onColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (updating) return
    const hex = e.target.value
    if (hex) {
      const _color = hex
        .split('#')[1]
        .match(/.{1,2}/g)
        ?.map((v) => parseInt(v, 16))
        .join(' ')
      setUpdating(true)
      api
        .updateConfig({ color: _color })
        .then((res) => {
          userData.current?.setConfig((prev: any) => prev && { ...prev, ...res })
        })
        .finally(() => setUpdating(false))
    }
  }

  const config = user?.config

  const color = `rgba(${(config?.primaryColor || '249 115 22').split(' ').join(',')},1)`

  const hexValue = `#${(config?.primaryColor || '249 115 22')
    .split(' ')
    .map((v) => parseInt(v).toString(16))
    .join('')}`

  return (
    <div className="rounded-2xl bg-white flex flex-col divide-y divide-y-base-200 overflow-y-auto">
      <header className="p-6 text-base text-base-950 font-semibold bg-white">Basic Settings</header>
      <div className="p-6 gap-6 flex items-center">
        <img src={require('../../../../../assets/stripe_icon.png')} className="h-16 w-16" alt="Stripe" />
        {!!user?.stripeConnected ? (
          <>
            <span className="text-base-500 text-sm grow">
              Your Stripe account <span className="text-primary-500 font-semibold">{email || "..."}</span> is connected.
            </span>
            <button className="button-primary" onClick={onClick}>
              Go To Dashboard
            </button>
          </>
        ) : (
          <>
            <span className="text-base-500 text-sm grow">By integrating Stripe you can enable automatic billing and improve the efficiency of your operations.</span>
            <button className="button-primary" onClick={onClick}>
              Connect With Stripe
            </button>
          </>
        )}
      </div>
      <header className="p-6 text-base text-base-950 font-semibold bg-white">Branding Settings</header>
      <div className={['flex items-center p-6 gap-6 justify-between', updating && 'pointer-events-none grayscale cursor-default'].asClass}>
        <div className="flex items-center gap-6">
          <input type="file" accept=".png" id="brandimg" className="hidden" onChange={onFileUpload} />
          <div className="w-16 h-16 border border-base-200 rounded-full p-1 flex items-center justify-center">
            {config?.logo ? <img src={config.logo} className="h-full w-full rounded-full" alt="Logo" /> : <SettingsIcons.Gallery className="color-base-300" />}
          </div>
          <label htmlFor="brandimg" className="button-secondary mb-0">
            Browse Image
          </label>
        </div>
        <div className="flex items-center gap-6">
          <input type="color" id="brandcolor" className="hidden" value={hexValue} onInput={onColorChange} />
          <div className="w-16 h-16 border border-base-200 rounded-full p-1 flex items-center justify-center">
            {config?.logo ? <div style={{ backgroundColor: color }} className="h-full w-full rounded-full" /> : <SettingsIcons.Gallery className="color-base-300" />}
          </div>
          <label htmlFor="brandcolor" className="button-secondary mb-0">
            Change Color
          </label>
        </div>
      </div>
      {/*<header className="p-6 text-base text-base-950 font-semibold bg-white">Billing Settings</header>*/}
    </div>
  )
}

export default BasicSettingsPage
