import { PaymentElement, Elements } from '@stripe/react-stripe-js'
import api from 'api'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import FullLoader from 'components/loaders/FullLoader'
import stripe from 'config/stripe'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import Submit from './Submit'

const AddPaymentMethod: AwaitableModal = ({ open, resolve, onCancel }) => {
  const [clientSecret, setClientSecret] = useState<string>()

  useEffect(() => {
    api.getPaymentMethodSetupSecret().then(setClientSecret)
  }, [])

  return (
    <Modal open={open} close={resolve}>
      <div className="modal w-[512px] relative">
        <header>
          <div>
            <h1>Add a Payment method</h1>
            <h3>Connect a Payment method for billing purposes.</h3>
          </div>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        {!clientSecret ? (
          <FullLoader />
        ) : (
          <Elements stripe={stripe} options={{ loader: 'always', clientSecret }}>
            <div className="w-full">
              <PaymentElement />
            </div>
            <footer className="!border-t-0">
              <Submit onSuccess={() => {}} />
            </footer>
          </Elements>
        )}
      </div>
    </Modal>
  )
}

export default AddPaymentMethod
