import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { IInvoiceListed } from 'types/billing'

interface props {
  invoice: IInvoiceListed
}

const Invoice: FC<props> = ({ invoice }) => {
  const navigate = useNavigate()
  return (
    <tr>
      <td>{invoice.number}</td>
      <td>{invoice.customerEmail}</td>
      <td>{(invoice.total / 100).toFixed(2)}$</td>
      <td>{new Date(invoice.created * 1000).toLocaleDateString()}</td>
      <td>
        <button className="button-secondary -mb-2.5 -mt-2" onClick={() => navigate("../invoice/"+invoice.id, {relative: "path"})}>{invoice.status === "draft" ? "Finalize and send" : "View"}</button>
      </td>
    </tr>
  )
}

export default Invoice
