import api from 'api'
import IconButton from 'components/buttons/IconButton'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import TextInput from 'components/TextInput'
import { FC, useEffect, useState } from 'react'
import { IAccess } from 'types/access'
import MyAccess from './components/MyAccess'

const AccountSettingsPage: FC = () => {
  const [email, setEmail] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [accesses, setAccesses] = useState<IAccess[]>()
  const [myAccesses, setMyAccessess] = useState<IAccess[]>()
  const [revoking, setRevoking] = useState(false)

  const grantAccess = () => {
    if (submitting) return
    setSubmitting(true)
    api
      .grantAccess(email)
      .then((createdAccess) => {
        setEmail('')
        setAccesses((prev) => prev && [...prev, createdAccess])
      })
      .finally(() => setSubmitting(false))
  }

  const revokeAccess = (id: string) => {
    if (revoking) return
    setRevoking(true)
    api
      .revokeAccess(id)
      .then(() => {
        setAccesses((prev) => prev?.filter((access) => access.id !== id))
      })
      .finally(() => setRevoking(false))
  }

  const updateAccessName = (id: string, name: string) => {
    setMyAccessess((prev) => prev?.map((access) => (access.id === id ? { ...access, name } : access)))
  }

  useEffect(() => {
    api.listGrantedAccesses().then(setAccesses)
  }, [])

  useEffect(() => {
    api.listAccesses().then(setMyAccessess)
  }, [])

  return (
    <div className="flex flex-col grow h-screen">
      <Header title="Settings" hideActAs />
      <div className="pt-6 flex flex-col h-full overflow-y-hidden">
        <h2 className="pb-6 px-6 mb-0">Account Access Settings</h2>
        <div className="flex px-6 grow overflow-hidden">
          <div className="flex flex-col grow overflow-hidden border-r border-r-secondary py-4 pr-4 h-full overflow-y-auto">
            <h3>Grant admin account access</h3>
            <TextInput placeholder="Email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
            <button className="button-primary" onClick={grantAccess} disabled={submitting || !email} children={'Grant'} />
            <h3 className="mt-4">Users with access</h3>
            <table className="mb-4">
              <thead>
                <tr>
                  <td>For</td>
                  <td>Access Type</td>
                  <td className="!px-0">Revoke</td>
                </tr>
              </thead>
              <tbody>
                {accesses === undefined ? (
                  <tr>
                    <td className="py-8" colSpan={3}>
                      <FullLoader />
                    </td>
                  </tr>
                ) : (
                  accesses.map(
                    (access) =>
                      access.accessType !== 'OWNER' && (
                        <tr key={access.id}>
                          <td>{access.contact?.legalName}</td>
                          <td>{access.accessType}</td>
                          <td className="pl-8">
                            <IconButton onClick={() => revokeAccess(access.id)} disabled={revoking} name="delete" wrapperClassName="flex justify-center" />
                          </td>
                        </tr>
                      )
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="h-full grow overflow-y-auto pl-4 py-4">
            <h3>Accounts access</h3>
            <table className="w-full">
              <thead>
                <tr>
                  <td>To</td>
                  <td>Access Type</td>
                </tr>
              </thead>
              <tbody>
                {myAccesses === undefined ? (
                  <tr>
                    <td className="py-8" colSpan={2}>
                      <FullLoader />
                    </td>
                  </tr>
                ) : (
                  myAccesses.map((access) => access.accessType !== 'OWNER' && <MyAccess key={access.id} updateAccessName={(name: string) => updateAccessName(access.id, name)} {...access} />)
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountSettingsPage
