import api from 'api'
import { handleError } from 'helpers/errors'
import { FC, useState } from 'react'

const ContactPage: FC = () => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [senderType, setType] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = () => {
    if (!firstName || !lastName || !emailAddress || !phoneNumber || !subject || !senderType || !message) return handleError('Please fill out all fields')
    setSubmitting(true)
    api
      .inquire({ firstName, lastName, emailAddress, phoneNumber, subject, senderType, message })
      .then(() => {
        setSubmitting(false)
        setSubmitted(true)
      })
      .catch(() => setSubmitting(false))
  }

  return (
    <>
      <div className="absolute rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] left-0 -top-16" />
      <div className="fixed rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] right-0 bottom-0" />
        <div className="flex flex-col gap-8 items-center z-[100] pt-64">
          <span className="whitespace-pre text-5xl font-extrabold text-base-950 text-center leading-[170%]">
            Get In Touch Today
          </span>
          <span className="text-lg text-base-950 leading-[200%] text-center">
            Our team is here to support you in any way possible.<br/>
            Let us know how we can help!
          </span>
        </div>
      <div className="flex flex-col px-32 mb-32 z-[100]">
        <div className="flex flex-col mx-32 p-8 gap-8 rounded-lg">
          {submitted ? (
            <>
              <span className="text-base-950 font-normal text-5xl text-center mt-12">
                Message <span className="text-base-950">Sent Successfully</span>
              </span>
              <span className="text-base-950-700 text-base text-center mb-16">One of our team members has been notified and will get back to you shortly.</span>
            </>
          ) : (
            <>
              <div className="flex wrap gap-8">
                <input
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  className="grow p-5 basis-1 shrink-0 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                />
                <input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                  className="grow p-5 basis-1 shrink-0 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                />
              </div>
              <div className="flex wrap gap-8">
                <input
                  placeholder="Email Address"
                  type="email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.currentTarget.value)}
                  className="grow p-5 basis-1 shrink-0 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                />
                <input
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                  className="grow p-5 basis-1 shrink-0 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                />
              </div>
              <input
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.currentTarget.value)}
                className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
              />
              <input
                placeholder="Are you a seller or prep center?"
                value={senderType}
                onChange={(e) => setType(e.currentTarget.value)}
                className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
              />
              <textarea
                placeholder="Type Your Message"
                maxLength={500}
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
                className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
              />
              <button disabled={submitting} className="button-orange w-max self-center !rounded-xl !px-16 !py-8 !text-lg" onClick={handleSubmit}>Contact Us Today</button>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ContactPage
