import useWindowDrag from 'hooks/useWindowDrag'
import { Dispatch, FC, SetStateAction, useState, DragEvent, ChangeEvent, useEffect, useRef } from 'react'
import IconButton from './buttons/IconButton'

interface props {
  modal: boolean
  closeModal: () => void
  image: File | undefined
  setImage: Dispatch<SetStateAction<File | undefined>>
}

const ImageUpload: FC<props> = ({ modal, closeModal, image, setImage }) => {
  const [dragActive, setDragActive] = useState(false)
  const ref = useRef<HTMLInputElement>(null)
  const windowDrag = useWindowDrag()

  useEffect(() => {
    if (!modal) return
    ref.current?.click()
    closeModal()
  }, [modal])

  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    setImage(e.target.files?.length ? e.target.files[0] : undefined)
  }

  const onDrag = (e: DragEvent<HTMLDivElement | HTMLLabelElement>) => {
    e.preventDefault()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    } else if (e.type === 'drop') {
      setDragActive(false)
      if (!dragActive) return
      if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
        if (e.dataTransfer.files[0].type === 'image/png' || e.dataTransfer.files[0].type === 'image/jpeg' || e.dataTransfer.files[0].type === 'image/jpg') {
          setImage(e.dataTransfer.files[0])
        }
      }
    }
  }

  return (
    <div className={['absolute top-0 left-0 h-full w-full items-center justify-center flex', windowDrag ? 'pointer-events-auto' : 'pointer-events-none'].join(' ')} onDragEnter={onDrag}>
      {windowDrag && dragActive && (
        <>
          <label
            onDragEnter={onDrag}
            onDragLeave={onDrag}
            onDragOver={onDrag}
            onDrop={onDrag}
            htmlFor="image"
            className="absolute top-0 left-0 h-full w-full items-center justify-center flex flex-col bg-semitransparent backdrop-blur-sm"
          >
            <i className="material-symbols-rounded text-primary-600 text-6xl pointer-events-none transition-transform" style={{ transform: `scale(${dragActive ? 2 : 1})` }}>
              upload
            </i>
          </label>
          <IconButton name="trash" onClick={closeModal} className="top-4 hidden" />
        </>
      )}
      <input ref={ref} id="image" type="file" accept=".png,.jpg,.jpeg" onChange={handleChange} value={image?.webkitRelativePath} className="hidden" />
    </div>
  )
}

export default ImageUpload
