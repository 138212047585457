import Empty from 'components/Empty'
import TableSpanner from 'components/TableSpanner'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import useUserContext from 'contexts/User/useUserContext'
import { FC, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { IBill } from 'types/billing'
import Bill from './components/Bill'
import api from 'api'
import useSelectedSeller from 'hooks/useSelectedSeller'
import { Tooltip } from '@material-tailwind/react'
import IconButton from 'components/buttons/IconButton'
import BillingSwitch from './components/BillingSwitch'
import useAwaitableModal from 'hooks/useAwaitableModal'
import CreateInvoiceModal from '../modals/CreateInvoiceModal'

const BillingPage: FC = () => {
  const [bills, setBills] = useState<IBill[]>()
  const user = useUserContext()
  const selectedClient = useSelectedSeller()
  const [selectedBills, setSelectedBills] = useState<string[]>([])
  const [request, Element] = useAwaitableModal(CreateInvoiceModal, { billIds: selectedBills, userId: selectedClient })

  useEffect(() => {
    api.getBills(selectedClient).then(setBills)
  }, [selectedClient])

  useEffect(() => {
    setSelectedBills([])
  }, [selectedClient])

  const tooltip = !selectedClient ? 'Select a client to create an invoice' : !selectedBills.length ? 'Add a bill' : undefined

  if (!user?.isPrepCenter) return <Navigate to="/app/dashboard" />

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header />
      <Element />
      <div className="grow flex flex-col overflow-hidden">
        <header className="flex justify-between items-center z-[1000]">
          <span>Outstanding Bills</span>
          <Tooltip
            content={tooltip}
            hidden={!tooltip}
            className="w-max h-max bg-base-200 text-base-900 py-1 px-2 rounded z-[10000]"
            placement="left"
            animate={{
              mount: { scale: 1 },
              unmount: { scale: 0 },
            }}
          >
            <div>
              <button className="button-primary relative" disabled={!!tooltip} onClick={() => request({ billIds: selectedBills, userId: selectedClient })}>
                Create an Invoice
              </button>
            </div>
          </Tooltip>
        </header>
        <div className="flex flex-col grow overflow-y-auto">
          <div className="flex flex-col grow px-6 h-max space-y-5 pb-6">
          <section className="box bg-white items-center overflow-y-hidden">
            <BillingSwitch />
            <div className="p-5 w-full">
              <div className="border border-base-200 rounded-xl overflow-y-hidden h-full">
                <table className="w-full">
                  <colgroup>
                    <col className="w-[200px]" />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      {Boolean(selectedClient) && (
                        <td>
                          <div className="flex items-center space-x-2">
                            <IconButton
                              name={selectedBills.length === bills?.length ? 'minus' : 'add'}
                              tooltip={selectedBills.length === bills?.length ? 'Clear all' : 'Select all'}
                              onClick={() => setSelectedBills(selectedBills.length === bills?.length ? [] : bills?.map(({ id }) => id) || [])}
                            />
                            <span>Add to Invoice</span>
                          </div>
                        </td>
                      )}
                      <td>For</td>
                      <td>Client</td>
                      <td>Created on</td>
                    </tr>
                  </thead>
                  <tbody>
                    {bills?.map((bill) => (
                      <Bill key={bill.id} bill={bill} showAdd={Boolean(selectedClient)} setSelectedBills={setSelectedBills} checked={selectedBills.includes(bill.id)} />
                    ))}
                    {!bills?.length && (
                      <TableSpanner rows={4} cols={6}>
                        {bills === undefined ? <FullLoader /> : <Empty text="No Bills" />}
                      </TableSpanner>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
        </div>
      </div>
    </div>
  )
}

export default BillingPage
