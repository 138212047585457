import inventoryApi from 'api/inventory'
import Empty from 'components/Empty'
import Header from 'components/layout/Header'
import FullLoader from 'components/loaders/FullLoader'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'
import Item from './components/Item'
import { IInventory } from 'types/inventory'
import IconButton from 'components/buttons/IconButton'
import TableSpanner from 'components/TableSpanner'
import useUserContext from 'contexts/User/useUserContext'
import useOpenModal from 'hooks/useOpenModal'
import Checkbox from 'components/Checkbox'

const UserInventoryPage: FC = () => {
  const location = useLocation()
  const [inventory, setInventory] = useState<(IInventory & { shipmentQuantity: number })[]>()
  const [name, setName] = useState(location.state.name)
  const user = useParams().user
  const navigate = useNavigate()
  const me = useUserContext()
  const openModal = useOpenModal()
  const prep = me?.isPrepCenter

  useEffect(() => {
    if (!user) return
    inventoryApi.getInventory(user).then(({ inventory, name }) => {
      setInventory(inventory.map((item) => ({ ...item, shipmentQuantity: 0 })))
      setName(name)
    })
  }, [user])

  const onItemUpdate = (id: string, data: { quantity?: number; location?: string; notes?: string }) => {
    setInventory((prev) =>
      prev?.map((item) =>
        item.id === id
          ? {
              ...item,
              quantity: data.quantity || item.quantity,
              location: data.location || item.location,
              item: {
                ...item.item,
                notes: data.notes || item.item.notes,
              },
            }
          : item
      )
    )
  }

  const setShipmentQuantity = (id: string, quantity: number) => {
    setInventory((prev) => prev?.map((i) => (i.id === id ? { ...i, shipmentQuantity: quantity } : i)))
  }

  const onShip = () => {
    if (!inventory) return
    navigate('./ship', { state: { inventory } })
  }

  const shipAll = () => {
    setInventory((prev) => prev?.map((i) => ({ ...i, shipmentQuantity: i.quantity })))
  }

  const shippingAll = inventory?.every((i) => i.shipmentQuantity === i.quantity)

  const shipNone = () => {
    setInventory((prev) => prev?.map((i) => ({ ...i, shipmentQuantity: 0 })))
  }

  const orderedByName = inventory?.sort((a, b) => a.item.name.localeCompare(b.item.name))

  return (
    <div className="flex flex-col grow h-screen overflow-hidden">
      <Header shipButton={<></>} />
      <div className="grow flex flex-col overflow-hidden pb-6">
        <header className="flex items-center z-[1000] justify-between">
          <div className="flex gap-2 items-center">
            <IconButton onClick={() => navigate('/app/inventory')} name="arrow-left" className="!bg-base-100" />
            <span>{name || '...'}</span>
          </div>
          <button
            className="button-primary"
            children="Ship Out"
            onClick={
              me?.isPrepCenter
                ? () => onShip()
                : me?.amazonConnected
                ? me?.subscription
                  ? () => onShip()
                  : () => openModal('/app/subscription/required')
                : () => openModal('/app/settings/amazon/connect')
            }
          />
        </header>
        <div className="px-6 gap-6 justify-start flex flex-col grow overflow-y-auto">
          <table>
            <thead>
              <tr>
                <td className="!text-center cursor-pointer" onClick={() => (shippingAll ? shipNone() : shipAll())}>
                  <Checkbox checked={!!shippingAll} />
                </td>
                <td>Image</td>
                <td>Product</td>
                <td>Quantity</td>
                {prep && (
                  <>
                    <td>Location</td>
                    <td>Notes</td>
                  </>
                )}
              </tr>
            </thead>
            {inventory === undefined ? (
              <TableSpanner cols={prep ? 5 : 3} rows={2}>
                <FullLoader />
              </TableSpanner>
            ) : inventory.length ? (
              orderedByName?.map((item) => (
                <Item
                  key={item.id}
                  item={item}
                  onUpdate={(data) => onItemUpdate(item.id, data)}
                  shipmentQty={item.shipmentQuantity}
                  onChangeShipmentQuantity={(qty: number) => setShipmentQuantity(item.id, qty)}
                />
              ))
            ) : (
              <Empty text="No Items In Inventory" />
            )}
          </table>
        </div>
      </div>
    </div>
  )
}

export default UserInventoryPage
