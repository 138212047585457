import { signInWithEmailAndPassword } from 'firebase/auth'
import { FormEvent, useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import firebase from '../../../config/firebase'
import api from 'api'
import FullLoader from 'components/loaders/FullLoader'

const RegisterPage = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>()
  const [initialEmail, setInitialEmail] = useState<string>()
  const [password, setPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const navigate = useNavigate()
  const { referrer } = useParams()
  const [sp] = useSearchParams()
  const distribution = sp.get('distribution')
  const affiliate = sp.get('affiliate')

  useEffect(() => {
    if (!referrer) return setInitialEmail('')
    api.getReferralEmail(referrer).then((email: string) => {
      if (email && email==="PREP") return navigate('/auth/register/prep/'+referrer)
      setEmail(email)
      setInitialEmail(email)
    }).catch(() => navigate('/auth/register'))
  }, [navigate, referrer])

  const passwordValid = password.length >= 8 && password.match(/[A-Z]/g) && password.match(/[0-9]/g)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    if (!email) return
    if (!passwordValid) return setError('Password must be at least 8 characters long and contain at least one number and one capital letter')
    if (!name) return setError('Please enter your name')
    setError(undefined)
    api
      .signup({ email, password, referrer, name, distribution: distribution ? true : false, affiliate: !!affiliate ? affiliate : undefined })
      .then(() => {
        localStorage.setItem('firstOpen', 'true')
        signInWithEmailAndPassword(firebase.auth, email, password)
      })
      .catch(() => {
        setError('An unknown error occurred')
        setSubmitting(false)
      })

    return false
  }

  if (initialEmail === undefined) return <FullLoader colorClass="orange-500" />

  return (
    <>
      <div className="absolute rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] left-0 -top-16" />
      <div className="fixed rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] right-0 bottom-0" />
    <div className="flex flex-col mx-32 p-8 gap-8 rounded-lg pt-32 z-[100]">
      <h1 className="text-orange-500 text-center">Sign up</h1>
      <form className="flex flex-col w-full gap-8" name="signup" onSubmit={handleSubmit}>
        <input
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
        />
        <input
          placeholder="Email"
          disabled={initialEmail !== ''}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
        />
        <input
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
        />
        <span className="text-xs text-base-500 text-center w-full -mt-4">Password must contain an uppercase letter, a number and have at least 8 characters</span>
        <p className="text-base-950 text-center text-xs">
          All fields are required. By clicking 'Register', you are indicating that you have read and agree to the 
          {' '}<Link className="text-orange-500" to="/terms-of-service">Terms of Service</Link> and <Link className="text-orange-500" to="/privacy-policy">Privacy Policy</Link>.
        </p>
        <button className="button-orange" children={submitting ? '...' : 'Register'} disabled={!name || !passwordValid || submitting} />
      </form>
      {error && <p className="text-rose-500 text-center">{error}</p>}
      <div>
        <p className="text-base-950 text-center pt-2">
          Already have an account?{' '}
          <Link className="text-orange-500" to="/auth/login">
            Log in
          </Link>
        </p>
      </div>
    </div>
    </>
  )
}

export default RegisterPage
