import ordersApi from 'api/orders'
import Icon from 'components/Icon'
import Radio from 'components/Radio'
import useUserContext from 'contexts/User/useUserContext'
import { FC, useState } from 'react'
import { handleError } from 'helpers/errors'
import { useOrder } from 'contexts/Order'

const Shipment: FC = () => {
  const { order, setOrder } = useOrder()
  const shipmentData = order.shipmentData
  const [type, setType] = useState(shipmentData?.type || 'SPD')
  const user = useUserContext()
  const isPrep = order.to === user?.id
  const [creating, setCreating] = useState(false)

  const onCreate = () => {
    if (creating) return
    setCreating(true)
    ordersApi
      .createAmazonShipment(order.id, type)
      .then((createdOrders) => {
        if (createdOrders.length === 0) return
        const [{ shipmentId, destinationAddress, items, issues, asins, quantity }, ...others] = createdOrders
        setOrder(
          (old) =>
            old && {
              ...old,
              shipmentData: type === 'SPD' ? { shipmentId, type, partnered: true, destinationAddress, boxes: [] } : ({ shipmentId, partnered: true, type, boxes: [], pallets: [] } as any),
              itemsData: items,
              issues: issues || [],
              asins,
              quantity,
            }
        )
        if (others.length > 0) {
          handleError(`${others.length} additional FBA shipments were created and the Shipment was split. Please check the order page to see others.`)
        }
      })
      .finally(() => setCreating(false))
  }

  return (
    <section className="box !rounded-b-none border-b border-b-base-200">
      <div className="overflow-x-auto min-w-full overflow-y-visible">
        <table className="w-full rounded-t-[0px] overflow-visible">
          <thead>
            <tr>
              <td>Shipment ID</td>
              <td>Prep Type</td>
              {shipmentData?.destinationAddress && <td>Destination</td>}
              <td>To</td>
              <td>From</td>
              {/*<td>Partnered</td>*/}
              <td>Type</td>
              <td>SKUs</td>
              <td>Units</td>
            </tr>
          </thead>
          <tbody className="min-h-full">
            <td className="select-text">{shipmentData?.shipmentId}</td>
            <td>Seller Label</td>
            {shipmentData?.destinationAddress && (
              <td className="select-text">
                {shipmentData.destinationAddress.AddressLine1}, {shipmentData.destinationAddress.PostalCode}
              </td>
            )}
            <td className="select-text">Amazon</td>
            <td className="select-text">{order.contact.name}</td>
            {/*<td>{(shipmentData?.partnered !== undefined ? shipmentData.partnered : true).toString()}</td>*/}
            <td>
              {!isPrep ? (
                type
              ) : !shipmentData?.shipmentId ? (
                <div className="flex w-max gap-3 text-xs">
                  <div className="flex items-center gap-3 cursor-pointer" onClick={() => setType('SPD')}>
                    <Radio checked={type === 'SPD'} />
                    <span>SPD</span>
                  </div>
                  <div className="flex items-center gap-3 cursor-pointer" onClick={() => setType('LTL')}>
                    <Radio checked={type === 'LTL'} />
                    <span>LTL</span>
                  </div>
                  <button onClick={onCreate} disabled={creating || order.state !== "AT_PREP_CENTER"} className="border border-sky-500 disabled:grayscale flex gap-2 px-4 py-2 rounded-full items-center hover:bg-sky-50 transition-all">
                    <span className="text-sky-500 font-medium text-sm w-max">{creating ? 'Creating Shipment...' : 'Create Shipment'}</span>
                  </button>
                </div>
              ) : (
                type
              )}
            </td>
            <td>{order.itemsData.reduce((acc, { actual }) => acc + (actual ? 1 : 0), 0)}</td>
            <td>
              <div className="flex items-center gap-3">
                <span>{order.itemsData.reduce((acc, { actual }) => acc + actual, 0)}</span>
                {shipmentData?.shipmentId ? <Icon name="lock" size={24} /> : null}
              </div>
            </td>
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default Shipment
