import { userData } from 'App'
import profileApi from 'api/profile'
import { DefaultProfilePic } from 'assets'
import useUserContext from 'contexts/User/useUserContext'
import { handleError } from 'helpers/errors'
import { ChangeEvent, FC, useState } from 'react'

const ProfileInfoSettingsPage: FC = () => {
  const user = useUserContext()
  const [name, setName] = useState<string>(user?.name || "")
  const [phone, setPhone] = useState<string>(user?.phone || "")
  const [updating, setUpdating] = useState(false)
  const [updatingProfile, setUpdatingProfile] = useState(false)

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (updating) return
    const _file = e.target.files?.[0]
    if (_file) {
      if (_file.size > 1024 * 1024 * 1) return handleError('File size must be less than 1MB')
      const fr = new FileReader()
      fr.onload = () => {
        setUpdating(true)
        profileApi
          .updateProfilePicture(fr.result as string)
          .then((res) => {
            userData.current?.setUser((prev: any) => prev && { ...prev, ...res })
          })
          .finally(() => setUpdating(false))
      }

      fr.readAsDataURL(_file)
    }
  }

  const onSave = () => {
    if (updatingProfile) return
    setUpdatingProfile(true)
    profileApi
      .updateContactInfo({ name, phone })
      .then((res) => {
        userData.current?.setUser((prev: any) => prev && { ...prev, ...res })
      })
      .finally(() => setUpdatingProfile(false))
  }

  return (
    <div className="rounded-2xl bg-white flex flex-col divide-y divide-y-base-200 overflow-y-auto">
      <header className="p-6 text-base text-base-950 font-semibold bg-white">Basic Settings</header>
      <div className={['flex items-center p-6 gap-6 justify-between', updating && 'pointer-events-none grayscale cursor-default'].asClass}>
        <div className="flex items-center gap-6">
          <input type="file" accept=".png" id="brandimg" className="hidden" onChange={onFileUpload} />
          <div className="w-24 h-24 border border-base-200 rounded-full p-0.5 flex items-center justify-center">
            {user?.image ? <img src={user?.image} className="h-full w-full rounded-full" alt="Logo" /> : <DefaultProfilePic className="w-full h-full" />}
          </div>
          <label htmlFor="brandimg" className="button-secondary mb-0">
            Browse Image
          </label>
        </div>
      </div>
      <header className="p-6 text-base text-base-950 font-semibold bg-white">Contact Information</header>
      <div className="p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <span className="text-base-500 font-semibold text-xs">Full Name</span>
          <input className="border-none" value={name} disabled={updatingProfile} onChange={(e) => setName(e.currentTarget.value)} />
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-base-500 font-semibold text-xs">Email Address</span>
          <input className="border-none" disabled value={user?.email} />
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-base-500 font-semibold text-xs">Phone Number</span>
          <input className="border-none" value={phone} disabled={updatingProfile} onChange={(e) => setPhone(e.currentTarget.value)} />
        </div>
      </div>
      <footer className="flex p-6 justify-end">
        <button className="button-primary" onClick={onSave} disabled={updatingProfile}>Save Changes</button>
      </footer>
      {/*<header className="p-6 text-base text-base-950 font-semibold bg-white">Billing Settings</header>*/}
    </div>
  )
}

export default ProfileInfoSettingsPage
