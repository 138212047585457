import { FC, useCallback, useState } from 'react'
import Icon from 'components/Icon'
import useOrder from '../context/hook'
import adminApi from 'api/admin'
import OrderItemSent from './OrderItemSent'


const OrderItemsSent: FC = () => {
  const { order, setOrder, frozen } = useOrder()
  const [search, setSearch] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const filtered = order?.data?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()) || item.upc.toLowerCase().includes(search.toLowerCase()))

  const onMarkSkus = useCallback(
    (itemId: string, sku: {
        sku: string
        qty: number,
        bundle: number,
        toStore: number
      }[]) => {
      setOrder((old) => {
        return {
          ...old, 
          shipmentData: {
            ...old.shipmentData,
            [itemId]: {
              fulfillable: 0,
              ...old.shipmentData?.[itemId],
              sku,
            }
          }
        }
      })
    }, [setOrder]);

  const markArrived = useCallback(() => {
    if (submitting) return
    setSubmitting(true)
    adminApi.distArrived(order.id).then(() => {
      setOrder((old) => ({ ...old, status: "delivered" }))
    })
    .finally(() => setSubmitting(false))
  }, [submitting, order, setOrder])

  return (
    <>
      <div className="p-6">
        <section className="box border border-base-200">
          <div className="relative border-b border-base-200">
            <Icon name="search-normal-1" className="fill-base-400 absolute pointer-events-none left-6 top-1/2 -translate-y-1/2" />
            <input className="border-0 bg-white w-full rounded-none outline-none p-6 pl-16" placeholder="Search Items..." value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
          </div>
          <div className="overflow-x-auto min-w-full overflow-y-visible">
            <table className="w-full rounded-t-[0px] overflow-visible">
              <thead>
                <tr>
                  <td>
                    Expected
                  </td>
                  <td>
                    UPC
                  </td>
                  <td>
                    Item Name
                  </td>
                  {!frozen && <td />}
                  <td>
                    SKU
                  </td>
                  <td>
                    Qty
                  </td>
                  <td>
                    Bundle Qty
                  </td>
                  <td>
                    Storage
                  </td>
                  <td>
                    Fulfillable
                  </td>
                  <td>
                    Configured
                  </td>
                  <td>
                    {!frozen && (
                    <button className="button-secondary float-right min-w-max" disabled={submitting} onClick={markArrived}>
                      Mark Arrived
                    </button>
                    )}
                  </td>
                </tr>
              </thead>
              <tbody className="min-h-full relative">
                {filtered.map((item) => (
                  <OrderItemSent key={item.id} item={item} onMarkSkus={onMarkSkus} />
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </>
  )
}

export default OrderItemsSent
