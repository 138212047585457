import ordersApi from 'api/orders'
import servicesApi from 'api/services'
import IconButton from 'components/buttons/IconButton'
import { FC, useEffect, useMemo, useState } from 'react'
import { IOrder } from 'types/orders'
import { IService } from 'types/services'
import { apexInboundOrderServices, apexOutboundOrderServices } from 'constants/apexServices'
import { useOrder } from 'contexts/Order'

interface props {
  services: IOrder['additionalServices']
  type: 'inbound' | 'outbound'
  cols?: number
}

const getAvailableApexServices = (type: "OA" | "Wholesale") => ([
  ...Object.entries(apexOutboundOrderServices[type]).map(([key, val]) => ({
    id: key,
    title: val,
    created_at: new Date(),
    prepCenter: 'APEX',
    identifier: key,
    basePrice: 0,
    type: 'outbound' as const,
  })),
  ...Object.entries(apexInboundOrderServices[type]).map(([key, val]) => ({
    id: key,
    title: val,
    created_at: new Date(),
    prepCenter: 'APEX',
    identifier: key,
    basePrice: 0,
    type: 'inbound' as const,
  })),
] as IService[])

const ServiceCharges: FC<props> = ({ services, type, cols }) => {
  const {
    order: { id: orderId, apexClient, shipmentData, apexWarehouseType },
    setOrder,
    frozen: _frozen,
  } = useOrder()
  const [saving, setSaving] = useState(false)
  const [availableServices, setAvailableServices] = useState<IService[]>()
  const [unsaved, setUnsaved] = useState<Record<string, number>>({})

  const availableApexServices = useMemo(() => apexWarehouseType ? getAvailableApexServices(apexWarehouseType) : [], [apexWarehouseType])

  const frozen = _frozen || (shipmentData && type === 'inbound')

  useEffect(() => {
    if (apexClient) {
      const s = availableApexServices.filter((s) => s.type === type)
      setAvailableServices(s)
      setUnsaved(s.map((service) => service.id).reduce((acc, id) => ({ ...acc, [id]: services?.[id] || 0 }), {}))
      return
    }
    servicesApi.getOrderServices().then((res) => {
      const s = res.filter((s) => s.type === type)
      setAvailableServices(s)
      setUnsaved(s.map((service) => service.id).reduce((acc, id) => ({ ...acc, [id]: services?.[id] || 0 }), {}))
    })
  }, [services, apexClient, type, availableApexServices])

  const onSave = () => {
    if (saving) return
    setSaving(true)
    ordersApi
      .addAdditionalServices(orderId, unsaved)
      .then(() => {
        setOrder((old) => old && { ...old, additionalServices: unsaved })
      })
      .finally(() => setSaving(false))
  }

  const areChanges = Object.keys(unsaved).some((key) => unsaved[key as keyof typeof unsaved] !== undefined && unsaved[key as keyof typeof unsaved] !== services?.[key as keyof typeof services])

  return (
    <section className="grow min-w-[500px] bg-white box h-[245px] overflow-hidden" style={{gridColumn: cols ? `span ${cols} / span ${cols}` : cols}}>
      <header>
        <span>{type === 'inbound' ? 'Inbound' : 'Outbound'} Services</span>
        {!frozen && (
          <button className="button-secondary -mb-3 -mt-2" onClick={onSave} disabled={!areChanges || saving}>
            {saving ? 'Saving...' : 'Save'}
          </button>
        )}
      </header>
      <div className="px-5 py-3">
        {Object.keys(unsaved).map((id) => (
          <div key={id} className="flex items-center py-2">
            <span className="grow">{availableServices?.find((i) => i.id === id)?.title}</span>
            {!frozen && <IconButton name="minus" disabled={!unsaved[id] || unsaved[id] <= 0 || saving} onClick={() => setUnsaved((old) => ({ ...old, [id]: Math.max((old?.[id] || 0) - 1, 0) }))} />}
            <span className="p-2">{unsaved[id] || 0}</span>
            {!frozen && <IconButton name="add" disabled={saving} onClick={() => setUnsaved((old) => ({ ...old, [id]: (old[id] || 0) + 1 }))} />}
          </div>
        ))}
      </div>
    </section>
  )
}

export default ServiceCharges
