import { FC } from 'react'
import { useNavigate } from 'react-router'
import OrderStatusTag from './OrderStatusTag'
import useUserContext from 'contexts/User/useUserContext'
import { IOrder } from 'types/orders'
import Icon from 'components/Icon'

const Order: FC<IOrder> = ({ ...order }) => {
  const { id, data, state: orderState, asins, quantity, shipmentData, outboundAt, bill, contact } = order
  const navigate = useNavigate()
  const user = useUserContext()

  const prepOrderNumber = order.orderNumber !== undefined ? '#' + order.orderNumber.toString().padStart(5, '0') : undefined

  const paid = Boolean(bill)

  return (
    <tr
      className={['cursor-pointer hover:opacity-80 border-x-4 [&>td]:text-base-950 group', order.apexClient ? 'border-orange-500' : 'border-transparent'].asClass}
      onClick={() => navigate('/app/orders/' + id)}
    >
      <td>
        <div className="flex flex-col gap-1.5">
          <span className="text-base text-base-950 font-semibold">{user?.isPrepCenter ? prepOrderNumber : `${data?.seller?.id} ${prepOrderNumber ? `(${prepOrderNumber})` : ''}`}</span>
          {!!contact && <span className="text-xs text-base-500">Seller: {contact.name || contact.email}</span>}
          <span className="text-xs text-base-500">ID: {shipmentData?.shipmentId}</span>
        </div>
      </td>
      <td>{new Date(outboundAt || Date.now()).toLocaleDateString()}</td>
      <td>
        <div className="flex gap-1">
          <OrderStatusTag status={orderState} />
          {['SENT_TO_AMAZON', 'HANDLED'].includes(orderState) && (
            <span className={['tag', !paid ? 'bg-rose-100 text-rose-600' : 'bg-green-100 text-green-600'].asClass}>{paid ? 'Billed' : 'Not Billed'}</span>
          )}
        </div>
      </td>
      <td>{asins}</td>
      <td>{quantity}</td>
      <td>
        <div className="w-full flex justify-end">
          <Icon name="arrow-right-3" className="group-hover:translate-x-1 transition-transform" size={16} />
        </div>
      </td>
    </tr>
  )
}

export default Order
