import { FC } from 'react'
import { IOrder } from 'types/orders'
import Box from './Box'
import BuyShipping from './BuyShipping'
import TableSpanner from 'components/TableSpanner'
import FullLoader from 'components/loaders/FullLoader'
import Empty from 'components/Empty'
import useHasRole from 'hooks/useHasRole'
import { useOrder } from 'contexts/Order'

const Boxes: FC = () => {
  const { order, setOrder } = useOrder()
  const canPrep = useHasRole("PREP")
  
  const onDelete = (boxId: string) => {
    setOrder((old) => old && ({ ...old, shipmentData: { ...old?.shipmentData, boxes: old?.shipmentData?.boxes.filter((box) => box.boxId !== boxId) } } as IOrder))
  }

  const orderedBoxes = order.shipmentData?.boxes.sort((a, b) => (a.boxNumber || 0) - (b.boxNumber || 0))

  return (
    <section className="grow min-w-[500px] bg-white box h-[245px] overflow-hidden relative">
      <header>
        <span>Actions</span>
        <div className="flex w-max gap-3">
          {/*<button className="border border-orange-500 flex gap-2 px-4 py-2 rounded-full items-center hover:bg-orange-50 transition-all">
            <span className="text-orange-500 font-medium text-sm w-max">Print Custom Labels</span>
          </button>
          <button className="border border-teal-500 flex gap-2 px-4 py-2 rounded-full items-center hover:bg-teal-50 transition-all">
            <span className="text-teal-500 font-medium text-sm w-max">Print Pallet Labels</span>
  </button>*/}
        </div>
        {canPrep && <BuyShipping order={order} setOrder={setOrder} />}
      </header>
      <div className="flex flex-col w-full overflow-y-auto h-[188px] p-6">
        <div className="border border-base-200 rounded-xl">
          <table className="w-full rounded-xl overflow-hidden">
            <thead>
              <tr>
                <td className="text-xs text-base-400">Box</td>
                <td className="text-xs text-base-400 font-medium border-l">Image</td>
                <td className="text-xs text-base-400 font-medium border-l">Quantity</td>
                {!order.bill && canPrep && <td className="text-xs text-base-400 font-medium border-l">Actions</td>}
              </tr>
            </thead>
            <tbody>
              {orderedBoxes?.map((box, i) => (
                <Box remove={() => onDelete(box.boxId)} key={JSON.stringify(box)} box={box} index={i + 1} />
              ))}
              {!order.shipmentData?.boxes.length && (
                <TableSpanner rows={1} cols={!order.bill ? 4 : 3}>
                    {order.shipmentData === undefined ? <FullLoader /> : <Empty text="No Boxes Yet" />}
                </TableSpanner>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default Boxes
