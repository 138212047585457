import { FC } from 'react'
import { useNavigate } from 'react-router'
import { IInventorySummary } from 'types/inventory'

interface props {
  storage: IInventorySummary
}

const Storage: FC<props> = ({ storage }) => {
  const navigate = useNavigate()

  return (
    <div className="rounded-lg bg-white flex-col flex gap-6 p-6 h-max">
      <div className="flex flex-col gap-1.5">
        <span className="text-base text-base-950 font-semibold">{storage.name}</span>
        <span className="text-sm text-primary-500">
          {storage.quantity} Unit{storage.quantity === 1 ? '' : 's'}
        </span>
      </div>
      <span className="text-xs text-base-500">
        {storage.asins} ASIN{storage.asins === 1 ? '' : 'S'}
      </span>
      <button className="button-secondary w-max group" onClick={() => navigate('/app/inventory/' + storage.user, { state: { name: storage.name } })}>
        View Details<span className="group-hover:translate-x-1 transition-all">&emsp;--&gt;</span>
      </button>
    </div>
  )
}

export default Storage
