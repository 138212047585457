import bwipjs from 'bwip-js'
import jsPDF from 'jspdf'
import { print } from './print'

  function wrap(context: CanvasRenderingContext2D, text: string, maxWidth: number) {
    let width = context.measureText(text).width
    if (width <= maxWidth) return text
    let a1 = text.substring(0, text.length / 2)
    let a2 = text.substring(a1.length)
    let i = 0
    while (width > maxWidth) {
      if (i % 2) {
        a1 = a1.substring(0, a1.length - 1)
      } else {
        a2 = a2.substring(1)
      }

      text = a1 + '...' + a2
      width = context.measureText(text).width
      i++
    }
    return text
  }

const generateFNSKULabelImg = ({fnsku, condition, name, expiryDate}: {fnsku: string, name: string, condition?: string, quantity: number, expiryDate?: string}) => {
    const canvas = document.createElement('canvas')
    bwipjs.toCanvas(canvas, {
      bcid: 'code128',
      text: fnsku,
      scale: 3,
      height: 10,
      textxalign: 'center',
      textsize: 10,
      textyoffset: 5,
      paddingwidth: 10,
      paddingheight: 10,
      paddingbottom: 30,
      backgroundcolor: '#FFFFFF',
      textcolor: '#000000',
      monochrome: true,
      rotate: 'N',
    })
    const context = canvas.getContext('2d')
    if (!context) return canvas
    context.font = '14px Arial'
    context.textAlign = 'center'
    context.fillText(fnsku, canvas.width / 2, 67 * 2)
    context.font = '14px Arial'
    context.textAlign = 'center'
    const wrappedName = wrap(context, name, canvas.width - 3 * 10 * 2)
    context.fillText(wrappedName, canvas.width / 2, 66 * 2 + 20)
    if (expiryDate) {
      context.font = '12px Arial'
      context.textAlign = 'left'
      context.fillText(expiryDate.toString(), 10 * 3, 72 * 2 + 20)
    }
    context.font = '12px Arial'
    context.textAlign = 'right'
    context.fillText((condition || 'NewItem').split(/(?=[A-Z])/).join(' '), canvas.width - 10 * 3, 72 * 2 + 20)

    return canvas
}

export const printFNSKULabels = async (fnskus: {fnsku: string, name: string, condition?: string, quantity: number, expiryDate?: string}[]) => {
    const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [2.5, 0.9],
      })

      let firstPage = true;
      fnskus.forEach((fnsku, i) => {
        const canvas = generateFNSKULabelImg(fnsku)
        var imgData = canvas.toDataURL('image/png', 1)
        for (let i = 0; i < fnsku.quantity; i++) {
            if (!firstPage) doc.addPage()
            firstPage = false
            doc.addImage(imgData, 0, 0, 2.5, 0.9)
        }
      })

      const uri = doc.output('bloburi')

      print(uri.toString())
}

export const fnskuConfigs = {
  "2x1": {
    paperDimensions: [2.5, 0.9],
    counts: [1, 1],
    paddings: [0, 0],
    spacings: [0, 0],
  },
  "USLetter30": {
    paperDimensions: [8.5, 11],
    counts: [10, 3],
    paddings: [0.1875, 0.5],
    spacings: [0.125, 0],
  }
}

export const printFNSKULabelsByConfig = async (fnskus: {fnsku: string, name: string, condition?: string, quantity: number, expiryDate?: string}[], configName?: keyof typeof fnskuConfigs) => {
    let config = fnskuConfigs[configName as keyof typeof fnskuConfigs]
    if (!config) config = fnskuConfigs["2x1"]
      const {
        paperDimensions,
        paddings,
        spacings
      } = config
    const orientation = paperDimensions[0] > paperDimensions[1] ? 'landscape' : 'portrait'
    const doc = new jsPDF({
        orientation,
        unit: 'in',
        format: paperDimensions,
      })

      let firstPage = true;
      let index = 0
      const [rows, cols] = config.counts
      const boxWidth = (paperDimensions[0] - 2 * paddings[0] - (cols - 1) * spacings[0]) / cols
      const boxHeight = (paperDimensions[1] - 2 * paddings[1] - (rows - 1) * spacings[1]) / rows
      fnskus.forEach((fnsku, i) => {
        const canvas = generateFNSKULabelImg(fnsku)
        var imgData = canvas.toDataURL('image/png', 1)
        for (let i = 0; i < fnsku.quantity; i++) {
            if (index % (cols * rows) === 0) {
              index = 0
              !firstPage && doc.addPage()
            }
            firstPage = false
            const col = index % cols
            const row = Math.floor(index / cols)
            const x = paddings[0] + col * (boxWidth)
            const y = paddings[1] + row * (boxHeight);
            doc.addImage(imgData, x, y, 2.5, 0.9)
            index++
        }
      })

      const uri = doc.output('bloburi')

      print(uri.toString(), paperDimensions)
}