import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'
import { v4 } from 'uuid'
import PalletConfig from './PalletConfig'
import { CloseIcon } from 'assets/icons'
import { IPartneredLtlDataInput } from 'types/amazon'

export interface IPalletConfig {
  id: string
  numPallets: number | undefined
  length: number | undefined
  width: number | undefined
  height: number | undefined
  weight: number | undefined
  isStacked: boolean | undefined
}

const transformPalletConfigsToBoxes = (pallets: IPalletConfig[]) => {
  const transformed: IPartneredLtlDataInput["PalletList"] = []
  for (const pallet of pallets) {
    if (!pallet.numPallets || !pallet.length || !pallet.weight || !pallet.width || !pallet.height) {
      handleError('Invalid box configuration')
      return []
    }
    for (let i = 0; i < pallet.numPallets; i++) {
      transformed.push({
        Dimensions: {
          Length: pallet.length,
          Width: pallet.width,
          Height: pallet.height,
          Unit: 'inches',
        },
        Weight: {
          Unit: 'pounds',
          Value: pallet.weight,
        },
        IsStacked: Boolean(pallet.isStacked),
      })
    }
  }
  return transformed
}

const transformPalletsToPalletConfigs = (pallets: IPartneredLtlDataInput["PalletList"]) => {
  const transformed: IPalletConfig[] = []
  for (const pallet of pallets) {
    const existing = transformed.find(
      (b) =>
        b.length === pallet.Dimensions.Length &&
        b.width === pallet.Dimensions.Width &&
        b.height === pallet.Dimensions.Height &&
        b.weight === pallet.Weight.Value &&
        !!b.isStacked === !!pallet.IsStacked
    )
    if (existing) {
      existing.numPallets = (existing.numPallets || 0) + 1
    } else {
      transformed.push({
        id: v4(),
        numPallets: 1,
        length: pallet.Dimensions.Length,
        width: pallet.Dimensions.Width,
        height: pallet.Dimensions.Height,
        weight: pallet.Weight.Value,
        isStacked: Boolean(pallet.IsStacked),
      })
    }
  }
  return transformed
}

const PalletInputModal: AwaitableModal<{ pallets?: IPartneredLtlDataInput["PalletList"] }, { pallets?: IPartneredLtlDataInput["PalletList"] }> = ({ onCancel, open, resolve, initialData: { pallets: _pallets } }) => {
  const initialPallets = transformPalletsToPalletConfigs(_pallets || [])
  const initialPallet = () => ({
    id: v4(),
    numPallets: undefined,
    length: undefined,
    width: undefined,
    height: undefined,
    weight: undefined,
    isStacked: undefined,
  })
  const [palletConfigs, setPalletConfigs] = useState(initialPallets.length ? initialPallets : [initialPallet()])

  const setPallet = (pallet: IPalletConfig) => {
    setPalletConfigs((old) => {
      const newPallets = [...old]
      const index = newPallets.findIndex((b) => b.id === pallet.id)
      if (index === -1) return old
      newPallets[index] = pallet
      return newPallets
    })
  }

  const deleteBox = (boxId: string) => {
    setPalletConfigs((old) => {
      const n = old.filter((b) => b.id !== boxId)
      if (n.length === 0) return [initialPallet()]
      return n
    })
  }

  const onSubmit = () => {
    const pallets = transformPalletConfigsToBoxes(palletConfigs)
    resolve({pallets})
  }

  return (
    <Modal open={open} close={onCancel}>
      <div className="modal relative max-h-[calc(100vh-64px)]">
        <header className="!items-center flex gap-3">
          <span className="grow text-xl">Buy Shipping and Complete Shipment</span>
          <CloseIcon width={48} height={48} className="stroke-base-900 hover:bg-base-200 transition-all p-3 rounded-full cursor-pointer" onClick={onCancel} />
        </header>
        <div className="overflow-y-auto p-6">
        <div className="border border-base-200 rounded-xl divide-y divide-y-base-200">
          <div className="text-xs text-base-900 divide-y divide-y-base-200">
            {palletConfigs.map((pallet) => (
              <PalletConfig key={pallet.id} pallet={pallet} setPallet={setPallet} onDelete={() => deleteBox(pallet.id)} />
            ))}
          </div>
        </div>
        </div>
        <footer className="bg-base-900 not-themed">
          <button className="button-dark" onClick={onCancel}>
            Cancel
          </button>
          <button className="button-primary" onClick={onSubmit}>
            Next
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default PalletInputModal
