import { FC } from 'react'
import useOrder from '../context/hook'
import { IDistributionOrder } from 'types/admin'

const OrderItem: FC<{
  item: IDistributionOrder['data'][0]
  onMarkFulfillable: (itemId: string, quantity: number) => void
}> = ({ item, onMarkFulfillable }) => {
  const { order } = useOrder()
  const { name, upc, id: itemId } = item
  const fulfillable = order.shipmentData?.[itemId]?.fulfillable


  return (
    <>
      <tr>
        <td>{(item.count * (item.caseQty || 1)).toLocaleString("en-US")}</td>
        <td>
          {fulfillable === undefined ? "..." : fulfillable}         
        </td>
        <td className="select-text max-w-[200px] truncate" title={upc}>
          {upc}
        </td>
        <td className="select-text max-w-[200px] truncate" colSpan={2} title={name}>
          {name}
        </td>
      </tr>
    </>
  )
}

export default OrderItem
