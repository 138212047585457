import api from 'api'
import Empty from 'components/Empty'
import FullLoader from 'components/loaders/FullLoader'
import Loader from 'components/loaders/Loader'
import TableSpanner from 'components/TableSpanner'
import useUserContext from 'contexts/User/useUserContext'
import useAwaitableModal from 'hooks/useAwaitableModal'
import useHasRole from 'hooks/useHasRole'
import { IContactInfo } from 'pages/app/InitialSetup/types'
import InviteClientModal from 'pages/app/modals/InviteClientModal'
import { FC, useEffect, useState } from 'react'
import { IPaymentStructure } from 'types/billing'
import ClientSubModal from '../modals/ClientSubModal'
import useAreYouSure from 'hooks/useAreYouSure'
import { LinkIcon } from 'assets/icons'
import { handleError } from 'helpers/errors'
import * as xlsx from 'xlsx'
import { Tooltip } from '@material-tailwind/react'

export interface IClient {
  id: string
  created_at: Date
  stats: {
    orderCount: number
    itemCount: number
  }
  email: string
  seller?: string
  accepted: boolean
  contact?: IContactInfo
  data?: { pricing?: string } & Record<string, any>
}

const ClientList: FC = () => {
  const user = useUserContext()
  const [clients, setClients] = useState<IClient[]>()
  const [inviteClient, Element] = useAwaitableModal<IClient[]>(InviteClientModal, {})
  const [clientSubModal, ClientSubElement] = useAwaitableModal(ClientSubModal, {})
  const [droppingClient, setDroppingClient] = useState<boolean>(false)
  const [changingStructure, setChangingStructure] = useState<boolean>(false)
  const [pricingStructures, setPricingStructures] = useState<IPaymentStructure[]>()
  const canInvite = useHasRole('INVITE_CLIENTS')
  const canDelete = useHasRole('ADMIN')
  const [areYouSure, AreYouSureModal] = useAreYouSure('Are you sure you want to kick this merchant?')

  useEffect(() => {
    api.getPaymentStructures().then(setPricingStructures)
  }, [])

  useEffect(() => {
    api.getClients().then(setClients)
  }, [])

  const onDrop = (id: string) => {
    if (droppingClient) return
    setDroppingClient(true)
    api
      .dropInvite(id)
      .then(() => setClients((old) => old?.filter((client) => client.id !== id)))
      .finally(() => setDroppingClient(false))
  }

  const onChangePaymentStructure = (id: string, pricing: string) => {
    if (changingStructure) return
    setChangingStructure(true)
    api
      .changeClientTier(id, pricing)
      .then(() => setClients((old) => old?.map((client) => (client.id !== id ? client : { ...client, data: { ...client.data, pricing } }))))
      .finally(() => setChangingStructure(false))
  }

  const downloadClientList = () => {
    if (!clients) return
    const data = clients?.map(({ email, contact, data, stats, created_at }) => [
      email,
      created_at,
      contact?.name,
      contact?.legalName,
      stats.orderCount,
      stats.itemCount,
      pricingStructures?.find(({ id }) => id === data?.pricing)?.identifier,
    ])
    const headers = ['Email', 'Joined At', 'Name', 'Store Name', 'Order Count', 'Item Count', 'Pricing']
    const x = xlsx.utils.json_to_sheet(data, { header: headers })
    const wb = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(wb, x, 'Client List')
    xlsx.writeFile(wb, `client-list_${new Date().toLocaleString()}.xlsx`)
  }

  const copyReferralLink = () => {
    const url = new URL('https://apexmgt.us/auth/register')
    user?.id && url.searchParams.set('affiliate', user?.id)
    navigator.clipboard.writeText(url.toString())
    handleError('Copied to clipboard')
  }

  const clientsSortedByAccepted = clients?.sort((a, b) => (a.accepted === b.accepted ? 0 : a.accepted ? -1 : 1))
  const clientsByOrders = clientsSortedByAccepted?.sort((a, b) => (a.stats && b.stats ? b.stats.orderCount - a.stats.orderCount : 0))

  const activeCount = clients?.filter(({ accepted }) => accepted).length

  const onInvite = () => {
    if (!user?.subscription && !user?.clientPaysSub) {
      clientSubModal()
    } else {
      inviteClient().then((invitations?: IClient[]) => invitations?.length && setClients((old) => old && [...invitations, ...old]))
    }
  }

  return (
    <section className="box w-full overflow-hidden col-span-2 h-max">
      <Element />
      <ClientSubElement />
      <AreYouSureModal />
      <header className="p-6 text-base text-base-950 font-semibold bg-white flex items-center gap-3 border-b border-b-base-200">
        <div className="flex items-center gap-4">
          <span className="flex-grow">Merchants</span>
          <Tooltip placement="top" content="Copy Invite Link">
            <LinkIcon className="cursor-pointer hover:scale-[1.1]" onClick={copyReferralLink} />
          </Tooltip>
        </div>
        <div className="grow" />
        <span className="bg-primary-100 text-primary-500 uppercase rounded-full py-2 px-3.5 font-medium text-[0.6125rem] leading-none">
          {clients === undefined ? '...' : activeCount} Merchant{activeCount === 1 ? '' : 's'}
        </span>
        {!!clients && (
          <button className="button-secondary -my-3" onClick={downloadClientList}>
            <span>Download Client List --&gt;</span>
          </button>
        )}
        {canInvite && (
          <button className="button-primary -my-3" onClick={onInvite}>
            <span>Invite a Merchant</span>
          </button>
        )}
      </header>
      <Element />
      <ClientSubElement />
      <div className="w-full h-full overflow-auto">
        <table className="rounded-t-none">
          <thead>
            <tr>
              <td>Title</td>
              <td>Email</td>
              <td>Store Name</td>
              <td>Status</td>
              <td>Shipments</td>
              <td>Units</td>
              <td>Pricing</td>
              {canDelete && <td>Action</td>}
            </tr>
          </thead>
          <tbody>
            {clientsByOrders?.map((client) => (
              <tr key={client.id}>
                <td className="select-text">{client.contact?.legalName || client.contact?.name}</td>
                <td className="select-text">{client.email}</td>
                <td className="select-text">{client.contact?.name}</td>
                <td>{client.accepted ? <span className="tag tag-lime">Active</span> : <span className="tag bg-yellow-100 text-yellow-600">Pending</span>}</td>
                <td>{client.stats.orderCount}</td>
                <td>{client.stats.itemCount}</td>
                <td>
                  {pricingStructures ? (
                    canDelete ? (
                      <select value={client.data?.pricing} disabled={changingStructure} placeholder="DEFAULT" onChange={(e) => onChangePaymentStructure(client.id, e.target.value)}>
                        {pricingStructures.map(({ id, identifier }) => (
                          <option key={id} value={id}>
                            {identifier}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>{pricingStructures.find(({ id }) => id === client.data?.pricing)?.identifier || 'DEFAULT'}</span>
                    )
                  ) : (
                    <Loader size={24} />
                  )}
                </td>
                {canDelete && (
                  <td>
                    {droppingClient ? (
                      <Loader size={24} />
                    ) : (
                      <button className="button-secondary" onClick={() => areYouSure(() => onDrop(client.id))}>
                        Kick Merchant
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
            {!clients?.length && (
              <TableSpanner rows={3} cols={8}>
                {clients === undefined ? <FullLoader /> : <Empty text="No Merchants" />}
              </TableSpanner>
            )}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default ClientList
