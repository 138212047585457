import TableSpanner from 'components/TableSpanner'
import { FC } from 'react'

const WarehousesSettingsPage: FC = () => {
  return (
    <div className="rounded-2xl bg-white flex flex-col divide-y divide-y-base-200 overflow-y-auto">
      <header className="p-6 text-base text-base-950 font-semibold bg-white flex items-center gap-3 border-b border-b-base-200">
        <span className="flex-grow">Warehouses</span>
        <button className="button-primary -my-3" onClick={() => {}}>
          <span>New Warehouse</span>
        </button>
      </header>
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Address</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <TableSpanner cols={3} rows={2}>
            <h3 className="text-center">Coming Soon</h3>
          </TableSpanner>
        </tbody>
      </table>
    </div>
  )
}

export default WarehousesSettingsPage
