import { useCallback } from "react"
import useAwaitableModal from "./useAwaitableModal"
import AreYouSureModal from "components/AreYouSureModal"

const useAreYouSure = (message?: string) => {
    const [request, Modal] = useAwaitableModal(AreYouSureModal, {text: message})

    const areYouSure = useCallback(async (cb: () => Promise<unknown> | unknown) => {
        request({text: message}).then(cb).catch(() => {})
    }, [request, message])

    return [areYouSure, Modal] as const
}

export default useAreYouSure