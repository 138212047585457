import { useEffect, useState } from "react"

const useDarkMode = () => {
    const [dark, setDark] = useState(localStorage.getItem("darkMode") === "true")

    const toggle = () => {
        setDark((old) => {
            localStorage.setItem("darkMode", (!old).toString())
            window.dispatchEvent(new Event("storage"))
            return !old
        })
    }

    useEffect(() => {
        const listener = () => {
            const to = localStorage.getItem("darkMode") === "true"
            setDark(to)
            document.querySelector("html")?.classList.toggle("dark", to)
        }

        window.addEventListener("storage", listener)

        return () => {
            window.removeEventListener("storage", listener)
        }
    }, [dark])

    return {
        dark,
        toggle
    }
}

export default useDarkMode