import firebase from "config/firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { FC, useEffect, useState, DetailedHTMLProps, ImgHTMLAttributes } from "react";
import FullLoader from "./loaders/FullLoader";

const AuthenticatedImage: FC<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {openOnClick?: boolean}> = ({ src, openOnClick, onClick, ...props}) => {
    const [authenticatedUri, setAuthenticatedUri] = useState<string>();
    useEffect(() => {
        if (!src) return;
        getDownloadURL(ref(firebase.storage, src))
        .then((url) => {
            setAuthenticatedUri(url)
        })
    }, [src])

    if (!authenticatedUri) return <div className="h-[200px] w-[200px]">
        <FullLoader />
    </div>;

    return <img src={authenticatedUri} {...props} onClick={(e) => {
        onClick?.(e);
        if (openOnClick) {
            window.open(authenticatedUri, '_blank')
        }
    }} />
}

export default AuthenticatedImage
