import { FC } from "react";
import Loader from "./Loader";

const FullpageLoader: FC<{className?: string}> = ({className}) => {
    return (
      <div className={[className, "w-screen h-screen flex items-center justify-center"].filter(Boolean).join(" ")}>
        <Loader />
      </div>
    )
}

export default FullpageLoader
