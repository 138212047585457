import { IOrder } from "types/orders";

const getDataByState = (state: IOrder["state"]) => {
    switch (state) {
        case "PREPPING":
            return ["Created", "bg-yellow-100 text-yellow-600"]
        default:
            return ["Closed", "bg-lime-100 text-lime-500"]
    }
}

const OrderStatusTag = ({ status }: { status: IOrder["state"] }) => {
    const [formatted, className] = getDataByState(status)
    return (
        <span className={`tag ${className}`}>
        {formatted}
        </span>
    );
}

export default OrderStatusTag;