import { signInWithEmailAndPassword } from 'firebase/auth'
import { FormEvent, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import firebase from '../../../config/firebase'
import api from 'api'
import StateSelector from 'components/StateSelector'
import PrepTOSModal from 'components/PrepTOSModal'

const RegisterPrepPage = () => {
  const [publicName, setPublicName] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [country, setCountry] = useState<string>('US')
  const [streetAddress, setStreetAddress] = useState<string>('')
  const [zipCode, setZipCode] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const { referrer } = useParams()

  const [prepTosOpen, setPrepTosOpen] = useState<boolean>(false)

  const passwordValid = password.length >= 8 && password.match(/[A-Z]/g) && password.match(/[0-9]/g)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!email) return
    if (!passwordValid) return setError('Password must be at least 8 characters long and contain at least one number and one capital letter')
    if (!name) return setError('Please enter your name')
    if (!publicName) return setError('Please enter your public name')
    if (!streetAddress) return setError('Please enter your street address')
    if (!city) return setError('Please enter your city')
    if (!zipCode) return setError('Please enter your zip code')
    if (!state) return setError('Please select your state')
    setError(undefined)

    setPrepTosOpen(true)

    return false
  }

  const signup = () => {
    if (submitting) return
    if (!email) return
    if (!passwordValid) return setError('Password must be at least 8 characters long and contain at least one number and one capital letter')
    if (!name) return setError('Please enter your name')
    if (!publicName) return setError('Please enter your public name')
    if (!streetAddress) return setError('Please enter your street address')
    if (!city) return setError('Please enter your city')
    if (!zipCode) return setError('Please enter your zip code')
    if (!state) return setError('Please select your state')
    if (!country) return setError('Please enter your contry code')
    setPrepTosOpen(false)
    setSubmitting(true)
    api
      .prepSignup({ email, password, name, state, zipCode, publicName, city, streetAddress, referrer, country })
      .then(() => {
        localStorage.setItem('firstOpen', 'true')
        signInWithEmailAndPassword(firebase.auth, email, password)
      })
      .catch(() => {
        setError('An unknown error occurred')
        setSubmitting(false)
      })
  }

  return (
    <>
      <div className="absolute rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] left-0 -top-16" />
      <div className="fixed rounded-full bg-orange-500 blur-[256px] w-[384px] h-[384px] right-0 bottom-0" />
      <div className="flex flex-col mx-32 p-8 gap-8 rounded-lg pt-32 z-[100]">
        <PrepTOSModal open={prepTosOpen} onAccept={signup} onClose={() => setPrepTosOpen(false)} />
        <h1 className="text-orange-500 text-center">Become an Apex Prep Center</h1>
        <form className="flex flex-col w-full gap-8" name="signup" onSubmit={handleSubmit}>
          <input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
          />
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
          />
          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
          />
          <h2 className="text-base-950 -my-3">Warehouse info</h2>
          <input
            placeholder="Public Name"
            value={publicName}
            onChange={(e) => setPublicName(e.currentTarget.value)}
            className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
          />
          <input
            placeholder="Street Address"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.currentTarget.value)}
            className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
          />
          <input
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.currentTarget.value)}
            className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
          />
          <input
            placeholder="Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.currentTarget.value)}
            className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
          />
          <StateSelector onChange={(e) => setState(e.target.value)} />
          <select value={country} onChange={(e) => setCountry(e.target.value)} className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950">
            <option value="US">United States</option>
            <option value="CA">Canada</option>
          </select>
          <p className="text-base-950 text-center text-xs">
            All fields are required. By clicking 'Register', you are indicating that you have read and agree to the{' '}
            <Link className="text-orange-500" to="/terms-of-service">
              Terms of Service
            </Link>{' '}
            and 
            <Link className="text-orange-500" to="/privacy-policy">
              Privacy Policy
            </Link>
            .
          </p>
          <button className="button-orange" children={submitting ? '...' : 'Register'} disabled={!name || !passwordValid || submitting} />
        </form>
        {error && <p className="text-rose-500 text-center">{error}</p>}
        <div>
          <p className="text-base-950 text-center pt-2">
            Already have an account?{' '}
            <Link className="text-orange-500" to="/auth/login">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default RegisterPrepPage
