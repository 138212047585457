import ordersApi from "api/orders";
import FullLoader from "components/loaders/FullLoader";
import { FC, useEffect, useState } from "react";
import { IOrderV0Update } from "types/orders";

interface props {
    orderId: string
    cols?: number
}

const OrderUpdates:FC<props> = ({ orderId, cols }) => {
    const [updates, setUpdates] = useState<IOrderV0Update[]>()

    useEffect(() => {
        if (!orderId) return
        ordersApi.getOrderUpdates(orderId).then(setUpdates)
    }, [orderId])
    
    return (
        <section className="grow min-w-[500px] bg-white box h-[245px] overflow-hidden" style={{gridColumn: cols ? `span ${cols} / span ${cols}` : cols}}>
            <header>Shipment Updates</header>
            <div className="flex flex-col grow overflow-y-auto">
                <div className="h-[185px] flex flex-col">
                {updates === undefined ? <FullLoader />: 
                    updates.length === 0 ? <div className="self-center mt-5">No updates yet</div> :
                    updates.map(update => (
                        <div key={update.id} className="flex flex-col items-start justify-start p-5">
                            <div className="text-sm">{update.text}</div>
                            <div className="text-xs text-gray-500">{(new Date(update.created_at)).toLocaleDateString()}</div>
                        </div>
                    ))
                }
                </div>
            </div>
        </section>
    )
}

export default OrderUpdates