export const apexItemServices = {
  OA: {
    OA_OVERSIZE: "Oversize",
    OA_BUNDLE: "Bundle",
  },
  Wholesale: {
    Wholesale_OVERSIZE: "Oversize",
    Wholesale_POLYBAG: "Polybag",
    Wholesale_BUBBLE_WRAP: 'Bubble Wrap',
    Wholesale_BUNDLE_2: "Bundle 2",
    Wholesale_BUNDLE_3_4: "Bundle 3-4",
    Wholesale_BUNDLE_5_8: "Bundle 5-8",
    Wholesale_BUNDLE_9_12: "Bundle 9-12",
  },
}

export const apexInboundOrderServices = {
  OA: {
  },
  Wholesale: {
    Wholesale_PALLET_RECEIVING: 'Pallet Handling',
  },
}

export const apexOutboundOrderServices = {
  OA: {
    'OA_ADDITIONAL_BUNDLE': 'Additional Bundle',
    OA_MEDIUM_BOX: 'Medium Box',
    OA_LARGE_BOX: 'Large Box',
  },
  Wholesale: {
    //Wholesale_ADDITIONAL_BUNDLE: 'Additional Bundle',
    Wholesale_SMALL_BOX: 'Small Box',
    Wholesale_MEDIUM_BOX: 'Medium Box',
    Wholesale_LARGE_BOX: 'Large Box',
    Wholesale_PALLET_HANDLING: 'Pallet Handling',
  },
}
